import { useEffect, useState } from 'react';
import { DataTable2, Skeleton, Button, Checkbox, IndexPointingUpEmoji, LoadingImed } from '@imed_npm/design-system';
import { UseStep } from '../../Store/step-context';
import Modal from '../../../Modal';
import AddCenter from './addCenter';
import AddSpecialties from './Specialities';
import './index.scss';
import styles from './style.module.scss';
import { LMECenter } from '../../../../utils/interfaces';
import { validFormat } from '../../../../utils/rut';
import { getBranches, getSpecialties } from '../../Store/actions';

const CenterInfo = (getType) => {
  const { state: { form, general, steps: { step3, step4: { addedCenters }, step2: { nid }, error } }, dispatch } = UseStep();

  // const { dispatch, state: { info, steps: { step3, step5 } } } = UseStep();

  const [finished, setFinished] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [allBranches, setAllBranches] = useState<LMECenter[]>([]);
  const [dataCenter, setDataCenter] = useState<LMECenter>();
  const [selectedCenter, setSelectedCenter] = useState<LMECenter>();

  const columns = [
    {
      Header: ' ',
      accessor: 'center_code',
      Cell: ({ row: { index, original } }: any) => {
        return (<Checkbox
          id={`checkbox-${index}`}
          isChecked={selectedCenter === original}
          label=""
          name="checkbox"
          onChange={() => setSelectedCenter(original)}
          value="0"
        />);
      },
      skeleton: <Skeleton customClass='w-[70%] h-4 pl-4 m-auto mt-1 mb-1' />
    },
    {
      Header: 'Nombre Lugar de atención',
      HeaderWidth: 'w-[31%]',
      accessor: 'name',
      Cell: ({ value }: any) => value || 'Sin nombre',
      skeleton: <Skeleton customClass='w-[70%] h-4 pl-4 m-auto mt-1 mb-1' />
    },
    {
      Header: 'Dirección',
      HeaderWidth: 'w-[31%]',
      accessor: 'address',
      Cell: ({ value, row }: any) => {
        return `${value}${row.original?.address_detail ? ', ' + row.original.address_detail : ''}` || 'Sin nombre';
      },
      skeleton: <Skeleton customClass='w-[70%] h-4 pl-4 m-auto mt-1 mb-1' />
    },
    {
      Header: 'Región',
      HeaderWidth: 'w-[31%]',
      accessor: 'region',
      Cell: ({ value }: any) => value || 'Sin nombre',
      skeleton: <Skeleton customClass='w-[70%] h-4 pl-4 m-auto mt-1 mb-1' />
    },
    {
      Header: 'Comuna',
      HeaderWidth: 'w-[31%]',
      accessor: 'locality',
      Cell: ({ value }: any) => value || 'Sin nombre',
      skeleton: <Skeleton customClass='w-[70%] h-4 pl-4 m-auto mt-1 mb-1' />
    }
  ];

  useEffect(() => {
    dispatch({ type: 'RESET_STEP', payload: { step: 'step6' } });
  }, []);

  useEffect(() => {
    const data = {
      nid
    };
    setShowLoading(true);
    getBranches({ dispatch, payload: data })
      .then<LMECenter[]>((centers) => [...centers, ...addedCenters])
      .then(setAllBranches).finally(() => setShowLoading(false))
      .catch(({ message }) => {
        dispatch({ type: 'SET_MODAL_ERROR', payload: message });
        setShowLoading(false);
      });
  }, []);

  const renderCheckingInfo = () => {
    return (
      <>
        {(showLoading) && (<LoadingImed text="Buscando Centros" />)}
      </>
    );
  };

  useEffect(() => {
    if (!dataCenter) return;

    setAllBranches([...allBranches, dataCenter]);
    setDataCenter(undefined);
    dispatch({ type: 'ADD_CENTER', payload: { center: dataCenter } });
  }, [dataCenter]);

  const renderNoResults = () => {
    return (
      <div className={styles.no_data_info}>
        <IndexPointingUpEmoji />
        <p>
          <strong>
            No se encontraron resultados.
          </strong>
        </p>
        <p>
          Vuelve a realizar una nueva búsqueda.
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (!selectedCenter) return;

    dispatch({ type: 'SELECT_CENTER', payload: { center: selectedCenter } });
    setFinished(true);
  }, [selectedCenter]);

  useEffect(() => {
    if (getType.type === 'EM' && form.personContractType === 'natural') {
      if (form.selectedSpecialties && form.selectedSpecialties.length > 0 && form.selectedSpecialties[0].amount !== 0) {
        dispatch({ type: 'SET_FINISHED_CENTER', payload: { finished } });
      }
    } else {
      dispatch({ type: 'SET_FINISHED_CENTER', payload: { finished } });
    }
  }, [finished, form]);

  useEffect(() => {
    if (!allBranches) return;
    if (allBranches.length === 1) {
      setSelectedCenter(allBranches[0]);
    }
  }, [allBranches]);

  useEffect(() => {
    if (getType.type !== 'EM') return;
    const validId = validFormat(nid);
    if (validId) {
      getSpecialties(dispatch, nid, 'company');
    }
  }, [nid, error]);

  useEffect(() => {
    dispatch({ type: 'CLEAR_ADDRESS' });
  }, []);

  return (
    <>
      {renderCheckingInfo()}
      <div className="flex justify-end">
        <Button buttonType='button' onClick={() => { setShowModal(true); }} type={(getType.type === 'EM' && form.personContractType === 'natural') ? 'primary' : 'secondary'}>
          Agregar nueva dirección
        </Button>
      </div>
      {allBranches.length ?
        <DataTable2
          customClass={`mt-10 selectrow-${selectedCenter}`}
          colorStyle='neutral'
          data={allBranches}
          columns={columns}
          sortBy='asc'
          orderBy='name'
          textButtonPlus='Agregar Programa'
          placeholderText='Realice una Búsqueda'
          disableHeader
          disablePagination
        />
        : renderNoResults()
      }
      {
        (form.personContractType === 'natural' && getType.type === 'EM') && <AddSpecialties info={form} />
      }
      <Modal title={'Nueva dirección'} showModal={showModal} setShowModal={setShowModal}>
        <AddCenter align="full" setShowModal={setShowModal} setFinished={setFinished} addCenter={setDataCenter} newShipping={general.shipping ? false : true} />
      </Modal>
    </>
  );
};

export default CenterInfo;
