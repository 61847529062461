/* eslint react/prop-types: 0 */
import React, { useEffect, useState, useContext } from 'react';

import { Button, Select, Input, Switch, LoadingImed, InfoIcon, TextArea, Label, XIcon } from '@imed_npm/design-system';
// import dummy from '../../dummy/data.json';
import { DateTime } from 'luxon';

import LoggedUserContext from '../../../../context/LoggedUserContext';
import DatePickerComponent from '../Charges/ChargesByDate/Components/DatePickerInputComponent';
import Modal from '../../../Modal';
import ToastMessage from '../../../../utils/ToastMessage';
import { postDiscount, getProducts, getAdminPlans, getColdData } from '../../utils/actions';
import { Product, GetPlansOptions } from '../../utils/interfaces';
import getEnv from '../../../../getEnv';
import styles from './style.module.scss';
import DiscountTable from './discountsTable';

const APP_ID = getEnv('REACT_APP_ID');
interface ItemType {
  id: number;
  description: string;
}

interface ItemUnity {
  id: number;
  name: string;
}

const AdminCampaign = (): JSX.Element => {
  const context = useContext(LoggedUserContext);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [cupon, setCupon] = useState('');
  const [monthAppy, setMonthsAplly] = useState<any>();
  const [amount, setAmount] = useState<any>();
  const [maxUsage, setMaxUsage] = useState<any>();
  const [status, setStatus] = useState(true);
  const [dataPlan, setDataPlan] = useState<any>();

  const todayFormatted = DateTime.local().toFormat('yyyy-MM-dd');
  const todayDate = DateTime.fromISO(DateTime.local().toISODate() || '');
  const yesterdayDate = DateTime.fromISO(DateTime.local().minus({ days: 1 }).toISODate() || '');
  const yesterdayFormatted = yesterdayDate.toFormat('yyyy-MM-dd');

  const [products, setProducts] = useState<Product[]>();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const [itemsType, setItemsType] = useState<ItemType[]>([]);
  const [itemsUnity, setItemsUnity] = useState<ItemUnity[]>([]);
  const [itemsTypeUsage, setItemsTypeUsage] = useState<ItemType[]>([]);

  const [type, setType] = useState<any>();
  const [typeUsage, setTypeUsage] = useState<any>();
  const [unity, setUnity] = useState<any>();
  const [plan, setPlan] = useState<any>();
  const [showModal, setShowModal] = useState(false);

  const [monthState, setMonthState] = useState([]);
  const [discountTypeState, setDiscountTypeState] = useState([]);
  const [discountUnitTypesState, setDiscountUnitTypesState] = useState([]);
  const [discountTypeUsageState, setDiscountTypeUsageState] = useState([]);

  const [showLoading, setShowLoading] = useState<boolean>(true);

  const [errorStartDate, setErrorStartDate] = useState('');
  const [errorEndDate, setErrorEndDate] = useState('');
  const [startDate, setStartDate] = useState(yesterdayFormatted);
  const [endDate, setEndDate] = useState(todayFormatted);

  const [update, setUpdate] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>();

  const handleUpdate = (value) => {
    setUpdate(value);
  };

  useEffect(() => {
    const timeout = setTimeout(async () => {
      const [initProducts] = await Promise.all(
        [
          getProducts(context),
        ]);
      setProducts(initProducts);
      setShowLoading(false);
    }, 250);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!context) return;
    if (sessionStorage.getItem('monthData') && sessionStorage.getItem('discountTypeData') && sessionStorage.getItem('discountUnitTypesData')) return;
    getColdData(context).then((data) => {
      setMonthState(data.month);
      setDiscountTypeState(data.discountType);
      setDiscountUnitTypesState(data.discountUnitTypes);
      setDiscountTypeUsageState(data.discountTypeUsage);
      sessionStorage.setItem('monthData', JSON.stringify(data.month));
      sessionStorage.setItem('discountTypeData', JSON.stringify(data.discountType));
      sessionStorage.setItem('discountUnitTypesData', JSON.stringify(data.discountUnitTypes));
      sessionStorage.setItem('discountTypeUsageData', JSON.stringify(data.discountTypeUsage));
    });
  }, [context]);

  useEffect(() => {
    if (!context) return;
    if (!selectedProduct) return;
    setShowLoading(true);
    const data: GetPlansOptions = {
      product: selectedProduct.name
    };
    // @ts-ignore
    getAdminPlans(context, data).then(setDataPlan).finally(() => setShowLoading(false));

  }, [context, selectedProduct, update]);

  useEffect(() => {
    const monthDataFromStorage = sessionStorage.getItem('monthData');
    const discountTypeData = sessionStorage.getItem('discountTypeData');
    const discountUnitTypesData = sessionStorage.getItem('discountUnitTypesData');
    const discountTypeUsageData = sessionStorage.getItem('discountTypeUsageData');
    if (monthDataFromStorage) {
      setMonthState(JSON.parse(monthDataFromStorage));
    }
    if (discountTypeData) {
      setDiscountTypeState(JSON.parse(discountTypeData));
    }
    if (discountUnitTypesData) {
      setDiscountUnitTypesState(JSON.parse(discountUnitTypesData));
    }
    if (discountTypeUsageData) {
      setDiscountTypeUsageState(JSON.parse(discountTypeUsageData));
    }

  }, []);

  useEffect(() => {
    if (discountTypeState) {
      setItemsType(discountTypeState);
    }
    if (discountUnitTypesState) {
      setItemsUnity(discountUnitTypesState);
    }
    if (discountTypeUsageState) {
      setItemsTypeUsage(discountTypeUsageState);
    }
  }, [monthState, discountTypeState, discountUnitTypesState]);

  useEffect(() => {
    if (!products) return;
    context.setUserData(prevUserData => ({
      ...prevUserData,
      products
    }));
  }, [products]);

  useEffect(() => {
    validateDates();
  }, [startDate, endDate]);

  const validateDates = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (!startDate || !endDate) {
      setValidationMessage('Por favor, selecciona ambas fechas.');
      return;
    }

    if (end < start) {
      setValidationMessage('La fecha de fin no puede ser menor que la fecha de inicio.');
    } else {
      setValidationMessage(undefined);
    }
  };

  const triggerPlanSearch = (select) => {
    setDataPlan(null);
    setSelectedProduct(select);
  };

  const renderCheckingInfo = () => {
    return (
      <>
        {(showLoading) && (<LoadingImed text="Cargando información" />)}
      </>
    );
  };

  const formatCoupon = (text) => {
    const formatText = text.replace(/\s/g, '').toUpperCase();
    return formatText;
  };

  const clear = () => {
    setName('');
    setDescription('');
    setCupon('');
    setMonthsAplly(null);
    setAmount(null);
    setStatus(true);
    setPlan(null);
    setUnity(null);
    setType(null);
    setTypeUsage(null);
    setStartDate(yesterdayFormatted);
    setEndDate(todayFormatted);
  };

  const create = () => {
    const [year, month] = startDate.split('-');
    const [y, m] = endDate.split('-');

    const formattedStartDate = `${year}${month}`;

    // if (!name || !type || !monthAppy || !selectedProduct) ToastMessage.enviarToastError('Campos faltantes');

    const data = {
      name: name,
      description: description,
      code: formatCoupon(cupon),
      start_date: new Date(startDate + 'T00:00:00.000Z').toISOString(),
      expiration_date: new Date(endDate + 'T00:00:00.000Z').toISOString(),
      start_period_application: +formattedStartDate,
      expiration_period_application: +(y + m),
      status: status ? 1 : 0,
      max_usage: 10000,
      product_id: selectedProduct?.id,
      // discount_type: type?.id,
      amount: +amount,
      months_application: +monthAppy,
      plan_id: +plan,
      unit_type_id: unity?.id,
      discount_type_usage_id: typeUsage?.id,
      discount_type_id: type?.id
    };
    // @ts-ignore
    postDiscount(context, data).then(() => { ToastMessage.enviarToastSuccess('Campaña creada correctamente'); }).finally(() => { clear(); setUpdate(!update); setShowModal(false); });
  };

  useEffect(() => {
    if (!showModal) clear();
  }, [showModal]);

  return (
    <>
      {renderCheckingInfo()}
      {showModal &&
        <Modal title={`Crear nueva campaña ${selectedProduct?.description ?? 'comercial'}`} modalClass={styles.modalWidth} containerClass='my-6' showModal={showModal} setShowModal={setShowModal} showCloseButton={true}>
          <div className='flex flex-col'>
            <Switch
              label="Campaña activada"
              id="status"
              className="pb-6"
              customSize="l"
              checked={status}
              onClick={() => setStatus(!status)}
            />
            <div className='flex items-start grow mt-4' id="dates">
              <div>
                <DatePickerComponent
                  title="Fecha de inicio"
                  calendarClassName={styles.calendar}
                  stateMessage={errorStartDate || ''}
                  stateType={errorStartDate ? 'error' : 'default'}
                  onChange={(e) => { console.log('e.value', e); setStartDate(e.target.value); }}
                  // value={startDate}
                  maxDate={''}
                  setFieldTouched={function (name: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined): void {
                    console.log('name');
                  }} />
              </div>

              <div className='pl-4'>
                <DatePickerComponent
                  title="Fecha de termino"
                  stateMessage={errorEndDate || ''}
                  stateType={errorEndDate ? 'error' : 'default'}
                  onChange={(e) => { setEndDate(e.target.value); }}
                  maxDate={''}
                  // value={endDate}
                  setFieldTouched={function (name: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined): void {
                    console.log('name');
                  }} />
              </div>
            </div>
            {
              validationMessage && <div className='mb-4 flex items-start'>
                <XIcon size="s" pathClass="stroke-error" />
                <Label
                  customClass="font-light ml-2"
                  htmlFor="dates"
                  title={validationMessage}
                />
              </div>
            }

            <Input customSize="l" name="name" id="name" title='Nombre de campaña'
              focused
              minLength={5} maxLength={100} placeholder='Ingrese nombre'
              onChange={({ target: { value } }) => { setName(value); }}
              stateMessage={name === '' ? '' : (name.length < 5 ? 'Nombre debe contener al menos 5 caracteres' : (name.length > 100 ? 'Nombre puede contener hasta 100 caracteres' : 'Nombre válido'))}
              stateType={name === '' ? 'default' : (name.length < 5 ? 'error' : (name.length > 100 ? 'error' : 'success'))}
            />

            <div className='pb-3 max-w-sm mt-4'>
              <TextArea
                minLength={5}
                maxLength={255}
                customSize="l"
                name="description"
                onChange={(e) => { setDescription(e.target.value); }}
                placeholder="Escribe una descripción"
                title="Descripción"
                stateMessage={description === '' ? '' : (description.length < 5 ? 'Descripción debe contener al menos 5 caracteres' : (description.length > 255 ? 'Descripción puede contener hasta 255 caracteres' : 'Descripción válida'))}
                stateType={description === '' ? 'default' : (description.length < 5 ? 'error' : (description.length > 255 ? 'error' : 'success'))}
              />
            </div>
            <Input
              customSize="l" name="cupon" id="cupon"
              minLength={1} maxLength={26}
              title='Código de Cupón' placeholder='Ingrese el codigo de cupón'
              onChange={({ target: { value } }) => { setCupon(value); }}
              // stateMessage={(cupon && cupon.length > 0) ? `Cupón se guardará sin espacios y en mayúsculas: ${formatCoupon(cupon)}` : ''}
              stateMessage={cupon === '' ? 'Cupón se guardará sin espacios y en mayúsculas' : (cupon.length < 1 ? 'Cupón debe contener al menos 1 caracter' : (cupon.length > 26 ? 'Cupón puede contener hasta 26 caracteres' : `Cupón válido - ${formatCoupon(cupon)}`))}
              stateType={cupon === '' ? 'info' : (cupon.length < 1 ? 'error' : (cupon.length > 26 ? 'error' : 'success'))}
            />

            {
              (dataPlan) && <div className="my-4">
                <Select
                  customClass={styles.z1000}
                  customSize="l"
                  // items={dataPlan}
                  items={dataPlan.filter(option => option.commercialized === 1)}
                  name="name"
                  nameType="name"
                  placeholder="Seleccione una opción"
                  onChange={(e) => setPlan(e.id)}
                  title="Plan"
                  id="plan"
                />
              </div>
            }
            <div className='mb-4'>
              <Select
                customClass={styles.z1000}
                customSize="l"
                items={itemsTypeUsage}
                name="description"
                nameType="description"
                placeholder="Seleccione una opción"
                onChange={(e) => setTypeUsage(e)}
                title="Tipo de campaña"
                id="type"
              />
            </div>
            <div className="flex items-end">
              <div className='mb-4 mr-6'>
                <Select
                  customClass={styles.z1000}
                  customSize="l"
                  items={itemsType}
                  name="description"
                  nameType="description"
                  placeholder="Seleccione una opción"
                  onChange={(e) => setType(e)}
                  title="Tipo de descuento"
                  id="type"
                />
              </div>
            </div>

            <div className="flex items-start">
              <div className='mt-1 mb-4 mr-6'>
                <Select
                  customClass={styles.z1000}
                  customSize="s"
                  items={itemsUnity}
                  name="name"
                  nameType="name"
                  placeholder="Seleccione una opción"
                  onChange={(e) => setUnity(e)}
                  title="Unidad de descuento"
                />
              </div>
              <div className='mb-4'>
                <Input customSize="s" name="amount"
                  minLength={1} maxLength={5} id="amount"
                  title='Monto de descuento' type="number"
                  placeholder='Ingrese el monto'
                  onChange={({ target: { value } }) => { setAmount(value); }}
                  stateMessage={!amount ? '' : (amount.length < 1 ? 'Monto debe contener al menos 1 caracter' : (amount.length > 5 ? 'Monto puede contener hasta 5 caracteres' : 'Monto válido'))}
                  stateType={!amount ? 'default' : (amount.length < 1 ? 'error' : (amount.length > 5 ? 'error' : 'success'))}
                />
              </div>
            </div>

            {/* <div className="mb-4">
              <Input customSize="l" name="max" id="max_usage" title='Máximo de beneficiarios' type="number" placeholder='Ingrese el máximo de uso' onChange={({ target: { value } }) => { setMaxUsage(value); }} />
            </div> */}

            <div className="mb-4">
              <Input customSize="l" name="month" minLength={1} maxLength={5}
                id="months_application" type="number" title='Meses de duración de descuento'
                placeholder='Ingrese el tiempo de duración' onChange={({ target: { value } }) => { setMonthsAplly(value); }}
                stateMessage={!monthAppy ? '' : (monthAppy.length < 1 ? 'Campo debe contener al menos 1 caracter' : (monthAppy.length > 5 ? 'Campo puede contener hasta 5 caracteres' : ((Number.isInteger(+monthAppy) ? 'Campo válido' : 'Valor debe ser n° entero'))))}
                stateType={!monthAppy ? 'default' : (monthAppy.length < 1 ? 'error' : (monthAppy.length > 5 ? 'error' : (Number.isInteger(+monthAppy) ? 'success' : 'error')))}
              />
            </div>
            <div className='flex justify-end'>
              <Button
                size="m"
                type='primary'
                buttonType='button'
                id="createButton"
                onClick={create}
                disabled={(name.length < 5 || name.length > 100) || (description.length < 5 || description.length > 255) || !startDate || !endDate || validationMessage || (cupon.length < 1 || cupon.length > 26) || (!amount || amount.length > 5) || !plan || !type || !typeUsage || !unity || (!monthAppy || monthAppy.length > 5 || !Number.isInteger(+monthAppy))}
              >Crear campaña</Button>
            </div>
          </div>

        </Modal>}
      <div className='w-full'>
        {/* tabs */}
        <div className="bg-white p-6">
          <h1 className="text-secondary-imed-60 text-title tracking-[0.1px] font-normal my-4 mt-0">Administrador de campañas</h1>
          <p className={`${styles.subtitle} pb-5`}>Seleccione el producto y administre la opción seleccionada</p>
          <hr />
          <div className="mt-5">
            <div className='flex justify-between items-center'>
              <Select
                customClass={`${styles.z0} mb-4`}
                customSize="l"
                items={products}
                name="selectName"
                nameType="description"
                onChange={(e) => triggerPlanSearch(e)}
                id="product"
                placeholder="Seleccione un producto"
                title="Producto"
              />
              {
                selectedProduct &&
                <Button className='ml-2' size="m" type='primary' id="modal_create" buttonType='button' onClick={() => setShowModal(true)}>Crear nueva campaña</Button>
              }
            </div>
          </div>
          {
            (itemsType && dataPlan && !showLoading) ?
              <DiscountTable type={selectedProduct} dataPlan={dataPlan} itemsType={itemsType} itemsUnity={itemsUnity} itemsTypeUsage={itemsTypeUsage} setUpdate={handleUpdate} update={update} /> : <div className='w-full border flex flex-col p-20 justify-center items-center'>
                <InfoIcon size="m" />
                <p className='mt-2'>Todavía no selecciona un producto para administrar</p>
              </div>
          }
        </div>
      </div>
    </>);
};


export default AdminCampaign;
