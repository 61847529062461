import { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Select, Input, Breadcrumb, PlusIcon, UserHelp, IndexPointingUpEmoji, LoadingImed } from '@imed_npm/design-system';
import { getInfoNewDoc, getSpecialtiesDoc } from '../Form/Store/actions';
import { formatRut, validFormat } from '../../utils/rut';
import LoggedUserContext from '../../context/LoggedUserContext';
import Modal from '../Modal';
import styles from './style.module.scss';
import { Product } from '../Admin/utils/interfaces';
import { getProducts } from '../Admin/utils/actions';
import { getAdscriptionProfessionals, createAdscriptionProfessionals, editAdscriptionProfessionals, enableAdscriptionProfessionals, getAuthToken, verifyEmToken, getRetokenizationInfo } from './utils/actions';
import SubscriptionInfo from './Components/SubscriptionInfo';
import SubscriptionProfessionals from './Components/SubscriptionProfessionals';
import LoadingComponent from '../Form/Components/LoadingComponent/LoadingComponent';
import SpecialtyRow from '../Form/Components/CenterInfo/Specialities/newRow';
import image from './assets/modal.png';
import { EUsersSubscriptionAdmin, IResponseAdscriptionProf, IResponseAdscriptionProfAdscription, IResponseAdscriptionProfAdscriptionLegalPerson, IResponseAdscriptionProfCenter, IResponseHomologateMedic, IRolesUsersSubscriptionAdmin, TRolesUsersSubscriptionAdmin, TSpecialtiesSubscriptionAdmin } from './interfaces/subscriptionAdmin.interface';
import { formatDotsRut } from '@imed_npm/valida-rut';
import { ETypeOfActionToPerson, ETypeOfActionToProfessional } from './utils/interfaces';
import { first, random, set } from 'lodash';
import PaymentMP from './Components/MercadoPago/PaymentMP';


const RetokenizationAdmin = () => {
  const params = useParams();
  const token = params.token as string;
  const context = useContext(LoggedUserContext);

  console.log('token',params.token);
  const [tokenAdscription, setTokenAdscription] = useState<string>();

  const [dataAdscription, setDataAdscription] = useState<any>();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingParent, setLoadingParent] = useState<boolean>(false);

  const [isExternalLogin, setIsExternalLogin] = useState<boolean>();



  useEffect(() => {
    const timer = setTimeout(() => {
      if (location.pathname.includes('external')) {
        //console.log('token1',token);
        setIsExternalLogin(true);

        getRetokenizationInfo(token).then(
          (info) => {
            console.log('info', info);
            if (info.token) {
              window.sessionStorage.setItem('flowkey', info.token);
            }
            setTokenAdscription(info.token);
            setDataAdscription(info.adscription);
          }
        ).catch(
          (err) => {
            console.debug('[LoginExternal] - error getRetokenizationInfo:', err);
          }
        ).finally( () => {
            setLoading(false);
        }
        );
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [token, loadingParent, loading]);


  return (
    <div className="bg-white p-6">
      {
        !loading &&
        <>
          <div className="mt-5">
            <div className='flex justify-between items-center'>
            </div>
          </div>
        </>
      }

      {
        !loading && dataAdscription && (
          <>
            <div className=''>
                <PaymentMP 
                    nid={dataAdscription.legal_persons? dataAdscription.legal_persons.nid : dataAdscription.professionals.nid}
                    email={dataAdscription.legal_persons? dataAdscription.legal_persons.email : dataAdscription.professionals.email}
                    adscriptionId={dataAdscription.id}
                    billingInfo={dataAdscription.billing_info}
                />
            </div>
          </>
        )
      }

    </div>);
};


export default RetokenizationAdmin;
