
import styles from './style.module.scss';
import icon from './assets/icon.svg';
import { Button } from '@imed_npm/design-system';

const DocumentSigned = ({ type }: any) => {

  const close = () => {
    window.location.href = '/';
  };

  const getText = (e) => {
    // (type.toLowerCase() === 'lme') ? 'licencia medica electrónica' : 'Mimed - Escritorio médico'
    if (e === 'lme') {
      return 'licencia médica electrónica';
    } else if (e === 'be') {
      return 'bono electrónico';
    } else if (e === 'em') {
      return 'Mimed - Escritorio médico';
    } else {
      return 'este producto Imed';
    }
  };

  return (
    <div className={styles.documents_signed}>
      <img src={icon} alt={'imagen'} />
      <h2 className={styles.title}>
        El proceso de suscripción se ha completado.
      </h2><br /><br />
      Hemos procesado la solicitud, estamos revisando la información.<br /><br />
      En caso de tener alguna inconsistencia nos comunicaremos contigo al número de celular o al email indicado<br /><br />
      Cuando el proceso de revisión finalice correctamente, te enviaremos un email para que agendes la hora de instalación del lector biómetrico que te enviaremos, esto es importante para terminar la instalación y poder usar {getText(type.toLowerCase())}.
      <div className={styles.button_close}>
        <Button buttonType='button' type="secondary" onClick={() => { close(); }}>
          Ok! entendido
        </Button>
      </div>
    </div>
  );
};


export default DocumentSigned;
