import { useState } from 'react';
import { initMercadoPago, Payment } from '@mercadopago/sdk-react';
import { UseStep } from '../../../Store/step-context';
import { AllOrArray, IBrickVisual, MpModalProps } from './enum/mpModal.interface';
import { EStatusEnrolmentCard } from './enum/statusEnrolmentCard.enum';
import { LoadingImed } from '@imed_npm/design-system';
import getEnv from '../../../../../getEnv';

function PaymentModal({ setShowInputCardMPModal, setStatusCardMPModal, professional, showLoader, billingInfo, adscriptionId }: MpModalProps): JSX.Element {
  const { dispatch } = UseStep();

  const [IsLoadingSearch, setIsLoadingSearch] = useState(showLoader);
  const { nid, email } = professional;
  const baseURLBackend = getEnv('REACT_APP_BASE_URL');

  const tokenMP = process.env.REACT_APP_MERCADOPAGO_ACCESS_TOKEN ?? getEnv('REACT_APP_MERCADOPAGO_ACCESS_TOKEN');
  initMercadoPago(`${tokenMP}`);
  const initialization = {
    amount: 1000,
    payer: {
      email
    }
  };
  // const customization = {
  //   paymentMethods: {
  //     creditCard: 'all' as AllOrArray,
  //     debitCard: 'all' as AllOrArray
  //   },
  //   visual: {
  //     hideFormTitle: true,
  //     style: {
  //       theme: 'flat', // | 'dark' | 'bootstrap' | 'flat'
  //     } as IBrickVisual
  //   }
  // };

    const customization = {
      paymentMethods: {
        creditCard: 'all' as AllOrArray,
        debitCard: 'all' as AllOrArray
      },
      visual: {
        hideFormTitle: true,
        texts: {
          formSubmit: 'Continuar'
        },
        style: {
          theme: 'flat', // | 'dark' | 'bootstrap' | 'flat'
        } as IBrickVisual
      },
    } as any;
  const onSubmit = async (
    { selectedPaymentMethod, formData }
  ) => {
    console.log(selectedPaymentMethod);
    console.log(formData);
    console.log(billingInfo);
    const billing_info = {
      nid: billingInfo && billingInfo['nid'] ? billingInfo['nid'] : '',
      social: billingInfo && billingInfo['name'] ? billingInfo['name'] : '',
      address: billingInfo && billingInfo['address'] ? billingInfo && billingInfo['address'] : '',
      address_detail: billingInfo && billingInfo['address_detail'] ? billingInfo && billingInfo['address_detail'] : '',
      locality_id: parseInt(billingInfo && billingInfo['commune']['id'], 10),
      activity_code: '',
      classification: '',
      email: billingInfo && billingInfo['email'] ? billingInfo['email'] : '',
    };
    // callback llamado al hacer clic en el botón enviar datos
    formData.nid = nid;
    return new Promise((resolve, reject) => {
      fetch(`${baseURLBackend}professional/store-verification-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ formData: formData, billing_info: billing_info, adscription_id: adscriptionId }),
      })
        .then((response) => {
          if (!response.ok) {
            setShowInputCardMPModal(false);
            setStatusCardMPModal(EStatusEnrolmentCard.REJECT);
            throw new Error(`Error HTTP: ${response.status}`);
          }
          return response.json();
        })
        .then((response) => {
          console.debug('TODO BIEN!!', response);

          setShowInputCardMPModal(false);
          setStatusCardMPModal(EStatusEnrolmentCard.SUCCESS);
          dispatch({ type: 'VALIDATION_MERCADOPAGO', payload: true });

          // recibir el resultado del pago
          resolve(true);
        })
        .catch((error) => {
          console.debug('Problemas!!', error);
          setStatusCardMPModal(EStatusEnrolmentCard.ERROR);
          setShowInputCardMPModal(false);
          dispatch({ type: 'VALIDATION_MERCADOPAGO', payload: false });
          // manejar la respuesta de error al intentar crear el pago
          reject();
        });
    });
  };
  const onError = async (error): Promise<void> => {
    // callback llamado para todos los casos de error de Brick
    console.error('Que es esto', error);
    return new Promise((resolve) => {
      setStatusCardMPModal(EStatusEnrolmentCard.ERROR);
      setShowInputCardMPModal(false);
      resolve();
    });
  };
  const onReady = async (): Promise<void> => {
    /*
      Callback llamado cuando el Brick está listo.
      Aquí puede ocultar cargamentos de su sitio, por ejemplo.
    */
    return new Promise((resolve) => {
      setStatusCardMPModal(EStatusEnrolmentCard.PENDING);
      setIsLoadingSearch(false);
      resolve();
    });
  };



  return (
    <>
      {IsLoadingSearch && (<LoadingImed text="Cargando Medio de Pago" />)}
      <Payment
        initialization={initialization}
        customization={customization}
        locale='es-CL'
        onSubmit={onSubmit}
        onReady={onReady}
        onError={onError}
      />
    </>
  );
}

export default PaymentModal;
