import { toast } from 'react-toastify';
import {
  AlertTriangleIcon,
  CheckCircleIcon,
  InfoIcon,
  XIcon,
  XCircleIcon
} from '@imed_npm/design-system';

const ToastMessage = {
  enviarToastError: function (mensaje, options) {
    if (!mensaje) return;
    toast.error(mensaje, {
      position: 'top-right',
      autoClose: true,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: mensaje,
      icon: <XCircleIcon size="l" pathClass="stroke-error-dark" />,
      closeButton: <CloseButton />,
      progressClassName: '!bg-error-dark',
      ...options
    });
  },

  enviarToastSuccess: function (mensaje) {
    if (!mensaje) return;
    toast.success(mensaje, {
      autoClose: true,
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: mensaje,
      icon: <CheckCircleIcon size="l" pathClass="stroke-success-dark" />,
      closeButton: <CloseButton />,
      progressClassName: '!bg-success-dark',
    });
  },

  enviarToastWarning: function (mensaje) {
    if (!mensaje) return;
    toast.warn(mensaje, {
      autoClose: true,
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: mensaje,
      icon: <AlertTriangleIcon size="l" pathClass="stroke-warning-dark" />,
      closeButton: <CloseButton />,
      progressClassName: '!bg-warning-dark'
    });
  },

  enviarToastInfo: function (mensaje) {
    if (!mensaje) return;
    toast.info(mensaje, {
      autoClose: true,
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: mensaje,
      icon: <InfoIcon size="l" pathClass="stroke-info-dark" />,
      closeButton: <CloseButton />,
      progressClassName: '!bg-info-dark'
    });
  },
};


const CloseButton = () => {
  return (
    <div className="toast-close-button">
      <XIcon size="s" pathClass="stroke-neutral-50" />
    </div>
  );
};

export default ToastMessage;
