import React, { useState, useEffect } from 'react';
import Header from '../../Components/Header';
import Card from '../../Components/Card';
import styles from './style.module.scss';
import icon from './assets/icon.svg';
import bono from './assets/bono.svg';
import mimed from './assets/mimed.svg';
import lme from './assets/lme.svg';
import getEnv from '../../getEnv';

const Home = () => {

  const [isTest, setIsTest] = useState(false);

  useEffect(() => {
    const baseBack = getEnv('REACT_APP_BASE_URL');
    const testDeploy = (
      baseBack === 'https://suscripcion-backend-v2.cl-qa.imedlatam.com/' ||
      baseBack === 'https://suscripcion-backend-v2.cl-pre.imedlatam.com/' ||
      baseBack === 'http://192.168.100.73:3050/'
    );
    if (testDeploy) {
      setIsTest(true);
    } else {
      setIsTest(false);
    }
  }, []);

  return (
    <>
      <Header
        subtitle="Seleccione el producto que desea contratar"
        title="Bienvenido"
      />
      <div className={`grid gap-5 md:grid-cols-2 my-10 ${styles.container_card}`}>
        <Card
          icon={icon}
          linkPath="/formulario-lme"
          linkText="Comenzar"
          description="Es una plataforma que permite conectar a las entidades de salud, médicos, pacientes y empleadores."
          title="Licencia Médica"
        />
        <Card
          icon={bono}
          linkPath={isTest ? '/formulario-be' : 'https://imed.cl/bono-electronico'}
          linkText="Comenzar"
          description="Te conectamos con Fonasa, isapres y aseguradoras, para valorizar y autorizar en línea el copago de tus pacientes cuando paguen su atención."
          title="Bono electrónico"
          external={!isTest}
        />
        <Card
          icon={mimed}
          linkPath="/formulario-em"
          linkText="Comenzar"
          description="Una plataforma creada para médicos independientes que cuenta con todos los servicios que necesitas para atender a tus pacientes de forma presencial y a distancia"
          title="mimed escritorio médico"
        />
        <Card
          icon={lme}
          linkPath="https://info.imed.cl/landing-contratacion-bono-licencia"
          linkText="Comenzar"
          description="Bono electrónico y licencia médica imed te conectan en linea con todos los participantes para automatizar ambos procesos"
          title="Licencia Médica & Bono Electrónico"
          external
        />
      </div>
    </>);
};


export default Home;
