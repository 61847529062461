import { useEffect, useState } from 'react';
import { Input, InputPhone, UserHelp, RocketEmoji, Button, LoadingImed } from '@imed_npm/design-system';
import styles from './style.module.scss';
import { UseStep } from '../../Store/step-context';
import { checkVerificationCode, sendVerificationCode, storeCI, storeProfessional } from '../../Store/actions';
import { SendVerificationEmailData, StoreCIData, StoreProfessionalData, VerificationCodeCheck } from '../../../../utils/interfaces';
import { validateEmail } from '../../../../utils/email';
import { RADIO_OPTION } from '../ProfileSelection/constants';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import TextInputPhoneComponent from '../InputPhone';

interface OwnProps {
  type: string;
}
const ContactInformation = ({ type }: OwnProps) => {
  const _timer = 210;
  const { dispatch, state: { info, adscription_id: adscriptionId , form: { personContractType }, steps: { step2: { nid, frontCode, backCode }, step22, step3 } } } = UseStep();
  const [email, setEmail] = useState<string>(step3.email);
  const [emailRepeated, setEmailRepeated] = useState<string>(step3.emailRepeated);
  const [phoneCode, setPhoneCode] = useState<string>(step3.phone_country_code);
  const [phone, setPhone] = useState<string>(step3.phone);
  const [verificationCode, setVerificationCode] = useState<string>(step3.emailVerificationCode);
  const [sameMail, setSameMail] = useState<boolean>(false);
  const [canStoreExtraInfo, setCanStoreExtraInfo] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);

  useEffect(() => {
    resetValidations();

    setSameMail(email == emailRepeated && !!emailRepeated);
  }, [email, emailRepeated]);

  useEffect(() => {
    if (canStoreExtraInfo)
      storeCIInfo();
  }, [canStoreExtraInfo && step3.stored.professional.validated]);

  useEffect(() => {
    const { stored: { ci: { back, front } }, emailVerificated } = step3;
    if (back && front && !emailVerificated)
      sendValidationEmail();
  }, [canStoreExtraInfo && step3.stored.ci.back && step3.stored.ci.front]);

  useEffect(() => {
    setCanStoreExtraInfo(sameMail && !!phone);

    const timeout = setTimeout(() => {
      if (sameMail && phone.length == 9) {
        resetValidations();
        storeProfessionalInfo();
      }
    }, 750);
    return () => clearTimeout(timeout);
  }, [sameMail && phone, phoneCode]);

  useEffect(() => {
    const timeout = setTimeout(verificateEmail, 750);
    return () => clearTimeout(timeout);
  }, [verificationCode]);

  useEffect(() => {
    if (timer) {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
  }, [timer]);

  const verificateEmail = () => {
    if (!verificationCode || verificationCode.length < 6) return;

    const payload: VerificationCodeCheck = {
      code: verificationCode,
      nid
    };
    checkVerificationCode({ dispatch, payload });
  };

  const storeProfessionalInfo = async () => {
    const { nombres, apellido_paterno, apellido_materno, fecha_nacimiento, universidad, codigo_busqueda, sexo } = info;
    const data: StoreProfessionalData = {
      adscription_id: adscriptionId,
      forenames: nombres,
      email,
      nid,
      phone_country_code: phoneCode,
      phone,
      surnames: apellido_paterno || apellido_materno ? `${apellido_paterno} ${apellido_materno}`.trim() : '',
      person_types: personContractType,
    };
    if (fecha_nacimiento) {
      data.birthday = fecha_nacimiento.split('/').reverse().join('-');
    }
    if (universidad) {
      data.study = universidad ?? null;
    }
    if (codigo_busqueda) {
      data.title = codigo_busqueda ?? null;
    }
    if (sexo) {
      data.gender = sexo ? ('Masculino' === sexo ? 1 : ('Femenino' === sexo ? 2 : 3)) : 3;
    }
    storeProfessional(dispatch, data);
  };

  const storeCIInfo = async () => {
    const { professional } = step3.validations;
    if (!professional.success) return;

    const data: StoreCIData = {
      backCode: (personContractType == RADIO_OPTION.nature.value && type !== 'BE' ? backCode : step22.backCode).split('base64,')[1],
      frontCode: (personContractType == RADIO_OPTION.nature.value && type !== 'BE' ? frontCode : step22.frontCode).split('base64,')[1],
      nid
    };
    storeCI(dispatch, data);
  };

  const sendValidationEmail = async () => {
    const { ci } = step3.validations;
    if (!ci.success) return;
    const payload: SendVerificationEmailData = {
      nid
    };

    sendVerificationCode({ dispatch, payload }).finally(() => setTimer(_timer));
  };

  const resetValidations = () => {
    dispatch({ type: 'RESET_STEP', payload: { step: 'step3' } });
  };

  const renderCheckingInfo = () => {
    const { email, professional, ci, verificationCode } = step3.validations;
    if (email.pending || professional.pending || ci.pending)
      return (
        <>
          {(professional.pending) && (<LoadingImed text="Registrando Prestador" />)}
          {(professional.success && ci.pending) && (<LoadingImed text="Registrando información adicional..." />)}
          {(ci.success && email.pending) && (<LoadingImed text="Enviando correo de validación..." />)}
          {(email.success && verificationCode.pending) && <LoadingImed text="Verificando código ingresado.." />}
        </>
      );
  };

  const storePhoneNumber = (phone: string, data: string): void => {

    if (data) {
      const phoneNumberObj = parsePhoneNumberFromString(data);
      if (phoneNumberObj) {
        const countryCode = `+${phoneNumberObj.countryCallingCode}`;
        const nationalNumber = phoneNumberObj.nationalNumber;
        setPhoneCode(countryCode); // Código de país
        setPhone(nationalNumber); // Número de teléfono sin código de país
      }
    }
  };

  const emailStateMessage = (emailToCheck?: string): { type: 'error' | 'success' | 'info' | 'warning' | 'default'; message: string; } => {
    const _email = emailToCheck ?? email;

    if (_email) {
      if (emailToCheck) {
        if (sameMail) return { type: 'success', message: 'Correo coincide' };
        else return { type: 'error', message: 'Correo no coincide' };
      }

      const valid = validateEmail(_email);
      const { validations: { professional }, stored: { professional: { error, validated } } } = step3;

      if (professional.error && !validated) return { type: 'error', message: error };

      if (!valid) return { type: 'error', message: 'Formato inválido' };
      else return { type: 'success', message: 'Formato válido' };
    }

    return { type: 'default', message: '' };
  };
  const phoneStateMessage = (): { type: 'error' | 'success' | 'info' | 'warning' | 'default'; message: string; } => {
    if (phone) {
      const { length } = phone;

      if (length === 9) return { type: 'success', message: 'Número de teléfono válido' };
      else return { type: 'error', message: 'Debe tener 9 dígitos' };
    }

    return { type: 'default', message: '' };
  };

  const codeStateMessage = (): { type: 'error' | 'success' | 'info' | 'warning' | 'default'; message: string; } => {
    if (verificationCode) {
      const { validations: { verificationCode: { error, success } }, emailVerificated } = step3;

      if (verificationCode.length < 6) return { type: 'error', message: 'Largo mínimo de 6' };
      else if (emailVerificated) return { type: 'success', message: 'Correo verificado' };
      else if ((success && !emailVerificated) || error) return { type: 'error', message: 'Código incorrecto' };
    }
    return { type: 'default', message: '' };
  };

  const printTimer = () => {
    const minutes = Math.trunc(timer / 60);
    const seconds = timer % 60;

    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const renderCountMailSection = (): JSX.Element => {
    const { validations } = step3;
    if (validations.email.success) {
      return (<div className={styles.user_help}>
        <UserHelp
          button={<Button buttonType='button' className="!min-w-[145px] mr-4 h-10"
            onClick={sendValidationEmail}
            size="s"
            disabled={step3.validations.verificationCode.pending || timer > 0}
            type="secondary">Reenviar código</Button>}
          colorStyle="green"
          icon={<RocketEmoji svgClass="h-47 w-37" />}
          text={<div style={{ fontSize: '14px' }}>
            Hemos enviado un correo electrónico a <strong>{email}</strong> con el código de
            verificación, recuerda que tienes <strong>{printTimer()}</strong> para ingresarlo correctamente.
          </div>}
        />
      </div>

      );
    }
    return (<></>);
  };

  const renderCheckMailSection = (): JSX.Element => {
    const { validations } = step3;
    if (validations.email.success) {
      return (
        <div>
          <Input
            focused
            name="name"
            customSize="m"
            value={verificationCode}
            onChange={({ target: { value } }) => setVerificationCode(value)}
            placeholder="Ingresa código"
            title="Código de verificación"
            type="text"
            stateMessage={codeStateMessage().message}
            stateType={codeStateMessage().type}
          />
        </div>

      );
    }
    return (<></>);
  };

  return (
    <div className={styles.contact_information}>
      <div className={styles.contact_information_container}>
        <div className={styles.input_group}>
          <Input
            value={email}
            focused
            customSize='m'
            name="mail"
            onChange={({ target: { value } }) => setEmail(value.trim().toLowerCase())}
            placeholder="Ingresa correo electrónico"
            title="Correo Electrónico"
            stateMessage={emailStateMessage().message}
            stateType={emailStateMessage().type}
          />
        </div>
        <div className={styles.input_group}>
          <Input
            value={emailRepeated}
            name="mail2"
            customSize='m'
            onChange={({ target: { value } }) => setEmailRepeated(value.trim().toLowerCase())}
            placeholder="Ingresa correo electrónico"
            title="Repetir correo electrónico"
            type="text"
            stateMessage={emailStateMessage(emailRepeated).message}
            stateType={emailStateMessage(emailRepeated).type}
          />
        </div>
        <div className={styles.input_group}>
          <TextInputPhoneComponent
            defaultCountry='CL'
            label="Celular"
            maxLength={12}
            name="telephoneNumber"
            // onChange={(e) => console.log(e)}
            onChange={({ target: { value }, nativeEvent: { data } }) => storePhoneNumber(value, data)}
            placeholder="950052059"
            setFieldValue={storePhoneNumber}
            value={phone}
          />
        </div>
      </div>
      {renderCountMailSection()}
      {renderCheckMailSection()}
      {renderCheckingInfo()}
    </div>
  );
};


export default ContactInformation;
