export const RADIO_OPTION = {
  nature: {
    id:'natural-person',
    name: 'person-contract-type',
    label: 'Persona Natural',
    value: 'natural'
  },
  legal:{
    id:'legal-person', 
    name: 'person-contract-type',
    label: 'Persona Jurídica',
    value: 'legal'
  }
};

export const DEFAULT_OPTION = RADIO_OPTION.nature.value;
