import React, { useState, useEffect, useContext, ChangeEvent } from 'react';
import { createBrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Body, Footer, version as ds_version } from '@imed_npm/design-system';
import packageInfo from '../../package.json';
import Logo from './logo.png';
import './style.css';
import HeaderExtendedComponent from '../Components/HeaderExtendedComponent/HeaderExtendedComponent';
import routes from '../Routes';
const router = createBrowserRouter(routes);
import styles from './style.module.scss';

const Layout = ({ children }: { children?: JSX.Element }) => {
  const location = (window.location);
  const newLocation = useLocation();
  const [name, setName] = useState<string>('Imed');

  const renderHeader = () => {
    if (location.pathname.includes('backoffice')) {
      return <HeaderExtendedComponent logo={Logo} />;
    } else if (location.pathname.includes('external')) {
      return '';
    } else {
      return <header className={'header-main'}>
        <a href="/" className={'header-logo'}>
          <img src={Logo} alt="logo-imed" />
        </a>
      </header>;
    }
  };

  const renderFooter = () => {
    if (location.pathname.includes('external')) {
      return '';
    } else {
      return <Footer country="chile" version={`${packageInfo.version} - ${ds_version}`} />;
    }
  };

  useEffect(() => {
    if (newLocation.pathname === '/formulario-em') {
      setName('Escritorio Médico');
    } else if (newLocation.pathname === '/formulario-lme') {
      setName('Licencia médica');
    } else if (newLocation.pathname === '/formulario-be') {
      setName('Bono electrónico');
    } else {
      setName('Imed');
    }
  }, [newLocation]);

  return (
    <>
      {renderHeader()}
      {
        // (location.pathname.includes('backoffice')) ? <div className="mt-6 mb-20 py-5"> {children}</div> : <div className="mt-6 mb-20 py-5">
        <div className={`${location.pathname.includes('external') ? 'mb-20 pb-5' : 'mt-6 mb-20 py-5'}`}>
          <Routes>
            {routes.map(({ path, component: Component, type }) => (
              <Route index={true} path={path} key={path} element={<Body className={`${location.pathname.includes('external') ? styles.body : location.pathname.includes('formulario') && styles.bodySuscribe}`} customMaxWidth={location.pathname.includes('backoffice') ? 'max-w-[1500px] ' : 'max-w-[1200px] '}>
                {/* <Route index={true} path={path} key={path} element={<Body className={`${location.pathname.includes('external') ? styles.body : location.pathname.includes('formulario') && styles.bodySuscribe}`} customMaxWidth={'max-w-[1200px] '}> */}
                {
                  location.pathname.includes('formulario') && <div className={`${styles.name} px-10 py-6 mb-10`}>Suscripción a <strong>{name}</strong></div>
                }
                <div className={`${location.pathname.includes('formulario') ? 'px-14' : 'px-0'}`}>
                  <Component type={type} />
                </div>
              </Body>} />
            ))}
            <Route path='/*' element={<Navigate to={routes[0].path} replace />} />
          </Routes>
        </div>
      }
      {renderFooter()}
    </>
  );
};

export default Layout;

