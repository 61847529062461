import { Professionals } from '../../../../interfaces/professional';
import { Services } from '../../../../interfaces/services';
import { Button, CheckMarkEmoji, ClockEmoji, UserHelp, FearfulFaceEmoji, WarningEmoji, DataTable2, Skeleton } from '@imed_npm/design-system';

import { useState, useEffect } from 'react';
import { activateService } from '../../../../utils/actions';
import { ServiceActivationResultError } from '../../Interfaces/responses';
import styles from './styles.module.scss';
import getEnv from '../../../../../../getEnv';
import { EServices } from '../../../../../../enum/services.enum';
import { Product } from '../../../../utils/interfaces';
import Pagination from 'rc-pagination';
import './pagination.scss';
import ServiceItem from '../ServiceItem';
import { EPersonType } from '../../../../../../enum/professional.enum';

interface OwnProps {
  professional: Professionals;
  services: Services[];
  selectedProduct: Product | undefined;
  context: any;
  updateServices: (service: Services) => void;
  setRefresh?: any;
  caseNumber: number;
}


const ServiceActivationLegalPerson = ({ professional, services, selectedProduct, context, updateServices, setRefresh, caseNumber }: OwnProps) => {

  const legalPerson = professional.legals.length > 0 ? [professional.legals[0]] : [];
  const personsToActivateData = legalPerson.concat(professional.professionals);
  const [personsToActivate, setPersonsToActivate] = useState<{ forenames: string; surnames: string | null; nid: string; person_type: number; id: number; }[]>(personsToActivateData);
  //console.log('personsToActivate',personsToActivate);
  const [currentPageData, setCurrentPageData] = useState<{ forenames: string; surnames: string | null; nid: string; person_type: number; id: number; }[]>([]);
  const [pageSize, setPageSize] = useState(1);

  const [current, setCurrent] = useState(1);
  const [currentServices, setCurrentServices] = useState<Services[]>([]);
  const [isAnyButtonLoading, setIsAnyButtonLoading] = useState(false);

  const onChange = (page) => {
    setCurrent(page);
  };
  useEffect(() => {
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentPerson = personsToActivate.slice(startIndex, endIndex);
    setCurrentPageData(currentPerson);
    const currentServices = currentPerson[0].person_type === EPersonType.JURIDICA ? services.filter(serv => serv.client_id === currentPerson[0].id) : services.filter(serv => serv.client_id === currentPerson[0].id);
    // console.log('currentPerson', currentPerson);
    // console.log('currentServices', currentServices);
    // console.log('professional.professional_services', professional.professional_services);

    setRefresh(true);
    setCurrentServices(currentServices.filter(service => service.service_place === caseNumber));
    // setCurrentServices(currentServices);
  }, [current, services, pageSize]);
  
  const renderServiceItem = (service: Services) => (
    <ServiceItem
      professional={professional}
      service={service}
      selectedProduct={selectedProduct}
      context={context}
      updateServices={updateServices}
      isAnyButtonLoading={isAnyButtonLoading}
      setIsAnyButtonLoading={setIsAnyButtonLoading}
    />
  );
  return (
    <div className={styles.container}>
      <div>
        {currentPageData.map((item) => (
          <div key={item.nid}>
            { /*JSON.stringify(item)*/}
            <div className='mb-3'>
              <strong>{item.person_type === EPersonType.JURIDICA ? 'Entidad' : 'Persona'}:</strong> {item.nid}
            </div>
            <div className='mb-5'>
              <strong>{item.person_type === EPersonType.JURIDICA ? 'Razón Social' : 'Nombre'}:</strong> {item.forenames} {item.surnames}
            </div>
            {
              currentServices.sort((a, b) => Number(a.service_id) - Number(b.service_id)).map(renderServiceItem)
            }
          </div>
        ))}
      </div>
      <div className={styles.pagination}>
        <Pagination onChange={onChange} pageSize={1} current={current} total={personsToActivate.length} />
      </div>

    </div>
  );
};

export default ServiceActivationLegalPerson;
