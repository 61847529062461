import React, { useEffect, useState } from 'react';
import { XIcon } from '@imed_npm/design-system';
import styles from './style.module.scss';

interface OwnProps {
  title?: string
  children?: React.ReactNode
  showModal?: boolean
  setShowModal?: any
  showCloseButton?: boolean
  onToggleStatus?: (status: boolean) => void
  functionCloseButton?: () => void
  containerClass?: string
  modalClass?: string,
  parentClass?: string,
  id?: string,
}

const Modal = ({ title, children, showModal = false, setShowModal, onToggleStatus, showCloseButton = true, functionCloseButton = undefined, containerClass = '', modalClass = '', parentClass, id }: OwnProps) => {

  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => {
    functionCloseButton ? functionCloseButton() : triggerShow();
  };

  useEffect(() => {
    setOpenModal(showModal);
  }, [showModal]);

  const toggleModal = (status) => {
    if (onToggleStatus) {
      onToggleStatus(status);
    } else {
      setOpenModal(status);
    }

  };

  const triggerShow = () => {
    if (setShowModal) {
      setShowModal(false);
    } else {
      toggleModal(false);
    }
  };

  return (
    <>
      {openModal ? (
        <>
          <div
            id={id ?? 'modal'}
            className={`${parentClass ? parentClass : styles.z1002} justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none subs-modal`}
          >
            <div className={`relative  mx-auto  ${styles.modal_container} ${containerClass ? containerClass : 'max-w-3xl my-6'} `}>
              <div className={`border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ${modalClass ? modalClass : ''} `}>
                {title &&
                  <div className={`flex items-start justify-between  rounded-t ${title ? 'p-4 border-b' : 'pt-0 pb-0 px-4'}`}>
                    <h3 className={`${styles.modal_title} px-4 pt-3`}>{title}</h3>
                    {showCloseButton &&
                      <button className={styles.modal_close_modal} onClick={triggerShow}>
                        <XIcon size="m" />
                      </button>
                    }
                  </div>
                }
                {
                  showCloseButton && <button className={styles.modal_close_modal} onClick={closeModal}>
                    <XIcon size="m" />
                  </button>
                }
                <div className={`relative p-6 flex-auto ${styles.overflowy}`}>
                  {children}
                </div>
              </div>
            </div>
          </div>
          <div className={`fixed inset-0 bg-black modal-opacity ${styles.opacity} ${styles.z1000}`} />
        </>
      ) : null}
    </>);
};


export default Modal;
