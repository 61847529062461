import { useContext, useEffect, useState } from 'react';
import { DataTabs, Label, UserHelp, HouseEmoji, Button, FileIcon, DownloadIcon, XIcon } from '@imed_npm/design-system';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import { Professionals } from '../../interfaces/professional';
import { getVoucherClientNid, getDocuments, editDocuments } from '../../utils/actions';
import LoadingComponent from '../../../Form/Components/LoadingComponent/LoadingComponent';
import ToastMessage from '../../../../utils/ToastMessage';
import image from '../../../../assets/info.png';
import icon from '../../../../assets/plan-standar.svg';
import { compressFile } from '../../../../utils/fileCompressor';
import styles from './styles.module.scss';

interface OwnProps {
  professional: Professionals;
  showButton: boolean;
}

interface UploadedFile {
  name: string;
  size: number;
  success: boolean;
  error?: string;
  url?: string;
  base64?: any;
}

const Info = ({ professional: _professional, showButton }: OwnProps) => {
  const context = useContext(LoggedUserContext);
  const [modalContent, setModalContent] = useState<any>(undefined);
  const [bankInfo, setBankInfo] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [showImage, setShowImage] = useState<boolean>(true);
  const [images, setImages] = useState<{ [key: string]: string }>({});
  const [showButtonLoading, setShowButtonLoading] = useState<boolean>(false);

  const [showChangeFile, setShowChangeFile] = useState<{ [key: string]: boolean }>({
    ci_front: false,
    ci_back: false,
    bank: false,
    cvp: false,
  });
  const [file, setFile] = useState<{ [key: string]: UploadedFile | null }>({
    ci_front: null,
    ci_back: null,
    bank: null,
    cvp: null,
  });


  useEffect(() => {
    searchRut();
  }, [_professional]);

  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const response = await getDocuments(context, Number(_professional.adscription_id));
        setImages({
          ci_front: response.files.ci_front,
          ci_back: response.files.ci_back,
          bank: response.files.bank,
          cvp: response.files.cvp,
        });
      } catch (error) {
        ToastMessage.enviarToastError('Error al obtener documentos');
      } finally {
        setLoading(false);
      }
    };

    const timer = setTimeout(fetchDocuments, 500);
    return () => clearTimeout(timer);
  }, [_professional, context, refresh]);

  useEffect(() => {
    if (modalContent) {
      setBankInfo(modalContent.bank_info);
    }
  }, [modalContent]);

  const searchRut = async () => {
    try {
      //@ts-ignore
      const response = await getVoucherClientNid(context, _professional.nid);
      const filter = response.find((item) => item.plan_id === _professional.plans.id);
      if (filter) {
        setModalContent(filter);
      } else {
        setModalContent(response[0]);
      }
    } catch (error) {
      ToastMessage.enviarToastError('Error al buscar RUT');
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, fileType: keyof typeof file) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const result = await compressFile(selectedFile);
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result as string;
        const base64WithoutHeader = base64String.substring(base64String.indexOf(',') + 1);

        const compressedFile = {
          name: result.file.name,
          size: result.file.size / (1024 * 1024),
          success: result.success,
          error: result.error,
          url: URL.createObjectURL(result.file),
          base64: base64WithoutHeader,
        };
        setFile(prevFile => ({ ...prevFile, [fileType]: compressedFile }));
      };

      reader.readAsDataURL(result.file);
    }
  };

  const handleRemoveFile = (fileType: keyof typeof file) => {
    setFile(prevFile => ({ ...prevFile, [fileType]: null }));
  };

  const updateFile = (type: number, fileType: keyof typeof file) => {
    setShowButtonLoading(true);
    const selectedFile = file[fileType];
    if (!selectedFile) return;
    // @ts-ignore
    editDocuments(context, Number(_professional.adscription_id), type, 'image/png', selectedFile.base64).then(
      () => {
        setShowButtonLoading(false);
        setShowChangeFile(prevState => ({ ...prevState, [fileType]: false }));
        handleRemoveFile(fileType);
        setRefresh(prev => !prev);
      }
    );
  };

  const getFileType = (base64: string): 'image' | 'pdf' | 'unknown' => {
    if (base64.startsWith('/9j/') || base64.startsWith('iVBORw0KG')) {
      return 'image'; // JPEG o PNG
    } else if (base64.startsWith('JVBERi0')) {
      return 'pdf'; // PDF
    }
    return 'unknown';
  };

  const renderFile = (base64: string, altText: string) => {
    const fileType = getFileType(base64);
    if (fileType === 'image') {
      return <img src={`data:image/jpeg;base64,${base64}`} alt={altText} className='mx-auto mb-1' />;
    } else if (fileType === 'pdf') {
      return <embed src={`data:application/pdf;base64,${base64}`} type="application/pdf" className='mx-auto mb-1' style={{ width: '100%', height: '500px' }} />;
    }
    return null;
  };

  const { name, nid, centers, external_id } = _professional;

  return (
    <div className={styles.professional_info}>
      <UserHelp
        colorStyle="success"
        icon={<HouseEmoji svgClass="h-10 w-10" />}
        text={<div className={styles.professional_info}>
          <p><span>Prestador:</span> {nid}</p>
          <p><span>Nombre:</span> {name}</p>
          <p><span>Prospecto Id:</span> {external_id}</p>
          <p><span>Código de lugar:</span> {centers[0]?.code}</p>
        </div>}
        title=" "
      />
      {(modalContent && images) ? (
        <>
          <DataTabs colorStyle="light-blue" customClass={styles.tabsHeader}>
            <div title="Información general" className='border p-5'>
              <p><span>Centros:</span>
                <ul>
                  {centers.map(center => (
                    <li key={center.code}>-{center.center_address}</li>
                  ))}
                </ul>
              </p>
              <hr />
              <p className="pt-4"><span>Email:</span> {modalContent.client.email}</p>
              <p className="pb-4"><span>Teléfono:</span> {modalContent.client.phone_country_code}{modalContent.client.phone}</p>
              <hr />
              <p className="mt-4"><span>Plan:</span> {modalContent.name}</p>
              <p><span>N° de lectores:</span> {modalContent.readers}</p>
              <p><span>Id Contrato:</span> {modalContent.contract_id}</p>
              <p className="pb-4"><span>Código de auditoria:</span> {modalContent.audit_code}</p>
            </div>

            <div title="Cédula de identidad" className='border p-5'>
              {loading ? <LoadingComponent /> : <>
                {images.ci_front ? (
                  <>
                    <div className={`${styles.uploadContainer} mb-5 p-4 ${!showChangeFile.ci_front && 'hide'}`}>
                      {file.ci_front ? (
                        <div className={styles.filePreview}>
                          <div className={`${styles.customFileLabel} min-w-96`}>
                            <img src={file.ci_front.url} alt={file.ci_front.name} className={styles.imagePreview} />
                            <span>{file.ci_front.name}</span>
                            <span>({file.ci_front.size.toFixed(2)} MB)</span>
                            <div className='ml-3'>
                              <Button buttonType='button' type='secondary' onClick={() => updateFile(1, 'ci_front')}>Guardar</Button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>

                          <label htmlFor="ci_front" className={`${styles.customFileLabel}`}>
                            <input
                              type="file"
                              id="ci_front"
                              onChange={(e) => handleFileChange(e, 'ci_front')}
                              accept=".jpg,.jpeg,.png"
                              className={styles.customFileInput}
                            />
                            <FileIcon pathClass='stroke-secondary-imed-30' size='l' />
                            <span className='block font-normal text-neutral-60'>Haz clic para seleccionar</span>
                            <div className={styles.uploadButton}>
                              <span className='text-secondary-imed-60 cursor-pointer text-sm font-medium mr-2'>Buscar Archivo</span>
                              <DownloadIcon size="s" pathClass='stroke-secondary-imed-60' />
                            </div>
                          </label>
                          <div className='flex justify-end w-full mt-2'>
                            <Button type='primary' size="s" buttonType='button' IconLeft={XIcon} onClick={() => setShowChangeFile(prev => ({ ...prev, ci_front: false }))}>Cancelar modificación</Button>
                          </div>
                        </>
                      )}
                    </div>
                    <div className={showChangeFile.ci_front ? 'hide' : ''}>
                      <Label htmlFor="front" title='Imagen frontal' />

                      <div className="flex flex-col">
                        {renderFile(images.ci_front, 'Imagen frontal')}
                        {
                          showButton && <div className='flex justify-end'>
                            <Button type='primary' size="s" buttonType='button' onClick={() => setShowChangeFile(prev => ({ ...prev, ci_front: true }))}>Modificar CI frontal</Button>
                          </div>
                        }

                      </div>
                    </div>
                  </>
                ) : (
                  <div className={styles.empty}>
                    <p className='pb-3 text-sm'>Sin imagen frontal</p>
                    <img src={image} alt="info" className={`${styles.image} mx-auto mb-4`} />
                  </div>
                )}
                <hr className='mt-10 pb-5' />
                {images.ci_back ? (
                  <>
                    {showChangeFile.ci_back ? (
                      <div className={`${styles.uploadContainer} mb-5 p-4`}>
                        {file.ci_back ? (
                          <div className={styles.filePreview}>
                            <div className={`${styles.customFileLabel} min-w-96`}>
                              <img src={file.ci_back.url} alt={file.ci_back.name} className={styles.imagePreview} />
                              <span>{file.ci_back.name}</span>
                              <span>({file.ci_back.size.toFixed(2)} MB)</span>
                              <div className='ml-3'>
                                <Button buttonType='button' type='secondary' onClick={() => updateFile(2, 'ci_back')}>Guardar</Button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>

                            <label htmlFor="ci_back" className={`${styles.customFileLabel}`}>
                              <input
                                type="file"
                                id="ci_back"
                                onChange={(e) => handleFileChange(e, 'ci_back')}
                                accept=".jpg,.jpeg,.png"
                                className={styles.customFileInput}
                              />
                              <FileIcon pathClass='stroke-secondary-imed-30' size='l' />
                              <span className='block font-normal text-neutral-60'>Haz clic para seleccionar</span>
                              <div className={styles.uploadButton}>
                                <span className='text-secondary-imed-60 cursor-pointer text-sm font-medium mr-2'>Buscar Archivo</span>
                                <DownloadIcon size="s" pathClass='stroke-secondary-imed-60' />
                              </div>
                            </label>
                            <div className='flex justify-end mt-2 w-full'>
                              <Button type='primary' size="s" buttonType='button' IconLeft={XIcon} onClick={() => setShowChangeFile(prev => ({ ...prev, ci_back: false }))}>Cancelar modificación</Button>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        <Label htmlFor="back" title="Imagen trasera" />
                        <div className="flex flex-col">
                          {renderFile(images.ci_back, 'Imagen trasera')}
                          {
                            showButton && <div className='flex justify-end'>
                              <Button type='primary' size="s" buttonType='button' onClick={() => setShowChangeFile(prev => ({ ...prev, ci_back: true }))}>Modificar CI trasera</Button>
                            </div>
                          }

                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className={styles.empty}>
                    <p className='pb-3 text-sm'>Sin imagen trasera</p>
                    <img src={image} alt="info" className={`${styles.image} mx-auto mb-4`} />
                  </div>
                )}
              </>}
            </div>

            {bankInfo ? (
              <div title="Datos bancarios" className='border p-5'>
                <p className="mb-4"><strong>Banco:</strong> {bankInfo.banks.name}</p>
                <p className="mb-4"><strong>Tipo de cuenta:</strong> {bankInfo.account_types.name}</p>
                <p className="mb-4"><strong>N° de cuenta:</strong> {bankInfo.account}</p>
                <hr className='py-5' />
                {loading ? <LoadingComponent /> : <>
                  {images.bank ? (
                    <>
                      {showChangeFile.bank ? (
                        <div className={`${styles.uploadContainer} mb-5 p-4`}>
                          {file.bank ? (
                            <div className={styles.filePreview}>
                              <div className={`${styles.customFileLabel} min-w-96`}>
                                <img src={file.bank.url} alt={file.bank.name} className={styles.imagePreview} />
                                <span>{file.bank.name}</span>
                                <span>({file.bank.size.toFixed(2)} MB)</span>
                                <div className='ml-3'>
                                  <Button buttonType='button' type='secondary' onClick={() => updateFile(3, 'bank')}>Guardar</Button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>

                              <label htmlFor="bank" className={`${styles.customFileLabel}`}>
                                <input
                                  type="file"
                                  id="bank"
                                  onChange={(e) => handleFileChange(e, 'bank')}
                                  accept=".jpg,.jpeg,.png,.pdf"
                                  className={styles.customFileInput}
                                />
                                <FileIcon pathClass='stroke-secondary-imed-30' size='l' />
                                <span className='block font-normal text-neutral-60'>Haz clic para seleccionar</span>
                                <div className={styles.uploadButton}>
                                  <span className='text-secondary-imed-60 cursor-pointer text-sm font-medium mr-2'>Buscar Archivo</span>
                                  <DownloadIcon size="s" pathClass='stroke-secondary-imed-60' />
                                </div>
                              </label>
                              <div className='flex justify-end mt-2 w-full'>
                                <Button type='primary' size="s" buttonType='button' IconLeft={XIcon} onClick={() => setShowChangeFile(prev => ({ ...prev, bank: false }))}>Cancelar modificación</Button>
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div>
                          <Label htmlFor="back-img" title='Cabecera bancaria' />
                          <div className="flex flex-col" id="back-img">
                            {renderFile(images.bank, 'Imagen cabecera banca')}
                            {
                              showButton && <div className='flex justify-end'>
                                <Button type='primary' size="s" buttonType='button' onClick={() => setShowChangeFile(prev => ({ ...prev, bank: true }))}>Modificar cabecera bancaria</Button>
                              </div>
                            }

                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className={styles.empty}>
                      <p className='pb-3 text-sm'>Sin cabecera bancaria</p>
                      <img src={image} alt="info" className={`${styles.image} mx-auto mb-4`} />
                    </div>
                  )}
                </>}
              </div>
            ) : <p>Sin datos bancarios</p>}

            {
              ((_professional?.plans?.products?.name === 'EM' && _professional?.plans?.code === 'PC71') || _professional?.plans?.products?.name === 'BE') && (<div title="CVP" className='border p-5'>
                {loading ? <LoadingComponent /> : <>
                  {showChangeFile.cvp ? (
                    <div className={`${styles.uploadContainer} mb-5 p-4`}>
                      {file.cvp ? (
                        <div className={styles.filePreview}>
                          <div className={`${styles.customFileLabel} min-w-96`}>
                            <img src={file.cvp.url} alt={file.cvp.name} className={styles.imagePreview} />
                            <span>{file.cvp.name}</span>
                            <span>({file.cvp.size.toFixed(2)} MB)</span>
                            <div className='ml-3'>
                              <Button buttonType='button' type='secondary' onClick={() => updateFile(4, 'cvp')}>Guardar</Button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <label htmlFor="cvp" className={`${styles.customFileLabel}`}>
                            <input
                              type="file"
                              id="cvp"
                              onChange={(e) => handleFileChange(e, 'cvp')}
                              accept=".pdf"
                              className={styles.customFileInput}
                            />
                            <FileIcon pathClass='stroke-secondary-imed-30' size='l' />
                            <span className='block font-normal text-neutral-60'>Haz clic para seleccionar</span>
                            <div className={styles.uploadButton}>
                              <span className='text-secondary-imed-60 cursor-pointer text-sm font-medium mr-2'>Buscar Archivo</span>
                              <DownloadIcon size="s" pathClass='stroke-secondary-imed-60' />
                            </div>
                          </label>
                          <div className='flex justify-end w-full mt-2'>
                            <Button type='primary' size="s" buttonType='button' IconLeft={XIcon} onClick={() => setShowChangeFile(prev => ({ ...prev, cvp: false }))}>Cancelar modificación</Button>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      {
                        images.cvp ? <div>
                          <Label htmlFor="cvp-img" title='CVP' />

                          <div className="flex flex-col" id="cvp-img">

                            {renderFile(images.cvp, 'Archivo CVP')}
                            {
                              showButton && <div className='flex justify-end'>
                                <Button type='primary' size="s" buttonType='button' onClick={() => setShowChangeFile(prev => ({ ...prev, cvp: true }))}>Modificar CVP</Button>
                              </div>
                            }
                          </div>
                        </div> : <div className={styles.empty}>
                          <p className='pb-3 text-sm'>Sin archivo CVP</p>
                          <img src={image} alt="info" className={`${styles.image} mx-auto mb-4`} />
                          {
                            showButton && <div className='flex justify-end'>
                              <Button type='primary' size="s" buttonType='button' onClick={() => setShowChangeFile(prev => ({ ...prev, cvp: true }))}>Modificar CVP</Button>
                            </div>
                          }
                        </div>
                      }
                    </>
                  )}
                </>}
              </div>)
            }
          </DataTabs>
        </>
      ) : <LoadingComponent />}
    </div>
  );
};

export default Info;
