import React, { useState } from 'react';
import { Label, XIcon } from '@imed_npm/design-system';

import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './InputPhone.css';

interface TextInputPhoneComponentProps
  extends React.ComponentPropsWithoutRef<'input'> {
  customCountries?: any;
  disableCountrySelection?: boolean;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: any;
  onKeyDown?: (event: any) => void;
  onChange: (event: any) => void;
  setFieldValue: (name: string, value: any) => void;
  name: string;
  value?: string;
  customClass?: string;
  maxLength?: number;
  touched?: boolean;
  error?: string;
  inputType?: string;
  customSize?: string;
  getCountryCode?: any;
  defaultCountry?: any;
}

function TextInputPhoneComponent(props: TextInputPhoneComponentProps) {
  const {
    customCountries,
    disableCountrySelection,

    label,
    placeholder,
    disabled,
    defaultValue,
    onKeyDown,
    onChange,
    setFieldValue,
    name,
    value,
    maxLength,
    touched,
    error,
    inputType,
    customClass,
    customSize,
    getCountryCode,
    defaultCountry,
    ...rest
  } = props;

  const [valuePhone, setValuePhone] = useState(value);
  const [countryState, setCountry] = useState<any>('');

  const handleChange = (event: any) => {
    // console.log(event);
    setFieldValue(name, event);
  };

  let errorObj = {
    stateType: '',
    stateMessage: '',
  };
  if (touched && error) {
    errorObj = {
      stateType: 'error',
      stateMessage: error,
    };
  }

  return (
    <>

      <Label
        htmlFor={name}
        title={label || 'Numero de telefono'}
      />
      <PhoneInput
        placeholder="Ej. 9 87654321"
        defaultCountry={countryState !== '' ? countryState : defaultCountry}
        displayInitialValueAsLocalNumber
        value={valuePhone}
        onChange={handleChange}
        name={name}
        id={name}
        className={`
          ${errorObj.stateType === 'error' ? 'input-phone-number !border-error' : 'input-phone-number'}
          `}
      />
    </>
  );
}

export default TextInputPhoneComponent;
