import React, { useContext, useEffect, useState } from 'react';
import styles from './style.module.scss';
import { Input, Button, LoadingImed } from '@imed_npm/design-system';
import { formatRut, validFormat } from '../../utils/rut';
import PDFDataViewer from '../PdfViewer';
import { PDFDocument } from 'pdf-lib';

import { changeStatus, getContractTemplate, patchBillingInfo, sendContractMail } from '../../Components/Form/Store/actions';
import LoggedUserContext from '../../context/LoggedUserContext';
import Swal from 'sweetalert2';
import { EProfessionalStatus } from '../../enum/professional.enum';

interface OwnProps {
  representativeProfessional: any;
  setShowModal: any
}

// interface InputState {
//   type: string;
//   message: string;
// }

const LegalRepresentativeForm = ({ representativeProfessional, setShowModal }: OwnProps) => {
  const context = useContext(LoggedUserContext);

  const [nid, setNid] = useState<string>('');

  const [contract, setContract] = useState<string>('');
  const [filledContract, setFilledContract] = useState<string>('');

  const [name, setName] = useState<string>('');
  const [preVisualize, setPreVisualize] = useState<boolean>(false);
  const [contractFilled, setContractFilled] = useState<boolean>(false);
  const [validNid, setValidNid] = useState<boolean>(false);
  const [validName, setValidName] = useState<boolean>(false);
  const [validMail, setValidMail] = useState<boolean>(false);
  const [clickedSend, setClickedSend] = useState<boolean>(false);

  const [showError, setShowError] = useState<boolean>(false);
  const [nidStateMessage, setNidStateMessage] = useState<string>('');
  const [nidStateType, setNidStateType] = useState<'error' | 'success' | 'info' | 'warning' | 'default' | undefined>(undefined);
  const [nameStateMessage, setNameStateMessage] = useState<string>('');
  const [nameStateType, setNameStateType] = useState<'error' | 'success' | 'info' | 'warning' | 'default' | undefined>(undefined);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    //cuando se modifique y no este vacio, obtener el dato del contrato/plantilla y traerlo
    if (representativeProfessional.original && Object.keys(representativeProfessional.original).length && !contract) {
      //encontrar el plan_id dentro de las adscriptions que correspondan al tipo de producto seleccionado
      //tiene que ser el adscription activo
      const plan_id = 11;
      getContractTemplate({ payload: { plan_id }, context }).then((template) => {
        setContract(template.contract_template);
      }).catch((err) => {
        //console.log('error',err.message);
        msgModal('No se pudo obtener datos de la plantilla de contrato, por favor recargue la pagina o contacte a soporte', 'Error');
      });
    }
  }, [representativeProfessional]);


  const msgModal = (msg = '', header = '') => {
    Swal.fire({
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#081E41',
      title: header || 'Error',
      text: msg || 'Ocurrió un error al ejecutar la acción, intente nuevamente o contacte a soporte'
    }).then(() => {
      setShowModal(false);
      return false;
    });

  };

  const saveAndSendMail = () => {


    setClickedSend(false);
    if (preVisualize && contractFilled) {
      setIsLoading(true);
      setClickedSend(true);
      const adscription = 902;
      (async () => {
        try {
          const responseBillingInfo = await patchBillingInfo({
            payload: {
              adscriptionId: adscription,
              social: name,
              nid
            }, context
          });
          if (responseBillingInfo.status !== 200) {
            msgModal('Error al guardar los datos, intente nuevamente o contacte a soporte', 'Error');
            setValidMail(false);
            setIsLoading(false);
            return;
          }
          const responseChangeStatus = await changeStatus({
            payload: {
              prospectId: adscription,
              prospectStatus: EProfessionalStatus.CONTRACT_PENDING
            }, context
          });
          if (responseChangeStatus.status !== 200) {
            msgModal('Error al cambiar el estado, intente nuevamente o contacte a soporte', 'Error');
            setValidMail(false);
            setIsLoading(false);
            return;
          }
          const responseContractEMail = await sendContractMail({
            payload: {
              adscriptionId: adscription
            }, context
          });
          if (!responseContractEMail.sended) {
            msgModal('Error al enviar el correo, intente nuevamente o contacte a soporte', 'Error');
            setValidMail(false);
            setIsLoading(false);
            return;
          }
          setValidMail(true);
          msgModal('Correo enviado al cliente.', 'Exito');
        } catch (error: any) {
          msgModal(error.message, 'Error');
        }
      })();
    }
  };



  useEffect(() => {
    if (preVisualize && !filledContract) {
      if (validName && validNid) {
        setPreVisualize(true);
        setShowError(false);
        if (contract && Object.keys(representativeProfessional.original).length) {
          //const nid = representativeProfessional?.original?.nid;
          const phone = representativeProfessional?.original?.phone;
          const email = representativeProfessional?.original?.email;
          //const nombres = representativeProfessional?.original?.forenames;
          //obtener el adscription que aplica para el valor lme/licencia medica desde el array adscriptions
          //obtener el center del adscription
          const address = representativeProfessional?.original?.address ? representativeProfessional?.original?.address : '';
          const locality = representativeProfessional?.original?.locality ? representativeProfessional?.original?.locality : '';
          const city = representativeProfessional?.original?.city ? representativeProfessional?.original?.city : '';

          PDFDocument.load(Uint8Array.from(atob(contract), c => c.charCodeAt(0))).then<string>(pdfDoc => {
            const form = pdfDoc.getForm();
            // Fill the form's fields
            form.getTextField('full_name').setText(`${name}`);
            form.getTextField('nid').setText(nid);
            form.getTextField('address').setText(`${address}, ${locality}, ${city}`);
            form.getTextField('phone').setText(`+56 ${phone}`);
            form.getTextField('email').setText(email);
            // Flatten the form's fields
            form.flatten();
            return pdfDoc.saveAsBase64();
          }).then(filledContract => {
            setFilledContract(filledContract);
            setContractFilled(true);
          });
        } else {
          setContractFilled(false);
          msgModal('faltan valores de contrato y profesional para generar la previsualización, recargue la página.', 'Error');
        }

      } else {
        setContractFilled(false);
        setPreVisualize(false);
        setShowError(true);
      }
    }
  }, [preVisualize, filledContract]);

  const setPreVisualization = () => {
    setPreVisualize(true);
    setFilledContract('');
  };


  const nameClientState = (name: string) => {

    if (name) {
      const regex = "^[A-ZÑa-zñáéíóúÁÉÍÓÚ'° ]+$";
      const validName = name.match(regex) != null && name.length >= 1;
      if (validName) {
        setValidName(true);
        setNameStateMessage('Nombre Válido');
        setNameStateType('success');
      } else {
        setValidName(false);
        setNameStateMessage('Nombre inválido');
        setNameStateType('error');
      }
    } else {
      setNameStateMessage('');
      setNameStateType(undefined);
    }
  };

  const setNameValue = ({ target: { value } }: { target: { value: string; } }) => {
    setName(value);
    setPreVisualize(false);
  };
  useEffect(() => {
    nameClientState(name);
  }, [name]);

  const setNidValue = ({ target: { value } }: { target: { value: string; } }) => {
    const rut = formatRut(value);
    setNid(rut);
    setPreVisualize(false);
  };

  useEffect(() => {
    nidClientState(nid);
  }, [nid]);

  const nidClientState = (nid) => {
    if (nid) {
      const validNid = validFormat(nid);
      if (validNid) {
        setValidNid(true);
        setNidStateMessage('RUT Válido');
        setNidStateType('success');
      } else if (!validNid) {
        setValidNid(false);
        setNidStateMessage('RUT inválido');
        setNidStateType('error');
      }
    } else {
      setNidStateMessage('');
      setNidStateType(undefined);
    }
  };


  return (

    <div>
      {
        isLoading && <LoadingImed text="" />
      }
      <div className={styles.company_info}>

        <div className={styles.company_info_container}>
          <div className="m-4">
            <div className={styles.rutCliente}>
              <div className={styles.rutCliente_name}>
                Rut Cliente
              </div>
              <div className={styles.rutCliente_value}>
                {representativeProfessional?.original?.nid}
              </div>
            </div>
            <div className={styles.razonSocial}>
              <div className={styles.razonSocial_name}>
                Razón Social
              </div>
              <div className={styles.razonSocial_value}>
                {representativeProfessional?.original?.forenames + ' ' + representativeProfessional?.original?.surnames ? representativeProfessional?.original?.surnames : ''}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.company_info_container}>
          <div className="m-4">
            <div className={styles.rutRepLegal}>
              <div className={styles.rutRepLegal_name}>
                Rut Representante Legal
              </div>
              <div className={styles.rutRepLegal_value}>
                <Input
                  customSize="m"
                  name="nid"
                  value={nid}
                  onChange={(e) => { setNidValue(e); }}
                  placeholder="Ingresa"
                  title=""
                  type="text"
                  stateMessage={nidStateMessage}
                  stateType={nidStateType}
                />
              </div>
            </div>
            <div className={styles.nombreRepLegal}>
              <div className={styles.rutRepLegal_name}>
                Nombre Representante Legal
              </div>
              <div className={styles.rutRepLegal_value}>
                {
                  <Input
                    name="name"
                    value={name}
                    //((onChange={({ target: { value } }) => setNameValue(value)}
                    onChange={(e) => { setNameValue(e); }}

                    placeholder="Ingresa nombre"
                    title=""
                    type="text"
                    stateMessage={nameStateMessage}
                    stateType={nameStateType}
                  />}
              </div>
            </div>
          </div>
        </div>

        <div className=''>
          <Button buttonType='button' className='m-4' size="s" type="secondary" onClick={() => { setPreVisualization(); }} >Previsualizar contrato</Button>
          {
            !validName && name && !validNid && nid ? <span className="errorInput">Nombre invalido o Rut Invalido, por favor ingresa datos correctos.</span> : ''
          }
        </div>
        <div className=''>
          {
            preVisualize && contractFilled && filledContract && <PDFDataViewer pdf={filledContract} props={{ view: 'fitH', toolbar: 0, navpanes: 0 }} />
          }
        </div>
        <div className=''>
          <Button buttonType='button' className='m-4' size="s" type="secondary" onClick={() => { saveAndSendMail(); }} >Enviar email al cliente con nuevo contrato</Button>
          {
            !validMail && clickedSend ? <span className="errorInput"></span> : ''
          }
        </div>

      </div>
    </div>


  );
};


export default React.memo(LegalRepresentativeForm);
