import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer, toast } from 'react-toastify';
import './index.css';
// Tailwindcss
import './assets/css/base/index.css';
import './assets/css/main.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// ReactToastify
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/utilities/toast.css';
import LoggedUserProvider from './context/LoggedUserProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const contextClass = {
  success: 'border-b-success',
  error: 'border-b-error',
  warning: 'border-b-warning',
  info: 'border-b-info',
};
toast.configure();

root.render(
  <StrictMode>
    <LoggedUserProvider>
      <ToastContainer
        toastClassName={(ctx) => contextClass[ctx?.type ?? 'default'] + ' toast-class shadow-select'}
        bodyClassName={(ctx) => contextClass[ctx?.type ?? 'default'] + ' toast-body'}
        position="top-right"
        autoClose={10000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={5}
      />
      <App />
    </LoggedUserProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
