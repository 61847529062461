import Keycloak from 'keycloak-js';

export interface IUserData {
  name: string,
  idUser: string,
  institutions: IInstitutionAuthServerHeader[],
  activeInstitution: IInstitutionAuthServerHeader
  roles: string[],
  email: string,
  email_verified: boolean,
  rut: string,
  products?: Product[];
}

export const initialUserData = {
  name: '',
  idUser: 'null',
  institutions: [],
  activeInstitution: {} as IInstitutionAuthServerHeader,
  roles: [],
  email: '',
  email_verified: false,
  rut: '',
  editor:{
    rows: [],
    data: []
  }
};

export interface IJwt {
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  iss: string;
  aud: string;
  sub: string;
  typ: string;
  azp: string;
  nonce: string;
  session_state: string;
  acr: string;
  'allowed-origins': string[];
  realm_access: RealmAccess;
  resource_access: ResourceAccess;
  scope: string;
  sid: string;
  institutions: Institutions;
  email_verified: boolean;
  name: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
}

interface Institutions {
  institutions: IInstitutionAuthServerHeader[];
}

export interface IInstitutionAuthServer {
  id: string;
  name: string;
  enabled: boolean;
  institutionType: number;
  auth_type: number;
  branchOffices: BranchOffice[];
}

interface BranchOffice {
  id: number;
  name: string;
  place_code: number;
  enabled: boolean;
  apps: App[];
}

interface App {
  id: string;
  name: string;
  enabled: boolean;
  resources: Resource[];
}

interface Resource {
  resource_id: string;
  resource_name: string;
  resource_enabled: boolean;
  resource_app_id: string;
}

interface RealmAccess {
  roles: string[];
}

interface ResourceAccess {
  account: RealmAccess;
}

export interface IInstitutionAuthServerHeader extends IInstitutionAuthServer {
  show: boolean
}

export interface Product {
  id: number;
  name: string;
  description: string;
  status: boolean;
  created_at: string;
  updated_at: string;
}

export interface AppContextInterface {
  userData: IUserData,
  setUserData: React.Dispatch<any>,
  token: string,
  keycloak?: Keycloak,
  logout: any,
  editor: {
    rows: any[],
    data: any[],
    idx: number
  }
}
