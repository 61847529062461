import { useContext, useEffect, useState } from 'react';
import ProfileSelection from './Components/ProfileSelection';
import ProfessionalInfo from './Components/ProfessionalInfo';
import CompanyInfo from './Components/CompanyInfo';
import ContactInformation from './Components/ContactInformation';
import CenterInfo from './Components/CenterInfo';
import ProductPlan from './Components/ProductPlan';
import DealSignature from './Components/DealSignature';
import LayoutForm from './LayoutForm';
import { initialState, UseStep } from './Store/step-context';
import { FORM_STEPS } from './constans';
import ToastMessage from '../../utils/ToastMessage';
import EndScreen from './Components/EndScreen';

import { getToken, initAdscription } from './Store/actions';


const LayoutFormContainer = () => {

  const { state, state: { step, steps: { error } }, dispatch } = UseStep();
  const [stepSelected, setStepSelected] = useState<string>(initialState.step);

  
  const handleSetNextStep = () => {
    if (stepSelected === 'step1') {
      const { form } = state;
      setStepSelected(form.personContractType === 'natural' ? 'step2.1' : 'step2.2');
    }
    else {
      setStepSelected(FORM_STEPS[stepSelected].nextStep);
    }
  };


  useEffect(() => {
    if (step != stepSelected) {
      setStepSelected(step);
    }
  }, [step]);

  const handleSetPreviousStep = () => {
    const { previousStep } = FORM_STEPS[stepSelected];
    if (previousStep === 'step2') {
      const { form } = state;
      return setStepSelected(form.personContractType === 'natural' ? 'step2.1' : 'step2.2');
    }
    setStepSelected(previousStep);
  };

  useEffect(() => {
    if (stepSelected != step)
      dispatch({ type: 'UPDATE_STEP', payload: { step: stepSelected } });
  }, [stepSelected]);

  useEffect(() => {
    setStepSelected(step);
  }, [step]);

  useEffect(() => {
    const { message } = error;
    const type = typeof message;

    if (type == 'string') {
      ToastMessage.enviarToastError(message);
    } else {
      ToastMessage.enviarToastError((
        <>
          <ul>
            {message.map(msg => (<li key={msg}>{msg}</li>))}
          </ul>
        </>
      ));
    }
  }, [error]);



  useEffect(() => {

    const timer = setTimeout(() => {
      getToken(dispatch);
      dispatch({ type: 'SET_PRODUCT', payload: { product: 'LME' } });
    }, 250);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <LayoutForm
        headerTitle={FORM_STEPS[stepSelected].headerTitle}
        headerSubtitle={FORM_STEPS[stepSelected].headerSubtitle}
        subtitleText={FORM_STEPS[stepSelected].subtitleText}
        actionNext={handleSetNextStep}
        actionPrevious={handleSetPreviousStep}
      >
        {
          stepSelected === 'step1' && <ProfileSelection />
        }
        {
          stepSelected === 'step2.1' && <ProfessionalInfo type="LME"/>
        }
        {
          stepSelected === 'step2.2' && <CompanyInfo type="LME"/>
        }
        {
          stepSelected === 'step3' && <ContactInformation  type="LME" />
        }
        {
          stepSelected === 'step4' && <CenterInfo />
        }
        {
          stepSelected === 'step5' && <ProductPlan  />
        }
        {
          stepSelected === 'step6' && <DealSignature type="LME" />
        }
        {
          stepSelected === 'endScreen' && <EndScreen />
        }
      </LayoutForm>
    </>

  );
};


export default LayoutFormContainer;
