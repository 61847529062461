import { useState, useEffect, FormEvent, useRef } from 'react';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Label, DatePicker } from '@imed_npm/design-system';

type DatePickerComponentProps = {
  placeholder?: string;
  title?: string;
  disabled?: any;
  value?: string;
  selected?: string;
  ref?: any;
  onChange?: (date: any) => void;
  name?: string;
  minDate?: any;
  maxDate?: any;
  touched?: any;
  error?: any;
  setFieldError?: any;
  includeTime?: boolean;
  dateFormat?: string;
  customClass?: string;
  customSize?: string;
  calendarClassName?: string;
  stateMessage?: string;
  stateType?: string;
  onClick?: (() => void) & ((date: string) => void);
  onKeyDown?: ((e: any) => void);
  onBlur?: (e: FormEvent<HTMLInputElement>) => void;
  setFieldTouched: (
    name: string,
    isTouched?: boolean,
    shouldValidate?: boolean
  ) => void;
}

const DatePickerComponent = ({
  placeholder,
  title,
  disabled,
  value,
  selected,
  onChange,
  setFieldTouched,
  name,
  minDate,
  maxDate,
  touched,
  error,
  setFieldError,
  customClass,
  calendarClassName,
  customSize,
  onKeyDown,
  stateMessage,
  stateType,
  ...rest
}: DatePickerComponentProps) => {
  const [inputValue, setInputValue] = useState<Date | null>(null);
  const [isInvalid, setIsInvalid] = useState(false);

  const onInput = (event) => {
    switch (event.target.name) {
      case 'day':
        if (event.target.value === '' || event.target.value === '0') {
          setIsInvalid(true);
        } else {
          setIsInvalid(false);
        }
        break;
      case 'month':
        if (event.target.value === '' || event.target.value === '0') {
          setIsInvalid(true);
        } else {
          setIsInvalid(false);
        }
        break;
      case 'year':
        if (event.target.value.length < 4) {
          setIsInvalid(true);
        } else {
          setIsInvalid(false);
        }
        break;
    }
  };

  const datepickerRef = useRef(null);

  useEffect(() => {
    if (value && typeof value === 'string') {
      const newValue = DateTime.fromISO(value, { zone: 'utc' }).toFormat('yyyy/MM/dd');
      setInputValue(new Date(newValue));
    } else {
      setInputValue(null);
    }
  }, [name, placeholder, value]);


  const handleChange = ({ target }: any) => {
    //console.log("handlechange",target);
    // Validación en caso de que value sea nulo
    if (target.value === null) return;

    if (typeof onChange !== 'function') return;

    const newDate = moment(target.value).format('yyyy/MM/DD');

    setInputValue(new Date(newDate));

    const event = {
      target: {
        name: name,
        value: null,
      }
    };

    const formattedDate: string | null = null;

    if (target.value === '') {
      event.target.value = null;
    } else {
      const dateValue = moment(target.value, 'DD/MM/YYYY', true);
      if (dateValue.isValid()) {
        setIsInvalid(false);
        // Use type assertion to cast the value as a string
        event.target.value = dateValue.format('YYYY-MM-DD') as any;
      } else {
        setIsInvalid(true); // Set isInvalid to true for invalid input
        event.target.value = null; // Set to null for invalid input
      }
    }
    onChange(event);
    if (typeof setFieldError === 'function') setFieldError(name);
  };

  let errorObj = {};
  if (touched && error) {
    errorObj = {
      stateType: 'error',
      stateMessage: error
    };
  }

  return (
    <div onInput={onInput}>
      <Label title={title}></Label>
      <DatePicker
        calendarClassName={calendarClassName}
        customClass={customClass}
        disabled={disabled}
        stateMessage={stateMessage}
        stateType={stateType ? (stateType as 'error' | 'success' | 'info' | 'warning' | 'default') : 'default'}
        // hasError={error ? true : false}
        locale="es"
        name={name}
        onChange={(date: any) => handleChange({ target: { name: name, value: date } })}
        value={inputValue}
        // selected={inputValue}
        // onKeyDown={onKeyDown}
        // setFieldTouched={setFieldTouched(name)}
        maxDate={new Date(moment(maxDate).format('yyyy/MM/DD'))}
        minDate={new Date('1900-01-01 10:00:00')}
        customSize={customSize}
        // onBlur={setFieldTouched(name)}
        {...errorObj}
        {...rest}
        inputRef={datepickerRef}
      />
      {/* {touched && error ?
        <AlertErrorComponent text={error} />
        :
        null
      } */}
      {
        isInvalid && (<span className='span-error block'>Seleccione una fecha válida</span>)
      }
    </div>
  );
};

export default DatePickerComponent;
