import React, { useContext, useEffect, useState } from 'react';
import { Label } from '@imed_npm/design-system';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import { Professionals } from '../../interfaces/professional';
import { getCI } from '../../utils/actions';
import styles from './styles.module.scss';
import ToastMessage from '../../../../utils/ToastMessage';
import LoadingComponent from '../../../Form/Components/LoadingComponent/LoadingComponent';

interface OwnProps {
  setShowChangeStatusModal?: (value: boolean) => void;
  professional: Professionals;
  showInfo?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ShowCI = ({ setShowChangeStatusModal, professional, showInfo }: OwnProps) => {
  const context = useContext(LoggedUserContext);
  const [imgFront, setImgFront] = useState<string>('');
  const [imgBack, setImgBack] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true);
      getCI(context, professional.nid).then((response) => {
        setImgFront(response.ci_code_front);
        setImgBack(response.ci_code_back);
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        ToastMessage.enviarToastError(`Error al obtener el CI: ${error.message}`);
      });
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.container}>
      {
        showInfo &&  <div className={styles.professional_info}>
        <p>
          <span>Prestador:</span> {professional.nid}
        </p>
        <p>
          <span>Código de lugar:</span> {professional.centers[0].code}
        </p>
      </div>
      }
     
      {loading && <LoadingComponent />}

      {imgFront && <div>
        <Label
          htmlFor="front"
          title="Imagen frontal"
        /><div className='flex flex-col'>
          <img id="front" className='mx-auto mb-1' src={`data:image/jpeg;base64,${imgFront}`} />
        </div>
      </div>
      }

      {imgBack && <div>
        <Label
          htmlFor="back"
          title="Imagen trasera"
        />
        <div className='flex flex-col'>
          <img id="back" className='mx-auto' src={`data:image/jpeg;base64,${imgBack}`} />
        </div>
      </div>
      }

    </div>
  );
};

export default ShowCI;
