/* eslint react/prop-types: 0 */
import React, { useEffect, useState, useContext } from 'react';
import { DataTable2, Skeleton, Select, Input, InfoIcon, Checkbox, Button, Label, LoadingImed, DataTabs, Tab } from '@imed_npm/design-system';
// import dummy from './dummy/data.json';
import { formatRut, validFormat, clearRut } from '../../../../../utils/rut';
import { DateTime } from 'luxon';
import LoggedUserContext from '../../../../../context/LoggedUserContext';
import Modal from '../../../../Modal';
import { Professionals } from '../../../interfaces/professional';
import { getProducts, getStatuses, getAdminUF, getAdminDownloadPaymentSubs, getPendingPayments } from '../../../utils/actions';
import { GetProfessionalsOptions, GroupedPeriodItem, Product } from '../../../utils/interfaces';
import { formatJsDate } from '../../../utils/utils';
import { Periodicity, Status } from '../../../interfaces/status';
import getEnv from '../../.../../../../../getEnv';
import styles from './style.module.scss';
import ChargeModal from '../ChargeModal';
import PendingChargesModal from './PendingChargesModal';

const APP_ID = getEnv('REACT_APP_ID');

function PendingCharges() {
  const context = useContext(LoggedUserContext);
  const [data, setData] = useState<any[]>([]);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);

  const [dataTable, setDataTable] = useState<GroupedPeriodItem[]>();

  const [products, setProducts] = useState<Product[]>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [periodicityList, setPeriodicityList] = useState<Periodicity[]>();

  const [showFilters, setShowFilters] = useState(false);
  const [filterRut, setFilterRut] = useState<string>();

  const [showModal, setShowModal] = useState(false);

  const [modalData, setModalData] = useState(null);

  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [UFvalue, setUFValue] = useState(0);
  const [UFformated, setUfFormated] = useState<any>(0);

  const today = DateTime.local().toFormat('yyyy-MM-dd');

  const [totalClients, setTotalClients] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [showDetailModal, setShowDetailModal] = useState(false);

  const [selectedRow, setSelectedRow] = useState<GroupedPeriodItem>();
  const [selectedGroups, setSelectedGroups] = useState<GroupedPeriodItem[]>([]);

  const [statusList, setStatusList] = useState<Status[]>();
  const [filteredData, setFilteredData] = useState([]);

  const handleDataFiltered = (data) => {
    setFilteredData(data);
    if (selectedGroups.length > 0) {
      setSelectedGroups(data);
    }
  };

  const getCurrentPeriod = () => {
    const today = DateTime.local();
    const month = today.month.toString().padStart(2, '0');
    const year = today.year;
    return `${year}-${month}`;
  };

  const handleCheckboxChange = (id: number) => {
    const updatedSelectedGroups = [...selectedGroups];
    const groupIndex = updatedSelectedGroups.findIndex(group => group.id === id.toString());
    if (groupIndex !== -1) {
      updatedSelectedGroups.splice(groupIndex, 1); // Remove from the array
    } else {
      const groupToAdd = data.find(item => item.id === id);
      if (groupToAdd) {
        updatedSelectedGroups.push(groupToAdd); // Add to the array
      }
    }
    setSelectedGroups(updatedSelectedGroups);
  };

  useEffect(() => {
    const timeout = setTimeout(async () => {
      const [initProducts, initStatusList] = await Promise.all(
        [
          getProducts(context),
          getStatuses(context, 'adscriptions')
        ]);
      setProducts(initProducts.filter((product) => product.name === 'EM'));
      setSelectedProduct(initProducts.filter((product) => product.name === 'EM')[0]);
      setStatusList(initStatusList);

      setShowLoading(false);
    }, 250);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!today) return;
    getAdminUF(context, today).then((e) => setUFValue(e.uf));

  }, []);

  useEffect(() => {
    if (!UFvalue) return;
    // Formatear la UF
    const ufFormateada = UFvalue.toLocaleString('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 2,
    });
    setUfFormated(ufFormateada);
  }, [UFvalue]);

  useEffect(() => {
    const timeout = setTimeout(getData, 250);

    return () => clearTimeout(timeout);
  }, [filterRut]);

  useEffect(() => {
    if (!selectedProduct) return;

    setLoadingTable(true);
    getData();
    setFilterRut(undefined);
  }, [selectedProduct]);

  const download = () => {
    getAdminDownloadPaymentSubs(context, filterRut).then(response => downloadCsv(response));
  };

  const charge = () => {
    if (!selectedGroups.length) return;
    setShowModal(true);
  };

  const downloadCsv = (base64String) => {
    try {
      // Decode the base64 string
      const binaryString = atob(base64String);

      // Convert the binary string to a Uint8Array
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob from the bytes
      const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a blob URL and download the file
      const blobURL = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = blobURL;
      downloadLink.download = 'cobros-suscripcion.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobURL);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  };


  const getData = async () => {
    if (!selectedProduct) return;
    setShowLoading(true);

    //const result = await getAdminPaymentSubs(context, filterRut);
    //return result.list[0];
    //getProfessionals(context, data).then(setDataTable).finally(() => {setShowLoading(false); setLoadingTable(false);});
    getPendingPayments(context).then((result) => {
      // console.log('result', result);
      setDataTable(result);
      setData(result);
    }).finally(() => {
      setShowLoading(false);
      setLoadingTable(false);
    });
  };

  useEffect(() => {
    if (modalData) {
      setShowModal(true);
    }
  }, [modalData]);

  useEffect(() => {
    if (!dataTable) return;
    //setTotalClients(calculateTotalClients());
    //setTotalAmount(calculateTotalAmount());
  }, [data]);

  useEffect(() => {
    if (!showLoading && !loadingTable) setShowFilters(true);
  }, [showLoading]);

  const triggerSearch = (select) => {
    setSelectedProduct(select);
  };

  const ActionCellRender = ({ row }: any) => {
    const { original } = row;
    const isChecked = selectedGroups.some(group => group.id === original.id);
    return (
      <input
        type="checkbox"
        checked={isChecked}
        onChange={() => handleCheckboxChange(original.id)}
      />
    );
  };

  const DetailCellRender = (row: any) => {
    return (
      <div>
        <Button type='secondary' buttonType='button' onClick={() => {
          setSelectedRow(row.original);
          setShowDetailModal(true);
        }}>Detalle</Button>
      </div>
    );
  };

  const priceFormat = (value, row) => {
    return value ? (value).toFixed(2) : '-';
  };

  const columnsDetail = [
    {
      Cell: ({ value, row }) => value ?? '-',
      Header: 'Id Voucher',
      HeaderWidth: 'w-[25%]',
      accessor: 'voucherId',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || 'Sin Periodo',
      Header: 'Periodo',
      HeaderWidth: 'w-[25%]',
      accessor: 'period',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value, row }) => priceFormat(value, row) ?? '-',
      Header: 'Monto en UF',
      HeaderWidth: 'w-[25%]',
      accessor: 'price',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Estado Voucher',
      HeaderWidth: 'w-[25%]',
      accessor: 'statusName',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }
  ];

  const selectAll = () => {
    if (!selectedGroups.length) {
      if (filteredData.length > 0) {
        setSelectedGroups(filteredData);
      } else {
        setSelectedGroups(data);
      }
    } else {
      setSelectedGroups([]);
    }
  };

  const columns = [
    {
      Cell: ActionCellRender,
      id: 'columncheck',
      Header: <div className='flex justify-center items-center'>
        <input
          type="checkbox"
          checked={selectedGroups.length === data.length}
          onChange={() => { selectAll(); }}
        /></div>,
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || 'Sin Periodo',
      Header: 'Periodo',
      HeaderWidth: 'w-[31%]',
      accessor: 'period',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Vouchers Pendientes',
      HeaderWidth: 'w-[31%]',
      accessor: 'count',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value, row }) => priceFormat(value, row) ?? '-',
      Header: 'Monto en UF',
      HeaderWidth: 'w-[31%]',
      accessor: 'sum',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Estado Actual Suscripción',
      HeaderWidth: 'w-[31%]',
      accessor: 'statusName',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Fecha de Cobro',
      HeaderWidth: 'w-[31%]',
      accessor: 'creationDate',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value, row }) => DetailCellRender(row),
      Header: 'Accion',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }
  ];

  const renderCheckingInfo = () => {
    return (
      <>
        {(showLoading) && (<LoadingImed text="Cargando información" />)}
      </>
    );
  };

  useEffect(() => {
    if (!context) return;
    if (!dataTable) return;

    //fetchData().then(() => { setShowLoading(false); });
    setShowLoading(false);
  }, [dataTable]);

  return (
    <>
      <div className="bg-white px-6 text-center">

        {renderCheckingInfo()}
        <div className='w-full mx-auto'>
          {
            // JSON.stringify(loadingTable)
          }
          {
            // JSON.stringify(showFilters)
            // <h1 className="text-secondary-imed-60 text-title tracking-[0.1px] font-normal my-4 mt-0">Administrador de Cobros</h1>
          }
          <div className="bg-white pb-6">
            <div className="mt-5">
              <div className='flex justify-between items-center'>
                {
                  false && <Select
                    customClass={`${styles.z0} mb-4`}
                    customSize="l"
                    items={products}
                    name="selectName"
                    nameType="description"
                    onChange={(e) => triggerSearch(e)}
                    defaultValue={selectedProduct?.description}
                    value={selectedProduct?.id}
                    placeholder="Seleccione una opción"
                    title="Producto"
                    disabled
                  />
                }
                {
                  false && showFilters && <>
                    <Button type='secondary' buttonType='button' onClick={download}>Descargar en Excel</Button>
                  </>
                }
              </div>
              <div className='flex justify-between items-center'>
                {
                  false && showFilters &&
                  <div>
                    <Input
                      focused
                      name="name"
                      value={filterRut}
                      onChange={({ target: { value } }) => { setFilterRut(clearRut(value)); }}
                      placeholder="Ingresa un rut"
                      title="Buscar por rut"
                      type="text"
                    />
                  </div>
                }

                {
                  !showFilters && <>
                    {
                      showLoading && <div className='grid grid-cols-4 gap-4 pt-2 mb-6'>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </div>
                    }
                  </>
                }
              </div>

              {showDetailModal &&
                <Modal title={'Detalle'} modalClass={styles.modalWidth} showModal={showDetailModal} setShowModal={setShowDetailModal} showCloseButton={true}>
                  {selectedRow && <div className='grid gap-4'>
                    <div>
                      <p className='text-base mb-10 text-left'>
                        <p><strong>Detalle del periodo:</strong> {selectedRow.period}</p>
                        <p><strong>Estado:</strong> {selectedRow?.statusName}</p>
                        <p><strong>Dia de Cobro:</strong> {selectedRow?.creationDate}</p>
                      </p>
                      <DataTable2
                        columns={columnsDetail}
                        data={selectedRow.records}
                        disableHeader
                        orderBy="period"
                        perPage={10}
                        totalResults={selectedRow.records.length}
                        sortBy="desc"
                        colorStyle="neutral"
                      />
                      <div>
                        <div className='mt-5'>
                          <div className='flex items-baseline'>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
                </Modal>}

              {showModal &&
                <Modal title={'Cobro Vouchers Pendientes'} showModal={showModal} setShowModal={setShowModal} showCloseButton={false}>
                  {data && <PendingChargesModal setShowModal={
                    (status) => {
                      getData();
                      setShowModal(status);
                      setSelectedGroups([]);
                    }
                  } data={selectedGroups} uf={UFvalue} product={selectedProduct} />}
                </Modal>}
              {
                //(data && !loadingTable) ? (
                (!loadingTable && statusList) ? (
                  <div>
                    <h4 className='text-base font-semibold my-10 text-left'>
                      Cobros pendientes para el producto: <span className='text-blue-800'>{selectedProduct?.description}</span>
                    </h4>
                    <DataTable2
                      columns={columns}
                      showUpperFilter
                      data={data}
                      disableHeader
                      orderBy="period"
                      hideShowFilter
                      hideOrderFilter
                      textPagination="cobros pendientes"
                      stateOptions={statusList.map(item => ({
                        value: item.name,
                        label: item.name
                      }))}
                      perPage={10}
                      totalResults={data && data.length ? data.length : 0}
                      sortBy="desc"
                      colorStyle="neutral"
                      onDataFiltered={handleDataFiltered}
                    />
                    <div>
                      <div className='mt-5'>
                        <div className='flex items-baseline'>
                          {
                            showFilters &&
                            <div className={`${styles.bgInfo} p-4 w-full flex justify-between`}>
                              <p className={styles.texts}>seleccionados:
                                <span>{selectedGroups.length} </span>
                              </p>
                              <Button type='primary' buttonType='button' onClick={charge}><div className="mx-10 text-base">Cobrar</div></Button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='w-full border flex flex-col p-20 justify-center items-center'>
                    <InfoIcon size="m" />
                    <p className='mt-2'>Todavía no selecciona un producto para administrar</p>
                  </div>
                )
              }
            </div>
          </div>

        </div>


      </div>
    </>);
}


export default PendingCharges;
