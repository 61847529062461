import Form from '../Components/Form';
import Admin from '../Components/Admin';
import AdminStatuses from '../Components/Admin/Components/Statuses';
import AdminCampaign from '../Components/Admin/Components/Campaign';
import AdminChanges from '../Components/Admin/Components/Charges';
import Requests from '../Pages/Requests';
import Home from '../Pages/Home';
import SubscriptionAdmin from '../Components/External/SubscriptionAdmin';
import RetokenizationAdmin from '../Components/External/RetokenizationAdmin';
import AdminExclusion from '../Components/Admin/Components/Exclusion';

interface RouteOld {
  path: string;
  secure: boolean;
  element: JSX.Element;
}

interface Route {
  path: string;
  component: (props: any) => JSX.Element,
  name?: string;
  children?: Route[];
  secure: boolean;
  type: string
}

const routes: Route[] = [
  {
    path: '/',
    secure: false,
    component: Home,
    type: ''
  },
  {
    path: '/external/card-update/:token',
    secure: false,
    component: RetokenizationAdmin,
    type: 'reporte'
  },
  {
    path: '/external/suscripcion/administracion/:token',
    secure: false,
    component: SubscriptionAdmin,
    type: 'reporte'
  },
  {
    path: '/backoffice',
    secure: true,
    component: Admin,
    type: 'reporte'
  },
  {
    path: '/backoffice/contract',
    secure: true,
    component: Admin,
    type: 'plans'
  },
  {
    path: '/backoffice/status',
    secure: true,
    component: AdminStatuses,
    type: ''
  },
  {
    path: '/backoffice/cobros',
    secure: true,
    component: AdminChanges,
    type: ''
  },
  {
    path: '/backoffice/exclusion',
    secure: true,
    component: AdminExclusion,
    type: ''
  },
    {
    path: '/backoffice/campaign',
    secure: true,
    component: AdminCampaign,
    type: 'campaign'
  },
  {
    path: '/backoffice/adscriptions/:product/:token',
    secure: true,
    component: SubscriptionAdmin,
    type: 'reporte'
  },
  {
    path: '/formulario-lme/:code?',
    secure: false,
    component: Form,
    type: 'lme'
  },
  {
    path: '/formulario-be/:code?',
    secure: false,
    component: Form,
    type: 'be'
  },
  {
    path: '/formulario-em/:code?',
    secure: false,
    component: Form,
    type: 'em'
  },
  {
    path: '/requests/:uuid',
    secure: false,
    component: Requests,
    type: ''
  }
];

const routesOLD: RouteOld[] = [
  {
    path: '/external/suscripcion/administracion/:token',
    secure: false,
    element: <SubscriptionAdmin />,
  },
  {
    path: '/backoffice',
    secure: true,
    element: <Admin type="reporte" />,
  },
  {
    path: '/backoffice/contract',
    secure: true,
    element: <Admin type="plans" />,
  },
  {
    path: '/backoffice/status',
    secure: true,
    element: <AdminStatuses />,
  },
  {
    path: '/backoffice/cobros',
    secure: true,
    element: <AdminChanges />,
  },
  {
    path: '/backoffice/exclusion',
    secure: true,
    element: <AdminExclusion />,
  },
  {
    path: '/backoffice/campaign',
    secure: true,
    element: <AdminCampaign />,
  },
  {
    path: '/backoffice/adscriptions/:product/:token',
    secure: true,
    element: <SubscriptionAdmin />,
  },
  {
    path: '/formulario-lme',
    secure: false,
    element: <Form type="lme" />,
  },
  {
    path: '/formulario-em',
    secure: false,
    element: <Form type="em" />,
  },
  {
    path: '/',
    secure: false,
    element: <Home />,
  },
  {
    path: '/requests/:uuid',
    secure: false,
    element: <Requests />
  }
];

export default routes;
