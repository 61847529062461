import React, { useContext, useEffect, useState } from 'react';
import { Button, InfoIcon, Skeleton, DataTable2, CheckIcon, Input, LoadingImed, UserHelp, XIcon } from '@imed_npm/design-system';
import { formatDotsRut } from '@imed_npm/valida-rut';

import LoggedUserContext from '../../../../context/LoggedUserContext';
import { excludeProfessional, getProfessionalsExcluded, excludeProfessionalDisable } from '../../utils/actions';
import ToastMessage from '../../../../utils/ToastMessage';
import { formatJsDate } from '../../utils/utils';
import Modal from '../../../Modal';
import image from '../../../External/assets/modal.png';
import styles from './style.module.scss';
import { finished } from 'stream';

const AdminExclusion = () => {
  const context = useContext(LoggedUserContext);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<any>(undefined);
  const [data, setData] = useState<any>();
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [showModal, setShowModal] = useState(false);
  const [modalDisableData, setModalDisableData] = useState<any>();
  const [finished, setFinish] = useState(true);

  const excludeRut = () => {
    if (!search) return;
    setLoading(true);
    setFinish(false);
    excludeProfessional(context, search).then((response) => {
      if (response.active) {
        ToastMessage.enviarToastSuccess('Rut excluído correctamente');
      } else {
        ToastMessage.enviarToastError(response.error ?? 'Error al excluir');
      }
    }).finally(() => { setFinish(true); setLoading(false); setSearch(''); });
  };

  const disabledUser = () => {
    setFinish(false);
    setLoading(true);
    const { nid, active } = modalDisableData;
    // setLoading(true);
    excludeProfessionalDisable(context, nid, !active).then(() => {
      setShowModal(false);
      setModalDisableData(undefined);
    }).finally(() => {
      setFinish(true);
    });
  };

  const modify = (action, value) => {
    setModalDisableData(value);
    setShowModal(true);
  };

  const ActionCellRender = ({ row }: any) => {
    const { original } = row;
    return (<Button size="s" type={original.active ? 'danger' : 'primary'} buttonType='button' onClick={(e) => modify(e, original)}>{original.active ? 'Desactivar' : 'Activar'}</Button>);
  };

  const columns = [
    {
      Cell: ({ value }) => value,
      Header: 'ID',
      accessor: 'id',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => <p className='w-40 break-word whitespace-normal'>{value || 'Sin nombre'}</p>,
      Header: 'rut',
      HeaderWidth: 'w-[31%]',
      accessor: 'nid',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value ? formatJsDate(value) : '-',
      Header: 'Creación',
      HeaderWidth: 'w-[31%]',
      accessor: 'created_at',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value ? formatJsDate(value) : '-',
      Header: 'Actualización',
      HeaderWidth: 'w-[31%]',
      accessor: 'updated_at',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ActionCellRender,
      Header: 'Acciones',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }
  ];

  useEffect(() => {
    if (finished) {
      const timeoutId = setTimeout(() => {
        getProfessionalsExcluded(context).then(setData).finally(() => { setLoadingTable(false); setLoading(false); });
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [finished]);

  return <>
    {loading && <LoadingImed text="Cargando..." />}

    <div className="bg-white p-6">
      <h1 className="text-secondary-imed-60 text-title tracking-[0.1px] font-normal my-4 mt-0">Excluir CI de profesional</h1>
      <p className={`${styles.subtitle} pb-5`}>Ingrese rut para remover validación de CI en suscripción</p>
      <hr />
      <div className='flex items-end my-5'>
        <div className='mr-2'>
          <Input
            focused
            name="value"
            onChange={(e) => { setSearch(e.target.value); }}
            value={search}
            placeholder="Escribe un rut"
            title="Ingrese rut"
            customSize="l"
          />
        </div>
        <Button size="m" type='secondary' buttonType='button' onClick={excludeRut} disabled={!search}>Excluir</Button>
      </div>
      {/* Tabla  */}
      {
        (data && !loadingTable) ? (
          <DataTable2
            columns={columns}
            data={data}
            disableHeader
            orderBy="id"
            perPage={10}
            totalResults={data && data.length ? data.length : 0}
            colorStyle="neutral"
          />
        ) : (
          <div className='w-full border flex flex-col p-20 justify-center items-center'>
            <InfoIcon size="m" />
            <p className='mt-2'>Sin datos</p>
          </div>
        )
      }
      {/* Modal */}
      {showModal &&
        <Modal title={''} showModal={showModal} setShowModal={setShowModal}>
          <div className='flex flex-col justify-center items-center px-10 mx-10'>
            <img src={image} alt="modal disable" />
            <h1 className={`text-center py-2 ${styles.title}`}>¿Estas seguro que<br />quieres deshabilitar?</h1>
            {
              modalDisableData && <p>Rut: {formatDotsRut(modalDisableData?.nid)}</p>
            }
            <div className='flex items-center justify-center mt-5'>


              <Button buttonType="button" type="secondary-two" className='mr-4' onClick={() => { setShowModal(false); }}>Salir</Button>
              <Button buttonType="button" type="secondary" disabled={loading} loading={loading} loadingText="Guardando" onClick={disabledUser}>Sí</Button>
            </div>
          </div>
        </Modal>
      }
    </div>
  </>;
};

export default AdminExclusion;