import { Step2, Steps } from '../../../utils/interfaces';
import { validFormat } from '../../../utils/rut';

interface NavigationValidations {
  backDisable: boolean;
  nextDisable: boolean;
}

export const checkNavigationStep1 = () => {
  return {
    backDisable: true,
    nextDisable: false
  };
};

export const checkNavigationStep21 = (step: Step2) => {
  const { nid, frontCode, backCode, serial, professionalHasDebt, validations } = step;
  const nextDisable = !(
    validations.serial.success &&
    validations.info.success &&
    !!frontCode &&
    !!backCode &&
    !!nid &&
    !!serial &&
    !professionalHasDebt);

  return {
    backDisable: false,
    nextDisable
  };
};

export const checkNavigationStep22 = ({ step2, step22 }: Steps) => {
  const { nid } = step2;
  const { address, backCode, frontCode, serial, commune, email, social, region, nid: nid2, name } = step22;

  //console.log('step2', step2);
  //console.log('step22', step22);

  const nextDisable = !(
    !!frontCode &&
    !!backCode &&
    !!nid &&
    !!serial &&
    (!!commune && commune.id) &&
    (!!region && region.id) &&
    !!address &&
    !!email &&
    !!social &&
    !!nid2 &&
    !!name
    && validFormat(step22.nid)
    && validFormat(step2.nid)
    // && step2.validations.existing.success
    && step22.validations.serial.success
    && step2.validations.nid.success
    && step22.validations.nid.success
    // && step22.validations.address.success
  );
  return {
    backDisable: false,
    nextDisable
  };
};

export const checkNavigationStep3 = ({ step3 }: Steps): NavigationValidations => {
  const { stored: { ci, professional }, validations: { email }, emailVerificated } = step3;

  const canAdvance = email.success && professional.validated && ci.front && ci.back && emailVerificated;

  return {
    backDisable: false,
    nextDisable: !canAdvance
  };
};

export const checkNavigationStep4 = ({ step4 }: Steps) => {
  const { finished, center } = step4;
  const canContinue = finished && !!center.name; //!(!center.center_code); // validar algo de center

  return {
    backDisable: false,
    nextDisable: !canContinue,
  };
};

export const checkNavigationStep5 =
  async ({ step5 }: Steps) => {
    const { plan, readers } = step5;

    const canStore = Boolean(plan.id) && readers > 0;
    // const canAdvance =  canStore && validations.plans.success;

    return {
      backDisable: false,
      nextDisable: canStore,
    };
  };

export const checkNavigationStep6 = ({ step6 }: Steps) => {
  const { validated, storedContract } = step6;
  const canAdvance = !!storedContract && validated;
  return {
    backDisable: false,
    nextDisable: !canAdvance
  };
};

// EM

export const checkNavigationEMStep5 = ({ step51 }: Steps): NavigationValidations => {
  const { plan_Selected } = step51;
  const canAdvance = plan_Selected.code !== '';
  return {
    backDisable: true,
    nextDisable: !canAdvance
  };
};

export const checkNavigationEMStep55 = ({ step55 }: Steps): NavigationValidations => {

  const canAdvance = Boolean(step55.cvp) && Boolean(step55.name);

  return {
    backDisable: false,
    nextDisable: !canAdvance
  };
};


export const checkNavigationEMStep6 = ({ step61 }: Steps): NavigationValidations => {
  const { bankSelected, file } = step61;

  const { bank, number, validate, type } = bankSelected;

  const canAdvance = Boolean(bank.id) && number && number.length > 2 && (number === validate) && Boolean(type.id) && Boolean(file);

  return {
    backDisable: false,
    nextDisable: !canAdvance
  };
};

export const checkNavigationStep8 = ({ step8 }: Steps): NavigationValidations => {

  const canAdvance = step8.validation;

  return {
    backDisable: false,
    nextDisable: !canAdvance
  };
};

export const checkNavigationFinal = ({ step7 }: Steps): NavigationValidations => {

  const canAdvance = step7.validation;

  return {
    backDisable: !canAdvance,
    nextDisable: true
  };
};


////////////////// BE /////////////////////


export const checkNavigationStep1BE = ({ step2, step22 }: Steps) => {
  const { nid } = step2;
  const { address, backCode, frontCode, serial, commune, email, social, region, nid: nid2, name, address_detail } = step22;

  //console.log('step2', step2);
  //console.log('step22', step22);

  const nextDisable = !(
    !!frontCode &&
    !!backCode &&
    !!nid &&
    !!serial &&
    (!!commune && commune.id) &&
    (!!region && region.id) &&
    !!address &&
    !!email &&
    !!social &&
    !!nid2 &&
    !!name
    && validFormat(step22.nid)
    && validFormat(step2.nid)
    // && step2.validations.existing.success
    && step22.validations.serial.success
    && step2.validations.nid.success
    && step22.validations.nid.success
    // && step22.validations.address.success
  );

  return {
    backDisable: true,
    nextDisable
  };
};