import React from 'react';
import AttachFile from '../ProfessionalInfo/Components/AttachFile';

const CVP = () => {

  return (
    <>
      <div className='flex justify-center my-10'>
        <img src="images/warning.svg" className='h-40 mr-8' />
        <div className='w-3/5'>
          <p className='mb-10'>Para habilitar el servicio de Bono Electrónico, debes contar con un convenio vigente con Fonasa o Isapre, e <strong>ingresar aquí un documento de Convenio de Venta Prestador</strong>, que respalde dicho convenio.</p>
          <div className='flex justify-between items-start'>
            <AttachFile />
          </div>
        </div>
      </div>
    </>
  );
};

export default CVP;
