import React, { useContext, useEffect, useState } from 'react';
import { DataTable2, Skeleton, DataTabs, InfoIcon } from '@imed_npm/design-system';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import { Professionals } from '../../interfaces/professional';
import { Status } from '../../interfaces/status';
import { getProfessionalStatusHistory, getProfessionalPlanHistory } from '../../utils/actions';
import { ProfessionalStatusHistory } from '../../utils/interfaces';
import { formatJsDate } from '../../utils/utils';
import styles from './styles.module.scss';

interface OwnProps {
  professional: Professionals;
  statusList: Status[];
}

const StatusHistory = ({ professional, statusList }: OwnProps) => {
  const context = useContext(LoggedUserContext);
  const [history, setHistory] = useState<ProfessionalStatusHistory[]>();
  const [planHistory, setPlanHistory] = useState<any[]>();

  useEffect(() => {
    const adscriptionId = professional.adscription_id;
    getProfessionalStatusHistory(context, Number(adscriptionId)).then(setHistory);
  }, []);

  useEffect(() => {
    const adscriptionId = professional.adscription_id;
    getProfessionalPlanHistory(context, Number(adscriptionId)).then(setPlanHistory);
  }, []);

  const data = () => {
    const _history = history ?? [];
    return _history.map(({ comment, new_status, created_at }) => ({ comment, new_status, created_at }))
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
  };

  const columns = [
    {
      Cell: ({ value }) => value ? formatJsDate(value) : 'Sin nombre',
      Header: 'Fecha',
      HeaderWidth: 'w-[31%]',
      accessor: 'created_at',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />,
      sortDescFirst: true
    },
    {
      Cell: ({ value }) => statusList.find(({ value: v2 }) => value == v2)?.name || 'Sin nombre',
      Header: 'Nuevo Estado',
      HeaderWidth: 'w-[31%]',
      accessor: 'new_status',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Comentario',
      HeaderWidth: 'w-[31%]',
      accessor: 'comment',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
  ];

  const columnsPlan = [
    {
      Cell: ({ value }) => value ? formatJsDate(value) : 'Sin nombre',
      Header: 'Fecha',
      HeaderWidth: 'w-[31%]',
      accessor: 'created_at',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />,
      sortDescFirst: true
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Auditoría',
      HeaderWidth: 'w-[31%]',
      accessor: 'audit_code',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Rut responsable',
      HeaderWidth: 'w-[31%]',
      accessor: 'user',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Comentario',
      HeaderWidth: 'w-[31%]',
      accessor: 'comment',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
     {
      Cell: ({ value }) => value || '-',
      Header: 'Plan anterior',
      HeaderWidth: 'w-[31%]',
      accessor: 'old_plan_id',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
      {
      Cell: ({ value }) => value || '-',
      Header: 'Plan seleccionado',
      HeaderWidth: 'w-[31%]',
      accessor: 'new_plan_id',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.professional_info}>
        <p>
          <span>Prestador:</span> {professional.nid}
        </p>
        <p>
          <span>Código de lugar:</span> {professional.centers[0].code}
        </p>
      </div>
      <DataTabs childrenCustomClass="bg-neutral-white"
        colorStyle="light-blue"
        customClass="border-child"
        mode="tab"
        step={1}
      >
        <div title="Estados">
          {history ? <DataTable2
            columns={columns}
            data={data()}
            disableHeader
            orderBy="created_at"
            perPage={10}
            totalResults={history.length}
            sortBy="asc"
            colorStyle="neutral"
          /> : <div className='w-full border flex flex-col p-20 justify-center items-center'>
            <InfoIcon size="m" />
            <p className='mt-2'>Sin historial de estados</p>
          </div>}
        </div>
        <div title="Planes">
          {
            (planHistory && planHistory.length > 0) ? <DataTable2
              columns={columnsPlan}
              data={planHistory}
              disableHeader
              orderBy="created_at"
              perPage={10}
              totalResults={planHistory.length}
              sortBy="asc"
              colorStyle="neutral"
            /> : <div className='w-full border flex flex-col p-20 justify-center items-center'>
              <InfoIcon size="m" />
              <p className='mt-2'>Sin historial de planes</p>
            </div>
          }
        </div>
      </DataTabs>
    </div>
  );
};

export default StatusHistory;
