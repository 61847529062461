import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ContractSign from './Components/ContractSign';
import { getRequestInfo } from './utils/actions';
import { RequestInfo } from './utils/interfaces';
import { ThinkingFaceEmoji, AvocadoEmoji, UserHelp, Button } from '@imed_npm/design-system';
import styles from './style.module.scss';

const Requests = () => {

  const { uuid } = useParams<{ uuid: string; }>();
  const [requestInfo, setRequestInfo] = useState<RequestInfo>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!uuid) return;

    const timer = setTimeout(() => {
      getRequestInfo({ uuid }).then(setRequestInfo);
    }, 500);
    return () => clearTimeout(timer);
  }, [uuid]);

  return (
    <>
      {!requestInfo &&
        <div className={styles.loader_box}>
          <ThinkingFaceEmoji customWidth='32' customHeight='32' /> Obteniendo información...
        </div>
      }
      {requestInfo && requestInfo.type == 'Firma contrato' &&
        <ContractSign info={requestInfo} uuid={uuid} />
      }
      {requestInfo && !requestInfo.active &&
        <>
          <UserHelp
            icon={<AvocadoEmoji />}
            title="Oh no!"
            text="El código que estas intentando utilizar, ya no está activo por lo que es necesario que vuelvas a realizar la solicitud para generar uno válido. Muchas gracias."
            type="danger"
            button={
              <Button buttonType='button' type="secondary" onClick={() => navigate('/')} >
                Volver al menú principal
              </Button>
            }
          />
        </>
      }
    </>
  );
};

export default Requests;
