import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import LoggedUserContext from '../../context/LoggedUserContext';
import { HeaderExtended, Button, LogOutIcon } from '@imed_npm/design-system';
import getEnv from '../../getEnv';
import { useNavigate } from 'react-router-dom';
const APP_ID = getEnv('REACT_APP_ID');

interface IInstitution {
  auth_type?: number;
  branchOffices: IBranchOffice[];
  enabled?: boolean;
  id?: string;
  institutionType?: number;
  name?: string;
  show: boolean;
}

interface IBranchOffice {
  apps: IApp[];
  enabled: boolean;
  id: number;
  name: string;
  place_code: number;
}

interface IApp {
  enabled: boolean;
  id: string;
  name: string;
  resources: IResource[];
}

interface IResource {
  resource_app_id: string,
  resource_enabled: boolean;
  resource_id: string;
  resource_name: string;
}

interface OwnProps {
  logo: string;
}

interface MenuItem {
  id: string;
  label: string;
  url?: string;
  command?(event: any): void;
}


const HeaderExtendedComponent = ({ logo }: OwnProps) => {
  const context = useContext(LoggedUserContext);
  const navigate = useNavigate();
  const [list, setList] = useState<IInstitution[]>([]);
  const [filterList, setFilterList] = useState<IInstitution[]>([]);
  const [selectedItem, setSelectedItem] = useState();

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [items, setItems] = useState<MenuItem[]>([
    {
      id: '1',
      label: 'Reportes',
      command: () => {
        navigate('/backoffice');
      }
    }
  ]);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  useEffect(() => {
    const { institutions } = context.userData;
    setList(
      institutions.map((institution) => {
        const { apps } = institution.branchOffices[0];
        const result = apps.filter(app => app.id === APP_ID);
        let show = false;
        if (result && result.length > 0) {
          show = true;
        }
        return { ...institution, show };
      })
    );
  }, [context]);

  useEffect(() => {
    if (list && list.length > 0) {
      const result = list.filter(listElement => listElement.show === true);
      setFilterList(result);
    }
  }, [list]);

  useEffect(() => {
    const activeInst = window.localStorage?.getItem('activeInstitution');
    const localActiveInstitution = activeInst ? JSON.parse(activeInst) : false;
    if (localActiveInstitution) {
      const addResources = { ...localActiveInstitution, resources: localActiveInstitution.branchOffices[0].apps[0].resources };
      window.localStorage.setItem('activeInstitution', JSON.stringify(addResources));
      setSelectedItem(localActiveInstitution.id);
    }
  }, [context]);

  // const id = useRef(null);

  useEffect(() => {
    // Add green class on selected institution on page refresh
    if (selectedItem && list.length > 0) {
      const result = list.findIndex(item => item.id === selectedItem);
      setSelectedOption(result);
    }
  }, [list, selectedItem]);

  useEffect(() => {
    if (!context) return;
    setShowMenu(false);
    const activeInstitution = context.userData.activeInstitution?.id;
    const dataInstitution = context.userData.institutions.find(o => o.id === activeInstitution);
    if (dataInstitution) {
      const findAppInstitution = dataInstitution.branchOffices[0].apps.find(o => o?.id === APP_ID);
      if (findAppInstitution) {
        const { resources } = findAppInstitution;
        // Función para verificar si un recurso está habilitado
        const isResourceEnabled = (resourceName: string) =>
          resources.some((resource) => resource.resource_name === resourceName && resource.resource_enabled);

        // Función para agregar rutas según los recursos habilitados
        const addRoutesBasedOnPermissions = () => {
          const updatedItems = items.filter((item) => item.label === 'Reportes'); // Mantenemos el primer item

          if (isResourceEnabled('GestionContratos')) {
            updatedItems.push({
              id: '2',
              label: 'Contratos',
              command: () => {
                navigate('/backoffice/contract');
              }
            });
          }

          if (isResourceEnabled('CRUD-Statuses')) {
            updatedItems.push({
              id: '4',
              label: 'Estados',
              command: () => {
                navigate('/backoffice/status');
              }
            });
          }

          if (isResourceEnabled('cobro-mensual') || isResourceEnabled('cobro-pendiente') || isResourceEnabled('cobro-cliente')) {
            updatedItems.push({
              id: '5',
              label: 'Cobros',
              command: () => {
                navigate('/backoffice/cobros');
              }
            });
          }

          if (isResourceEnabled('exclusion-ci-professional')) {
            updatedItems.push({
              id: '6',
              label: 'Excluir',
              command: () => {
                navigate('/backoffice/exclusion');
              }
            });
          }

          if (isResourceEnabled('gestion-descuentos')) {
            updatedItems.push({
              id: '7',
              label: 'Campañas',
              command: () => {
                navigate('/backoffice/campaign');
              }
            });
          }

          setItems(updatedItems);
          setShowMenu(true);
        };
        addRoutesBasedOnPermissions();
      }
    } else {
      Swal.fire({
        title: 'Usuario sin permisos',
        text: 'El usuario no tiene permisos asignados a esta app, redireccionando al login',
        didOpen: () => {
          Swal.showLoading();
          setTimeout(function () {
            localStorage.removeItem('activeInstitution');
      context.keycloak?.logout();
          }, 3500);
        },
      });
    }
  }, [context]);


  return (
    <>
      {
        showMenu &&
        <HeaderExtended
          // subProductLogo={<a href="/"><img src={logo} /></a>}
          actions={
            <Button buttonType='button' className='mt-2' size="m" type="danger" Icon={LogOutIcon} onClick={() => {
              localStorage.removeItem('activeInstitution');
              context.keycloak?.logout();
            }} />
          }
          items={items}
          withOptions={true}
          isCompactLogo={false}
          truncateLabel={false}

          userInfo={{
            firstName: context?.userData?.name.split(' ')[0] ?? '',
            lastName: context?.userData?.name.split(' ')[1] ?? '',
            activeInstitution: {
              id: 1,
              name: 'Bienvenido'
            },
            //activeInstitution: context.userData?.institutions?.find( i => i.enabled),
          }}

          signOut={() => {
            // console.log('signOut');
            localStorage.removeItem('activeInstitution');
            // keycloak.logout()
            context.keycloak?.logout();

          }}
        />
      }

    </>
  );
};
HeaderExtendedComponent.propTypes = {};

HeaderExtendedComponent.defaultProps = {};

export default HeaderExtendedComponent;
