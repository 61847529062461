import { useContext } from 'react';
import {
  BrowserRouter,
} from 'react-router-dom';
import LoggedUserContext from './context/LoggedUserContext';
import getEnv from './getEnv';
import Layout from './Layout';
const IS_KEYCLOAK_DISABLED = getEnv('REACT_APP_KEYCLOAK_DISABLED') === 'true';
import isRouteProtected from './utils/isRouteProtected';


function App() {
  const context = useContext(LoggedUserContext);
  if (!IS_KEYCLOAK_DISABLED && (!context.token || !context.userData?.idUser) && isRouteProtected(window.location.pathname)) {
    return <span>{/* 'Cargando...' */}</span>;
  } else {
    return (
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    );
  }
}
/* <RouterProvider router={router} fallbackElement={<LoadingImed text='Cargando' />} /> */

export default App;
