import { useState } from 'react';
import { initMercadoPago, Payment } from '@mercadopago/sdk-react';
import { AllOrArray, IBrickVisual, MpModalProps } from './mpModal.interface';
import { EStatusEnrolmentCard } from './statusEnrolmentCard.enum';
import { LoadingImed } from '@imed_npm/design-system';
import getEnv from '../../../../getEnv';

function PaymentModal({ setShowInputCardMPModal, setStatusCardMPModal, professional, showLoader, billingInfo, adscriptionId }: MpModalProps): JSX.Element {
  const [IsLoadingSearch, setIsLoadingSearch] = useState(showLoader);
  const { nid, email } = professional;
  const baseURLBackend = getEnv('REACT_APP_BASE_URL');

  const tokenMP = process.env.REACT_APP_MERCADOPAGO_ACCESS_TOKEN ?? getEnv('REACT_APP_MERCADOPAGO_ACCESS_TOKEN');
  initMercadoPago(`${tokenMP}`);
  const initialization = {
    amount: 1000,
    payer: {
      email
    }
  };
  const customization = {
    paymentMethods: {
      creditCard: 'all' as AllOrArray,
      debitCard: 'all' as AllOrArray
    },
    visual: {
      hideFormTitle: true,
      texts: {
        formSubmit: 'Continuar'
      },
      style: {
        theme: 'flat', // | 'dark' | 'bootstrap' | 'flat'
      } as IBrickVisual
    },
  } as any;
  const onSubmit = async (
    { selectedPaymentMethod, formData }
  ) => {
    console.log(selectedPaymentMethod);
    console.log(formData);
    console.log(billingInfo);
    // callback llamado al hacer clic en el botón enviar datos
    formData.nid = nid;
    return new Promise((resolve, reject) => {
      fetch(`${baseURLBackend}cards/retokenize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ formData: formData, billing_info: billingInfo, adscription_id: adscriptionId }),
      })
        .then((response) => {
          if (!response.ok) {
            setShowInputCardMPModal(false);
            setStatusCardMPModal(EStatusEnrolmentCard.REJECT);
            throw new Error(`Error HTTP: ${response.status}`);
          }
          return response.json();
        })
        .then((response) => {
          console.debug('TODO BIEN!!', response);

          setShowInputCardMPModal(false);
          setStatusCardMPModal(EStatusEnrolmentCard.SUCCESS);
          // recibir el resultado del pago
          resolve(true);
        })
        .catch((error) => {
          console.debug('Problemas!!', error);
          setStatusCardMPModal(EStatusEnrolmentCard.ERROR);
          setShowInputCardMPModal(false);
          // manejar la respuesta de error al intentar crear el pago
          reject();
        });
    });
  };
  const onError = async (error): Promise<void> => {
    // callback llamado para todos los casos de error de Brick
    console.error('Que es esto', error);
    return new Promise((resolve) => {
      setStatusCardMPModal(EStatusEnrolmentCard.ERROR);
      setShowInputCardMPModal(false);
      resolve();
    });
  };
  const onReady = async (): Promise<void> => {
    /*
      Callback llamado cuando el Brick está listo.
      Aquí puede ocultar cargamentos de su sitio, por ejemplo.
    */
    return new Promise((resolve) => {
      setStatusCardMPModal(EStatusEnrolmentCard.PENDING);
      setIsLoadingSearch(false);
      resolve();
    });
  };



  return (
    <>
      {IsLoadingSearch && (<LoadingImed text="Cargando Medio de Pago" />)}
      <Payment
        initialization={initialization}
        customization={customization}
        locale='es-CL'
        onSubmit={onSubmit}
        onReady={onReady}
        onError={onError}
      />
    </>
  );
}

export default PaymentModal;
