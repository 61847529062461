import React, { createContext, useContext, useReducer } from 'react';
import { Action, reducer } from './reducers';
import { RADIO_OPTION } from '../Components/ProfileSelection/constants';
import { InitialState, SelectOptionItem } from '../../../utils/interfaces';

type CountProviderProps = { children: React.ReactNode }

interface ContextStep {
  state: InitialState;
  dispatch: React.Dispatch<Action>
}

const readersSelectOptions: SelectOptionItem[] = [];

for (let i = 1; i <= 100; i++) {
  readersSelectOptions.push({ value: i, id: i, name: String(i) });
}

export const initialState: InitialState = {
  token: '',
  product: '',
  adscription_id: 0,
  form: {
    personContractType: RADIO_OPTION.nature.value,
    personPlanType: undefined,
  },
  general: {
    regions: []
  },
  info: {
    nro_registro: '',
    sexo: '',
    nombres: '',
    apellido_paterno: '',
    apellido_materno: '',
    fecha_nacimiento: '',
    fecha_registro: '',
    nacionalidad: '',
    rut: 0,
    dv: '',
    codigo_busqueda: '',
    universidad: '',
    observaciones: '',
    fecha_carga: '',
    antecedentes: [],
  },
  // info: exampleInfo, // '',
  step: 'step1',
  steps: {
    error: {
      showModal: false,
      message: '',
    },
    success: {
      showModal: false,
      message: '',
    },
    step1: {},
    step2: {
      nid: '',
      // nid: exampleStep2.nid, // '',
      serial: '',
      frontCode: '',
      // frontCode: exampleStep2.frontCode, // '',
      backCode: '',
      // backCode: exampleStep2.backCode, // '',
      validations: {
        debt: { error: false, success: false, pending: false },
        info: { error: false, success: false, pending: false },
        serial: { error: false, success: false, pending: false },
        storeCI: { error: false, success: false, pending: false },
        existing: { error: false, success: false, pending: false, warning: false },
        nid: { error: false, success: false, pending: false }
      },
      professionalHasDebt: false,
      debtType: ''
    },
    step22: {
      nid: '',
      serial: '',
      frontCode: '',
      backCode: '',
      address: '',
      address_detail: '',
      commune: {
        id: '',
        code: '',
        city_id: '',
        name: '',
        created_at: '',
        updated_at: '',
      },
      email: '',
      name: '',
      professionalHasDebt: false,
      debtType: '',
      region: {
        id: '',
        code: '',
        name: '',
        created_at: '',
        updated_at: '',
        cities: [],
      },
      social: '',
      validations: {
        address: { error: false, success: false, pending: false },
        debt: { error: false, success: false, pending: false },
        info: { error: false, success: false, pending: false },
        serial: { error: false, success: false, pending: false },
        existing: { error: false, success: false, pending: false, warning: false },
        nid: { error: false, success: false, pending: false }
      },
    },
    step3: {
      email: '',
      emailRepeated: '',
      emailVerificationCode: '',
      verificationCodeSended: false,
      emailVerificated: false,
      phone: '',
      phone_country_code: '+56',
      validations: {
        email: { error: false, success: false, pending: false },
        professional: { error: false, success: false, pending: false },
        ci: { error: false, success: false, pending: false },
        verificationCode: { error: false, success: false, pending: false },
      },
      stored: {
        ci: {
          front: false,
          back: false,
        },
        professional: {
          error: '',
          validated: false,
        },
      }
    },
    step4: {
      storedCenterInfo: undefined,
      finished: false,
      addedCenters: [],
      center: {
        center_code: '',
        phone: '',
        name: '',
        address: '',
        address_detail: '',
        locality: '',
        locality_id: '',
        region: '',
        region_id: '',
        city: '',
        city_id: '',
      },
      validations: {
        address: { error: false, success: false, pending: false },
        center: { error: false, success: false, pending: false },
      },
    },
    step5: {
      plan: {
        id: 0,
        name: '',
        description: '',
        price: 0,
        transactions: 0,
        type: 0,
        status: 0,
        created_at: '',
        updated_at: '',
        image: '',
        transaction_cost: 0,
        code: '',
        contract_template: '',
        mail_template: '',
        // contract_template: examplePlans[0].contract_template,
        // mail_template: examplePlans[0].mail_template,
        commercialized: 0,
        items: '',
        product: '',
        feature_plan: [],
        discount_price: 0,
      },
      readers: 1,
      validations: {
        plans: { error: false, success: false, pending: false },
      },
      discount: '',
      readersSelectOptions,
    },
    step51: {
      plans: [],
      plan_Selected: {
        id: 0,
        name: '',
        description: '',
        price: 0,
        transactions: 0,
        type: 0,
        status: 0,
        created_at: '',
        updated_at: '',
        image: '',
        transaction_cost: 0,
        code: '',
        contract_template: '',
        mail_template: '',
        // contract_template: examplePlans[0].contract_template,
        // mail_template: examplePlans[0].mail_template,
        commercialized: 0,
        items: '',
        product: '',
        feature_plan: [],
        discount_price: 0,
      },
    },
    step55: {
      cvp: '',
    },
    step6: {
      accepted: false,
      validated: false,
      oid: '',
      validations: {
        checkSmsCode: { error: false, success: false, pending: false },
        sendSmsCode: { error: false, success: false, pending: false },
        storeContract: { error: false, success: false, pending: false },
      },
      storedContract: undefined,
    },
    step61: {
      bankList: [],
      bankSelected: {
        bank: {
          id: 0,
          name: ''
        },
        type: {
          id: 0,
          name: ''
        },
        number: '',
        validate: ''
      },
      file: ''
    },
    step7: {
      validation: false
    },
    step8: {
      validation: false
    }
  }
};

const StepContext = createContext<ContextStep | undefined>(undefined);


function StepProvider({ children }: CountProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <StepContext.Provider value={value}>
      {children}
    </StepContext.Provider>
  );
}

function UseStep(): ContextStep {
  const context = useContext(StepContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider');
  }
  return context;
}

export { StepProvider, UseStep };
