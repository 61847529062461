export enum EProfessionalStatus {
    DISABLED = 0, // Producto de baja
    SIN_TARJETA = 1, // SIN TARJETA NO SE USA
    PENDIENTE = 2, // No termino el proceso de suscripción
    ENABLED = 3, // Cierre OT, estado informado por QBiz 
    SUSCRITO = 4, // Terminado exitosamente el proceso de suscripción, pero sin enviar a QBiz
    PROSPECT_CREATE = 7, // enviado a QBiz
    CONTRACT_PENDING = 8, // Contrato Pendiente
    INSTALLATION = 10, // en instalación
    SERVICES_ENABLED = 11, // servicios Habilitados
    INCOMPLETE_SALE = 12, // venta incompleta
}

export enum EPersonType {
    NATURAL = 1,
    JURIDICA = 2,
}

export enum EPersonNidType {
    RUT = '1',
    PASAPORTE = '2',
}


export enum EServices {
    BONO = 3,
}