/* eslint-disable */
import React, {
  useState, useEffect,
} from 'react';
import { Input, Button, Select } from '@imed_npm/design-system';
import { UseStep } from '../../../Store/step-context';
import SpecialtyRow from './newRow'
import styles from './styles.module.scss'
import { IResponseHomologateMedic } from '../../../../External/interfaces/subscriptionAdmin.interface';

interface OwnProps {
  info: any;
}

function AddSpecialties({ info }: OwnProps) {

  const { dispatch, state } = UseStep();

  const [rows, setRows] = useState<JSX.Element[]>([]);
  const [rowId, setRowId] = useState(0);
  const [isMultipleRow, setIsMultipleRow] = useState(false);
  const [selected, setSelected] = useState('');
  const [principalPrice, setPrincipalPrice] = useState();
  const [NE, setNE] = useState();
  const [especialidades, setEspecialidades] = useState(info.specialties);
  const [AddAvailable, setAddAvailable] = useState(true);

  const [data, setData] = useState<{ specialtyId: string; amount: number }[]>([]);
  const [prices, setPrices] = useState<number[]>([]);
  const [specialties, setSpecialties] = useState<any[]>([]);

  const [rowsToDelete, setRowsToDelete] = useState<number[]>([]);

  useEffect(() => {
    if (!selected && info.specialties && info.specialties.length > 0) {
      setEspecialidades(info.specialties);
      setSelected(info.specialties[0]);
    }
  }, [info]);

  useEffect(() => {
    if (especialidades) {
      if (!selected) {
        setAddAvailable(true);
      } else {
        if (especialidades.length === (rows.length + 1)) {
          setAddAvailable(true);
        } else if (!principalPrice) {
          setAddAvailable(true);
        } else {
          setAddAvailable(false);
        }
      }
    }
  }, [selected, rows, especialidades, principalPrice]);

  useEffect(() => {
    dispatch({ type: 'SELECTED_SPECIALTIES', payload: data });
  }, [data, prices]);

  useEffect(() => {
    if (principalPrice === 0) dispatch({ type: 'SET_FINISHED_CENTER', payload: false });
  }, [principalPrice]);

  useEffect(() => {
    if (data.length > 0) {
      const updatedData = data.map((item, index) => {
        if (index === 0) {
          // @ts-ignore
          return { specialtyId: selected.homologation.id || '', amount: principalPrice || 0 };
        } else {
          return item;
        }
      });
      setData(updatedData);
    } else {
      if (selected) {
        setData([{
          // @ts-ignore
          specialtyId: selected.homologation.id || '',
          amount: principalPrice || 0
        }]);
      }
    }

  }, [principalPrice, selected]);

  const filas: IResponseHomologateMedic[] = [];

  const agregarFila = (especialidad: IResponseHomologateMedic) => {
    filas.push(especialidad);
    const nuevasEspecialidades = especialidades.filter((e) => e.specialty !== especialidad.specialty);
    setNE(nuevasEspecialidades);
    setRows([
      ...rows,
      <SpecialtyRow
        key={rowId}
        id={rowId}
        especialidad={nuevasEspecialidades}
        setSpecialtySelected={(specialty) => addRowSpecialty(rowId, specialty)}
        onAmountChange={(price) => addPrice(rowId, price.amount)}
        onDelete={() => deleteRow(rowId)}
      />,
    ]);
    setRowId(rowId + 1);
    setData([
      ...data,
      { specialtyId: especialidad.homologation.id, amount: prices[prices.length - 1] ?? 0 },
    ]);
  };

  const handleAgregarClick = () => {
    const especialidad = especialidades.find((e) => e === selected);
    if (especialidad) {
      agregarFila(especialidad);
    }
  };

  const deleteItemFromData = (id: number) => {
    setData((prevData) => prevData.filter((_, index) => index !== id));
  };

  const deleteRow = (id: number) => {
    const updatedRows = rows.filter((_, index) => index !== id);
    setRows(updatedRows);
    deleteItemFromData(id);
    setRowsToDelete((prevRowsToDelete) => [...prevRowsToDelete, id]);
  };

  useEffect(() => {
    if (rowsToDelete.length > 0) {
      const updatedRows = rows.filter((_, index) => !rowsToDelete.includes(index));
      setRows(updatedRows);
      setRowsToDelete([]);
    }
  }, [rows, rowsToDelete]);

  const addPrice = (id: number, price: number) => {
    const updatedPrices = [...prices];
    updatedPrices[id] = price;
    setPrices(updatedPrices);
  };

  const addRowSpecialty = (id: number, item: any) => {
    const updatedSpecialties = [...specialties];
    updatedSpecialties[id] = item;
    setSpecialties(updatedSpecialties);
  };

  const handleDataChange = (updatedData: { specialtyId: string, id: number }) => {
    setData((prevData) => {
      const rowId = updatedData.id;
      const newData = prevData.map((item, index) => {
        if ((rowId + 1) === index) {
          return { ...item, specialtyId: updatedData.specialtyId };
        }
        return item;
      });
      return newData;
    });
  };

  const handleAmountChange = (updatedData: { amount: any, id: number }) => {
    setData((prevData) => {
      const rowId = updatedData.id;
      const number = updatedData.amount.replace(/[.,]/g, '')

      const newData = prevData.map((item, index) => {
        if ((rowId + 1) === index) {
          return { ...item, amount: Number(number) };
        }
        return item;
      });
      return newData;
    });
  };

   const handleInputChange = (event) => {
    const inputValue = event.target.value.replace(/[.,]/g, '');
    setPrincipalPrice(inputValue);
  };

  useEffect(() => {
    if (rows.length > 0) {
      setIsMultipleRow(true);
    } else {
      setIsMultipleRow(false);
    }
  }, [rows]);

  return (
    <>
      <p className={`${styles.subtitle} mt-10`}>Selecciona la especialidad y proporciona el precio de la consulta particular. Si lo deseas, puedes agregar más de una especialidad y más de un valor de consulta. <strong>Esta información se mostrará en tu Escritorio Médico al utilizar la agenda médica.</strong></p>

      {
        (especialidades && especialidades.length) && <div className="flex items-end justify-between mt-5">
          <div className='flex items-end'>
            <Select
              customClass={styles.z0}
              customSize="l"
              items={info.specialties}
              name="specialty"
              nameType="specialty"
              placeholder="Seleccione especialidad"
              title="Especialidad"
              disabled={isMultipleRow}
              // onChange={handleSelectChange}
              value={especialidades[0]}
              onChange={(value) => setSelected(value)}
            />

            <div className='ml-5'>
              <Input
                focused
                name="name"
                // @ts-ignore
                // onChange={(e) => setPrincipalPrice(Number(e.target.value))}
                onChange={handleInputChange}
                value={principalPrice}
                customSize="l"
                placeholder="Ingresa monto consulta"
                title="Precio consulta particular"
                type="number"
              />
            </div>
          </div>
          {
            (especialidades.length > 1) && <Button
              buttonType="button"
              type="secondary"
              disabled={AddAvailable}
              onClick={handleAgregarClick}>
              Agregar nueva especialidad
            </Button>
          }
        </div>
      }
      {rows.map((row, index) => (
        <SpecialtyRow
          key={index}
          id={index}
          especialidad={NE ? NE : especialidades}
          onDelete={() => deleteRow(index)}
          onDataChange={handleDataChange}
          onAmountChange={handleAmountChange}
        />
      ))}
    </>
  );
};

export default AddSpecialties;
