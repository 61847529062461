import { validateRUT } from '@imed_npm/valida-rut';

export const RUT_FORMAT_REGEX = /^[\D0]*([\d]{7,}-[\dk]{1})[\D0]*$/i;
export const RUT_VALID_INPUT = /[^\d-k]/gi;
export const formatRut = (rut: string): string => {
  rut = clearRut(rut.toUpperCase()).replaceAll(/[^\dkK]/g, '');
  const _rut: string[] = rut.split('');

  if (rut.length >= 2) _rut.splice(-1, 0, '-');

  rut = _rut.join('');
  const validated = RUT_FORMAT_REGEX.exec(rut);
  return validated ? validated[1] : rut;
};

export const clearRut = (rut: string): string => rut.replaceAll(RUT_VALID_INPUT, '');

export const validFormat = (rut: string): boolean => RUT_FORMAT_REGEX.test(rut) && validateRUT(rut);