import React, { useEffect, useState, useContext } from 'react';
import { Button, Select, Input, Switch, XIcon, Label, TextArea } from '@imed_npm/design-system';
import { DateTime } from 'luxon';

import { editDiscount } from '../../utils/actions';
import LoadingComponent from '../../../Form/Components/LoadingComponent/LoadingComponent';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import DatePickerComponent from '../Charges/ChargesByDate/Components/DatePickerInputComponent';
import ToastMessage from '../../../../utils/ToastMessage';

import styles from './style.module.scss';

interface IEditProgramProps {
  programId: number;
  reloadUpdateComponent?: (programData: { programId: any; }) => void;
  program: any;
  itemsType: any;
  dataPlan: any;
  itemsUnity: any;
  itemsTypeUsage: any;
  setUpdate?: any;
  update: boolean;
}

interface ItemType {
  id: number;
  description: string;
}

interface ItemUnity {
  id: number;
  name: string;
}

const EditProgram = (props: IEditProgramProps): JSX.Element => {
  const { programId, reloadUpdateComponent, program, itemsType, dataPlan, itemsUnity, itemsTypeUsage, setUpdate, update } = props;
  const { original } = program;

  const [loading, setLoading] = useState(true);

  const context = useContext(LoggedUserContext);
  const [name, setName] = useState(original.name);
  const [description, setDescription] = useState(original.description);
  const [cupon, setCupon] = useState(original.code);
  const [monthAppy, setMonthsAplly] = useState<any>(original.months_application);
  const [amount, setAmount] = useState<any>(original.amount);
  const [maxUsage, setMaxUsage] = useState<any>(original.max_usage);
  const [status, setStatus] = useState(original.status);

  const todayDate = DateTime.fromISO(DateTime.local().toISODate() || '');
  // const startFormatted = (original.start_date).toFormat('yyyy-MM-dd');
  // const endFormattedsetSelectedProduct = (original.expiration_date).local().toFormat('yyyy-MM-dd');

  const [type, setType] = useState<any>();
  const [typeUsage, setTypeUsage] = useState<any>();
  const [unity, setUnity] = useState<any>();
  const [plan, setPlan] = useState<any>();
  const [defaultPlan, setDefaultPlan] = useState<any>();
  const [defaultType, setDefaultType] = useState<any>();
  const [defaultTypeUsage, setDefaultTypeUsage] = useState<any>();
  const [defaultUnity, setDefaultUnity] = useState<any>();

  const [errorStartDate, setErrorStartDate] = useState('');
  const [errorEndDate, setErrorEndDate] = useState('');
  const [startDate, setStartDate] = useState(original.start_date);
  const [endDate, setEndDate] = useState(original.expiration_date);
  const [validationMessage, setValidationMessage] = useState<string>();

  const validateDate = (date) => {
    const formatRegex = /^\d{4}-\d{2}-\d{2}$/;
    const formatRegex2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

    if (formatRegex2.test(date)) {
      return true;
    } else if (formatRegex.test(date)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    validateDates();
  }, [startDate, endDate]);

  const validateDates = () => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (!startDate || !endDate) {
      setValidationMessage('Por favor, selecciona ambas fechas.');
      return;
    }

    if (end < start) {
      setValidationMessage('La fecha de fin no puede ser menor que la fecha de inicio.');
    } else {
      setValidationMessage(undefined);
    }
  };

  const formatCoupon = (text) => {
    const formatText = text.replace(/\s/g, '').toUpperCase();
    return formatText;
  };

  const edit = () => {
    const [year, month] = startDate.split('-');
    const [y, m] = endDate.split('-');

    const formattedStartDate = `${year}${month}`;

    // if (!name || !type || !monthAppy || !selectedProduct) ToastMessage.enviarToastError('Campos faltantes');
    const data = {
      id: +original.id,
      name: name,
      description: description,
      code: formatCoupon(cupon),
      start_date: validateDate(startDate) ? startDate : new Date(startDate + 'T00:00:00.000Z').toISOString(),
      expiration_date: validateDate(endDate) ? endDate : new Date(endDate + 'T00:00:00.000Z').toISOString(),
      start_period_application: +formattedStartDate,
      expiration_period_application: +(y + m),
      status: status ? 1 : 0,
      max_usage: 10000,
      product_id: +programId,
      // discount_type: type ? type?.id : +original.discount_type,
      amount: +amount,
      months_application: +monthAppy,
      plan_id: plan ? +plan : +original.plan_id,
      unit_type_id: unity ? +unity?.id : +original.unit_type_id,
      discount_type_usage_id: typeUsage ? typeUsage?.id : +original.discount_type_usage_id,
      discount_type_id: type ? type?.id : +original.discount_type_id,
    };
    editDiscount(context, data).then(() => ToastMessage.enviarToastSuccess('Campaña editada correctamente')).finally(() => { program.toggleRowExpanded(false); setUpdate(!update); });
  };

  useEffect(() => {
    const filterPLan = dataPlan.find((plan) => plan.id === original.plan_id);
    setDefaultPlan(filterPLan);
  }, [dataPlan]);

  useEffect(() => {
    const filterUnity = itemsUnity.find((unit) => unit.id === original.unit_type_id);
    setDefaultUnity(filterUnity);
  }, [itemsUnity]);

  useEffect(() => {
    const filterType = itemsType.find((item) => item.id === original.discount_type_id);
    setDefaultType(filterType);
  }, [itemsType]);

  useEffect(() => {
    const filterTypeUsage = itemsTypeUsage.find((item) => item.id === original.discount_type_usage_id);
    setDefaultTypeUsage(filterTypeUsage);
  }, [itemsTypeUsage]);

  useEffect(() => {
    if (defaultType) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [defaultType, defaultPlan]);

  return <>
    {loading ? <LoadingComponent /> : <div className='flex flex-col my-4'>
      <Switch
        label="Campaña activada"
        id="status"
        className="py-6"
        customSize="l"
        checked={status}
        onClick={() => setStatus(!status)}
      />
      {
        dataPlan && <div className="mb-4">
          <Select
            customClass={styles.z0}
            customSize="m"
            items={dataPlan}
            name="name"
            nameType="name"
            placeholder="Seleccione una opción"
            onChange={(e) => setPlan(e.id)}
            value={plan ? plan : defaultPlan}
            title="Plan"
            id="plan"
          />
        </div>
      }
      <div className='mb-4'>
        <Select
          customClass={styles.z0}
          customSize="m"
          items={itemsTypeUsage}
          name="description"
          nameType="description"
          placeholder="Seleccione una opción"
          onChange={(e) => setTypeUsage(e)}
          value={typeUsage ? typeUsage : defaultTypeUsage}
          title="Tipo de campaña"
          id="type"
        />
      </div>
      <hr className="w-full my-4" />

      <Input customSize="l" name="name" id="name"
        minLength={5} maxLength={100} title='Nombre de campaña'
        placeholder='Ingrese el nombre' value={name}
        onChange={({ target: { value } }) => { setName(value); }}
        stateMessage={name === '' ? '' : (name.length < 5 ? 'Nombre debe contener al menos 5 caracteres' : (name.length > 100 ? 'Nombre puede contener hasta 100 caracteres' : 'Nombre válido'))}
        stateType={name === '' ? 'default' : (name.length < 5 ? 'error' : (name.length > 100 ? 'error' : 'success'))}
      />

      <div className='pb-3 max-w-sm mt-4'>
        <TextArea
          minLength={5}
          maxLength={255}
          name="description"
          onChange={(e) => { setDescription(e.target.value); }}
          value={description}
          placeholder="Escribe una descripción"
          title="Descripción"
          stateMessage={description === '' ? '' : (description.length < 5 ? 'Descripción debe contener al menos 5 caracteres' : (description.length > 255 ? 'Descripción puede contener hasta 255 caracteres' : 'Descripción válida'))}
          stateType={description === '' ? 'default' : (description.length < 5 ? 'error' : (description.length > 255 ? 'error' : 'success'))}
        />
      </div>
      <Input
        customSize="l" name="cupon" id="cupon"
        minLength={1} maxLength={26}
        title='Código de Cupón' placeholder='Ingrese el codigo de cupón'
        value={cupon} onChange={({ target: { value } }) => { setCupon(value); }}
        stateMessage={cupon === '' ? 'Cupón se guardará sin espacios y en mayúsculas' : (cupon.length < 1 ? 'Cupón debe contener al menos 1 caracter' : (cupon.length > 26 ? 'Cupón puede contener hasta 26 caracteres' : `Cupón válido - ${formatCoupon(cupon)}`))}
        stateType={cupon === '' ? 'info' : (cupon.length < 1 ? 'error' : (cupon.length > 26 ? 'error' : 'success'))}
      />


      <hr className="w-full my-4" />
      <div className="flex items-end">
        <div className='mb-4 mr-6'>
          <Select
            customClass={styles.z0}
            customSize="s"
            items={itemsType}
            name="description"
            nameType="description"
            placeholder="Seleccione una opción"
            onChange={(e) => setType(e)}
            value={type ? type : defaultType}
            title="Tipo de descuento"
            id="type"
          />
        </div>

        <div className='mt-1 mb-4'>
          <Select
            customClass={styles.z0}
            customSize="s"
            items={itemsUnity}
            name="name"
            nameType="name"
            placeholder="Seleccione una opción"
            onChange={(e) => setUnity(e)}
            value={unity ? unity : defaultUnity}
            title="Unidad de descuento"
          />
        </div>
      </div>
      <div className='mb-4'>
        <Input customSize="l" minLength={1} maxLength={5} name="amount"
          id="amount" title='Monto de descuento' type="number"
          placeholder='Ingrese el monto' value={amount}
          onChange={({ target: { value } }) => { setAmount(value); }}
          stateMessage={!amount ? '' : (amount.length < 1 ? 'Monto debe contener al menos 1 caracter' : (amount.length > 5 ? 'Monto puede contener hasta 5 caracteres' : 'Monto válido'))}
          stateType={!amount ? 'default' : (amount.length < 1 ? 'error' : (amount.length > 5 ? 'error' : 'success'))}
        />
      </div>

      {/* <div className="mb-4">
        <Input customSize="l" name="max" id="max_usage" title='Máximo de beneficiarios' type="number" placeholder='Ingrese el máximo de uso' onChange={({ target: { value } }) => { setMaxUsage(value); }} value={maxUsage} />
      </div> */}

      <hr className="w-full my-4" />

      <div className="mb-4">
        <Input customSize="l"
          minLength={1} maxLength={5}
          name="month" id="months_application"
          type="number" title='Meses de duración de descuento'
          placeholder='Ingrese el tiempo de duración'
          // onChange={({ target: { value } }) => { setMonthsAplly(value); }}
          onChange={({ target: { value } }) => { setMonthsAplly(value); }}
          value={monthAppy}
          // stateMessage={!monthAppy ? '' : (Number.isInteger(+monthAppy) ? 'si lo es!' : 'no es entero')}
          stateMessage={!monthAppy ? '' : (monthAppy.length < 1 ? 'Campo debe contener al menos 1 caracter' : (monthAppy.length > 5 ? 'Campo puede contener hasta 5 caracteres' : ((Number.isInteger(+monthAppy) ? 'Campo válido' : 'Valor debe ser n° entero'))))}
          stateType={!monthAppy ? 'default' : (monthAppy.length < 1 ? 'error' : (monthAppy.length > 5 ? 'error' : (Number.isInteger(+monthAppy) ? 'success' : 'error')))}
        />
      </div>

      <div className='flex items-start grow mt-4' id="dates">
        <div>
          <DatePickerComponent
            title="Fecha de inicio"
            calendarClassName={styles.calendar}
            stateMessage={errorStartDate || ''}
            stateType={errorStartDate ? 'error' : 'default'}
            onChange={(e) => { console.log('e.value', e); setStartDate(e.target.value); }}
            value={startDate}
            maxDate={''}
            setFieldTouched={function (name: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined): void {
              console.log('name');
            }} />
        </div>

        <div className='pl-4'>
          <DatePickerComponent
            title="Fecha de termino"
            stateMessage={errorEndDate || ''}
            stateType={errorEndDate ? 'error' : 'default'}
            onChange={(e) => { setEndDate(e.target.value); }}
            maxDate={''}
            value={endDate}
            setFieldTouched={function (name: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined): void {
              console.log('name');
            }} />
        </div>
      </div>
       {
        validationMessage && <div className='mb-4 flex items-start'>
          <XIcon size="s" pathClass="stroke-error" />
          <Label
            customClass="font-light ml-2"
            htmlFor="dates"
            title={validationMessage}
          />
        </div>
      }

      <div className='flex justify-between mt-4'>
        <Button size="m" type='secondary-two' buttonType='button' id="cancel" onClick={() => program.toggleRowExpanded(false)}>Cancelar</Button>

        <Button size="m" type='secondary'
          buttonType='button' id="editButton"
          onClick={edit}
          disabled={(name.length < 5 || name.length > 100) || (description.length < 5 || description.length > 255 ) || validationMessage || (cupon.length < 1 || cupon.length > 26) || (!amount || amount.length > 5) || (!monthAppy || monthAppy.length > 5 || !Number.isInteger(+monthAppy))}
        >Editar campaña</Button>
      </div>
    </div>}
  </>;
};
export default EditProgram;