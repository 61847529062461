import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, IndexPointingUpEmoji, UserHelp, Select, InfoIcon, Input, Skeleton } from '@imed_npm/design-system';
import getEnv from '../../../../getEnv';
import styles from './style.module.scss';
import { UseStep } from '../../Store/step-context';
import { getPlans, storeCenter, discountValidate } from '../../Store/actions';
import { GetPlansData } from '../../../../utils/interfaces';
import Modal from '../../../Modal';
import ModalSuccess from './ModalSuccess';
import AddCenter from '../CenterInfo/addCenter';

const ProductPlan = () => {

  const params = useParams();

  const [code, setCode] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [showModalAddress, setShowModalAddress] = useState(false);
  const [available, setAvailable] = useState(false);
  const [message, setModalMessage] = useState<string>('');
  const [finished, setFinished] = useState(false);
  const [dataCenter, setDataCenter] = useState<any>();
  const [defaultAddress, setDefaultAddress] = useState<any>();
  const [refresh, setRefresh] = useState(false);

  const { dispatch, state, state: { adscription_id, product, general, steps: { success, step2: { nid }, step4, step4: { center, storedCenterInfo }, step5,
    step5: { readersSelectOptions, readers, plan, validations } } } } = UseStep();
  const [readersQuantity, setReadersQuantity] = useState<number>(readers);

  useEffect(() => {
    dispatch({ type: 'RESET_STEP', payload: { step: 'step6' } });
  }, []);

  // Store Center
  useEffect(() => {
    if (general && general?.shipping?.address !== undefined) return;
    const timeout = setTimeout(() => {
      storeCenter({ dispatch, payload: { center, nid, product, adscription_id }, newShipping: true });
    }, 250);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (params && params.code) return;
    const timeout = setTimeout(() => {
      if (plan.id) return;
      const payload: GetPlansData = { product: 'LME' };
      getPlans({ dispatch, payload });
    }, 600);
    return () => clearTimeout(timeout);
  }, [step4.validations.center]);

  useEffect(() => {
    if (readersQuantity > 0) {
      dispatch({ type: 'STORE_READERS', payload: { readers: readersQuantity } });
    }
  }, [readersQuantity]);

  const renderCheckingInfo = () => {
    const { plans } = validations;
    const { center } = step4.validations;

    if (!plan.id || plans.pending || center.pending)
      return (
        <div className={styles.loading_info}>
          <InfoIcon svgClass={styles.icon} />
          <div>
            {center.error && <p>Hubo un problema al registrar el centro médico, por favor volver a intentarlo.</p>}
            {!center.error && center.pending && <p>Almacenando centro médico...</p>}
            {center.success && !plan.id && <p>Obteniendo información de planes...</p>}
          </div>
        </div>
      );

    return;
  };

  const validate = () => {
    if (code) {
      discountValidate(dispatch, code, 'LME', plan.id);
    }
  };

  useEffect(() => {
    setModalMessage(success.message);
    if (success && success.showModal && code.length > 3) {
      setShowModal(success.showModal);
      dispatch({ type: 'STORE_PROMOCIONAL_CODE', payload: { discount: code } });

      if (params && params.code) {
        const payload: GetPlansData = { product: 'LME', discount: code };
        getPlans({ dispatch, payload });
      }
    }
  }, [success, code]);

  useEffect(() => {
    if (step5.discount) {
      setCode(step5.discount);
      setAvailable(true);
    }
  }, [step5]);

  useEffect(() => {
    if (params && params.code) {
      const { code } = params;
      if (!code) return;
      setCode(code);
      validate();
    }
  }, [params, plan]);

  useEffect(() => {
    if (!dataCenter) return;
    setFinished(true);
    const { dataCenter: internalDataCenter, ...rest } = dataCenter;
    const mergedData = { ...rest, ...internalDataCenter };

    setDefaultAddress(mergedData);
    dispatch({ type: 'SET_SHIPPING', payload: mergedData });
  }, [dataCenter]);

  useEffect(() => {
    if (general.shipping) {
      setDefaultAddress(general.shipping);
      setFinished(false);
    } else if (general.shipping_locality !== '') {
      setDefaultAddress(general.shipping_locality);
      setFinished(false);
    }
  }, [general]);

  useEffect(() => {
    if (finished) {
      setRefresh(true);
    } else {
      setRefresh(false);
    }
  }, [finished]);

  return (
    <>
      {showModal && (
        <Modal showModal={showModal} >
          <ModalSuccess text={message} setShowModal={setShowModal} />
        </Modal>
      )}
      {renderCheckingInfo()}
      {Boolean(plan.id) && Boolean(storedCenterInfo) && step4.validations.center.success &&
        <div className={styles.product_plan}>
          <div className={styles.uf}>
            <span>Seleccione número de lectores</span>
            <div className='reader-select'>
              <Select
                customSize="m"
                items={readersSelectOptions}
                name="readers"
                nameType="name"
                onChange={(value) => setReadersQuantity(value)}
                value={readersQuantity}
              />
            </div>
            <span>=</span>
            <span className={styles.border}>Monto fijo a pagar</span>
            {
              plan.discount_applied ?
                <div className='flex flex-col'>
                  <span className={styles.priceBefore}>antes {plan.price} {plan.discount_unit_name}+IVA / mes</span>
                  <span className={styles.priceNow}>{(readers * plan.discount_price).toFixed(1)}UF + IVA/mensual </span>
                </div>
                :
                <span>{(readers * plan.price).toFixed(1)}UF + IVA/mensual </span>
            }
          </div>
          <p className='mt-4 mb-8 text-primary'>
            <span className={styles.help_description} >
              <strong>¿Cómo se calcula el monto total a pagar?:</strong> éste corresponde al monto fijo más el monto variable transaccional:
            </span>
          </p>
          {Boolean(plan.id) && <div className='grid grid-cols-2 gap-2'>
            <div className={styles.table_info}>
              <div className={styles.header}>
                <span>Lector de huella </span>
                <span>UF mes + IVA*</span>
              </div>
              <div className={styles.body}>
                <span>Costo Unitario</span>
                <span>{plan?.discount_amount}</span>
              </div>
            </div>
            <div className={styles.table_info}>
              <div className={styles.header}>
                <span>Costo por transacción </span>
                <span>UF + IVA / mensual</span>
              </div>
              <div className={styles.body}>
                <span>{plan?.description}</span>
                <span>{plan.transaction_cost}</span>
              </div>
            </div>
          </div>}

          {/* codigo promocional */}
          <div className="w-full full-userhelp">
            <UserHelp
              colorStyle="green"
              icon={<IndexPointingUpEmoji svgClass="h-10 w-10" />}
              text={<>
                <div className='flex items-center grow'>
                  <p className='mb-0 grow'><strong>¿Cuentas con un código promocional?</strong>, ingresalo aquí para utilizarlo</p>
                  <div className='flex'>
                    <Input
                      name="promocional"
                      onChange={(e) => { setCode(e.target.value); }}
                      value={code}
                      placeholder="Ingresa código"
                      type="text"
                      customSize="s"
                      disabled={available}
                    />

                    <Button
                      size="s"
                      type="secondary"
                      buttonType='button'
                      className='ml-2'
                      disabled={code.length < 3 || available}
                      onClick={validate}
                    >
                      Aplicar
                    </Button>
                  </div>
                </div>
              </>}
            />
          </div>

          {/* direccion */}
          {
            (getEnv('REACT_APP_SHIPPING_VALIDATION') === 'true' || getEnv('REACT_APP_SHIPPING_VALIDATION') === true) && defaultAddress && <div>
              <p className={`${styles.subtitle} mt-6`}>B. Dirección de entrega de lector de huellas </p>
              <p className='mb-5'>Para asegurar la entrega adecuada de los lectores biométricos, verifica que la dirección de envío sea la correcta. <u>En caso contrario, ajústala para garantizar su entrega en el lugar correcto.</u></p>
              <div className="flex items-center">
                <div className="w-3/4">
                  {
                    refresh ? <Skeleton
                      borderColor="default"
                      customClass=""
                      customSize="m"
                    /> :
                      <AddCenter align="center" direction="row" setFinished={setFinished} addCenter={setDataCenter} defaultAddress={defaultAddress} hideName={true} hideButton={true} hideRegion={true} blockItems={true} />
                  }
                </div>
                <div className='w-1/4 flex justify-end items-end mb-5'>
                  <Button buttonType='button' onClick={() => { setShowModalAddress(true); }} type="secondary">
                    Modificar
                  </Button>
                </div>
              </div>
            </div>
          }
          <Modal title={'Modificar dirección de envío'} showModal={showModalAddress} setShowModal={setShowModalAddress} containerClass='w-4/12'>
            <AddCenter align="full" setShowModal={setShowModalAddress} setFinished={setFinished} addCenter={setDataCenter} hideName={true} newShipping={true} />
          </Modal>
        </div>}
    </>
  );
};

export default ProductPlan;
