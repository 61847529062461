export interface ResponseVerifyEmToken {
  status: number;
  token: string;
  userInfo: UserInfo
}

export interface UserInfo {
  nid: string,
  name: string,
  codLugar: string[]
}

export enum ETypeOfActionToPerson {
  EDIT = 'edit',
  CREATE = 'create'
}

export enum ETypeOfActionToProfessional {
  ENABLE = 'enable',
  DISABLE = 'disable'
}
