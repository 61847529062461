import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { RocketEmoji, UserHelp, Button, Input } from '@imed_npm/design-system';

interface OwnProps {
  phone: string;
  phone_country_code?: string;
  sendCode: () => void;
  setSmsCode: React.Dispatch<React.SetStateAction<string>>;
  checkSmsCode: () => void;
  canResend: boolean;
  checking: boolean;
  codeStateMessage: () => { message: string; type: 'error' | 'success' | 'info' | 'warning' | 'default'; };
}

const SignContract = ({ phone, phone_country_code, sendCode, setSmsCode, checkSmsCode, canResend, checking, codeStateMessage }: OwnProps) => {
  const _timer = 210;
  const [code, setCode] = useState<string>('');
  const [timer, setTimer] = useState<number>(0);

  useEffect(() => setSmsCode(code), [code]);

  useEffect(() => {
    if (timer) {
      setTimeout(() => setTimer(timer - 1), 1000);
    }
  }, [timer]);

  const printTimer = () => {
    const minutes = Math.trunc(timer / 60);
    const seconds = timer % 60;

    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };
  const sendSmsCode = () => {
    setTimer(_timer);
    sendCode();
  };

  useEffect(() => setTimer(_timer), []);

  return (
    <div className={styles.sign_contract}>
      <h2 className={styles.title}>
        Firma de contrato
      </h2>
      <UserHelp
        colorStyle="green"
        icon={<RocketEmoji svgClass="h-10 w-10" />}
        text={<div style={{ fontSize: '14px' }}>
          Hemos enviado un SMS al número registrado <strong>{phone_country_code || '+56'} {phone}</strong> con el código de verificación, recuerda que tienes <strong>{printTimer()}</strong> para ingresarlo correctamente.
        </div>}
        button={
          <Button
            buttonType='button'
            className="!min-w-[145px] mr-4 h-10"
            disabled={!canResend || timer > 0}
            onClick={sendSmsCode}
            size="s"
            type="secondary">Reenviar código</Button>}
      />
      {canResend && <div>
        <Input
          focused
          disabled={checking}
          name="name"
          onChange={({ target: { value } }) => setCode(value)}
          placeholder="Ingresa codigo"
          title="Código de validación"
          type="text"
          customSize="m"
          stateMessage={codeStateMessage().message}
          stateType={codeStateMessage().type}
        />
      </div>}
      {canResend && <Button
        buttonType='button'
        className={'px-4 py-2'}
        onClick={checkSmsCode}
        disabled={!code || checking}
        form="createProgram"
        size="s"
        type="secondary">Firmar Contrato</Button>}
    </div>
  );
};


export default SignContract;


