import { AXIOS, AXIOS_SECURE, AXIOS_APIKEY, CANCEL_TOKEN } from '../../../utils/axios';
import {
  BILLING_INFO, VOUCHERS, CHECK_DEBT,
  CHECK_VERIFICATION_CODE,
  CREATE_CENTER,
  GET_DEC_CHECK,
  GET_INFO,
  GET_SPECIALTIES,
  GET_LME_BRANCHES,
  GET_PLANS,
  GET_REGIONS_WITH_LOCALITIES,
  GOOGLE_PLACES_API,
  PROSPECT_BACKOFFICE_STATUS,
  SEND_CONTRACT_EMAIL,
  SEND_VERIFICATION_EMAIL,
  STORE_ADSCRIPTION,
  STORE_CI,
  STORE_CONTRACT,
  STORE_PROFESSIONAL,
  TRUST_CHECK_CODE,
  TRUST_SEND_CODE,
  TRUST_SERVICE,
  GET_TOKEN,
  GET_PROFESSIONAL,
  CHECK_PFP_DEBT,
  BANKS,
  CVP,
  VALIDATE_ADDRESS,
  DISCOUNT_VALIDATE,
  ADSCRIPTION_INIT,
  ADSCRIPTION_BANK_INFO
} from '../../../utils/endpoints';
import {
  ActionParameters,
  Adscription,
  Center,
  CheckSmsCodeData,
  GooglePlacesApiPrediction,
  LMECenter,
  Plan,
  Region,
  Bank,
  storeCVPdata,
  SendSmsCodeData,
  StoreCenterData,
  StoreCIData,
  StoreContractData,
  StoredContract,
  StoreProfessionalData,
  TrustSendCode,
  TrustStatus,
  ResponsePatchBillingInfo,
  ResponseContractEmail,
  ResponseProspectAdapter
} from '../../../utils/interfaces';
import { CHECK_CI } from '../../../utils/vars';
import { Action } from './reducers';
import Swal from 'sweetalert2';
import ToastMessage from '../../../utils/ToastMessage';
import { IPfpResponse } from './pfp.interface';
import { DateTime, Interval } from 'luxon';
import { AxiosError } from 'axios';
import { RADIO_OPTION } from '../Components/ProfileSelection/constants';

const luxonEndOf = (date: any) => DateTime.fromJSDate(new Date(date)).toUTC().endOf('day').toJSDate();

export const getAddressValidation = async (dispatch: React.Dispatch<Action>, payload, step = 'step22') => {
  dispatch({ type: 'ADDRESS_VALIDATION', payload: { key: 'pending' }, step });

  try {
    const response = await AXIOS_APIKEY().post(VALIDATE_ADDRESS, payload);
    if (response.data.valid === true) {
      dispatch({ type: 'ADDRESS_VALIDATION', payload: { key: 'success' }, step });
    }
    else {
      dispatch({ type: 'ADDRESS_VALIDATION', payload: { key: 'error' }, step });
    }
  } catch (error) {

    dispatch({ type: 'ADDRESS_VALIDATION', payload: { key: 'error' }, step });
  }
};


export const getToken = async (dispatch: React.Dispatch<Action>, payload = null) => {
  try {
    const response = await AXIOS.post(GET_TOKEN);
    if (response.data) {
      window.sessionStorage.setItem('flowkey', response.data.data);
      dispatch({ type: 'SET_TOKEN', payload: { token: response.data.data } });

      initAdscription(response.data.data, dispatch);

    }
    else {
      errorModal('no se pudo crear la sesión');
    }
  } catch (error) {
    errorModal('no se pudo crear la sesión');
  }
};

export const getCheck = async (dispatch: React.Dispatch<Action>, payload, step = 'step2') => {
  dispatch({ type: 'CHECK_SERIAL_VALIDATION', payload: { key: 'pending' }, step });

  if (!CHECK_CI) {
    dispatch({ type: 'CHECK_SERIAL_SUCCESS', payload, step });
    dispatch({ type: 'CHECK_SERIAL_VALIDATION', payload: { key: 'success' }, step });
    return;
  }

  try {
    // se quita por cambio de dec a validacion simple
    // await AXIOS_APIKEY().post(GET_DEC_LOGIN);
    const response = await AXIOS_APIKEY().post(GET_DEC_CHECK, payload);
    if (response.data.result.Verificacion === 'V') {
      dispatch({ type: 'CHECK_SERIAL_SUCCESS', payload, step });
      dispatch({ type: 'CHECK_SERIAL_VALIDATION', payload: { key: 'success' }, step });
    }
    else {
      dispatch({ type: 'CHECK_SERIAL_VALIDATION', payload: { key: 'error' }, step });
    }
  } catch (error) {
    dispatch({ type: 'CHECK_SERIAL_VALIDATION', payload: { key: 'error' }, step });
  }
};

export const getInfo = async (dispatch: React.Dispatch<Action>, nid: string, type?: string, product?: string) => {
  dispatch({ type: 'GET_INFO_VALIDATION', payload: { key: 'pending' } });

  try {
    const response = await AXIOS_APIKEY().get(`${GET_INFO}?nid=${nid}`);
    dispatch({ type: 'GET_INFO', payload: { data: response.data.data } });
    dispatch({ type: 'GET_INFO_VALIDATION', payload: { key: 'success' } });
    if (type && type === 'company' && product !== 'BE') {
      dispatch({ type: 'SET_MODAL_ERROR', payload: 'Rut ingresado corresponde a médico particular' });
    }

    if (product === 'BE') {
      dispatch({ type: 'SET_PERSON_CONTRACT_TYPE', payload: { personContractType: RADIO_OPTION.nature.value } });
    }

  } catch (error) {
    if (product === 'BE') {
      dispatch({ type: 'SET_PERSON_CONTRACT_TYPE', payload: { personContractType: RADIO_OPTION.legal.value } });
    }

    if (type && type !== 'company') {
      dispatch({ type: 'GET_INFO_VALIDATION', payload: { key: 'error' } });
      dispatch({ type: 'SET_MODAL_ERROR', payload: 'Rut ingresado, no encontrado en superintendencia de salud' });
    }
  }
};

export const getSpecialties = async (dispatch: React.Dispatch<Action>, nid: string, type?: string) => {
  try {
    const response = await AXIOS_APIKEY().get(`${GET_SPECIALTIES}?nid=${nid}`);
    dispatch({ type: 'GET_SPECIALTIES', payload: { data: response.data } });
  } catch (error) {
    if (type && type !== 'company') {
      dispatch({ type: 'SET_MODAL_ERROR', payload: 'Rut ingresado, no encontrado en superintendencia de salud' });
    }
  }
};

export const getProfessionalStatus = async ({ dispatch, payload, step }: ActionParameters) => {
  dispatch({ type: 'CHECK_EXISTING_PROFESSIONAL', payload: { key: 'pending' }, step });
  try {
    const response = await AXIOS_APIKEY().get(`${GET_PROFESSIONAL}/status/${payload.nid}/${payload.product ?? 'LME'}`);
    const stateResponse = ((response.data.subscriptions > 0) && (response.data.status !== null && response.data.status !== 0 && response.data.status !== 12 && response.data.status !== 2)) ? 'error' : 'success';
    dispatch({ type: 'CHECK_EXISTING_PROFESSIONAL', payload: { key: stateResponse }, step });
    dispatch({ type: 'GET_SUSCRIPCION_STATUS', payload: { data: response.data } });
  } catch (error: any) {
    if (error?.response?.data?.status === 404) {
      dispatch({ type: 'CHECK_EXISTING_PROFESSIONAL', payload: { key: 'success' }, step });
    } else {
      dispatch({ type: 'CHECK_EXISTING_PROFESSIONAL', payload: { key: 'error' }, step });
    }
  }
};

function productoCorresponde(producto, elemento) {
  return elemento.plans?.products?.name === producto;
}

// /professional/newStatus/{nid}
export const getProfessionalProductStatus = async ({ dispatch, payload, step }: ActionParameters) => {
  dispatch({ type: 'CHECK_EXISTING_PROFESSIONAL', payload: { key: 'pending' }, step });
  try {
    const response = await AXIOS_APIKEY().get(`${GET_PROFESSIONAL}/newStatus/${payload.nid}`);
    const stateResponse = ((response.data.contract_date) && (response.data.status !== null && response.data.status !== 0 && response.data.status !== 12 && response.data.status !== 2 && response.data.status !== 13)) ? 'error' : 'success';

    const corresponde = response.data.some(function (elemento) {
      return productoCorresponde(payload.product, elemento);
    });

    if (response.data.length > 0) {
      if (corresponde) {
        dispatch({ type: 'CHECK_EXISTING_PROFESSIONAL', payload: { key: 'success' }, step });
      } else {
        dispatch({ type: 'CHECK_EXISTING_PROFESSIONAL', payload: { key: 'warning' }, step });
      }
    } else {
      dispatch({ type: 'CHECK_EXISTING_PROFESSIONAL', payload: { key: stateResponse }, step });
    }
    // dispatch({ type: 'CHECK_EXISTING_PROFESSIONAL', payload: { key: stateResponse }, step });

    dispatch({ type: 'GET_SUSCRIPCION_STATUS', payload: { data: response.data } });
  } catch (error: any) {
    if (error?.response?.data?.status === 404) {
      dispatch({ type: 'CHECK_EXISTING_PROFESSIONAL', payload: { key: 'success' }, step });
    } else {
      dispatch({ type: 'CHECK_EXISTING_PROFESSIONAL', payload: { key: 'error' }, step });
    }
  }
};


export const checkSerial = async ({ dispatch, payload, step }: ActionParameters) => {
  dispatch({ type: 'CHECK_SERIAL_VALIDATION', payload: { key: 'pending' }, step });

  if (!CHECK_CI) {
    dispatch({ type: 'CHECK_SERIAL_SUCCESS', payload, step });
    dispatch({ type: 'CHECK_SERIAL_VALIDATION', payload: { key: 'success' }, step });
    return;
  }

  try {
    // se quita por cambio de dec a validacion simple
    // await AXIOS_APIKEY().post(GET_DEC_LOGIN);
    const response = await AXIOS_APIKEY().post(GET_DEC_CHECK, payload);
    if (response.data.result.Verificacion === 'V') {
      dispatch({ type: 'CHECK_SERIAL_SUCCESS', payload, step });
      dispatch({ type: 'CHECK_SERIAL_VALIDATION', payload: { key: 'success' }, step });
    }
    else {
      dispatch({ type: 'CHECK_SERIAL_VALIDATION', payload: { key: 'error' }, step });
      dispatch({ type: 'SET_MODAL_ERROR', payload: 'Rut y serial ingresado, no es valido', step });
    }
  } catch (error) {
    dispatch({ type: 'CHECK_SERIAL_VALIDATION', payload: { key: 'error' }, step });
  }
};
export const storeCI = async (dispatch: React.Dispatch<Action>, payload: StoreCIData) => {
  dispatch({ type: 'STORE_CI_VALIDATION', payload: { key: 'pending' } });
  try {
    const { backCode, frontCode, nid } = payload;
    const _data = {
      front_code: frontCode,
      back_code: backCode,
      nid: nid
    };

    const { data: { front_code, back_code } } = await AXIOS_APIKEY().post<{ front_code: boolean; back_code: boolean; }>(STORE_CI, _data);
    dispatch({ type: 'STORE_CI_VALIDATION', payload: { key: 'success' } });
    dispatch({ type: 'STORE_CI_RESULT', payload: { result: { front: front_code, back: back_code } } });
  } catch (error) {
    dispatch({ type: 'STORE_CI_VALIDATION', payload: { key: 'error' } });
  }
};

export const checkDebt = async (dispatch, data: { nid: string, callback: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { nid, callback } = data;

  dispatch({ type: 'CHECK_DEBT_VALIDATION', payload: { key: 'pending' } });

  try {
    const path = `${CHECK_DEBT}?nid=${nid}`;
    const path_pfp_debt = `${CHECK_PFP_DEBT}`.replace(':rut', nid);

    //const { debt } = (await AXIOS_APIKEY().get<{ debt: boolean }>(path)).data;
    //const pfp_debt = await AXIOS_APIKEY().get(path_pfp_debt);
    const em_debt = await getDebtEM(nid);
    const pfp_debt = await getDebtPFP(nid);
    const hasDebt = (em_debt || pfp_debt);
    // console.log('em_debt', em_debt);
    // console.log('pfp_debt', pfp_debt);
    // const debt = false;
    dispatch({ type: 'PROFESSIONAL_HAS_DEBT', payload: { debt: hasDebt, debtType: getDebtType(em_debt, pfp_debt) } });
    dispatch({ type: 'CHECK_DEBT_VALIDATION', payload: { key: 'success' } });
    callback(hasDebt);
  } catch (error) {
    // dispatch({ type: 'CHECK_DEBT_VALIDATION', payload: { key: 'error' } });
    dispatch({ type: 'CHECK_DEBT_VALIDATION', payload: { key: 'success' } });
    // dispatch({ type: 'SET_MODAL_ERROR', payload: 'No se puede verificar la deuda del prestador' });
  }
};

const getDebtType = (em_debt: boolean, pfp_debt: boolean) => {
  if (em_debt && pfp_debt) {
    return 'EM_PFP';
  } else if (em_debt) {
    return 'EM';
  } else if (pfp_debt) {
    return 'PFP';
  } else {
    return '';
  }
};
async function getDebtEM(nid: string) {
  const path = `${CHECK_DEBT}?nid=${nid}`;
  const { debt } = (await AXIOS_APIKEY().get<{ debt: boolean }>(path)).data;
  return debt;
}

async function getDebtPFP(nid: string) {
  try {
    const path_pfp_debt = `${CHECK_PFP_DEBT}`.replace(':rut', nid);
    const pfp_debt = await AXIOS_APIKEY().get(path_pfp_debt);
    // console.log('pfp_debt', pfp_debt);
    const pfpData: IPfpResponse = pfp_debt.data;
    if (pfpData.status === 200 && pfpData.data.cantidad > 0) {
      const today = luxonEndOf(DateTime.now());
      const documentFind = pfpData.data.documentos.find((documento) => {
        const dateVcto = luxonEndOf(documento.FechaVcto);
        const diffDays = Interval.fromDateTimes(today, dateVcto);
        const countDiffDays = diffDays.length('days');
        // console.log("aaaa", countDiffDays);
        if (isNaN(countDiffDays) || countDiffDays < 0) {
          return documento;
        }
      });
      return !!documentFind;
    }
    return false;
  } catch (error: any) {
    console.log('error', error.response);
    return false;
  }
}

export const storeProfessional = async (dispatch: React.Dispatch<Action>, data?: StoreProfessionalData) => {
  dispatch({ type: 'STORE_PROFESSIONAL_VALIDATION', payload: { key: 'pending' } });
  dispatch({ type: 'STORE_CONTACT_INFO', payload: { phone: data?.phone, email: data?.email, phone_country_code: data?.phone_country_code, } });

  try {
    const { data: { stored, message } } = await AXIOS_APIKEY().post<{ stored: boolean, message?: string; }>(STORE_PROFESSIONAL, data);

    if (!stored) throw Error(message);

    dispatch({ type: 'STORE_PROFESSIONAL_VALIDATION', payload: { key: 'success' } });
    dispatch({ type: 'STORE_PROFESSIONAL_RESULT', payload: { result: { validated: stored } } });
  } catch (error: any) {
    const _error: string = error?.response?.data?.error?.message ?? String(error);
    //errorModal(_error ? _error : JSON.stringify(_error));
    ToastMessage.enviarToastError(_error);

    dispatch({ type: 'STORE_PROFESSIONAL_VALIDATION', payload: { key: 'error' } });
    dispatch({ type: 'STORE_PROFESSIONAL_RESULT', payload: { result: { error: _error, validated: false } } });
  }
};

export const sendVerificationCode = async ({ dispatch, payload }: ActionParameters) => {
  dispatch({ type: 'SEND_VERIFICATION_EMAIL', payload: { key: 'pending' } });
  try {
    const { data: { sended } } = await AXIOS_APIKEY().post<{ sended: boolean; }>(SEND_VERIFICATION_EMAIL, payload);

    dispatch({ type: 'VERICATION_EMAIL_SEND_RESULT', payload: { sended } });
    dispatch({ type: 'SEND_VERIFICATION_EMAIL', payload: { key: 'success' } });
  } catch (error) {
    dispatch({ type: 'SEND_VERIFICATION_EMAIL', payload: { key: 'error' } });
  }
};

export const checkVerificationCode = async ({ dispatch, payload }: ActionParameters) => {
  dispatch({ type: 'CHECK_VERIFICATION_CODE', payload: { key: 'pending' } });
  try {
    const { data: { valid } } = await AXIOS_APIKEY().post<{ valid: boolean; }>(CHECK_VERIFICATION_CODE, payload);
    dispatch({ type: 'CHECK_VERIFICATION_CODE_RESULT', payload: { valid } });
    dispatch({ type: 'CHECK_VERIFICATION_CODE', payload: { key: 'success' } });
  } catch (error) {
    dispatch({ type: 'CHECK_VERIFICATION_CODE', payload: { key: 'error' } });
  }
};

export const getPlans = async ({ dispatch, payload }: ActionParameters) => {
  let plans: Plan[];
  try {
    if (payload.product === 'LME' || payload.product === 'BE') {
      const { data: [plan] } = await AXIOS_APIKEY().get<Plan[]>(`${GET_PLANS}?product=${payload.product}${payload.discount ? '&discount_code=' + payload.discount : ''}`);
      dispatch({ type: 'STORE_PLAN', payload: { plan } });
    } else {
      const { data } = await AXIOS_APIKEY().get<Plan[]>(`${GET_PLANS}?product=${payload.product}${payload.discount ? '&discount_code=' + payload.discount : ''}`);
      plans = data;
      dispatch({ type: 'SET_TYPE_PLAN', payload: { plans } });
    }
  } catch (error: any) {
    const _error: string = error?.response?.data?.error?.message ?? String(error);
    errorModal(_error ? _error : JSON.stringify(_error));
  }
};

export const storeAdscription = async ({ dispatch, payload }: ActionParameters) => {
  dispatch({ type: 'STORE_ADSCRIPTION', payload: { key: 'pending' } });
  try {
    const response = await AXIOS_APIKEY().post<Adscription>(STORE_ADSCRIPTION, payload);
    dispatch({ type: 'STORE_ADSCRIPTION', payload: { key: 'success' } });
    return Number(response.data.id);
  } catch (error: any) {
    const _error: string = error?.response?.data?.error?.message ?? String(error);
    errorModal(_error ? _error : JSON.stringify(_error));
    dispatch({ type: 'STORE_ADSCRIPTION', payload: { key: 'error' } });
    return undefined;
  }
};

export const storeCVP = async ({ dispatch, payload }: ActionParameters) => {
  dispatch({ type: 'STORE_CVP', payload: { key: 'pending' } });
  if (payload === '') {
    dispatch({ type: 'STORE_CVP', payload: null });
  } else {
    try {
      const response = await AXIOS_APIKEY().post<storeCVPdata>(CVP, payload);
      // console.log('response', response.data);
      const { code } = response.data;
      ToastMessage.enviarToastSuccess('Archivo cargado correctamente');
      dispatch({ type: 'STORE_CVP', payload: code });
    } catch (error) {
      ToastMessage.enviarToastError('Error al subir archivo');
      dispatch({ type: 'STORE_CVP', payload: null });
    }
  }
};

export const getRegions = async (): Promise<Region[]> => {
  let regions: Region[];

  try {
    const { data } = await AXIOS_APIKEY().get<Region[]>(GET_REGIONS_WITH_LOCALITIES);
    regions = data;
  } catch {
    regions = [];
  }

  return regions;
};

export const getBanks = async (): Promise<Bank[]> => {
  let bancos: Bank[];

  try {
    const { data } = await AXIOS_APIKEY().get<Bank[]>(BANKS);
    const { banks }: any = data;
    bancos = banks;
  } catch {
    bancos = [];
  }

  return bancos;
};

export const getPlansArray = async ({ payload }): Promise<Plan[]> => {
  let plans: Plan[];
  try {
    const { data } = await AXIOS_APIKEY().get<Plan[]>(`${GET_PLANS}?product=${payload.product}${payload.discount ? '&discount_code=' + payload.discount : ''}`);

    plans = data;
  } catch {
    plans = [];
  }

  return plans;
};

export const checkTrustStatus = async (): Promise<TrustStatus> => {
  const { data } = await AXIOS.post<TrustStatus>(TRUST_SERVICE);
  return data;
};

export const sendSmsCode = async ({ dispatch, payload }: ActionParameters): Promise<string> => {
  const _data: SendSmsCodeData = payload;
  dispatch({ type: 'SEND_SMS_CODE', payload: { key: 'pending' } });
  try {
    const { data: { status, oid: { $oid } } } = await AXIOS_APIKEY().post<TrustSendCode>(TRUST_SEND_CODE, _data);

    if (!status) new Error();

    dispatch({ type: 'STORE_SMS_OID', payload: { oid: $oid } });
    dispatch({ type: 'SEND_SMS_CODE', payload: { key: 'success' } });

    return $oid;
  } catch (error) {
    dispatch({ type: 'SEND_SMS_CODE', payload: { key: 'error' } });
  }
  return '';
};

export const checkSmsCode = async ({ dispatch, payload }: ActionParameters): Promise<boolean> => {
  const _data: CheckSmsCodeData = payload;
  dispatch({ type: 'CHECK_SMS_CODE', payload: { key: 'pending' } });

  try {
    const { data: { status } } = await AXIOS_APIKEY().post<{ status: boolean; message: string; }>(TRUST_CHECK_CODE, _data);
    dispatch({ type: 'STORE_SMS_CODE_CHECK', payload: { validated: status } });

    if (!status) new Error('Código incorrecto');

    dispatch({ type: 'CHECK_SMS_CODE', payload: { key: 'success' } });

    return status;
  } catch (error) {
    const { message }: any = error;
    dispatch({ type: 'SET_MODAL_ERROR', payload: message });
    dispatch({ type: 'CHECK_SMS_CODE', payload: { key: 'error' } });
  }
  return false;
};

export const getAddressPredictions = async ({ payload }: ActionParameters): Promise<GooglePlacesApiPrediction[]> => {
  const input: string = payload.input;
  const config = { cancelToken: CANCEL_TOKEN.token, };

  try {
    const { data: { predictions } } =
      await AXIOS_APIKEY().get<{ status: number; predictions: GooglePlacesApiPrediction[] }>(`${GOOGLE_PLACES_API}/${input}`, config);
    return predictions;
  } catch {
    return [];
  }
};

// export const storeCenter = async ({ dispatch, payload, newShipping }: ActionParameters) => {
export const storeCenter = async ({ dispatch, payload, newShipping }: any) => {
  const { center: { address, address_detail, center_code, locality_id, name }, nid, product, adscription_id }:
    { center: LMECenter, nid: string, product: string; adscription_id: number } = payload;
  dispatch({ type: 'STORE_CENTER', payload: { key: 'pending' } });

  try {
    const _data: StoreCenterData = {
      product,
      nid,
      address,
      address_detail,
      locality_id,
      name,
      center_code,
      adscription_id
    };

    const { data } = await AXIOS_APIKEY().post<Center>(CREATE_CENTER, _data);

    dispatch({ type: 'STORE_CENTER_RESULT', payload: { center: data } });
    if (newShipping) {
      dispatch({ type: 'SET_SHIPPING', payload: data });
    }
    dispatch({ type: 'STORE_CENTER', payload: { key: 'success' } });
  } catch (error: any) {
    const _error: string = error?.response?.data?.error?.message ?? String(error);
    errorModal(_error ? _error : JSON.stringify(_error));
    dispatch({ type: 'STORE_CENTER', payload: { key: 'error' } });
  }
};

export const storeContract = async ({ dispatch, payload }: ActionParameters) => {
  dispatch({ type: 'STORE_CONTRACT', payload: { key: 'pending' } });
  try {
    const dataStoreContract: StoreContractData = {
      nid: payload.nid,
      code: payload.code,
      adscription_id: payload.adscription_id,
    };

    if (payload.plan_id) {
      dataStoreContract.plan_id = payload.plan_id;
    }

    if (payload.uuid) {
      dataStoreContract.uuid = payload.uuid;
    }

    if (payload.cvp_file) {
      dataStoreContract.cvp_file = payload.cvp_file;
    }

    if (payload.cvp_nid) {
      dataStoreContract.cvp_nid = payload.cvp_nid;
    }

    if (payload.discount_code) {
      dataStoreContract.discount_code = payload.discount_code;
    }

    if (payload.shipping) {
      dataStoreContract.shipping = payload.shipping;
    }

    if (payload.specialties) {
      dataStoreContract.specialties = payload.specialties.map((specialty) => {
        return {
          specialtyId: specialty.specialtyId,
          amount: parseInt(specialty.amount, 10),
        };
      });
    }
    const { data } = await AXIOS_APIKEY().post<StoredContract>(STORE_CONTRACT, dataStoreContract);
    dispatch({ type: 'STORE_CONTRACT', payload: { key: 'success' } });
    dispatch({ type: 'STORE_CONTRACT_RESULT', payload: { storedContract: data } });
    return true;
  } catch (error: any) {
    const _error: string = error?.response?.data?.error?.message ?? String(error);
    errorModal(_error ? _error : JSON.stringify(_error));
    dispatch({ type: 'STORE_CONTRACT', payload: { key: 'error' } });
  }
  return false;
};

export const getBranches = async ({ dispatch, payload }: ActionParameters): Promise<LMECenter[]> => {
  try {
    const { data } = await AXIOS_APIKEY().post<LMECenter[]>(GET_LME_BRANCHES, payload);
    return data;
  } catch (error: any) {
    const _error: string = error?.response?.data?.error?.message ?? String(error);
    errorModal(_error ? _error : JSON.stringify(_error));
    return [];
  }
};


export const getContractTemplate = async ({ payload, context }): Promise<Plan> => {
  try {
    const { data } = await AXIOS_SECURE(context).get<Plan>(GET_PLANS + '/' + payload.plan_id);
    return data;
  } catch (error: any) {
    if (error.response && error.response.status && error.response.status === 401) {
      throw errorMsg(context);
    } else {
      throw new Error(error && error.message ? error.message : JSON.stringify(error), error.response.status);
    }
  }
};

export const patchBillingInfo = async ({ payload, context }): Promise<ResponsePatchBillingInfo> => {
  try {
    const { data } = await AXIOS_SECURE(context).patch<ResponsePatchBillingInfo>(BILLING_INFO, payload);
    return data;
  } catch (error: any) {
    if (error.response && error.response.status && error.response.status === 401) {
      throw errorMsg(context);
    } else {
      throw new Error(error && error.message ? error.message : JSON.stringify(error), error.response.status);
    }
  }
};


export const sendContractMail = async ({ payload, context }): Promise<ResponseContractEmail> => {
  try {
    const { data } = await AXIOS_SECURE(context).post<ResponseContractEmail>(SEND_CONTRACT_EMAIL, payload);
    return data;
  } catch (error: any) {
    if (error.response && error.response.status && error.response.status === 401) {
      throw errorMsg(context);
    } else {
      throw new Error(error && error.message ? error.message : JSON.stringify(error), error.response.status);
    }
  }
};


export const changeStatus = async ({ payload, context }): Promise<ResponseProspectAdapter> => {
  try {
    const { data } = await AXIOS_SECURE(context).patch<ResponseProspectAdapter>(PROSPECT_BACKOFFICE_STATUS, payload);
    return data;
  } catch (error: any) {
    if (error.response && error.response.status && error.response.status === 401) {
      throw errorMsg(context);
    } else {
      throw new Error(error && error.message ? error.message : JSON.stringify(error), error.response.status);
    }
  }
};

export const discountValidate = async (dispatch: React.Dispatch<Action>, code: string, productName: string, plan_id?: number) => {
  try {
    const response = await AXIOS_APIKEY().get(`${DISCOUNT_VALIDATE}/${code}/${productName}${plan_id ? `?planId=${plan_id}` : ''}`);
    if (productName === 'LME') {
      dispatch({ type: 'STORE_PROMOCIONAL_CODE', payload: response });
    }
    dispatch({ type: 'SET_MODAL_SUCCESS', payload: '¡Tu código ha sido validado con éxito! Esto significa que podrás ver el descuento aplicado en tu primera factura' });
  } catch (error) {
    dispatch({ type: 'SET_MODAL_ERROR', payload: 'Código inválido o no disponible' });
  }
};

export const getInfoNewDoc = async (nid: string) => {
  try {
    const { data } = await AXIOS_APIKEY().get(`${GET_INFO}?nid=${nid}`);
    return data.data;
  } catch (error: any) {
    // console.error(error);
    if (error instanceof AxiosError) {
      throw error?.response?.status;
    }
    throw error;
  }
};

export const getSpecialtiesDoc = async (nid: string) => {
  try {
    const { data } = await AXIOS_APIKEY().get(`${GET_SPECIALTIES}?nid=${nid}`);
    console.log('especialidades', data);
    //const specialties = data;
    return data;
    /* 
    specialties.push({
      'specialty': 'MEDICINA INTERNA',
      'homologation': {
          'id': '42',
          'name': 'MEDICINA INTERNA',
          'description': 'MEDICINA INTERNA',
          'created_at': '2020-06-30T12:02:21.000Z',
          'updated_at': '2020-06-30T12:02:21.000Z',
          'code': '02700'
      }
    });
    specialties.push({
      'specialty': 'NEFROLOGIA ADULTOS',
      'homologation': {
          'id': '43',
          'name': 'NEFROLOGIA ADULTOS',
          'description': 'NEFROLOGIA ADULTOS',
          'created_at': '2020-06-30T12:02:21.000Z',
          'updated_at': '2020-06-30T12:02:21.000Z',
          'code': '02780'
      }
    });
    specialties.push({
      'specialty': 'NEFROLOGIA INFANTIL',
      'homologation': {
          'id': '44',
          'name': 'NEFROLOGIA INFANTIL',
          'description': 'NEFROLOGIA INFANTIL',
          'created_at': '2020-06-30T12:02:21.000Z',
          'updated_at': '2020-06-30T12:02:21.000Z',
          'code': '02790'
      }
    });
    
    console.log('especialidadesmods',specialties);
    return specialties;
    */

  } catch (error: any) {
    if (error instanceof AxiosError) {
      throw error?.response?.status;
    }
    throw error;
  }
};


export const initAdscription = async (context, dispatch) => {
  const errMessage = 'no se pudo crear la adscripción';
  try {
    const response = await AXIOS_APIKEY(context).post(ADSCRIPTION_INIT);
    console.log('response', response);
    console.log('responseid', response?.data?.id);

    if (response.data.id) {
      dispatch({ type: 'SET_ADSCRIPTION', payload: { adscription_id: response.data.id } });
    } else {
      throw errorMsg(context);
    }
  } catch (error) {
    ToastMessage.enviarToastError('Error', errMessage);
    // @ts-ignore
    if (error.response && error.response.status && error.response.status === 401) {
      throw errorMsg(context);
    } else {
      // @ts-ignore
      errorModal(errMessage);
    }
  }
};

export const postBankInfo = async (context, data) => {
  const errMessage = 'no se pudo guardar la información bancaria';
  try {
    const response = await AXIOS_APIKEY(context).post(ADSCRIPTION_BANK_INFO, data);
    console.log('response bank_info', response);

    // if (response.data.id) {
    //   dispatch({ type: 'SET_ADSCRIPTION', payload: { adscription_id: response.data.id } });
    // } else {
    //   throw errorMsg(context);
    // }
  } catch (error) {
    ToastMessage.enviarToastError('Error', errMessage);
    // @ts-ignore
    if (error.response && error.response.status && error.response.status === 401) {
      throw errorMsg(context);
    } else {
      // @ts-ignore
      errorModal(errMessage);
    }
  }
};


const errorModal = async (msg) => {
  return await Swal.fire({
    title: 'Ha ocurrido un error',
    html: (msg || '') + '<br><b> reintente el flujo nuevamente o contacte a soporte.</b>',
    target: 'root',
    didOpen: () => {
      Swal.showLoading();
      setTimeout(function () {
        window.location.href = '/';
      }, 5000);
    },
  }).then(() => {
    return false;
  });
};


const errorMsg = async (context) => {
  return await Swal.fire({
    title: 'Sesión expirada',
    text: 'La sesión ha expirado, redireccionando al login',
    target: 'root',
    didOpen: () => {
      Swal.showLoading();
      setTimeout(function () {
        if (context?.keycloak) {
          context.keycloak?.logout();
        }
      }, 2500);
    },
  }).then(() => {
    return false;
  });
};
