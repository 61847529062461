import { Professionals } from '../../interfaces/professional';
import { Status } from '../../interfaces/status';
import styles from './styles.module.scss';
import { Input, Select, TextArea, Button } from '@imed_npm/design-system';
import { useContext, useState } from 'react';
import { updateStatus } from '../../utils/actions';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import { UpdateStatusData } from '../../utils/interfaces';
import ToastMessage from '../../../../utils/ToastMessage';

interface OwnProps {
  professional: Professionals;
  statusList: Status[];
  statusListAllowed: Status[];
  setShowChangeStatusModal: (status: boolean) => void;
  setRefresh?: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChangeStatus = ({ setShowChangeStatusModal, professional, statusList, statusListAllowed, setRefresh }: OwnProps) => {
  const context = useContext(LoggedUserContext);
  const [oldStatus, setOldStatus] = useState<number>(professional.adscription_status);
  const [storing, setStoring] = useState<boolean>();
  const [comment, setComment] = useState<string>();
  const [status, setStatus] = useState<number>();

  const change = () => {
    const { nid, adscription_id } = professional;

    if (nid && status !== undefined) {
      const _data: UpdateStatusData = { adscriptionId: Number(adscription_id), status: Number(status) };
      if (comment) _data.comment = comment;

      updateStatus(context, _data).then(() => {
        setOldStatus(status);
        setStoring(false);
        setStatus(undefined);
        setComment(undefined);
        ToastMessage.enviarToastSuccess('Estado actualizado correctamente');
        setRefresh(true);
        setShowChangeStatusModal(false);
      }).catch((err) => {
        ToastMessage.enviarToastError(`${err.message}`);
      });
    }
  };

  const statusOptionsList = () => {
    const _current = statusList.find(({ value }) => value == oldStatus);
    //transiciones
    return !_current ? [] : _current.status_transitions_status_transitions_base_status_idTostatuses?.map(({ statuses_status_transitions_next_status_idTostatuses: status }) => status);
    //columna backoffice
    //return statusListAllowed.map(({ value, name }) => ({ value, name }));
    //columna backoffice y transiciones
    //return !_current ? [] : (_current.status_transitions_status_transitions_base_status_idTostatuses?.map(({ statuses_status_transitions_next_status_idTostatuses: status }) => status))?.filter((status)=>{  return statusListAllowed.map(({ value }) => value).includes(status.value);  });
  };

  return (
    <div className={styles.container}>
      <div className={styles.professional_info}>
        <div>
          <p>
            <span>Prestador:</span> {professional.nid}
          </p>
          <p>
            <span>Id interno de Suscripción:</span> {professional.adscription_id}
          </p>
          <p>
            <span>Código de lugar:</span> {professional.centers[0].code}
          </p>
        </div>
      </div>
      {professional && statusList && <div className={styles.form}>
        <div>
          <Input
            disabled={storing}
            customSize="l"
            placeholder="Escribe un texto"
            title="Estado actual"
            type="text"
            readOnly
            value={statusList.find(({ value }) => value == oldStatus)?.name}
          />
        </div>
        <div>
          <Select
            disabled={storing}
            customClass={styles.z1000}
            customSize="l"
            items={statusOptionsList()?.map(({ value, name }) => ({ value: value.toString(), name }))}
            nameType="name"
            onChange={value => {
              setStatus(value);
            }}
            placeholder="Seleccione una opción"
            title="Nuevo estado"
          />
        </div>
        <div>
          <TextArea
            disabled={storing}
            maxLength={255}
            name="name"
            placeholder="Escribe un texto"
            title="Comentario"
            typeof="text"
            value={comment}
            onChange={({ target: { value } }) => setComment(value)}
          />
        </div>
        <div className={styles.actions}>
          <Button
            disabled={storing || status == undefined}
            loading={storing}
            buttonType="button"
            loadingText="Guardando"
            type="secondary"
            onClick={change}
          >
            Cambiar
          </Button>
        </div>
      </div>}
    </div>
  );
};

export default ChangeStatus;
