import routes from '../Routes';

import { matchPath } from 'react-router';

const isRouteProtected = (route: string | null) => {
  const isProtectedRoute = routes.filter(r => {
    return r.secure === true && matchPath({ path: r.path }, (route ?? window.location.pathname));
  });
  return !!isProtectedRoute.length;
};

export default isRouteProtected;

