import { useState, useEffect, useCallback, useMemo } from 'react';
import LoggedUserContext from './LoggedUserContext';
import Keycloak from 'keycloak-js';
import Swal from 'sweetalert2';
import getEnv from '../getEnv';

import jwt_decode from 'jwt-decode';
import isRouteProtected from '../utils/isRouteProtected';
import { IJwt, IUserData, initialUserData } from './loggedUsercontext.interface';

function LoggedUserProvider({ children }: { children: any }): JSX.Element {
  const [keycloak, setKeycloak] = useState<Keycloak>();
  const [userData, setUserData] = useState<IUserData>(initialUserData);
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    setKeycloak(new Keycloak({
      url: getEnv('REACT_APP_KEYCLOAK_URL') ?? '',
      realm: getEnv('REACT_APP_KEYCLOAK_REALM') ?? '',
      clientId: getEnv('REACT_APP_KEYCLOAK_CLIENT_ID') ?? '',
    }));
  }, []);

  const IS_KEYCLOAK_DISABLED = getEnv('REACT_APP_KEYCLOAK_DISABLED') === 'true';

  const APP_ID = getEnv('REACT_APP_ID');
  const MIN_VALID_EXPIRATION = parseInt(getEnv('REACT_APP_MIN_VALID_EXPIRATION'), 10) || -1;
  useEffect(() => {
    const location = new URL(window.location.href);
    if (!isRouteProtected(location.pathname)) {
      return;
    }

    if (!keycloak) {
      return;
    }

    if (!keycloak?.authenticated) {
      // eslint-disable-next-line no-restricted-globals
      const silentSSo = `${location.origin}/silent-check-sso.html`;
      keycloak
        .init({
          onLoad: 'login-required',
          silentCheckSsoRedirectUri: silentSSo
        })
        .then((authenticated) => {
          keycloak.updateToken(MIN_VALID_EXPIRATION).then((response) => {
            // console.log('authenticated :>> ', authenticated);
            try {
              const response: IJwt = jwt_decode(keycloak.token ?? '');

              setToken(keycloak.token ?? '');

              if (response) {

                const activeInst = window.localStorage?.getItem('activeInstitution');
                let localActiveInstitution = activeInst ? JSON.parse(activeInst) : false;
                const objInstitutions: { institutions } = response.institutions;
                const filtered = objInstitutions.institutions.filter(o => {
                  const app = o.branchOffices[0].apps.find(o => o.id === APP_ID);
                  return (app);
                });
                // console.log('filtered', filtered);
                if (!filtered.length) {
                  Swal.fire({
                    title: 'Usuario sin permisos',
                    text: 'El usuario no tiene permisos asignados a esta app, redireccionando al login',
                    didOpen: () => {
                      // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                      Swal.showLoading();
                      setTimeout(function () {
                        keycloak.logout();
                      }, 3500);
                    },
                  }).then(() => {
                    return false;
                  });
                  return;
                }

                if (!localActiveInstitution) {
                  //console.log('INSTITUTION',institutions)
                  if (objInstitutions && !objInstitutions.institutions) {
                    Swal.fire({
                      title: 'Usuario sin permisos',
                      text: 'El usuario no tiene permisos asignados, redireccionando al login',
                      didOpen: () => {
                        // `MySwal` is a subclass of `Swal` with all the same instance & static methods
                        Swal.showLoading();
                        setTimeout(function () {
                          keycloak.logout();
                        }, 2500);
                      },
                    });
                    return;
                  }

                  const resources = (filtered[0].branchOffices[0].apps.find(o => o.id === APP_ID));
                  localActiveInstitution = filtered[0];
                  const addResources = { ...localActiveInstitution, resources: resources };
                  localStorage.setItem('activeInstitution', JSON.stringify(addResources));
                  console.log('Hola Mundo!');
                  setUserData({
                    name: response.name ?? '',
                    idUser: response.sub ?? '',
                    institutions: filtered ?? response.institutions?.institutions,
                    activeInstitution: localActiveInstitution,
                    roles: response.resource_access?.account?.roles ?? [],
                    email: response.email ?? '',
                    email_verified: response.email_verified ?? false,
                    rut: response.preferred_username ?? '',
                  });
                } else {
                  const { institutions } = response.institutions;
                  const compareInstitution = institutions.filter(institution => institution.id === localActiveInstitution.id);
                  if (compareInstitution.length < 1) {
                    localActiveInstitution = response.institutions?.institutions[0];
                    const addResources = { ...localActiveInstitution, resources: localActiveInstitution.branchOffices[0].apps[0].resources };
                    localStorage.setItem('activeInstitution', JSON.stringify(addResources));
                  }
                  setUserData({
                    name: response.name ?? '',
                    idUser: response.sub ?? '',
                    institutions: filtered ?? response.institutions?.institutions,
                    activeInstitution: localActiveInstitution,
                    roles: response.resource_access?.account?.roles ?? [],
                    email: response.email ?? '',
                    email_verified: response.email_verified ?? false,
                    rut: response.preferred_username ?? '',
                  });
                }
              }
            } catch (error) {
              console.log(error);
            }
          }).catch((err) => {
            //console.log('catch',err);
          });
        });
    }
  }, [keycloak, IS_KEYCLOAK_DISABLED]);

  const logout = useCallback(async () => {
    const location = new URL(window.location.href);
    const logoutOptions = { redirectUri: location.origin };
    keycloak?.logout(logoutOptions).then((success) => {
      // console.log('--> log: logout success ', success);
    }).catch((error) => {
      console.error('--> log: logout error ', error);
    });
  }, [keycloak]);

  const memoizedValue = useMemo(() => ({ userData, setUserData, token, keycloak, logout, editor:{ rows:[],data:[],idx:0 } }), [userData, setUserData, token, keycloak, logout, { rows:[],data:[],idx:0 }	]);

  return (
    <LoggedUserContext.Provider value={memoizedValue} > 
      {children}
    </LoggedUserContext.Provider>
  );
}


export default LoggedUserProvider;
