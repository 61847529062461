import { DateTime } from 'luxon';

export const objToQueryParams = (obj) => obj ? Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&') : null;

export const formatJsDate = (date) => { // De aburrido lo hice hahaha
  const _date = new Date(date);
  
  return [
    [_date.getDate(), _date.getMonth() + 1, _date.getFullYear()].map(v => String(v).padStart(2,'0')).join('-'), 
    // [_date.getHours(), _date.getMinutes(), _date.getSeconds()].map(v => String(v).padStart(2,'0')).join(':')
  ].join(' ');
};

export const formatLuxon = (date) => {
  const newValue = DateTime.fromISO(date, { zone: 'utc' }).toFormat('yyyy/MM/dd');
  return newValue;
};
