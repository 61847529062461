import React, { useState, useEffect, useContext, useCallback } from 'react';
import { DataTable2, Skeleton, Select, Input, Button, LoadingImed, InfoIcon, Switch, IndexPointingUpEmoji } from '@imed_npm/design-system';
import { formatJsDate, formatLuxon } from '../../utils/utils';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import { GetPlansOptions, Product } from '../../utils/interfaces';
import { getAllDiscounts } from '../../utils/actions';
import EditProgram from './EditProgram';
import Modal from '../../../Modal';
import styles from './style.module.scss';

interface Props {
  type: any;
  dataPlan: any;
  itemsType?: any;
  itemsUnity?: any;
  itemsTypeUsage?: any;
  setUpdate: any;
  update: any;
}

const discountTable: React.FC<Props> = (props) => {
  const context = useContext(LoggedUserContext);
  const [data, setData] = useState<any>();

  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();

  const [updatedStatus, setUpdatedStatus] = useState({});

  const renderCheckingInfo = () => {
    return (
      <>
        {(showLoading) && (<LoadingImed text="Cargando información" />)}
      </>
    );
  };

  const formatName = (e) => {
    switch (e) {
      case 1:
        return 'Código único';
      case 2:
        return 'URL';
      default:
        return e;
    }
  };

  const formatUnity = (e) => {
    if (!e) return 'Sin unidad';
    const discountUnitTypesDataString = sessionStorage.getItem('discountUnitTypesData');
    // @ts-ignore
    const discountUnitTypesData = JSON.parse(discountUnitTypesDataString);
    const getName = discountUnitTypesData.find(objeto => objeto.id === parseInt(e));
    return getName.name;
  };

  const formatNamePlan = (e) => {
    if (!e) return 'Sin plan';
    // @ts-ignore
    const getName = props.dataPlan.find(objeto => objeto.id === e);
    return getName.name;
  };

  const reloadUpdateComponent = (programData: { programId: number; }) => {
    setUpdatedStatus({
      ...updatedStatus,
      [programData.programId]: Date.now()
    });
  };

  const closeExpandedRows = (row, rows, toggleRowExpanded) => {
    const expandedRow = rows.find((r) => r.isExpanded);
    if (expandedRow) {
      const isSubItemOfRow = Boolean(
        expandedRow && row.id.split('.')[0] === expandedRow.id,
      );

      if (isSubItemOfRow) {
        const expandedSubItem = expandedRow.subRows.find(
          (subRow) => subRow.isExpanded,
        );

        if (expandedSubItem) {
          const isClickedOnExpandedSubItem = expandedSubItem.id === row.id;
          if (!isClickedOnExpandedSubItem) {
            toggleRowExpanded(expandedSubItem.id, false);
          }
        }
      } else {
        toggleRowExpanded(expandedRow.id, false);
      }
    }
    row.toggleRowExpanded();
  };

  const columns = [
    {
      accessor: 'status',
      Header: ' ',
      HeaderWidth: 'w-[6%]',
      Cell: ({ value }: any) => (
        <div
          className={`w-2 h-2 rounded-full ${value ? 'bg-success' : 'bg-error'
            }`}
        />
      ),
      filter: 'exact',
      sortDescFirst: true
    },
    {
      Cell: ({ value }: any) => (
        <div
          className={styles.wrap}>{value}</div>
      ),
      Header: 'Nombre',
      HeaderWidth: 'w-[31%]',
      accessor: 'name',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => (
        <div
          className={styles.wrapCupon}>{value}</div>
      ),
      Header: 'Código cupón',
      HeaderWidth: 'w-[31%]',
      accessor: 'code',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => (
        <div
          className={styles.wrapCupon}>{formatNamePlan(value) || 'Sin Plan'}</div>
      ),
      Header: 'Plan',
      HeaderWidth: 'w-[31%]',
      accessor: 'plan_id',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value ? formatLuxon(value) : '-',
      Header: 'Fecha de inicio',
      HeaderWidth: 'w-[31%]',
      accessor: 'start_date',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value ? formatLuxon(value) : '-',
      Header: 'Fecha de termino',
      HeaderWidth: 'w-[31%]',
      accessor: 'expiration_date',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    // {
    //   Cell: ({ value }: any) => <div>{formatName(value)}</div> || 'Sin data',
    //   Header: 'Tipo',
    //   HeaderWidth: 'w-[31%]',
    //   accessor: 'discount_type_id',
    //   skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    // },
    // {
    //   Cell: ({ value }) => value || 'Sin máximo',
    //   Header: 'Máximo',
    //   HeaderWidth: 'w-[31%]',
    //   accessor: 'max_usage',
    //   skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    // },
    // {
    //   Cell: ({ value }) => value || 'Indefinido',
    //   Header: 'Meses de aplicación',
    //   HeaderWidth: 'w-[31%]',
    //   accessor: 'months_application',
    //   skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    // },
    {
      Cell: ({ value }) => value || 'Sin monto',
      Header: 'Monto',
      HeaderWidth: 'w-[31%]',
      accessor: 'amount',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => <div>{formatUnity(value)}</div> || 'Sin data',
      Header: 'Unidad',
      HeaderWidth: 'w-[31%]',
      accessor: 'unit_type_id',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      id: 'actions',
      Header: 'Acciones',
      HeaderWidth: 'w-[15%]',
      Cell: ({ row, rows, toggleRowExpanded }: any) => (
        <span className="flex justify-center relative">
          <Button
            buttonType='button'
            size="s"
            type="ghost-primary"
            Icon={'EditIcon'}
            onClick={() => {
              closeExpandedRows(row, rows, toggleRowExpanded);
            }}
            id="edit_table"
            activeTooltip={true}
            optionsTooltip={{
              tooltipText: 'Editar',
              direction: 'top',
              delay: 1,
              customClass: '-mt-4',
              type: 'secondary'
            }}
          >
          </Button>
        </span>
      ),
      SubCell: () => null
    }
  ];

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <>
        <EditProgram
          program={row}
          programId={row.original.product_id}
          // key={updatedStatus[row.original.program_id] ?? row.original.program_id}
          // reloadUpdateComponent={reloadUpdateComponent}
          itemsType={props.itemsType}
          dataPlan={props.dataPlan}
          itemsUnity={props.itemsUnity}
          itemsTypeUsage={props.itemsTypeUsage}
          setUpdate={props.setUpdate}
          update={props.update}
        />
      </>
    ),
    [updatedStatus]
  );

  useEffect(() => {
    if (props.type) {
      setSelectedProduct(props.type);
      setShowLoading(true);
      getAllDiscounts(context, props.type.id).then(setData).finally(() => setShowLoading(false));
    }
  }, [props.type, props.update]);

  return (
    <div>
      {renderCheckingInfo()}

      {
        (data && props.dataPlan && !loadingTable) ? (
          <>
            <h4 className='text-base font-semibold my-10'>
              Resultados de la búsqueda: <span className='text-blue-800'>{selectedProduct?.description}</span>
            </h4>

            <DataTable2
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
              disableHeader
              orderBy="status"
              totalResults={data && data.length ? data.length : 0}
              perPage={10}
              sortBy="asc"
              colorStyle="neutral"
            /></>
        ) : (
          <div className='w-full border flex flex-col p-20 justify-center items-center'>
            <InfoIcon size="m" />
            <p className='mt-2'>Todavía no selecciona un producto para administrar</p>
          </div>
        )
      }
    </div>
  );
};

export default discountTable;
