import React, { useContext, useEffect, useState } from 'react';
import { Button, InfoIcon, Skeleton, DataTable2, Badge, Input, LoadingImed, UserHelp, SlightlySmilingFaceEmoji, Select } from '@imed_npm/design-system';
import Accordion from '../../../../Form/Components/Accordion';
import { formatRut, validFormat } from '../../../../../utils/rut';
import LoggedUserContext from '../../../../../context/LoggedUserContext';
import { formatJsDate } from '../../../utils/utils';
import getEnv from '../../../../../getEnv';
import { getVoucherClientNid, putVoucher } from '../../../utils/actions';
import { Status } from '../../../interfaces/status';
import { getProducts, getStatuses } from '../../../utils/actions';
import Modal from '../../../../Modal';
import ToastMessage from '../../../../../utils/ToastMessage';
import styles from '../style.module.scss';

const APP_ID = getEnv('REACT_APP_ID');

const Clients = () => {
  const context = useContext(LoggedUserContext);
  const [statusList, setStatusList] = useState<Status[]>();
  const [statusListPayments, setStatusListPayments] = useState<Status[]>();
  const [filtered, setFiltered] = useState<Status[]>();

  const [currentStatus, setCurrentStatus] = useState<any>(undefined);

  const [show, setShow] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [detailContent, setDetailContent] = useState<any>(undefined);
  const [discount, setDiscount] = useState<any>(undefined);
  const [statuschange, setStatusChange] = useState<any>(undefined);
  const [condoned, setCondoned] = useState<any>(undefined);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [modalContent, setModalContent] = useState<any>(undefined);
  const [userInfo, setUserInfo] = useState<any>(undefined);

  const [accordionItems, setAccordionItems] = useState<any>(undefined);

  const [search, setSearch] = useState<any>(undefined);
  const [value, setValue] = useState<any>(undefined);
  const [stateType, setStateType] = useState<any>('default');

  const [motive, setMotive] = useState<string>('');
  const [status, setStatus] = useState<any>(undefined);

  const [item, setItem] = useState<any>();
  const [title, setTitle] = useState<string>('');

  const activeInstitution = context.userData.activeInstitution?.id;

  const dataInstitution = context.userData.institutions.find(o => o.id === activeInstitution);

  let findAppInstitution, resources;
  if (dataInstitution) {
    findAppInstitution = dataInstitution.branchOffices[0].apps.find(o => o?.id === APP_ID);
    if (findAppInstitution) {
      resources = findAppInstitution.resources;
    }
  }

  const isResourceEnabled = (resourceName: string) => resources.some((resource) => resource.resource_name === resourceName && resource.resource_enabled);

  const formatChannelName = (channel) => {
    if (channel === '1') {
      return 'Automático o Suscripción';
    } else {
      return 'MedPay';
    }
  };

  useEffect(() => {
    const timeout = setTimeout(async () => {
      const [initProducts, initStatusList, initStatusListChange] = await Promise.all(
        [
          getProducts(context),
          getStatuses(context, 'voucher'),
          getStatuses(context, 'voucher', true),
        ]);
      setStatusList(initStatusList);

    }, 250);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      const [initProducts, initStatusList, initStatusListChange] = await Promise.all(
        [
          getProducts(context),
          getStatuses(context, 'payments'),
          getStatuses(context, 'payments', true),
        ]);
      setStatusListPayments(initStatusList);

    }, 250);
    return () => clearTimeout(timeout);
  }, []);

  const getLabelName = (e) => {
    switch (e) {
      case 'Abortado':
        return 'No pagado';
      case 'Creado':
        return 'No pagado';
      case 'Pendiente':
        return 'No pagado';
      case 'Procesando':
        return 'No pagado';
      default:
        return e;
    }
  };

  const getLabel = (e) => {
    switch (e) {
      case 0:
        return 'pink';
      case 1:
        return 'pink';
      case 2:
        return 'pink';
      case 3:
        return 'pink';
      case 4:
        return 'green';
      case 5:
        return 'orange';
      case 6:
        return 'blue';
      case 7:
        return 'purple';
      default:
        return 'purple';
    }
  };

  const getLabelPayment = (e) => {
    switch (e) {
      case 0:
        return 'pink';
      case 1:
        return 'green';
      case 2:
        return 'orange';
      case 3:
        return 'blue';
      default:
        return 'purple';
    }
  };

  useEffect(() => {
    if (modalContent) {
      const filterData = modalContent.filter(content => content.plans.products.name.toLowerCase() === 'em');
      const sortedData = filterData.sort((a, b) => new Date(b.contract_date).getTime() - new Date(a.contract_date).getTime());
      const newAccordionItems = sortedData.map((item) => ({
        title: `Plan: ${item.name} (Código: ${item.plans.code}) - Adscription ID #${item.id}`,
        content: <DataTable2
          columns={vouchers}
          data={item.vouchers.filter(element => element.status !== -1)}
          // data={item.vouchers}
          orderBy="period"
          disableHeader
          perPage={10}
          totalResults={item.vouchers.length}
          sortBy="desc"
          colorStyle="neutral"
        />,
      }));

      setAccordionItems(newAccordionItems);

      setUserInfo(modalContent[0].client);
      setShow(true);
      setLoading(false);
    }
  }, [modalContent, showDetail]);

  const getSelectItems = (row) => {
    const { original: { status } } = row;
    // if (status === -1 || status === 0 || status === 1 || status === 2 || status === 3) {
    if (status === 0 || status === 1 || status === 2 || status === 3) {
      return [
        {
          id: 1,
          name: 'Ver voucher',
          show: true
        },
        {
          id: 2,
          name: 'Revalorizar voucher',
          show: isResourceEnabled('crud-deuda-cliente')
        },
        {
          id: 3,
          name: 'Cambiar estado',
          show: isResourceEnabled('crud-deuda-cliente')
        },
        {
          id: 4,
          name: 'Condonar',
          show: isResourceEnabled('crud-deuda-cliente')
        }
      ].filter(({ show }) => show);
    } else if (status === 4 || status === 5 || status === 6) {
      return [
        {
          id: 1,
          name: 'Ver voucher',
          show: true
        },
        {
          id: 3,
          name: 'Cambiar estado',
          show: isResourceEnabled('crud-deuda-cliente')
        },
        {
          id: 4,
          name: 'Condonar',
          show: isResourceEnabled('crud-deuda-cliente')
        }
      ].filter(({ show }) => show);
    } else {
      return [
        {
          id: 1,
          name: 'Ver voucher',
          show: true
        }
      ];
    }

  };

  useEffect(() => {
    if (!value) {
      setStateType('default');
    } else if (+value < 1) {
      setStateType('error');
    } else {
      setStateType('success');
    }
  }, [value]);

  useEffect(() => {
    if (!statusList) return;
    // @ts-ignore
    setFiltered(statusList.filter(obj => obj.value === 5 || obj.value === 6));
  }, [statusList]);

  const filterOptions = (status) => {
    if (!statusList) return;
    if (status === 4) {
      return statusList.filter(obj => obj.value === 5 || obj.value === 6);
    } else if (status === 5 || status === 6) {
      return statusList.filter(obj => obj.value === 1 || obj.value === 7);
    } else {
      return statusList;
    }
    // statusList.filter(obj => obj.value === 5 || obj.value === 6)
  };

  const handleActions = (action, value) => {
    setCurrentStatus(value.original.status);
    setItem(value.original);
    setDetailContent(null);
    setDiscount(null);
    setStatusChange(null);
    setCondoned(null);
    // setShowModal(false);
    switch (action.id) {
      case 1:
        setDetailContent(value.original);
        setTitle('Pagos asociados');
        setShowDetail(true);
        break;
      case 2:
        setDiscount(value.original);
        setTitle('Revalorar voucher');
        setShowDetail(true);
        break;
      case 3:
        setStatusChange(value.original);
        setTitle('Cambio de estado');
        setShowDetail(true);
        break;
      case 4:
        setCondoned(value.original);
        setTitle('Condonación');
        setShowDetail(true);
        break;
      default:
        setTitle('');
        break;
    }
  };

  const ActionModal = ({ row }: any) => {
    return (<Select
      customClass={styles.z0}
      customSize="s"
      items={getSelectItems(row)}
      name="selectName"
      nameType="name"
      onChange={(e) => handleActions(e, row)}
      placeholder="Seleccione"
    />);
  };

  const getVoucherId = (value) => {
    if (!value) return 'Sin ID';
    const filter = value.find(voucher => voucher.external_id.length > 2);
    return filter ? filter.external_id : 'Sin ID';
  };

  const vouchers = [
    // {
    //   Cell: ({ value }) => value || 'Sin rut',
    //   Header: 'ID',
    //   HeaderWidth: 'w-[31%]',
    //   accessor: 'id',
    //   skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    // },
    // {
    //   Cell: ({ value }: any) => <div className='max-w-xs text-wrap whitespace-pre'>{value}</div> || '-',
    //   Header: 'Glosa',
    //   HeaderWidth: 'w-[31%]',
    //   accessor: 'glosa',
    //   skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    // },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Periodo',
      HeaderWidth: 'w-[31%]',
      accessor: 'period',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }, {
      Cell: ({ value }) => value ? formatJsDate(value) : '-',
      Header: 'Fecha de emisión',
      HeaderWidth: 'w-[31%]',
      accessor: 'created_at',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }, {
      Cell: ({ value }) => `$${value}` || '-',
      Header: 'Precio CLP (+IVA)',
      HeaderWidth: 'w-[31%]',
      accessor: 'total',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }, {
      Cell: ({ value }: any) => {
        return <div className='has-tooltip w-3/4 mx-auto text-left'>
          <span className='tooltip rounded shadow-lg p-1 bg-black text-white -mt-8'>{(value && value.length > 0) ? formatChannelName(value[0].channel_id) : 'Sin identificar'}</span>
          <p className='truncate cursor-help'>{(value && value.length > 0) ? value[0].channel_id : '-'}</p>
        </div>;
      },
      Header: 'Forma de pago',
      HeaderWidth: 'w-[31%]',
      accessor: 'payments',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }, {
      Cell: ({ value, row }: any) => {
        if (row.original.payments && row.original.payments.length > 0) {
          // return formatJsDate(row.original.payments[0].created_at);
          return <div className='has-tooltip w-3/4 mx-auto text-left'>
            <span className='tooltip rounded shadow-lg p-1 bg-black text-white -mt-8'>Fecha efectiva de pago del voucher según forma de pago</span>
            <p className='cursor-help'>{formatJsDate(row.original.payments[0].created_at)}</p>
          </div>;
        } else {
          return '-';
        }
      },
      Header: 'Fecha de pago',
      HeaderWidth: 'w-[31%]',
      accessor: 'id',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }, {
      Cell: ({ value, row }: any) => {
        if (row.original.payments && row.original.payments.length > 0) {
          // return formatJsDate(row.original.payments[0].created_at);
          return <div className='has-tooltip w-3/4 mx-auto text-left'>
            <span className='tooltip rounded shadow-lg p-1 bg-black text-white -mt-8'>{row.original.payments ? 'identificador externo de la transacción, ej. código Mercado Pago' : ''}</span>
            <p className='truncate cursor-help'>{row.original.payments ? getVoucherId(row.original.payments) : 'Sin id'}</p>
          </div>;
        } else {
          return '-';
        }
      },
      Header: 'Id externo',
      HeaderWidth: 'w-[31%]',
      accessor: 'medpay_payment_check_id',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }, {
      Cell: ({ value }: any) => {
        const list: any = statusList;
        return <Badge
          colorStyle={getLabel(value)}
          label={getLabelName(
            list.find(({ value: v2 }) => value == v2)?.name || '-'
          )}
        />;
      },
      Header: 'Estado',
      accessor: 'status',
      filter: 'exact',
      skeleton: <Skeleton customClass="w-[50%] h-4 pl-4 m-auto mt-1 mb-1" />,
      sortDescFirst: false,
    },
    {
      Cell: ActionModal,
      Header: 'Acciones',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }
  ];

  const voucherDetail = [
    {
      Cell: ({ value }: any) => `**** **** **** ${value}` || '****',
      Header: 'Tarjeta',
      HeaderWidth: 'w-[31%]',
      accessor: 'card_last_four_digits',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => <div className='max-w-md whitespace-pre'>{value ?? '-'}</div> || '-',
      Header: 'ID externo',
      HeaderWidth: 'w-[31%]',
      accessor: 'external_id',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => <div className='max-w-md whitespace-pre'>{value ?? '-'}</div> || '-',
      Header: 'Método de pago',
      HeaderWidth: 'w-[31%]',
      accessor: 'payment_method',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => `$${value}` || '-',
      Header: 'Valor (CLP)',
      HeaderWidth: 'w-[31%]',
      accessor: 'amount',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }, {
      Cell: ({ value }: any) => {
        const list: any = statusListPayments;
        return <Badge
          colorStyle={getLabelPayment(value)}
          label={(
            list.find(({ value: v2 }) => value == v2)?.name || '-'
          )}
        />;
      },
      Header: 'Estado',
      accessor: 'status',
      filter: 'exact',
      skeleton: <Skeleton customClass="w-[50%] h-4 pl-4 m-auto mt-1 mb-1" />,
      sortDescFirst: false,
    }, {
      Cell: ({ value }) => value ? formatJsDate(value) : '-',
      Header: 'Fecha',
      HeaderWidth: 'w-[31%]',
      accessor: 'created_at',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }
  ];

  const putDiscount = async (parameters: any, motive: string, value?: number, status?: number) => {
    const data = {
      reason: motive,
      status: status ?? parameters.status,
      amount: value
    };
    try {
      const result = await putVoucher(context, parameters.id, data);
      if (result) {
        ToastMessage.enviarToastSuccess('Voucher modificado correctamente');
        searchRut();
        setShowDetail(false);
      }
    } catch (error) {
      setShowDetail(false);
      ToastMessage.enviarToastError('Error al modificar voucher: ', error);
    }
  };

  const searchRut = () => {
    if (!search) return;
    setLoading(true);
    getVoucherClientNid(context, search).then((response) => setModalContent(response));
  };

  const modify = (e, type) => {
    if (!motive) return;
    if (type === 'statuschange') {
      putDiscount(e, motive, value ?? e.total, status);
    } else if (type === 'condoned') {
      putDiscount(e, motive, value ?? e.total, 7);
    } else {
      putDiscount(e, motive, value ?? e.total);
    }
  };

  useEffect(() => {
    if (!showDetail) {
      setValue(null);
      setMotive('');
      setStatus(null);
    }
  }, [showDetail]);

  useEffect(() => {
    if (showDetail === true) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [showDetail]);

  return <>
    {loading && <LoadingImed text="Buscando cobros generados" />}

    <div className='flex items-end mt-4'>
      <div className='mr-2'>
        <Input
          focused
          name="value"
          onChange={(e) => { setSearch(formatRut(e.target.value)); }}
          placeholder="Escribe un rut"
          title="Buscado por rut"
          customSize="l"
        />
      </div>
      <Button size="m" type='secondary' buttonType='button' onClick={searchRut} disabled={(search && search.length < 9) || !validFormat(search)}>Buscar</Button>

    </div>

    {show && modalContent &&
      <div className='mt-6'>

        <UserHelp
          colorStyle="green"
          icon={<SlightlySmilingFaceEmoji svgClass="h-10 w-10" />}
          text={<><p><strong>Título</strong>: {userInfo.title}</p>
            <p><strong>Rut</strong>: {userInfo.nid}</p></>}
          title={`${userInfo.forenames} ${userInfo.surnames}`}
        />

        <div>
          <h3 className='title text-xl mb-1 mt-0'>Cobros generados</h3>
          <div className='flex items-center text-gray-500 text-sm mb-2'>
            <InfoIcon pathClass="stroke-gray" size="s" /><p className='ml-1'>Detalles del médico consultado e historial de pagos</p>
          </div>
          {
            accordionItems && <Accordion items={accordionItems} />
          }
        </div>
      </div>
    }
    {
      showDetail && <Modal title={title} modalClass="w-full" showModal={showDetail} setShowModal={setShowDetail}>
        {
          (detailContent) && (
            <>
              <p className='mb-4 text-sm'>
                <strong>ID:</strong> {detailContent?.id ?? 'sin id'}
              </p>
              {
                statusList && <p className='mb-4 text-sm'>
                  <strong>Estado</strong>: {statusList.find(({ value: v2 }) => detailContent.status == v2)?.name} ({detailContent.status})
                </p>
              }
              <p className='mb-4 text-sm'>
                <strong>Monto:</strong> ${detailContent?.total ?? '0'}
              </p>
              <DataTable2
                columns={voucherDetail}
                data={detailContent.payments}
                orderBy="created_at"
                perPage={10}
                totalResults={detailContent.payments.length}
                sortBy="asc"
                colorStyle="neutral"
                disableHeader
              />
            </>
          )
        }
        {
          (discount) && (
            <div>
              <p className='mb-4 text-sm'><strong>Valor actual</strong>: ${discount.total ?? '-'}</p>
              <Input
                focused
                name="value"
                onChange={(e) => { setMotive(e.target.value); }}
                maxLength={63}
                placeholder="Escribe un motivo"
                title="Motivo de revalorización"
                customSize="l"
                stateType={motive ? 'success' : 'default'}
              />
              <br />
              <Input
                focused
                name="value"
                maxLength={63}
                onChange={(e) => { setValue(e.target.value); }}
                type='number'
                placeholder="Escribe un monto"
                title="Modificar valor voucher"
                customSize="l"
                stateMessage={(value && stateType === 'error') ? 'Valor debe ser superior a 0' : ''}
                stateType={stateType}
              />
              <div className='pt-2 mt-4 border-t-2 flex justify-end items-end'>
                <Button className='ml-2' size="m" type='primary' buttonType='button' onClick={() => modify(discount, 'discount')} disabled={!value || !motive || stateType === 'error'}>Aplicar</Button>
              </div>
            </div>
          )
        }
        {
          (statuschange && statusList) && (
            <div>
              <p className='mb-4 text-sm'><strong>Estado actual</strong>: {statusList.find(({ value: v2 }) => statuschange.status == v2)?.name} ({statuschange.status})</p>
              <p className='mb-4 text-sm'><strong>Valor voucher</strong>: ${statuschange.total ?? '-'}</p>
              <Select
                customClass={styles.z1000}
                customSize="m"
                items={filterOptions(currentStatus)}
                name="name"
                nameType="name"
                onChange={setStatus}
                placeholder="Seleccione una opción"
                title="Nuevo estado"
              />
              <br />
              <Input
                focused
                name="value"
                onChange={(e) => { setMotive(e.target.value); }}
                placeholder="Escribe un motivo"
                title="Motivo de cambio de estado"
                maxLength={63}
                customSize="l"
                stateType={motive ? 'success' : 'default'}
              />

              <div className='pt-2 mt-4 border-t-2 flex justify-end items-end'>
                <Button className='ml-2' size="m" type='primary' buttonType='button' onClick={() => modify(statuschange, 'statuschange')} disabled={!motive || (status === null || status === undefined)}>Cambiar estado</Button>
              </div>
            </div>
          )
        }
        {
          condoned && (
            <div>
              <p className='mb-4 text-sm'><strong>Valor actual</strong>: ${condoned.total ?? '-'}</p>
              <Input
                focused
                name="value"
                onChange={(e) => { setValue(e.target.value); }}
                type='number'
                placeholder="Escribe un monto"
                title="Modificar valor voucher"
                customSize="l"
                maxLength={63}
              // stateMessage={(value && stateType === 'error') ? 'Valor debe ser superior a 0' : ''}
              // stateType={stateType}
              />
              <br />
              <Input
                focused
                name="value"
                onChange={(e) => { setMotive(e.target.value); }}
                placeholder="Escribe un motivo"
                title="Motivo de condonación"
                customSize="l"
                stateType={motive ? 'success' : 'default'}
                maxLength={63}
              />

              <div className='pt-2 mt-4 border-t-2 flex justify-end items-end'>
                <Button className='ml-2' size="m" type='primary' buttonType='button' onClick={() => modify(condoned, 'condoned')} disabled={!motive}>Condonar</Button>
              </div>
            </div>
          )
        }

      </Modal>
    }
  </>;
};

export default Clients;