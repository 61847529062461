import { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Select, Input, Breadcrumb, PlusIcon, UserHelp, IndexPointingUpEmoji, LoadingImed } from '@imed_npm/design-system';
import { getInfoNewDoc, getSpecialtiesDoc } from '../Form/Store/actions';
import { formatRut, validFormat } from '../../utils/rut';
import LoggedUserContext from '../../context/LoggedUserContext';
import Modal from '../Modal';
import styles from './style.module.scss';
import { Product } from '../Admin/utils/interfaces';
import { getProducts } from '../Admin/utils/actions';
import { getAdscriptionProfessionals, createAdscriptionProfessionals, editAdscriptionProfessionals, enableAdscriptionProfessionals, getAuthToken, verifyEmToken } from './utils/actions';
import SubscriptionInfo from './Components/SubscriptionInfo';
import SubscriptionProfessionals from './Components/SubscriptionProfessionals';
import LoadingComponent from '../Form/Components/LoadingComponent/LoadingComponent';
import SpecialtyRow from '../Form/Components/CenterInfo/Specialities/newRow';
import image from './assets/modal.png';
import { EUsersSubscriptionAdmin, IResponseAdscriptionProf, IResponseAdscriptionProfAdscription, IResponseAdscriptionProfAdscriptionLegalPerson, IResponseAdscriptionProfCenter, IResponseHomologateMedic, IRolesUsersSubscriptionAdmin, TRolesUsersSubscriptionAdmin, TSpecialtiesSubscriptionAdmin } from './interfaces/subscriptionAdmin.interface';
import { formatDotsRut } from '@imed_npm/valida-rut';
import { ETypeOfActionToPerson, ETypeOfActionToProfessional } from './utils/interfaces';
import { first, random, set } from 'lodash';

interface Params {
  setShowSubscriptionEditorModal?: (value: boolean) => void;
  adscriptionId?: string;
}

const SubscriptionAdmin = (args: Params) => {
  const params = useParams();
  const token = params.token as string;
  const context = useContext(LoggedUserContext);

  //console.log('token',params.token);
  const [tokenAdscription, setTokenAdscription] = useState<string>();
  const [permission, setPermission] = useState<string>();

  const [dataUser, setDataUser] = useState<any>();
  const [canAdvance, setCanAdvance] = useState<boolean>(true);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>();

  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingParent, setLoadingParent] = useState<boolean>(false);

  const [loadingUserType, setLoadingUserType] = useState<boolean>(false);
  const [loadingSpecialties, setLoadingSpecialties] = useState<boolean>(false);

  const [adscriptionProfessionals, setAdscriptionProfessionals] = useState<IResponseAdscriptionProf>();
  const [showFilters, setShowFilters] = useState(false);
  const [adscriptions, setAdscriptions] = useState<IResponseAdscriptionProfAdscription[]>();
  const [centers, setCenters] = useState<IResponseAdscriptionProfCenter[]>();

  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [modalDisable, setShowModalDisable] = useState(false);

  const [adscriptionData, setAdscriptionData] = useState<IResponseAdscriptionProfAdscriptionLegalPerson>();

  const [rutToCreate, setRutToCreate] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [role, setRole] = useState<IRolesUsersSubscriptionAdmin>();
  const [isExternalLogin, setIsExternalLogin] = useState<boolean>();
  const [isMedic, setIsMedic] = useState<boolean>();

  // const [value, setValue] = useState<number>(0);

  const [infoMedicToCreate, setInfoMedicToCreate] = useState<any>();
  const [specialties, setSpecialties] = useState<IResponseHomologateMedic[]>([]);
  const [principalPrice, setPrincipalPrice] = useState<number>(0);
  const [principalSpecialty, setPrincipalSpecialty] = useState<string | undefined>(undefined);
  //const [rows, setRows] = useState<JSX.Element[]>([]);
  //const [rowId, setRowId] = useState(0);
  //const [dataToSave, setDataToSave] = useState<TSpecialtiesSubscriptionAdmin[]>([]);
  // const [prices, setPrices] = useState<number[]>([]);
  const [AddAvailable, setAddAvailable] = useState(true);
  const [isMultipleRow, setIsMultipleRow] = useState(false);

  const [selected, setSelected] = useState<IResponseHomologateMedic>();
  const [specialtiesSelect, setSpecialtiesSelect] = useState<IResponseHomologateMedic[]>([]);

  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [refreshRows, setRefreshRows] = useState<number>(1);

  const roles = TRolesUsersSubscriptionAdmin;

  const [modalDisableData, setModalDisableData] = useState<any>();


  useEffect(() => {
    console.log('contextrows', context?.editor?.rows);
    setRefreshRows( Math.random() );
  } , [context.editor.rows]);


  const setContextRows = (value) => {
    context.editor.rows = [];
    context.editor.rows = value;
    setRefreshRows( Math.random() );
  };

  const setContextData = (value) => {
    context.editor.data = value;
  };

  const setRowId = (value) => {
    context.editor.idx = value;
  };

  const validateIfIsMedic = (rut: string) => {
    getInfoNewDoc(rut).then(
      (data) => {
        setIsMedic(true);
        setInfoMedicToCreate(data);
      }
    ).catch(
      (error) => {
        // console.error('aaaaaaaaaaaaa', error);
        setIsMedic(false);
        setInfoMedicToCreate(error);
      }
    );
  };

  useEffect(() => {
    const format = formatRut(rutToCreate);

    if (validFormat(format)) {
      setLoadingUserType(true);
      setIsMedic(undefined);
      setInfoMedicToCreate(undefined);
      validateIfIsMedic(format);
    }
  }, [rutToCreate]);


  const takeSpecialties = async (rut: string) => {
    return getSpecialtiesDoc(rut).then((result: IResponseHomologateMedic[]) => {
      const filtered = result.filter(item => Object.keys(item.homologation).length > 0);
      console.log('SPECIALTIES', filtered);
      setSpecialties(filtered);
      setLoadingSpecialties(false);
    }).catch((err) => {
      console.debug(err);
      setLoadingSpecialties(false);
    });
  };

  useEffect(() => {
    if (infoMedicToCreate === undefined) {
      return;
    }
    if (!infoMedicToCreate) {
      if (loadingUserType) {
        setLoadingUserType(false);
      }
      return;
    }
    const format = formatRut(rutToCreate);

    if (validFormat(format) && isMedic) {
      setLoadingSpecialties(true);
      takeSpecialties(format);
    }
    setLoadingUserType(false);
  }, [infoMedicToCreate, isMedic]);

  useEffect(() => {
    if (infoMedicToCreate === 400 && !isMedic) {
      setRole(
        {
          id: 2,
          name: 'Asistente',
          alternative: EUsersSubscriptionAdmin.CALLCENTER,
        }
      );
      setName('');
      setLastName('');
      setSpecialties([]);
      setSpecialtiesSelect([]);
    }
    if (infoMedicToCreate !== 400 && isMedic) {
      setName(infoMedicToCreate?.nombres);
      setLastName(`${infoMedicToCreate?.apellido_paterno || ''} ${infoMedicToCreate?.apellido_materno || ''}`);
    }
    if (!infoMedicToCreate) return;
  }, [infoMedicToCreate, isMedic]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (location.pathname.includes('external')) {
        //console.log('token1',token);
        setIsExternalLogin(true);

        verifyEmToken(token).then(
          (verification) => {
            // console.log('verification', verification);
            if (verification.token) {
              window.sessionStorage.setItem('flowkey', verification.token);
            }
            setTokenAdscription(verification.token);
            setDataUser(verification.userInfo);
            getAdscriptionProfessionals(verification.token, verification.userInfo.nid).then(
              (result) => {
                // console.log('result 222', result);
                setAdscriptionProfessionals(result);
                setLoading(false);
              }
            ).catch(
              (err) => {
                console.debug('[LoginExternal] - error getAdscriptionProfessionals:', err);
                setLoading(false);
              }
            );
          }
        ).catch(
          (err) => {
            console.debug('[LoginExternal] - error verifyEmToken:', err);
            setLoading(false);
          }
        );
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [token, loadingParent, loading]);

  const searchProfessionals = async () => {
    getAuthToken().then((authToken) => {
      setTokenAdscription(authToken);
      let adscriptionId = '';
      if (location.pathname.includes('external')) {
        adscriptionId = adscriptionProfessionals?.adscriptions[0]?.adscription_id ?? '';
      } else {
        adscriptionId = token; //adscription id al llamarlo del backoffice
      }
      getAdscriptionProfessionals(authToken, undefined, adscriptionId).then((result) => {
        setAdscriptionProfessionals(result);
        setLoading(false);
        setLoadingUserType(false);
      }).catch((err) => {
        console.debug('[InternalAccess] - error getAdscriptionProfessionals:', err);
        setLoading(false);
        setLoadingUserType(false);
      });
    }).catch((err) => {
      console.debug('[InternalAccess] - error getAuthToken:', err);
      setLoading(false);
      setLoadingUserType(false);
    });
  };

  useEffect(() => {
    if (showModalEdit) {
      setContextRows([]);
    }
  },[showModalEdit]);

  useEffect(() => {
    if (showModal) {
      setContextRows([]);
    }
  },[showModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (location.pathname.includes('backoffice')) {
        searchProfessionals();
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [token, loadingParent, loading]);

  const triggerSearch = (select) => {
    setSelectedProduct(select);
  };

  useEffect(() => {
    if (!tokenAdscription) return;
    setPermission(tokenAdscription);
  }, [tokenAdscription]);

  const clearModule = (type?: string) => {
    setLoadingUserType(true);

    // console.log('hola', type);
    if (type === ETypeOfActionToPerson.EDIT) {
      setAdscriptionData(undefined);
      setShowModalEdit(false);
    } else {
      setShowModal(false);
    }
    setName('');
    setLastName('');
    setRutToCreate('');
    setEmail('');
    setPhone('');
    setRole(undefined);
    setInfoMedicToCreate(undefined);
    setContextRows([]);
    setContextData([]);
    setRowId(0);
    setSpecialties([]);
    setSpecialtiesSelect([]);
    setContextData([]);
    setLoadingParent(false);
    setShowEdit(false);

    setSelected(undefined);
    setPrincipalPrice(0);
    setPrincipalSpecialty(undefined);
  

    searchProfessionals();
    
  };

  const createOrEdit = async (type: string, tokenAdscription: string, saveData, adscriptionId?: string) => {
    try {
      if (type === ETypeOfActionToPerson.CREATE) {
        await createAdscriptionProfessionals(tokenAdscription, adscriptionId, saveData);
      }
      if (type === ETypeOfActionToPerson.EDIT) {
        await editAdscriptionProfessionals(tokenAdscription, adscriptionId, saveData);
      }
    } catch (err) {
      console.error(err);
    }
    setLoadingButton(false);
    setCanAdvance(false);
    clearModule(type);
  };

  const professionalService = async (type: string) => {
    if (!tokenAdscription) return;
    const adscriptionId = isExternalLogin ? adscriptionProfessionals?.adscriptions[0].adscription_id : token;
    setCanAdvance(true);
    setLoading(true);
    setLoadingButton(true);


    /*const specialtiesToSave = type === ETypeOfActionToPerson.CREATE ? dataToSave : [
      ...dataToSave,
      {
        specialtyId: principalSpecialty ?? '',
        amount: principalPrice ?? 0
      }
    ];*/

    const specialtiesToSave = context.editor.data;

    console.log('specialtiesToSave',specialtiesToSave);
    const saveData = {
      professional: {
        nid: formatRut(rutToCreate) ? formatRut(rutToCreate) : formatRut(adscriptionData?.nid ?? ''),
        forenames: name ?? adscriptionData?.forenames,
        surnames: lastName ?? adscriptionData?.surnames,
        email: email ?? adscriptionData?.email,
        phone_code: '+56',
        phone_number: phone ?? adscriptionData?.phone,
      },
      specialties: (role?.alternative === EUsersSubscriptionAdmin.MEDICO) ? specialtiesToSave : null,
      rol: role?.alternative
    };
    await createOrEdit(type, tokenAdscription, saveData, adscriptionId);
  };

  const disabledUser = () => {
    if (!modalDisableData) return;
    if (!tokenAdscription) return;
    const adscriptionId = modalDisableData.adscription_id;
    setLoading(true);
    console.log('modalDisableData', modalDisableData);
    enableAdscriptionProfessionals(tokenAdscription, adscriptionId, formatRut(modalDisableData.nid), ETypeOfActionToProfessional.DISABLE)
      .then(() => setLoading(false))
      .finally(() => {
        clearModule();
        setShowModalDisable(false);
        setModalDisableData(undefined);
      });
  };

  // useEffect(() => {
  //   if (data.length > 0) {
  //     const updatedData = data.map((item, index) => {
  //       if (index === 0) {
  //         return {
  //           specialtyId: selected ? selected.homologation.id : '',
  //           amount: principalPrice ?? 0
  //         };
  //       } else {
  //         return item;
  //       }
  //     });
  //     setContextData(updatedData);
  //   } else {
  //     if (selected) {
  //       setContextData([{
  //         specialtyId: selected.homologation.id ?? '',
  //         amount: principalPrice ?? 0
  //       }]);
  //     }
  //   }

  // }, [principalPrice, selected]);

  const filas: IResponseHomologateMedic[] = [];

  const agregarFila = (index: number,especialidad: IResponseHomologateMedic, amounts?: any, isEdit = true) => {
    filas.push(especialidad);
    console.log('especialidad: ', especialidad);
    console.log('monto: ', amounts);
    if (!isEdit) {
      console.log('especialidadasdasdasdasd: ', especialidad);
      handleDataChange({ specialtyId: especialidad.homologation.id, id: 0 });
    }
    console.log('FILAS: ', filas);
    console.log('data: ', context.editor.data); 
    console.log('selected: ', selected);
    const nuevasEspecialidades: IResponseHomologateMedic[] = specialties.filter(
      ({ homologation: { id: id1 } }) => !specialtiesSelect.some(({ homologation: { id: id2 } }) => id2 === id1)
    );
    console.log('SP', specialties);
    console.log('SS', specialtiesSelect);
    console.log('NE', nuevasEspecialidades);
    console.log('SSindex: ', index);

    setRowId(index+1);

    console.log('data: ', context.editor.data);
    return <SpecialtyRow
    key={index+1}
    id={index+1}
    type="backoffice"
    especialidad={nuevasEspecialidades}
    setEspecialidad={amounts ? especialidad : undefined}
    setSpecialtySelected={(specialty) => addRowSpecialty(index+1, specialty)}
    price={amounts}
    onDelete={() => deleteRow(index+1)}
    onDataChange={handleDataChange}
    onAmountChange={handleAmountChange}
    editModal={showModalEdit}
    />;
    // if (isButtonAdd) {
    //   setContextData([
    //     ...data,
    //     {
    //       specialtyId: especialidad.homologation.id,
    //       amount: prices[prices.length - 1] ?? 0
    //     },
    //   ]);
    // }
  };

  const handleAgregarClick = () => {
    // const especialidad = specialties.find((e) => e === selected);
    if (selected) {
      const especialidad = specialties.find((e) => e.specialty === selected.specialty);
      if (especialidad) {
        const fila = agregarFila(context.editor.idx,especialidad);
        setContextRows([...(context.editor.rows as JSX.Element[]), fila]);
      }
    }
  };


  const deleteRow = (id: number) => {
    console.log('deleteRow', id);
    console.log('rows', context.editor.rows);

    const toEliminate = context.editor.rows.find((element) => element.key === id.toString());
    const updatedRows = (context.editor.rows as JSX.Element[]).filter((element, index) => {
      console.log('CCindex', index);
      console.log('CCid', id);
      console.log('CCelement.key', element.key);
      return element.key  !== id.toString();
    });
    console.log('CCupdatedRows', updatedRows);
    console.log('CCData', context.editor.data);

    setTimeout(() => {
      setContextRows(updatedRows);
    } , 1000);
    //setContextRows(updatedRows);
    //deleteItemFromData(id);
    console.log('CCtoEliminate', toEliminate);
    const newDataPrev = context.editor.data;
    const filteredData = newDataPrev.filter((element, index) => {
      console.log('DDindex', index);
      console.log('DDid', id);
      console.log('DDelement', element);
      return element.specialtyId.toString() !== toEliminate?.props?.setEspecialidad?.homologation?.id && element.amount !== toEliminate?.props?.price;
    });
    console.log('DDfilteredData', filteredData);
    setContextData(filteredData);
  };

  const addRowSpecialty = (id: number, item: any) => {
    const updatedSpecialties = [...specialties];
    updatedSpecialties[id] = item;
    setSpecialties(updatedSpecialties);
  };

  const getSpecialty = ( specialtyId:string ) => {
    if(specialties){
      const findSpecialty = specialties.find(x => x.homologation.id === specialtyId);
      return {
        specialty: findSpecialty?.specialty ?? '',
        homologation: findSpecialty?.homologation
      };
    }
    return {};
  };

  const handleDataChange = (updatedData: { specialtyId: string, id: number, amount?: number }) => {
    const findSpecialty = specialties.find(x => x.homologation.id === updatedData.specialtyId);
    console.log('handleDataChange - 0',context.editor.data);
    console.log('handleDataChange - 1',findSpecialty,specialtiesSelect);
    setSelected(findSpecialty);
    if (findSpecialty) {
      setSpecialtiesSelect(prev => {
        const existSpecialty = prev.some(x => x.homologation.id === findSpecialty.homologation.id);
        if (existSpecialty) {
          const newData = prev.filter(y => y.homologation.id !== findSpecialty.homologation.id);
          const dataToSave = [...newData, findSpecialty];
          // console.log('000', dataToSave);
          return dataToSave;
        }
        const dataToSave = [...prev, findSpecialty];
        // console.log('001', dataToSave);
        return dataToSave;
      });
    }
    const prevData = context.editor.data;
    let dataToRegister;
    
    const findData = prevData.find(x => x.specialtyId === updatedData.specialtyId);
    console.log('handleDataChange - 2', findData);
    
    if (findData) {
      const dataToSave = {
        specialtyId: updatedData.specialtyId,
        amount: Number(findData.amount),
        specialty: getSpecialty(updatedData.specialtyId)
      };
      const newData = prevData.filter(x => x.specialtyId !== updatedData.specialtyId);
      dataToRegister = [...newData, dataToSave];
    } else {
      const dataToSave = {
        specialtyId: updatedData.specialtyId,
        specialty: getSpecialty(updatedData.specialtyId),
        amount: updatedData.amount ? Number(updatedData.amount) : undefined
      };
      dataToRegister = [...prevData, dataToSave];
    }
    
    console.log('123AAAAA', dataToRegister);    
    setContextData(dataToRegister);
    
  };

  const handleAmountChange = (updatedData: { specialtyId: string, amount: number, id: number }) => {
    console.log('BBdatatosave', context.editor.data);
    console.log('BBupdateddata', updatedData);

    const prevData = context.editor.data;
    let dataToRegister;
    
    const findData = prevData.find(x => x.specialtyId === updatedData.specialtyId);
    console.log('BBfindData', findData);
    console.log('BBspecialties', specialties);
    console.log('BBprincipalPrice', principalPrice);
    console.log('BBprincipalspecialty', principalSpecialty);
    
    if (findData) {
      const dataToSave = {
        specialtyId: updatedData.specialtyId,
        amount: Number(updatedData.amount),
        specialty: getSpecialty(updatedData.specialtyId)
      };
      const newData = prevData.filter(x => x.specialtyId !== updatedData.specialtyId);
      dataToRegister = [...newData, dataToSave];
      console.log('100', dataToRegister);
    } else {
      console.log('BBno existe');
      console.log('BBprevData', prevData);
      console.log('BBupdatedData', updatedData);
    
      const dataToSave = {
        specialtyId: updatedData.specialtyId,
        amount: Number(updatedData.amount),
        specialty: getSpecialty(updatedData.specialtyId)
      };
    
      dataToRegister = [...prevData, dataToSave];
      console.log('101', dataToRegister);
    }
    
    // Now, pass the new state directly to setContextData
    setContextData(dataToRegister);
    
  };

  // const addPrice = (id: number, price: number) => {
  //   const updatedPrices = [...prices];
  //   updatedPrices[id] = price;
  //   setPrices(updatedPrices);
  // };

  useEffect(() => {
    if ((context.editor.rows as JSX.Element[]).length > 0) {
      setIsMultipleRow(true);
    } else {
      setIsMultipleRow(false);
    }
  }, [(context.editor.rows as JSX.Element[])]);

  useEffect(() => {
    if (location.pathname.includes('backoffice')) {
      const { userData } = context;
      if (userData && userData.products) {
        setProducts(userData.products);
      } else {
        const timeout = setTimeout(async () => {
          getProducts(context).then(setProducts);
        }, 250);
        return () => clearTimeout(timeout);
      }
    }
  }, [context]);

  useEffect(() => {
    if (!products) return;
    const options = products.find((option) => option.name === params.product);
    setShowFilters(true);

    if (options) {
      setSelectedProduct(options);
    }
  }, [params, products]);

  useEffect(() => {
    if (!adscriptionProfessionals) return;
    setCenters(adscriptionProfessionals.centers);
    setAdscriptions(adscriptionProfessionals.adscriptions);
  }, [adscriptionProfessionals]);

  useEffect(() => {
    if (specialties) {
      if (!selected) {
        setAddAvailable(true);
      } else {
        if (specialties?.length === ((context.editor.rows as JSX.Element[]).length + 1)) {
          setAddAvailable(true);
        } else if (!principalPrice) {
          setAddAvailable(true);
        } else {
          setAddAvailable(false);
        }
      }
    }
  }, [selected, (context.editor.rows as JSX.Element[]), specialties, principalPrice]);

  useEffect(() => {

    console.log('MODdata',context.editor.data);

    // setCanAdvance(true);
    if (rutToCreate && name && lastName && email && phone && role && context.editor.data) {
      if (role?.alternative === EUsersSubscriptionAdmin.MEDICO && principalPrice > 0) {
        setCanAdvance(false);
      } else if (role?.alternative === EUsersSubscriptionAdmin.CALLCENTER) {
        setCanAdvance(false);
      } else {
        setCanAdvance(true);
      }
    } else {
      setCanAdvance(true);
    }
  }, [context.editor.data, rutToCreate, name, lastName, email, phone, role]);

  useEffect(() => {
    if (!adscriptionData) return;
    const { representative_professional_specialty } = adscriptionData;

    if (representative_professional_specialty) {
      setName(adscriptionData.forenames);
      setLastName(adscriptionData.surnames);
      setEmail(adscriptionData.email);
      setPhone(adscriptionData.phone);
      if (adscriptionData.userTypeName === EUsersSubscriptionAdmin.CALLCENTER) {
        setRole({
          id: 2,
          name: 'Asistente',
          alternative: EUsersSubscriptionAdmin.CALLCENTER,
        });
      } else if (adscriptionData.userTypeName === EUsersSubscriptionAdmin.MEDICO) {
        setRutToCreate(adscriptionData.nid);
        setRole({
          id: 1,
          name: 'Médico',
          alternative: EUsersSubscriptionAdmin.MEDICO,
        });
        const firstSpecialty = {
          specialty: representative_professional_specialty[0]?.specialties?.description,
          homologation: representative_professional_specialty[0]?.specialties,
        };
        console.log('firstSpecialty - 2', firstSpecialty,representative_professional_specialty,representative_professional_specialty[0]?.amount);
        setSpecialtiesSelect([firstSpecialty]);
        setSelected(firstSpecialty);

        setPrincipalSpecialty(representative_professional_specialty?.[0]?.specialties?.id);
        setPrincipalPrice(representative_professional_specialty?.[0]?.amount);
      }
    }
  }, [adscriptionData]);


  //esto sirve para que se setee la fila por defecto en la edicion
  //despues de eso no vuelve a hacerse, por eso se usa el showEdit
  useEffect(() => {
    if(specialties.length && selected && showModalEdit && !showEdit){
      console.log('specialties - 2', specialties);
      setTimeout(() => {
        handleDataChange({ specialtyId: specialtiesSelect[0].homologation.id, id: 0, amount: principalPrice });
        setShowEdit(true);
      }, 1000);
    }
  }, [selected, specialties]);


  useEffect(() => {
    if (!adscriptionData) return;
    if (!rutToCreate) return;
    const { representative_professional_specialty } = adscriptionData;

    if (representative_professional_specialty) {
      const formattedArray: IResponseHomologateMedic[] = representative_professional_specialty.map((item) => {
        const findSpecialty = specialties.find(x => x.homologation.id === item.specialties.id);

        return {
          specialty: findSpecialty?.specialty ?? '',
          homologation: findSpecialty?.homologation ?? item.specialties
        };
      });
      const filtered = formattedArray.filter(item => item.homologation.id !== selected?.homologation.id);
      console.log('ASDADSASDASDASDad',selected);

      //const amount: number[] = representative_professional_specialty.map((item) => item.amount);
      //formattedArray.shift();
      const findAmount = (id: string) => {
        const find = representative_professional_specialty.find(x => x.specialties.id === id);
        return find?.amount ?? 0;
      };
      console.log('formattedArray - 1', filtered);
      const infoToSaveInData: any[] = filtered.map((elemento, index) => ({ specialty: elemento, specialtyId: elemento.homologation.id, amount: findAmount(elemento.homologation.id) }));
      console.log('info in data', infoToSaveInData);
      const newRows = infoToSaveInData.map((x,index) => {
        //if(showEdit){
        //  handleDataChange({ specialtyId: x.specialty.homologation.id, id: rowId+1 });
        //}
        // handleDataChange({ specialtyId: x.specialty.homologation.id, id: 0 });
        // TODO: en la edicion aqui esta el problema, no carga el segundo row de especialidad
        return agregarFila(index,x.specialty, x.amount, showModalEdit);
      });
      console.log('oldRows', (context.editor.rows as JSX.Element[]));
      console.log('newRows', newRows);
      console.log('try newRows', newRows[0]?.props?.id);

      setContextRows([...(context.editor.rows as JSX.Element[]), ...newRows]);
      setContextData(infoToSaveInData);
      if(showModalEdit){
        setSelected(specialtiesSelect[0]);
      }
    }
  }, [adscriptionData, specialties]);

  useEffect(() => {
    if (!adscriptionData) return;
    const { representative_professional_specialty, nid } = adscriptionData;
    if (representative_professional_specialty) {
      if (representative_professional_specialty?.length < 1) {
        if (validFormat(nid)) {
          setLoadingSpecialties(true);
          takeSpecialties(nid);
        }
      }
    }
  }, [adscriptionData]);

  const modalsToShow = () => {
    return (
      <>
        {
          loadingUserType && <LoadingImed key={'loader_specialty'} text='Verificando información adicional' />
        }
        {
          loadingButton && <LoadingImed key={'loader_create'} text='Creado ...' />
        }
        {
          loadingSpecialties && <LoadingImed key={'loader_create'} text='Cargando información adicional' />
        }
        {showModal &&
          <Modal title={'Crear usuario'} showModal={showModal} showCloseButton={false}>
            <div className='w-[700px]'>
              <ol className='list-decimal pl-8'>
                <li className='pl-6'>
                  <div>
                    <p>Ingrese los siguientes datos</p>
                    <div className='grid grid-cols-2 col-gap-3 mt-5'>
                      <div className={styles.fullinput}>
                       <Input
                          focused
                          name="rut"
                          onChange={(e) => setRutToCreate(e.target.value)}
                          stateMessage={(rutToCreate.length >= 9) ? ((rutToCreate && validFormat(formatRut(rutToCreate))) ? 'Rut válido' : 'Rut inválido') : ''}
                          stateType={(rutToCreate.length >= 9) ? ((rutToCreate && validFormat(formatRut(rutToCreate))) ? 'success' : 'error') : 'default'}
                          placeholder="Ingresa rut"
                          title="Rut*"
                        />
                      </div>
                      <div className={styles.fullinput}>
                        {
                          !loadingUserType &&
                          <div className={styles.fullinput}>
                            <Select
                              customSize="l"
                              customClass={styles.z1000}
                              items={roles}
                              name="roles"
                              nameType="name"
                              placeholder="Seleccione rol"
                              title="Rol"
                              disabled={!infoMedicToCreate ? true : ((infoMedicToCreate === 400 && !isMedic) ? true : false)}
                              value={(infoMedicToCreate === 400 && !isMedic) && role}
                              onChange={(value) => setRole(value)}
                            />
                          </div>
                        }
                      </div>
                    </div>
                    <div className='grid grid-cols-2 col-gap-3 mt-5'>
                      <div className={styles.fullinput}>
                        <Input
                          focused
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          placeholder="Ingresa nombre"
                          title="Nombre de usuario*"
                        />
                      </div>
                      <div className={styles.fullinput}>
                        <Input
                          focused
                          name="lastname"
                          onChange={(e) => setLastName(e.target.value)}
                          value={lastName}
                          placeholder="Ingresa Apellidos"
                          title="Apellido de usuario"
                        />
                      </div>
                    </div>
                    <div className='grid grid-cols-2 col-gap-2 mt-5 mb-10'>
                      <div className={styles.fullinput}>
                        <Input
                          focused
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Ingresa email"
                          title="Correo electrónico"
                          type="email"
                        />
                      </div>
                      <div className={styles.fullinput}>
                        <Input
                          focused
                          name="phone"
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="Ingresa teléfono"
                          title="Teléfono celular"
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                </li>
                {
                  !loadingUserType && !loadingSpecialties && (role && role.alternative === EUsersSubscriptionAdmin.MEDICO) && <li className='pl-6'>
                    <div>
                      <p>
                        Indique su especialidad y el valor que asignará a su(s) consulta(s) cuando configure su Agenda Médica para disponibilizar sus horas:
                      </p>
                      <div className='grid grid-cols-2 col-gap-2 mt-5 mb-10'>
                        <div className={styles.fullinput}>
                          <Select
                            customSize="l"
                            customClass={styles.z1000}
                            items={specialties}
                            name="specialty"
                            nameType="specialty"
                            placeholder="Seleccione especialidad"
                            title="Especialidad"
                            disabled={isMultipleRow}
                            // onChange={handleSelectChange}
                            value={selected}
                            onChange={
                              (value) => {
                                setPrincipalSpecialty(value.homologation.id);
                                handleDataChange({ specialtyId: value.homologation.id, id: 0 });
                              }
                            }
                          />
                        </div>

                        <div className={`${styles.fullinput} flex items-end justify-between`}>
                          <div>
                            <Input
                              focused
                              name="value"
                              onChange={(e) => {
                                console.log('PRINCIPALCHANGEe.target.value', e.target.value);
                                setPrincipalPrice(Number(e.target.value));
                                handleAmountChange(
                                  {
                                    specialtyId: principalSpecialty ?? '',
                                    amount: Number(e.target.value),
                                    id: 0
                                  }
                                );

                              }}
                              onBlur={
                                (e) => {
                                  console.log('PRINCIPALBLURe.target.value', e.target.value);
                                  setPrincipalPrice(Number(e.target.value));
                                  handleAmountChange(
                                    {
                                      specialtyId: principalSpecialty ?? '',
                                      amount: Number(e.target.value),
                                      id: 0
                                    }
                                  );
                                }
                              }
                              placeholder="Ingresa monto consulta"
                              title="Precio consulta particular"
                              disabled={false}
                              type="number"
                            />
                          </div>

                          {
                            (specialties?.length > 1) && <Button
                              className='ml-3'
                              buttonType="button"
                              type="secondary"
                              disabled={AddAvailable}
                              onClick={handleAgregarClick}>
                              <PlusIcon />
                            </Button>
                          }
                        </div>
                      </div>
                      <div className='mb-10'>
                        {(context.editor.rows as JSX.Element[]).map((row, index) => row)}
                      </div>
                    </div>
                  </li>
                }

              </ol>
              <UserHelp
                colorStyle="green"
                icon={<IndexPointingUpEmoji svgClass="h-10 w-10" />}
                text="Toda la información ingresada podrá ser editada luego de crear al usuario."
                title="Para tu tranquilidad"
              />
              <div className='flex items-center justify-end mt-5'>
                <Button buttonType="button" type="secondary-two" className='mr-4' onClick={() => clearModule(ETypeOfActionToPerson.CREATE)}>Salir</Button>
                <Button
                  buttonType="button"
                  type="secondary"
                  onClick={() => professionalService(ETypeOfActionToPerson.CREATE)}
                  disabled={canAdvance}>
                  {
                    loadingButton ?
                      <><svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg> Creando
                      </>
                      : 'Crear usuario'
                  }
                </Button>
              </div>
            </div>
          </Modal>}
        {(showModalEdit && adscriptionData) &&  
          <Modal title={'Editar usuario'} showModal={showModalEdit} showCloseButton={false}>
            <div className='w-[700px]'>
              <ol className='list-decimal pl-8'>
                <li className='pl-6'>
                  <div>
                    <p>Ingrese los siguientes datos</p>
                    <div className='grid grid-cols-2 col-gap-3 mt-5'>
                      <div className={styles.fullinput}>
                        <Input
                          focused
                          name="rut"
                          value={adscriptionData.nid}
                          placeholder="Ingresa rut"
                          title="Rut*"
                          disabled
                        />
                      </div>
                      <div className={styles.fullinput}>
                        {
                          !loadingUserType &&
                          <div className={styles.fullinput}>
                            <Select
                              customSize="l"
                              customClass={styles.z1000}
                              items={roles}
                              name="roles"
                              nameType="name"
                              placeholder="Seleccione rol"
                              title="Rol"
                              disabled={role ? true : false}
                              value={role}
                              onChange={(value) => setRole(value)}
                            />
                          </div>
                        }
                      </div>
                    </div>
                    <div className='grid grid-cols-2 col-gap-3 mt-5'>
                      <div className={styles.fullinput}>
                        <Input
                          focused
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          value={name ?? adscriptionData.forenames}
                          placeholder="Ingresa nombre"
                          title="Nombre de usuario*"
                        />
                      </div>
                      <div className={styles.fullinput}>
                        <Input
                          focused
                          name="lastname"
                          value={lastName ?? adscriptionData.surnames}
                          onChange={(e) => setLastName(e.target.value)}
                          placeholder="Ingresa Apellidos"
                          title="Apellido de usuario"
                        />
                      </div>
                    </div>
                    <div className='grid grid-cols-2 col-gap-2 mt-5 mb-10'>
                      <div className={styles.fullinput}>
                        <Input
                          focused
                          name="email"
                          value={email ?? adscriptionData.email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Ingresa email"
                          title="Correo electrónico"
                          type="email"
                        />
                      </div>
                      <div className={styles.fullinput}>
                        <Input
                          focused
                          name="phone"
                          value={phone ?? adscriptionData.phone}
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="Ingresa teléfono"
                          title="Teléfono celular"
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                </li>
                {
                  !loadingUserType && !loadingSpecialties && (role && role.alternative === EUsersSubscriptionAdmin.MEDICO) && showEdit  && <li className='pl-6'>
                    <div>
                      <p>
                        Indique su especialidad y el valor que asignará a su(s) consulta(s) cuando configure su Agenda Médica para disponibilizar sus horas:
                      </p>
                      <div className='grid grid-cols-2 col-gap-2 mt-5 mb-10'>
                        {
                          selected && specialties && <div className={styles.fullinput}>
                          <Select
                            customSize="l"
                            customClass={styles.z1000}
                            items={specialties}
                            value={selected}
                            name="specialty"
                            nameType="specialty"
                            placeholder="Seleccione especialidad"
                            title="Especialidad"
                            disabled={false}
                            onChange={
                              (value) => {
                                setPrincipalSpecialty(value.homologation.id);
                                handleDataChange({ specialtyId: value.homologation.id, id: 0 });
                              }
                            }
                          />
                        </div>
                        }

                        <div className={`${styles.fullinput} flex items-end justify-between`}>
                          <div>
                            <Input
                              focused
                              name="value"
                              value={principalPrice}
                              onChange={(e) => {
                                console.log('PRINCIPALCHANGEe.target.value', e.target.value);
                                setPrincipalPrice(Number(e.target.value));
                                handleAmountChange(
                                  {
                                    specialtyId: principalSpecialty ?? '',
                                    amount: Number(e.target.value),
                                    id: 0
                                  }
                                );

                              }}
                              onBlur={
                                (e) => {
                                  console.log('PRINCIPALBLURe.target.value', e.target.value);
                                  setPrincipalPrice(Number(e.target.value));
                                  handleAmountChange(
                                    {
                                      specialtyId: principalSpecialty ?? '',
                                      amount: Number(e.target.value),
                                      id: 0
                                    }
                                  );
                                }
                              }
                              placeholder="Ingresa monto consulta"
                              title="Precio consulta particular"
                              type="number"
                            />
                          </div>

                          {
                            (specialties?.length > 1) && <Button
                              className='ml-3'
                              buttonType="button"
                              type="secondary"
                              disabled={AddAvailable}
                              onClick={handleAgregarClick}>
                              <PlusIcon />
                            </Button>
                          }
                        </div>
                      </div>
                      { refreshRows && <div className='mb-10'>
                          {context.editor.rows.map((row, index) => row) }
                      </div>
                      }
                    </div>
                  </li>
                }

              </ol>
              <UserHelp
                colorStyle="green"
                icon={<IndexPointingUpEmoji svgClass="h-10 w-10" />}
                text="Toda la información ingresada podrá ser editada luego de crear al usuario."
                title="Para tu tranquilidad"
              />
              <div className='flex items-center justify-end mt-5'>
                <Button buttonType="button" type="secondary-two" className='mr-4' onClick={() => clearModule(ETypeOfActionToPerson.EDIT)}>Salir</Button>
                <Button
                  buttonType="button"
                  type="secondary"
                  onClick={() => professionalService(ETypeOfActionToPerson.EDIT)}
                >
                  {
                    loadingButton ?
                      <><svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg> Guardando
                      </>
                      : 'Editar usuario'
                  }
                </Button>
              </div>
            </div>
          </Modal>}
        {modalDisable && modalDisableData &&
          <Modal title={''} showModal={modalDisable} setShowModal={setShowModalDisable}>
            <div className='flex flex-col justify-center items-center px-10 mx-10'>
              <img src={image} alt="modal disable" />
              <h1 className={`text-center py-2 ${styles.title}`}>¿Estas seguro que<br />quieres deshabilitar?</h1>
              {
                modalDisableData && <p>{formatDotsRut(modalDisableData?.nid)} - {modalDisableData?.forenames} {modalDisableData?.surnames} - {modalDisableData?.userTypeName}</p>
              }
              <div className='flex items-center justify-center mt-5'>
              
            
                <Button buttonType="button" type="secondary-two"  className='mr-4' onClick={() => { setShowModalDisable(false);setModalDisableData(undefined); }}>Salir</Button>
                <Button buttonType="button" type="secondary" disabled={loading} loading={loading} loadingText="Guardando" onClick={disabledUser}>Sí</Button>
              </div>
            </div>
          </Modal>
        }
      </>
    );
  };


  return (
    <div className="bg-white p-6">
      {
        !loading && !isExternalLogin && (<Breadcrumb
          Link={Link}
          navOptions={[
            {
              label: 'Home',
              url: '/backoffice'
            },
            {
              label: 'Búsqueda de beneficiario',
              url: '/backoffice'
            },
            {
              label: (params && params.product) ? params.product : 'Escritorio_médico',
              url: ''
            }
          ]}
          showBackButton={false}

        />)
      }
      {modalsToShow()}

      {
        !loading &&
        <>
          <h1 className="text-secondary-imed-60 text-title tracking-[0.1px] font-normal my-4 mt-0">Gestionar lugar de atención</h1>
          {
            isExternalLogin ? (<p className={`${styles.subtitle} pb-5`}>Aquí puedes crear, modificar, ver y borrar usuarios para el lugar de atención.</p>) : (<p className={`${styles.subtitle} pb-5`}>Seleccione el Producto y el Centro médico que desea administrar</p>)
          }
          <hr />
          <div className="mt-5">
            <div className='flex justify-between items-center'>
              <div className='flex items-center'>
                {
                  (products && products.length > 0) && <Select
                    customClass={`${styles.z0} mb-4 mr-2`}
                    customSize="m"
                    items={products}
                    name="selectName"
                    nameType="description"
                    onChange={(e) => triggerSearch(e)}
                    value={selectedProduct}
                    placeholder="Seleccione un producto"
                    title="Producto"
                    disabled={params.product ? true : false}
                  />
                }
                {
                  !isExternalLogin && (centers && centers?.length > 0) &&
                  <Select
                    customClass={`${styles.z0} mb-4`}
                    customSize="m"
                    items={centers}
                    name="Centers"
                    nameType="name"
                    onChange={(e) => triggerSearch(e)}
                    placeholder="Seleccione un centro"
                    title="Centro médico"
                  />
                }
              </div>
              {
                (showFilters || isExternalLogin) && adscriptionProfessionals &&
                <Button type='secondary' buttonType='button' onClick={() => setShowModal(true)} IconLeft={PlusIcon}>Crear usuario</Button>
              }

            </div>
          </div>
        </>
      }

      {loading && <LoadingComponent />}
      {
        !loading && !isExternalLogin && (
          <>
            <h4 className='text-base font-semibold my-10'>
              Producto seleccionado: <span className='text-blue-800'>{selectedProduct?.description}</span>
            </h4>
          </>
        )
      }
      {!loading && adscriptionProfessionals &&
        <div className="mt-5">
          <div className='w-full'>
            {
              adscriptionProfessionals &&
              <>
                <SubscriptionInfo
                  adscriptions={adscriptionProfessionals.adscriptions[0]}
                />
              </>
            }
          </div>

          <div className='w-full'>
            {
              adscriptionProfessionals && adscriptions &&
              <>
                <SubscriptionProfessionals
                  adscription={adscriptions[0]}
                  permission={permission}
                  setShowModalDisable={setShowModalDisable}
                  setShowModalEdit={setShowModalEdit}
                  setAdscriptionData={setAdscriptionData}
                  setLoadingParent={setLoadingParent}
                  setClear={clearModule}
                  setModalDisableData={setModalDisableData}
                />
              </>

            }
          </div>
        </div>
      }
    </div>);
};


export default SubscriptionAdmin;
