import React, { useState, useEffect } from 'react';
import Modal from '../../../../Modal';
import icon from '../../DealSignature/Components/DocumentsSignedCorrectly/assets/icon.svg';
import iconDegree from '../../ProfessionalInfo/Components/IncompatibleDegree/assets/icon.svg';
import { Button } from '@imed_npm/design-system';
import PaymentModal from './mercadoPagoModal';
import { EStatusEnrolmentCard } from './enum/statusEnrolmentCard.enum';
import LoadingComponent from '../../LoadingComponent/LoadingComponent';
import { UseStep } from '../../../Store/step-context';
import SuccessModal from '../../ProfessionalInfo/Components/SuccessModal';

function PaymentMP() {
  const { state: { steps: { step2: { nid }, step3: { email }, step22 } , adscription_id: adscriptionId  }, dispatch } = UseStep();

  const [showInputCardMPModal, setShowInputCardMPModal] = useState<boolean>();
  // const [showRenderMessageFail, setShowRenderMessageFail] = useState<boolean>(false);
  const [statusCardMPModal, setStatusCardMPModal] = useState<EStatusEnrolmentCard>(EStatusEnrolmentCard.INIT);
  const selectedProfessional = {
    nid: nid,
    email: email
  };
  const [showFinal, setShowFinal] = useState<boolean>(false);

  const onCloseModal = () => {
    setStatusCardMPModal(EStatusEnrolmentCard.REJECT);
    setShowInputCardMPModal(false);
  };

  const renderModalMpShow = () => {
    return (<Modal
      title={''}
      showModal={showInputCardMPModal}
      containerClass='w-1/2'
      setShowModal={setShowInputCardMPModal}
      functionCloseButton={() => onCloseModal()}>
      {
        <>
          <div className='ml-4'>
            <h1 className='title mb-0'>Nueva tarjeta</h1>
            <p className='subtitle'>Selecciona e ingresa los datos de la tarjeta a la que se cargará el pago.</p>
          </div>
          <PaymentModal
          adscriptionId={adscriptionId}
          setShowInputCardMPModal={setShowInputCardMPModal}
          showLoader={statusCardMPModal === EStatusEnrolmentCard.LOADING}
          setStatusCardMPModal={setStatusCardMPModal}
          professional={selectedProfessional}
          billingInfo={step22}
        />
        </>
      }
    </Modal>);
  };

  const onStartModal = () => {
    setStatusCardMPModal(EStatusEnrolmentCard.LOADING);
    setShowInputCardMPModal(true);
  };

  useEffect(() => {
    if (statusCardMPModal === EStatusEnrolmentCard.INIT) {
      onStartModal();
    }
  }, [statusCardMPModal]);

  useEffect(() => {
    if (statusCardMPModal === EStatusEnrolmentCard.SUCCESS) {
      setShowFinal(true);
    } else {
      setShowFinal(false);
    }
  }, [statusCardMPModal]);

  const goToEndScreen = (show) => {
    if (!show) {
      dispatch({ type: 'UPDATE_STEP', payload: { step: 'step9' } });
    }
  };

  // Mensaje inicio
  const renderMessageInit = () => {
    return (
      <div className='flex items-start'>
        <img src={icon} alt={'imagen'} />
        <div className='px-10'>
          <p className='mt-5'>
            <strong>¡Estás a un paso de activar tu servicio!</strong>
          </p>
          <p className='my-5'>
            Para finalizar tu suscripción y habilitar tu servicio de Escritorio Médico, debes ingresar un método de pago.
          </p>
          <p>
            Una vez ingresado, para resguardar la seguridad de la transacción, <strong>se realizará un cargo temporal de $1.000.-, el que será reembolsado inmediatamente.</strong>
          </p>
          <div>
            <Button buttonType='button' type="secondary" onClick={() => onStartModal()}>
              ¡Comencemos!
            </Button>
          </div>
        </div>
      </div>
    );
  };
  // Mensaje exito
  const renderMessageSuccess = () => {
    return <Modal showModal={showFinal} containerClass='w-2/5' setShowModal={goToEndScreen}>
      <SuccessModal text="Has completado con éxito el proceso de inscripción de tu tarjeta." />
    </Modal>;
  };
  const renderMessageFailed = () => {
    return (
      <div className='flex items-start'>
        <img src={iconDegree} alt={'imagen'} />
        <div className='px-10'>
          <p className='mt-5'>
            <strong>Recomendamos intentar lo siguiente antes de ingresar nuevamente tu tarjeta</strong>
          </p>
          <p className='my-5'>
            Verifica tus datos ingresados
          </p>
          <div>
            <Button buttonType='button' type="secondary" onClick={() => onStartModal()}>
              Verificar
            </Button>
          </div>
          <p className='mt-5'>
            Recuerda que para finalizar el proceso de Suscripción y activar tu servicio, necesitamos que ingreses los datos de tu forma de pago.
          </p>

          <p className='mt-5'>
            Además, para garantizar la seguridad de tu información y validar tu tarjeta de crédito, <strong>se realizará un cargo temporal de $1.000, que será reembolsado a tu medio de pago inmediatamente.</strong>
          </p>

        </div>
      </div>
    );
  };

  return (
    <>
      {/* {(statusCardMPModal === EStatusEnrolmentCard.INIT) && renderMessageInit()} */}
      {(statusCardMPModal === EStatusEnrolmentCard.SUCCESS) && renderMessageSuccess()}
      {(statusCardMPModal === EStatusEnrolmentCard.REJECT || statusCardMPModal === EStatusEnrolmentCard.ERROR) && renderMessageFailed()}
      {(statusCardMPModal === EStatusEnrolmentCard.PENDING) && <LoadingComponent />}
      {
        showInputCardMPModal && renderModalMpShow()
      }
    </>
  );
}
export default PaymentMP;
