import { useEffect, useState } from 'react';
import { Select } from '@imed_npm/design-system';
import { Locality, Region } from '../../utils/interfaces';
import { getRegions } from '../Form/Store/actions';
import { GooglePredictionSelectItem } from '../../utils/interfaces';
import LoadingComponent from '../Form/Components/LoadingComponent/LoadingComponent';
import styles from '../Form/style.module.scss';

interface OwnProps {
  setLocality: React.Dispatch<React.SetStateAction<Locality>>;
  setRegion: React.Dispatch<React.SetStateAction<Region>>;
  address: GooglePredictionSelectItem | undefined;
  customClass?: string;
}

const SelectRegion = ({ setLocality, setRegion, address, customClass }: OwnProps): JSX.Element => {
  const [loader, setLoader] = useState(true);
  const [regions, setRegions] = useState<Region[]>([]);
  const [localities, setLocalities] = useState<Locality[]>([]);
  const [defaultRegion, setDefaultRegion] = useState<any>();
  const [defaultLocalty, setDefaultLocalty] = useState<any>();
  const [selectedRegion, setSelectedRegion] = useState<Region>();
  const [showLocalities, setshowLocalities] = useState<boolean>(false);

  const normalizeAndRemoveDiacritics = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  useEffect(() => {
    getRegions().then(setRegions);
  }, []);

  useEffect(() => {
    const { length } = regions;
    setLoader(true);
    if (!length) {
      setLoader(false);
    } else {
      if (address) {
        const [_address, _locality1, _locality2, _country] = address.description.split(/[,|-]/).map(text => text.trim());
        let commune;
        if (!_locality2) {
          //direccion de 2 segmentos
          //comuna, pais
          commune = _address;
        } else if (!_country) {
          //direccion de 3 segmentos
          //direccion, comuna, pais
          commune = _locality1;
        } else {
          //direccion de 4 segmentos
          //direccion, localidad, comuna, pais
          commune = _locality2;
        }
        const filter = regions.filter(
          ({ cities }) => {
            return cities.find(
              ({ localities }) => {
                return localities.find(({ name }) => {
                  const normalizedCommune = normalizeAndRemoveDiacritics(commune);
                  const normalizedName = normalizeAndRemoveDiacritics(name);
                  return normalizedName === normalizedCommune;
                });
              }
            );
          }
        );

        setDefaultRegion(!commune ? regions : filter);

        //setLoader(true);
      } else {
        setLoader(false);
      }
    }
  }, [regions, address]);

  useEffect(() => {
    if (!defaultRegion || defaultRegion.length < 1) return;

    setRegion(defaultRegion[0]);
    const _localities = defaultRegion[0].cities.reduce((_localities, { localities }) => [..._localities, ...localities], []);
    if (address) {
      const [_address, _locality1, _locality2, _country] = address.description.split(/[,|-]/).map(text => text.trim());
      setLocalities(_localities);
      if (!_locality2) {
        // dirección de 2 segmentos: comuna, país
        setDefaultLocalty(_localities.filter(({ name }) =>
          normalizeAndRemoveDiacritics(name) === normalizeAndRemoveDiacritics(_address)
        ));
      } else if (!_country) {
        // dirección de 3 segmentos: dirección, comuna, país
        setDefaultLocalty(_localities.filter(({ name }) =>
          normalizeAndRemoveDiacritics(name) === normalizeAndRemoveDiacritics(_locality1)
        ));
      } else {
        // dirección de 4 segmentos: dirección, localidad, comuna, país
        setDefaultLocalty(_localities.filter(({ name }) =>
          normalizeAndRemoveDiacritics(name) === normalizeAndRemoveDiacritics(_locality2)
        ));
      }
    } else {
      setLocalities(_localities);
    }
    setLoader(false);

    setshowLocalities(true);
  }, [selectedRegion, defaultRegion]);

  useEffect(() => {
    if (defaultRegion && defaultRegion.length > 0) {
      setSelectedRegion(defaultRegion[0]);
    }
  }, [defaultRegion]);

  useEffect(() => {
    if (defaultLocalty) {
      setLocality(defaultLocalty[0]);
    }
  }, [defaultLocalty]);

  return (
    <>
      {
        loader && defaultRegion && (
          <>
            <LoadingComponent />
          </>
        )
      }
      {
        !loader && (
          <>
            {
              (regions && defaultRegion) && (
                <Select
                  items={regions}
                  value={defaultRegion[0]}
                  // customClass={styles.z1000}
                  customClass={customClass ?? styles.z1000}
                  name="name"
                  nameType="name"
                  onChange={setSelectedRegion}
                  placeholder="Seleccione una opción"
                  title="Región"
                />
              )
            }
            {
              (localities && defaultLocalty) && (<Select
                items={localities}
                value={defaultLocalty[0]}
                customClass={customClass ?? styles.z1000}
                name="name"
                nameType="name"
                onChange={setLocality}
                placeholder="Seleccione una opción"
                title="Comuna"
                disabled={!showLocalities}
              />)
            }
          </>
        )
      }
    </>
  );
};

export default SelectRegion;
