/* eslint react/prop-types: 0 */
import React, { useEffect, useState, useContext } from 'react';
import { DataTable2, Skeleton, Select, Input, InfoIcon, Checkbox, Button, Label, LoadingImed, DataTabs, Tab, UserHelp, CalendarEmoji } from '@imed_npm/design-system';
// import dummy from './dummy/data.json';
import { formatRut, validFormat, clearRut } from '../../../../utils/rut';
import { DateTime } from 'luxon';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import Modal from '../../../Modal';
import { Professionals } from '../../interfaces/professional';
import { getPeriodicity, getProducts, getProfessionals, getStatuses, getAdminUF, getAdminUFrun, getAdminPaymentSubs, getAdminDownloadPaymentSubs, excludePerson, deletePersonExclusion, getSuccessfulVouchersPeriod, getVouchersDownload } from '../../utils/actions';
import { GetProfessionalsOptions, Product } from '../../utils/interfaces';
import { formatJsDate } from '../../utils/utils';
import { Periodicity, Status } from '../../interfaces/status';
import getEnv from '../.../../../../../getEnv';
import styles from '../../style.module.scss';
import ChargeModal from './ChargeModal';
import PendingCharges from './PendingCharges';
import Clients from './Clients';
import ChargesByPeriod from './ChargesByPeriod';
import ChargesByDate from './ChargesByDate';
import warningImg from '../../../../assets/warning.png';
import LoadingComponent from '../../../Form/Components/LoadingComponent/LoadingComponent';
const APP_ID = getEnv('REACT_APP_ID');

function AdminCharges() {
  const context = useContext(LoggedUserContext);
  const [data, setData] = useState<any[]>([]);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);

  const [dataTable, setDataTable] = useState<Professionals[]>();

  const [products, setProducts] = useState<Product[]>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [statusList, setStatusList] = useState<Status[]>();
  const [periodicityList, setPeriodicityList] = useState<Periodicity[]>();

  const [statusListChangeStatus, setStatusListChangeStatus] = useState<Status[]>();
  const [showFilters, setShowFilters] = useState(false);
  const [filterRut, setFilterRut] = useState<string>();

  const [showModal, setShowModal] = useState(false);

  const [modalData, setModalData] = useState(null);

  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [UFvalue, setUFValue] = useState(0);
  const [UFformated, setUfFormated] = useState<any>(0);

  const today = DateTime.local().toFormat('yyyy-MM-dd');

  const [totalClients, setTotalClients] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [validatePeriod, setValidatePeriod] = useState(false);
  const now = DateTime.local();
  const [inputPeriod, setInputPeriod] = useState<string>(now.toFormat('yyyy-MM'));

  const [exclusionReason, setExclusionReason] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxHeader, setCheckboxHeader] = useState(false);
  const [showExclusionModal, setShowExclusionModal] = useState(false);

  const [modalText, setModalText] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);

  const [excludedPersons, setExcludedPersons] = useState<any[]>([]);

  const activeInstitution = context.userData.activeInstitution?.id;
  const dataInstitution = context.userData.institutions.find(o => o.id === activeInstitution);
  let findAppInstitution, resources;
  if (dataInstitution) {
    findAppInstitution = dataInstitution.branchOffices[0].apps.find(o => o?.id === APP_ID);
    if (findAppInstitution) {
      resources = findAppInstitution.resources;
    }
  }

  const isResourceEnabled = (resourceName: string) => resources.some((resource) => resource.resource_name === resourceName && resource.resource_enabled);

  const calculateTotalClients = () => {
    return data.filter(item => item.adscription_status !== 'Excluido').length;
  };

  const calculateTotalAmount = () => {
    return data.reduce((acc, item) => {
      if (item.adscription_status !== 'Excluido') {
        return acc + item.price;
      }
      return acc;
    }, 0);
  };

  const getCurrentPeriod = () => {
    const today = DateTime.local();
    const month = today.month.toString().padStart(2, '0');
    const year = today.year;
    return `${year}-${month}`;
  };

  const handleCheckboxChange = (id: number) => {
    //setShowLoading(true);
    const dataIndex = data.findIndex(item => item.nid === id);
    //let reasonExclusion = 'exclusion interfaz';
    const period = getCurrentPeriod();
    if (dataIndex !== -1) {

      setExcludedPersons([...excludedPersons, data[dataIndex]]);

      if (data[dataIndex].adscription_status.toLowerCase() === 'excluido') {
        handleExcludeSubmit();
      } else {
        setShowExclusionModal(true);
      }

      /*if (data[dataIndex].adscription_status === 'Excluido') {
        // DIFF
        //setShowExclusionModal(true);
        //setModalData(data[dataIndex]);
        deletePersonExclusion(context, [{ person_id: parseInt(data[dataIndex].person_id, 10), person_type: data[dataIndex].person_type }], period, reasonExclusion).then((result) => {
          getData().finally(() => {
            setShowLoading(false);
          });
        }).catch((error) => {
          setShowLoading(false);
        });
      } else {
        reasonExclusion = exclusionReason;
        const period = getCurrentPeriod();
        if (reasonExclusion) {
          excludePerson(context, [{ person_id: parseInt(data[dataIndex].person_id, 10), person_type: data[dataIndex].person_type }], period, reasonExclusion)
            .then(() => {
              getData().finally(() => {
                setShowLoading(false);
              });
            })
            .catch((error) => {
              console.error('Error excluding person:', error);
            }).finally(() => {
              setShowLoading(false);
            });
        }
      }*/
    } else {
      //setShowLoading(false);
    }
  };

  const handleExcludeAll = () => {
    setExcludedPersons([...excludedPersons, data]);

    //setShowLoading(true);
    //const personsToExclude = data
    //  .map(item => ({ person_id: parseInt(item.person_id, 10), person_type: item.person_type }));
    //.filter(item => item.adscription_status !== 'Excluido')
    //setShowExclusionModal(true);
    //setModalData(personsToExclude);
    //todo
  };

  const handleCheckboxHeaderChange = () => {
    if (checkboxHeader) {
      setExcludedPersons([...excludedPersons, data]);
    } else {
      //setShowModal(false);
      setExcludedPersons([]);
    }
    //setSelectAll(!selectAll);
    setCheckboxHeader(!checkboxHeader);
  };

  const handleExcludeCancel = () => {
    setShowExclusionModal(false);
    //setSelectAll(false); // Deselect all if the exclusion is canceled
    setExclusionReason('');
  };

  const excludeAllPersons = () => {
    if (selectAll) {
      setShowModal(true);
    }
  };

  const handleExcludeSubmit = () => {
    //if (exclusionReason) {
    setShowExclusionModal(false);
    setShowLoading(true);
    //const personsToExclude = modalData;
    //if (exclusionReason) {
    console.log('excludedPersons', excludedPersons);
    const period = getCurrentPeriod();
    if (!excludedPersons.length) {
      setShowLoading(false);
      return;
    }

    if (excludedPersons[0].adscription_status === 'Excluido') {
      deletePersonExclusion(context, excludedPersons.map((person) => {
        return { person_id: parseInt(person.person_id, 10), person_type: person.person_type };
      }), period, exclusionReason)
        .then(() => {
          getData();
        })
        .catch((error) => {
          console.error('Error excluding persons:', error);
        }).finally(() => {
          setExcludedPersons([]);
          setShowLoading(false);
        });
    } else {
      excludePerson(context, excludedPersons.map((person) => {
        return { person_id: parseInt(person.person_id, 10), person_type: person.person_type };
      }), period, exclusionReason)
        .then(() => {
          getData();
        })
        .catch((error) => {
          console.error('Error excluding persons:', error);
        }).finally(() => {
          setExcludedPersons([]);
          setShowLoading(false);
        });
    }

    //}    
    /*} else {
      if (modalData) {
        setShowModal(false);
        const personToExclude = modalData;
        if (exclusionReason) {
          const period = getCurrentPeriod();
          excludePerson(context, [personToExclude], inputPeriod, exclusionReason)
            .then(() => {
              getData();
            })
            .catch((error) => {
              console.error('Error excluding person:', error);
            });
        }
      }
    }*/
    setExclusionReason('');
  };




  useEffect(() => {
    const timeout = setTimeout(async () => {
      const [initProducts, initStatusList, initStatusListChange, initPeriodicityList] = await Promise.all(
        [
          getProducts(context),
          getStatuses(context, 'adscriptions'),
          getStatuses(context, 'adscriptions', true),
          getPeriodicity(context)
        ]);
      setProducts(initProducts.filter((product) => product.name === 'EM'));
      setSelectedProduct(initProducts.filter((product) => product.name === 'EM')[0]);
      setStatusList(initStatusList);
      setStatusListChangeStatus(initStatusListChange);
      // const arrayInvertido = [...initPeriodicityList].reverse();
      setPeriodicityList([...initPeriodicityList].reverse());
      setShowLoading(false);
    }, 250);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!today) return;
    getAdminUF(context, today)
      .then((e) => setUFValue(e.uf))
      .catch((error) => {
        setModalText(`Error al obtener UF: ${error}`);
        const timeout = setTimeout(() => {
          setShowError(true);
        }, 1000);
        return () => clearTimeout(timeout);
      });

    const period = getCurrentPeriod();
    getSuccessfulVouchersPeriod(context, period)
      .then((response) => {
        if (response && response.length) {
          setValidatePeriod(false);
        } else {
          setValidatePeriod(true);
        }
      })
      .catch((error) => {
        console.error('Error al obtener vouchers:', error);
      });
  }, []);

  useEffect(() => {
    if (showError) {
      getAdminUFrun(context).then(() => { setShowError(false); window.location.reload();});
    }
  },[showError]);

  // useEffect(() => {
  //   if (!today) return;
  //   getAdminUF(context, today).then((e) => setUFValue(e.uf));
  //   const period = getCurrentPeriod();
  //   getSuccessfulVouchersPeriod(context, period).then((response) => {
  //     // console.log('response', response);
  //     //setValidatePeriod()
  //     if (response && response.length) {
  //       setValidatePeriod(false);
  //     } else {
  //       setValidatePeriod(true);
  //     }
  //   });
  // }, []);

  useEffect(() => {
    if (!UFvalue) return;
    // Formatear la UF
    const ufFormateada = UFvalue.toLocaleString('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 2,
    });
    setUfFormated(ufFormateada);
  }, [UFvalue]);

  useEffect(() => {
    const timeout = setTimeout(getData, 250);

    return () => clearTimeout(timeout);
  }, [filterRut]);

  useEffect(() => {
    if (!selectedProduct) return;

    setLoadingTable(true);
    getData();
    setFilterRut(undefined);
  }, [selectedProduct]);

  const download = () => {
    getAdminDownloadPaymentSubs(context, filterRut).then(response => downloadCsv(response));
  };

  const charge = () => {
    setShowModal(true);
  };

  const downloadCsv = (base64String) => {
    try {
      // Decode the base64 string
      const binaryString = atob(base64String);

      // Convert the binary string to a Uint8Array
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob from the bytes
      const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a blob URL and download the file
      const blobURL = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = blobURL;
      downloadLink.download = 'cobros-suscripcion.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobURL);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  };


  const getData = async () => {
    if (!selectedProduct) return;
    setShowLoading(true);

    //const result = await getAdminPaymentSubs(context, filterRut);
    //return result.list[0];
    //getProfessionals(context, data).then(setDataTable).finally(() => {setShowLoading(false); setLoadingTable(false);});
    getAdminPaymentSubs(context, filterRut).then((result) => {
      //console.log('result',result);
      setDataTable(result.list);
      setData(result.list);
    }).finally(() => {
      setShowLoading(false);
      setLoadingTable(false);
    });
  };

  useEffect(() => {
    if (modalData) {
      setShowModal(true);
    }
  }, [modalData]);

  useEffect(() => {
    if (!dataTable) return;
    setTotalClients(calculateTotalClients());
    setTotalAmount(calculateTotalAmount());
  }, [data]);

  useEffect(() => {
    if (!showLoading && !loadingTable) setShowFilters(true);
  }, [showLoading]);

  const triggerSearch = (select) => {
    setSelectedProduct(select);
  };

  const ActionCellRender = ({ row }: any) => {
    // console.log(row);
    const { original } = row;
    return (<input
      type="checkbox"
      checked={original.adscription_status === 'Excluido'}
      //checked={excluded.includes(original.nid)}
      onChange={() => handleCheckboxChange(original.nid)}
    />);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    const statusNoExcluido = data.filter(item => item.adscription_status !== 'Excluido');
    const statusExcluido = data.filter(item => item.adscription_status === 'Excluido');

    if (selectAll) {
      statusNoExcluido.map((item) => handleCheckboxChange(item.nid));
    } else {
      statusExcluido.map((item) => handleCheckboxChange(item.nid));
    }
  }, [selectAll]);

  const priceFormat = (price, value) => {
    const { original } = value;
    return (price).toFixed(1);
  };

  const columns = [
    {
      Cell: ({ value }: any) => {
        return <div className='has-tooltip w-3/4 m-auto cursor-help'>
          <span className='tooltip rounded shadow-lg p-1 bg-black text-white -mt-8'>{value && value.length ? 'Cupón aplicado' : 'Sin descuento'}</span>
          <div className={(value && value.length) ? styles.bgSuccess : styles.bgWarning}></div>
        </div>;
      },
      Header: 'Dscto',
      accessor: 'discount_id',
      filter: 'exact',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || 'Sin rut',
      Header: 'RUT Cliente',
      HeaderWidth: 'w-[31%]',
      accessor: 'nid',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Código de lugar',
      HeaderWidth: 'w-[31%]',
      accessor: 'center_code',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || 'Sin estado',
      Header: 'Estado',
      HeaderWidth: 'w-[31%]',
      accessor: 'adscription_status',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || 'Sin plan',
      Header: 'Plan',
      HeaderWidth: 'w-[31%]',
      accessor: 'plan',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || 'Sin lector',
      Header: 'Lectores',
      HeaderWidth: 'w-[31%]',
      accessor: 'readers',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value, row }: any) => priceFormat(value, row) ?? '-',
      Header: 'Monto UF sin (s/iva)',
      HeaderWidth: 'w-[31%]',
      accessor: 'price',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value ? formatJsDate(value) : '-',
      Header: 'Fecha cierre OT',
      HeaderWidth: 'w-[31%]',
      accessor: 'work_order_last_status_date',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ActionCellRender,
      id: 'actions',
      Header: (
        <>
          Excluir

        </>
      ),
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
  ];
  /*
            <Checkbox
              checked={checkboxHeader}
              onChange={handleCheckboxHeaderChange}
            />
  */
  const renderCheckingInfo = () => {
    return (
      <>
        {(showLoading) && (<LoadingImed text="Cargando información" />)}
      </>
    );
  };

  useEffect(() => {
    if (!context) return;
    if (!dataTable) return;
    const fetchData = async () => {
      const newData: any[] = await Promise.all(dataTable.map(async (item) => {
        const result = await getAdminPaymentSubs(context, item.nid);
        return result.list[0];
      }));

      setData(newData);
    };

    //fetchData().then(() => { setShowLoading(false); });
    setShowLoading(false);
  }, [dataTable]);

  useEffect(() => {
    if (!products) return;
    // @ts-ignore
    const getEM = products.filter(item => item.id === '2');
    setSelectedProduct(getEM[0]);

  }, [products]);

  return (
    <>
      <h1 className="text-secondary-imed-60 text-title tracking-[0.1px] font-normal my-4 mt-0">Administrador de Cobros</h1>
      <p className={`${styles.subtitle} pb-5`}>Seleccione una pestaña y administre la opción seleccionada</p>
      <hr />
      <DataTabs
        childrenCustomClass="bg-neutral-white"
        colorStyle="light-blue"
        customClass="border-child"
        mode="tab"
        step={1}
      >
        {isResourceEnabled('cobro-mensual') && <div title="Administrador de Cobros">
          <div className="bg-white px-6 text-center">

            {renderCheckingInfo()}
            <div className='w-full mx-auto'>
              {
                // JSON.stringify(loadingTable)
              }
              {
                // JSON.stringify(showFilters)
                // <h1 className="text-secondary-imed-60 text-title tracking-[0.1px] font-normal my-4 mt-0">Administrador de Cobros</h1>

              }
              <div className="bg-white pb-6">
                <div className="mt-5">
                  <div className='flex justify-end items-center'>
                    {/* <Select
                      customClass={`${styles.z0} mb-4`}
                      customSize="l"
                      items={products}
                      name="selectName"
                      nameType="description"
                      defaultValue={selectedProduct?.description}
                      value={selectedProduct?.id}
                      onChange={(e) => triggerSearch(e)}
                      placeholder="Seleccione una opción"
                      title="Producto"
                      disabled
                    /> */}
                  </div>

                  {showModal &&
                    <Modal title={'Cobrar en Mercadopago: período actual'} showModal={showModal} setShowModal={setShowModal} showCloseButton={false}>
                      {data && <ChargeModal setShowModal={
                        (status) => {
                          setShowLoading(true);
                          setShowModal(status);
                          setValidatePeriod(false);
                          const period = getCurrentPeriod();
                          getSuccessfulVouchersPeriod(context, period).then((response) => {
                            if (response && response.length) {
                              setValidatePeriod(false);
                            } else {
                              setValidatePeriod(true);
                            }
                          }).catch((error) => {
                            console.log('error', error);
                            setValidatePeriod(false);
                            setShowLoading(false);
                          })
                            .finally(() => {
                              setShowLoading(false);
                            });
                        }
                      } data={data} uf={UFvalue} periodList={periodicityList} product={selectedProduct} />}
                    </Modal>}

                  {
                    !loadingTable && !validatePeriod ? <UserHelp
                      colorStyle="warning"
                      icon={<CalendarEmoji svgClass="h-10 w-10" />}
                      title="Ya se han generado cobros para el periodo"
                    /> : ''
                  }
                  {
                    //(data && !loadingTable) ? (-
                    (!loadingTable && validatePeriod) ? (
                      <div>
                        {
                          (showFilters) &&
                          <div className='w-full flex justify-between'>
                            {
                              validatePeriod &&
                              <div className='flex flex-col items-start'>
                                <Input
                                  focused
                                  name="name"
                                  value={filterRut}
                                  onChange={({ target: { value } }) => { setFilterRut(clearRut(value)); }}
                                  placeholder="Ingresa un rut"
                                  title="Buscar por rut"
                                  customSize="l"
                                  type="text"
                                />
                              </div>
                            }

                            <div className='flex items-end'>
                              <Button type='secondary-two' buttonType='button' onClick={download}>Descargar en Excel</Button>
                            </div>
                          </div>
                        }
                        <DataTable2
                          columns={columns}
                          data={data}
                          disableHeader
                          orderBy="work_order_last_status_date"
                          perPage={10}
                          totalResults={data && data.length ? data.length : 0}
                          sortBy="desc"
                          colorStyle="neutral"
                        />
                        <div className={`${styles.bgInfo} p-4`}>
                          <div className='mt-5 flex flex-col justify-start items-start'>
                            <Label
                              customClass="text-base"
                              htmlFor="base-input"
                              title={`Valor UF: ${UFformated ?? '0'}`}
                            />
                            <Label
                              customClass="text-base"
                              htmlFor="base-input"
                              title={`Cantidad de Clientes a cobrar: ${totalClients ?? '0'}`}
                            />
                            <hr className="w-full my-4" />
                            <div className='w-full flex justify-between items-center'>
                              <p className={styles.texts}>
                                Total UF a cobrar:&nbsp;&nbsp;&nbsp;<small>$</small><span>{totalAmount.toFixed(1) ?? '0'}</span>
                              </p>
                              {
                                showFilters && validatePeriod &&
                                <div className='ml-2'>
                                  <Button type='primary' buttonType='button' onClick={charge} disabled={!UFformated}><div className='mx-10 text-base'>Cobrar</div></Button>
                                </div>
                              }
                            </div>
                          </div>
                        </div>

                      </div>
                    ) : (
                      validatePeriod &&
                      <div className='w-full border flex flex-col p-20 justify-center items-center'>
                        <InfoIcon size="m" />
                        <p className='mt-2'>Todavía no selecciona un producto para administrar</p>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>}

        {
          isResourceEnabled('cobro-pendiente') && <div title="Cobros Pendientes">
            <PendingCharges />
          </div>
        }
        {
          isResourceEnabled('cobro-cliente') && <div title="Cobros por cliente" className='px-6'>
            <Clients />
          </div>
        }
        <div title="Reporte por periodo">
          <ChargesByPeriod />
        </div>
        <div title="Reporte por fecha">
          <ChargesByDate />
        </div>
      </DataTabs>
      <Modal
        showModal={showExclusionModal}
        functionCloseButton={() => setShowExclusionModal(false)}
      >
        <div className={'p-2'}>
          <h2>Exclusión de Cliente</h2>
          <div className={'py-2'}>
            <Label htmlFor="reason">Motivo de Exclusión</Label>
            <Input
              id="reason"
              type="text"
              size={90}
              placeholder="Motivo de Exclusión"
              onChange={(e) => setExclusionReason(e.target.value)}
              value={exclusionReason}
            />
          </div>
          <div className="mt-4">
            <div className="pt-4 ml-auto flex justify-between items-center">
              <Button
                onClick={() => handleExcludeCancel()} type='secondary-two' buttonType='button'>
                Cancelar
              </Button>
              <Button
                onClick={() => handleExcludeSubmit()} type='secondary' buttonType='button' disabled={!exclusionReason}>
                Guardar Cambios
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Modal UF error */}
      <Modal title="Error al cargar UF" showModal={showError} showCloseButton={false} id="modal-error">
        <div className="flex flex-col items-center justify-center">
          <img src={warningImg} className={styles.img} alt="imagen de error" />
          {/* <h1 className='subtitle text-center pt-2'>Error al cargar UF</h1> */}
          <p>Permanece en esta página mientras se obtiene el valor de la UF</p>
          <LoadingComponent/>
          </div>
      </Modal>


    </>);
}


export default AdminCharges;