import { useEffect, useState } from 'react';
import { Input, Label, Button, CheckCircleIcon, InfoIcon, TooltipGlobal, LoadingImed } from '@imed_npm/design-system';
import { DateTime } from 'luxon';
import styles from './style.module.scss';
import AttachCI from './Components/AttachCI';
import Modal from '../../../Modal';
import PreviewAttach from './Components/PreviewAttach';
import CustomerDebts from './Components/CustomerDebts';
import { checkDebt, getCheck, getInfo, getProfessionalProductStatus } from '../../Store/actions';
import { UseStep } from '../../Store/step-context';
import { useNavigate } from 'react-router-dom';
import { formatRut, validFormat } from '../../../../utils/rut';
import IncompatibleDegree from './Components/IncompatibleDegree';
import getEnv from '../../../../getEnv';
import image from '../../../../../src/assets/serial_help.jpg';
import icon from '../../../../../src/assets/plan-standar.svg';
import alerta from '../../../../../src/assets/alerta.svg';
const allowedTitles = (getEnv('REACT_APP_ALLOWED_MEDICAL_TITLES') || '').replace(new RegExp('_', 'g'), ' ').split(',');

interface OwnProps {
  type: string;
}
const ProfessionalInfo = ({ type }: OwnProps) => {
  const { state: { info, form: { suscripcionStatus }, steps: { error, step2, step2: { validations, validations: { existing: { warning } }, professionalHasDebt, debtType }, }, }, dispatch } = UseStep();
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [nid, setNid] = useState(step2.nid);
  const [validNid, setValidNid] = useState<boolean>(false);
  const [serial, setSerial] = useState(step2.serial);
  const [showCustomerDebtsModal, setCustomerDebtsShowModal] = useState(false);
  const [showPreviewAttach, setShowPreviewAttach] = useState<boolean>(false);
  const [existentNid, setExistentNid] = useState<boolean>(true);
  const [nidStatus, setNidStatus] = useState({ type: 'default' as 'error' | 'success' | 'info' | 'warning' | 'default', message: '' });
  const [showIncompatibleDegreeModal, setShowIncompatibleDegreeModal] = useState(false);
  const [contractDate, setContractDate] = useState<any>();
  const [status, setStatus] = useState<any>();

  const [productFind, setProductFind] = useState<any>();
  const [productStatus, setProductStatus] = useState<any>();
  const [products, setProducts] = useState<any>();
  const [validInput, setValidInput] = useState<any>();

  const navigate = useNavigate();

  const handleToggleStatusModal = (showModal: boolean) => {
    setShowModal(showModal);
  };

  const handleToggleStatusCustomerDebtsModal = () => navigate('/');

  useEffect(() => {
    if (validations.serial.success && !validations.info.success) {
      // const nidTemp = '18393591-7';˚
      // Replace nidTemp for nid
      getInfo(dispatch, nid, 'natural', type);
    }
  }, [validations.serial]);

  function compareConditions(e) {
    const statusAllowed = new Set([1, 2, 3, 4, 5, 7, 10, 11, 20]);
    return e.plans?.products?.name === type && statusAllowed.has(e.status);
  }

  function compareStatus(e) {
    const statusAllowed = new Set([1, 2, 3, 4, 5, 6, 7, 10, 11, 20]);
    return statusAllowed.has(e.status);
  }

  useEffect(() => {
    if (!suscripcionStatus || suscripcionStatus.length < 1) return;

    const firstElementConditions = suscripcionStatus.find(compareConditions);
    const firstElementStatus = suscripcionStatus.find(compareStatus);

    setProductFind(firstElementConditions);
    setProductStatus(firstElementStatus);

  }, [suscripcionStatus]);

  useEffect(() => {
    if (!suscripcionStatus || suscripcionStatus.length < 1) return;

    if (warning) {
      const primerElementoCumplidor = suscripcionStatus.find(compareStatus);
      if (primerElementoCumplidor) {
        setShowAlert2(true);
      }
    }
  }, [suscripcionStatus]);


  useEffect(() => {
    if (productFind && productFind.contract_date) {
      const { contract_date } = productFind;
      const date = DateTime.fromISO(contract_date);
      const formatedDate = date.setLocale('es').toFormat('dd \'de\' LLLL \'de\' yyyy');
      setContractDate(formatedDate);
    }
  }, [productFind]);

  useEffect(() => {
    if (productStatus) {
      const { status } = productStatus;
      let message = '';
      switch (status) {
        case 0:
          message = 'abortado';
          break;
        case 1:
          message = 'activo';
          break;
        case 2:
          message = 'pendiente de suscripción';
          break;
        case 3:
          message = 'habilitado';
          break;
        case 4:
          message = 'suscrito';
          break;
        case 5:
          message = 'pendiente de habilitación';
          break;
        case 6:
          message = 'suspendido';
          break;
        case 7:
          message = 'pendiente de habilitación';
          break;
        case 8:
          message = 'pendiente de contratar';
          break;
        case 9:
          message = 'pendiente de firmar';
          break;
        case 10:
          message = 'en instalación';
          break;
        case 11:
          message = 'habilitado';
          break;
        case 12:
          message = 'pendiente de anulación';
          break;
        case 13:
          message = 'pendiente de anulación';
          break;
        case 14:
          message = 'OT devuelta a inicio';
          break;
        case 20:
          message = 'en espera de habilitación';
          break;
        default:
          break;
      }
      if (message !== '') setStatus(message);
    }

  }, [productStatus]);

  useEffect(() => {
    if (productFind) {
      setShowAlert(true);
    }

  }, [productFind]);

  useEffect(() => {
    if (!suscripcionStatus || suscripcionStatus.length < 1) return;
    const planesContratados = suscripcionStatus.filter(function (elemento) {
      return elemento.plans !== null && elemento.plans !== undefined && elemento.plans.products !== null && elemento.plans.products !== undefined;
    });

    const nombresPlanesContratados = planesContratados.map(function (elemento) {
      return elemento.plans.products.description;
    });
    setProducts(nombresPlanesContratados);
  }, [suscripcionStatus]);

  // useEffect(() => {
  //   setExistentNid(!step2.validations.existing.success);
  // }, [step2.validations.existing]);

  useEffect(() => {
    if (nid) {
      const { validations: { existing: { pending, warning, error, success } } } = step2;
      const validNid = validFormat(nid);
      if (!validNid) setNidStatus({ type: 'error', message: 'RUT inválido' });
      if (validNid) {
        if (success) setNidStatus({ type: 'success', message: 'Rut Cliente Validado' }), setExistentNid(false);
        else if (pending) setNidStatus({ type: 'info', message: 'Buscando si usuario esta suscrito' });
        else if (warning) {
          setNidStatus({ type: 'success', message: 'Rut Cliente Validado' });
          setValidInput(true);
        }
        else if (error) {
          setNidStatus({ type: 'error', message: 'Rut ya tiene este producto contratado' });
          setShowAlert(true);
        }
      }
    }

  }, [step2.validations.existing]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (nid.length >= 9 && validFormat(nid)) {
        getProfessionalProductStatus({ dispatch, payload: { nid, product: type }, step: 'step2' }).then(setContractDate);
      }
    }, 750);
    return () => clearTimeout(timeout);
  }, [nid]);

  useEffect(() => {
    const { serial, info, debt } = validations;
    if (serial.success && info.success && !debt.success)
      checkDebt(dispatch, { nid, callback: setCustomerDebtsShowModal });
  }, [validations.info.success]);

  useEffect(() => {
    setShowPreviewAttach(!!step2.backCode && !!step2.frontCode);
  }, [step2.backCode, step2.frontCode]);

  useEffect(() => {
    dispatch({ type: 'RESET_STEP', payload: { step: 'step3' } });
  }, []);


  useEffect(() => {
    if (validations.info.success) {
      const filteredArray = info.antecedentes.filter((antecedente) => {
        return antecedente.clase_antecedente === 'Título' && allowedTitles.includes(antecedente.cod_antecedente.toLowerCase());
      });
      if (filteredArray.length === 0) {
        setShowIncompatibleDegreeModal(true);
      }
    }
  }, [validations, info]);

  const renderInfoMedical = () => {
    if (!validations.info.success) return (<></>);

    return (
      <div className={'w-full  mb-12 flex flex-col'}>
        <Label
          customClass=""
          htmlFor="base-input"
          title="Datos verificados"
        />
        <div className={`flex flex-row shadow-md p-3 md:p-6 ${styles.info}`}>
          <div className={'flex flex-row mx-2'}>
            {/* <CheckCircleIcon svgClass={styles.icon_color} /> */}
            <img src={icon} />
            <span className={'ml-1'}>{info.nombres}</span>
          </div>
          <div className={'flex flex-row mx-2'}>
            <img src={icon} />
            <span className={'ml-1'}>{`${info.apellido_paterno} ${info.apellido_materno}`}</span>
          </div>
          <div className={'flex flex-row mx-2'}>
            <img src={icon} />
            <span className={'ml-1'}>{info.codigo_busqueda}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderCheckingInfo = () => {
    const { serial, info, debt } = validations;
    if (serial.pending || info.pending || debt.pending)
      return (
        <>
          {(serial.pending) && (<LoadingImed text="Validando documento" />)}
          {(serial.success && info.pending) && (<LoadingImed text="Verificando en Super de Salud" />)}
          {info.success && debt.pending && (<LoadingImed text="Buscando deudas en Imed" />)}
        </>
      );

    return;
  };

  const resetValidations = () => {
    dispatch({ type: 'RESET_STEP', payload: { step: 'step2' } });
    dispatch({ type: 'GET_INFO_VALIDATION', payload: { key: '' } });
    dispatch({ type: 'CHECK_SERIAL_VALIDATION', payload: { key: '' } });
    dispatch({ type: 'CHECK_DEBT_VALIDATION', payload: { key: '' } });
  };

  const setNidValue = ({ target: { value = '' } }) => {
    resetValidations();
    const rut = formatRut(value);

    setNid(rut);
  };
  const setSerialValue = ({ target: { value } }) => {
    resetValidations();
    setSerial(value);
  };

  const handleRemoveBase64 = async (event) => {
    dispatch({ type: 'REMOVE_FILE', payload: { key: event.target.dataset.name, stepKey: 'step2' } });
  };

  /*const nidStateMessage = (): { type: string; mesage: string; } => {
    if (nid) {
      if (validations.info.error) return { type: 'error', mesage: 'RUT no se encuentra registrado' };
      else if (validNid) return { type: 'success', mesage: 'RUT Válido' };
      else if (!validNid) return { type: 'error', mesage: 'RUT inválido' };
    }
    return { type: 'default', mesage: '' };
  };*/

  useEffect(() => {
    setValidNid(validFormat(nid));
    const timeout = setTimeout(() => {
      if (nid.length >= 9 && serial.length >= 8 && validNid) {
        getCheck(dispatch, { nid, serial });
      }
    }, 750);
    return () => clearTimeout(timeout);
  }, [nid, serial, validNid]);

  return (
    <>
      <div className={`${styles.professional_info_container} md:w-3/4`}>
        <div className={styles.professional_info_container_rut}>
          <div className={'w-full'}>
            <Input
              value={nid}
              name="nid"
              onChange={setNidValue}
              placeholder="Ingresa rut"
              title="Rut cliente"
              type="text"
              stateMessage={nidStatus.message}
              stateType={nidStatus.type}
              disabled={(validations.serial.pending || validations.debt.pending || validations.info.pending) || validations.existing.pending ||
                (validations.serial.success && validations.info.success && validations.existing.success)}
            />
          </div>
          <div className={'w-full'}>
            <Input
              value={serial}
              name="serial"
              onChange={setSerialValue}
              placeholder="Ingresa n° documento"
              title="Nº de documento cliente"
              type="text"
              stateMessage={validations.serial.error ? 'N° Documento inválido' : (validations.serial.pending ? 'Validando Documento' : (validations.serial.success ? 'N° Documento Válido ' : ''))}
              stateType={validations.serial.error ? 'error' : (validations.serial.success ? 'success' : 'default')}
              disabled={(existentNid && !validInput) || (validations.serial.pending || validations.debt.pending || validations.info.pending) ||
                validations.serial.success && validations.info.success || nidStatus.type === 'error'}
            />
          </div>
          <div className={`self-center ${nidStatus.message ? '' : 'mt-6'}`}>
            <TooltipGlobal customClass={`${styles.tooltip_serial} flex items-center`}
              content={<p><img src={image} /></p>}
              delay={0}
              direction="top"
              visible
            >
              <InfoIcon svgClass={styles.icon_color} />
            </TooltipGlobal>
          </div>
        </div>
        {renderCheckingInfo()}
        {renderInfoMedical()}
        {validations.debt.success &&
          validations.info.success &&
          validations.serial.success &&
          !step2.professionalHasDebt &&
          !showPreviewAttach &&
          <div className={styles.attach_ci}>
            <p>
              Adjunte las imágenes del <span>RUT por ambos lados:</span>
            </p>
            <Button buttonType='button' type="secondary" onClick={() => setShowModal(true)}>
              Adjuntar CI
            </Button>
          </div>}
        {validations.debt.success &&
          validations.info.success &&
          validations.serial.success &&
          !step2.professionalHasDebt &&
          showPreviewAttach &&
          <PreviewAttach backCode={step2.backCode} frontCode={step2.frontCode} handleRemoveBase64={handleRemoveBase64} />
        }
      </div>
      <Modal title={'Título no compatible'} showModal={showIncompatibleDegreeModal} showCloseButton={false}>
        <IncompatibleDegree />
      </Modal>
      <Modal title={'Adjuntar CI'} showModal={showModal} onToggleStatus={handleToggleStatusModal}>
        <AttachCI stepKey={'step2'} setShowModal={setShowModal} />
      </Modal>
      <Modal title={''} showModal={showCustomerDebtsModal} onToggleStatus={handleToggleStatusCustomerDebtsModal} showCloseButton={false}>
        <CustomerDebts debtType={debtType} onToggleStatus={handleToggleStatusCustomerDebtsModal} />
      </Modal>
      <Modal title={''} showModal={showAlert} showCloseButton={false} containerClass={styles.modalWidth}>
        <div className="flex flex-col text-center items-center justify-center py-6 px-10">
          <img className={styles.img} src={alerta} />
          <p className={`${styles.warningTitle} mt-5`}>
            Usted ya contrató este producto, {contractDate && ` el ${contractDate}, `}
            <span className={styles.highlight}>y está {status ?? 'aún vigente'}</span>.
          </p>
          <p className={`${styles.warning} mt-5`}>
            No es necesario contratar nuevamente
          </p>
          <p className={`${styles.warning} my-5`}>
            Para consultas acerca de su Suscripción,
            comuníquese a <a href="comercial@imed.cl" target='_blank'><strong>comercial@imed.cl</strong></a>
          </p>
          <Button buttonType='button' type="secondary" onClick={() => { setShowAlert(false); window.location.reload(); }}>
            Ok, entendido
          </Button>
        </div>
      </Modal>
      <Modal title={''} showModal={showAlert2} showCloseButton={false} containerClass={styles.modalWidth}>
        <div className="flex flex-col text-center items-center justify-center py-6 px-10">
          <img className={styles.img} src={alerta} />
          <p className={`${styles.warningTitle} mt-5`}>
            Usted contrató previamente un producto Imed {products && `: ${products}`}, {contractDate && ` el ${contractDate}, `}
            <span className={styles.highlight}>y está {status ?? 'aún vigente'}</span>.
          </p>
          {
            (productStatus && productStatus.status === 6) ? <p className={`${styles.warning} mt-5`}>Para continuar con el proceso de contratación, le invitamos a regularizar su situación.</p> : <p className={`${styles.warning} mt-5`}>
              Si desea continuar con el proceso de suscripción, presione el botón a continuación.
            </p>
          }
          <p className={`${styles.warning} my-5`}>
            Para consultas acerca de su Suscripción,
            comuníquese a <a href="comercial@imed.cl" target='_blank'><strong>comercial@imed.cl</strong></a>
          </p>
          {
            (productStatus && productStatus.status === 6) ? <Button buttonType='button' type="secondary" onClick={() => { setShowAlert2(false); window.location.reload(); }}>
              Ok, entendido
            </Button> : <Button buttonType='button' type="secondary" onClick={() => setShowAlert2(false)}>
              Ok, deseo contratar de todas formas
            </Button>
          }
        </div>
      </Modal>
    </>
  );
};

export default ProfessionalInfo;
