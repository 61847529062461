import { useContext, useState } from 'react';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import { Professionals } from '../../interfaces/professional';
import ProspectItem from './Components/prospectItem';
import styles from './styles.module.scss';
import { Button } from '@imed_npm/design-system';
import { updateStatus } from '../../utils/actions';
import { EProfessionalStatus } from '../../../../enum/professional.enum';

interface OwnProps {
  professional: Professionals;
  setShowModal: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const SendProspect = ({ professional: _professional, setShowModal }: OwnProps) => {
  const context = useContext(LoggedUserContext);
  const [professional, setProfessional] = useState<Professionals>(_professional);
  const [prospect, setProspect] = useState<boolean>(false);
  const [enabling, setEnabling] = useState<boolean>();

  const updateProspect = (status: boolean) => {
    setProspect(status);
    changeStatus();
  };

  const closeModal = (notShow: boolean) => {
    return setShowModal(notShow);
  };

  const changeStatus = () => {
    setEnabling(true);

    updateStatus(context, { adscriptionId: Number(professional.adscription_id), status: EProfessionalStatus.PROSPECT_CREATE }).then(result => {
      const { status } = result;
      setProfessional({
        ...professional,
        adscription_status: status
      });
    }).catch(err => {
      console.error({ err });
    }).then(() => {
      setEnabling(false);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.professional_info}>
        <p>
          <span>Prestador:</span> {professional.nid}
        </p>
        <p>
          <span>Código de lugar:</span> {professional.centers[0].code}
        </p>
      </div>
      <div className={styles.prospect}>
        <ProspectItem
          professional={professional}
          context={context}
          prospect={prospect}
          updateProspect={updateProspect}
        />
      </div>
      {prospect &&
        <div className={styles.footer}>
          {professional.adscription_status === EProfessionalStatus.SUSCRITO && <Button
            buttonType='button'
            key={'btn-change-status'}
            type='secondary'
            loading={enabling}
            disabled={enabling}
            onClick={changeStatus}>
            Cambiar estado
          </Button>}
          {professional.adscription_status === EProfessionalStatus.PROSPECT_CREATE && <Button
            IconLeft="CheckCircleIcon"
            buttonType='button'
            type='primary'
            onClick={() => closeModal(false)}>
            Prospecto creado
          </Button>}
        </div>}
    </div>
  );
};

export default SendProspect;
