import React, { useState, useEffect, useContext } from 'react';
import { TextArea, Select, Button } from '@imed_npm/design-system';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import { Professionals } from '../../interfaces/professional';
import { GetPlansOptions, Product } from '../../utils/interfaces';
import { getVoucherClientNid, changeAdscriptionPlan } from '../../utils/actions';
import ToastMessage from '../../../../utils/ToastMessage';
import LoadingComponent from '../../../Form/Components/LoadingComponent/LoadingComponent';
import getEnv from '../../../../getEnv';
import styles from './style.module.scss';

interface Props {
  options: any;
  professional: Professionals;
  setShowModal?: any;
  selectedProduct: any;
  setRefresh?: any;
}

const APP_ID = getEnv('REACT_APP_ID');

const ChangePlan: React.FC<Props> = ({ options, professional, setShowModal, selectedProduct: product, setRefresh }: Props) => {
  const context = useContext(LoggedUserContext);
  const [modalContent, setModalContent] = useState<any>(undefined);

  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();
  const [defaultOpt, setDefault] = useState<Product | undefined>();
  const [description, setDescription] = useState<string>('');

  const activeInstitution = context.userData.activeInstitution?.id;

  const dataInstitution = context.userData.institutions.find(o => o.id === activeInstitution);

  let findAppInstitution, resources;
  if (dataInstitution) {
    findAppInstitution = dataInstitution.branchOffices[0].apps.find(o => o?.id === APP_ID);
    if (findAppInstitution) {
      resources = findAppInstitution.resources;
    }
  }

  useEffect(() => {
    if (!professional) return;
    searchRut();
  }, [professional]);

  const searchRut = () => {
    const { nid } = professional;
    // @ts-ignore
    getVoucherClientNid(context, nid).then((response) => setModalContent(response[0]));
  };

  const savePlan = () => {
    const data = {
      'adscription_id': +modalContent.id,
      //@ts-ignore
      'plan_id': +selectedProduct.id,
      'comment': description
    };
    changeAdscriptionPlan(context, data).then(() => ToastMessage.enviarToastSuccess(`Plan de cliente ${modalContent.client.nid} modificado correctamente`)).finally(() => { setShowModal(false); setRefresh(true); });
  };

  const selectOption = (select) => {
    setSelectedProduct(select);
  };

  useEffect(() => {
    if (!modalContent) return;
    const filter = options.find((option) => option.id === modalContent.plan_id);
    if (filter) {
      setDefault(options.find((option) => option.id === modalContent.plan_id));
    } else {
      setDefault(options[0]);
    }
  }, [modalContent]);

  return (
    <>
      {
        (modalContent && defaultOpt) ? <div>

          <div className={styles.professional_info}>
            <p className='mb-4'>
              <span>Prestador:</span> {modalContent.client.nid}
            </p>
            <p className='mb-4'>
              <span>Producto:</span> {product.description}
            </p>
            <p className='mb-4'>
              <span>Plan actual:</span> {modalContent.name} {defaultOpt && <>(código: {defaultOpt?.code})</>}
            </p>
          </div>

          <Select
            customClass={`${styles.z1000} mb-4`}
            customSize="l"
            items={options.filter(option => option.commercialized === 1)}
            name="selectName"
            nameType="name"
            onChange={(e) => selectOption(e)}
            value={defaultOpt}
            placeholder="Seleccione una opción"
            title="Plan"
          />
          <div className='pb-3 max-w-sm mt-4'>
            <TextArea
              maxLength={255}
              name="comentario"
              onChange={(e) => { setDescription(e.target.value); }}
              value={description}
              placeholder="Escribe un comentario"
              title="Comentario"
            />
          </div>
          <Button type='primary' buttonType='button' onClick={savePlan} disabled={!description}>Guardar</Button>
        </div> : <LoadingComponent />
      }
    </>

  );
};

export default ChangePlan;
