import React, { useContext, useEffect, useState } from 'react';
import LoggedUserContext from '../../../../../../context/LoggedUserContext';
import { Professionals } from '../../../../interfaces/professional';
import styles from './styles.module.scss';
import ToastMessage from '../../../../../../utils/ToastMessage';
import LoadingComponent from '../../../../../Form/Components/LoadingComponent/LoadingComponent';
import ProgressBar from '@ramonak/react-progress-bar';
import { Button, InfoIcon, Skeleton, DataTable2, Select, Input } from '@imed_npm/design-system';
import * as Bluebird from 'bluebird';
import { DateTime } from 'luxon';
import { chargePayment, chargePendingPayment, getPendingVouchersDownload, getVouchersDownload, getVouchersResult } from '../../../../utils/actions';
import image from '../../../../../../assets/info.png';
import { GroupedPeriodItem, VoucherRecord } from '../../../../utils/interfaces';

interface OwnProps {
  setShowModal: (value: boolean) => void;
  data: GroupedPeriodItem[];
  uf: number;
  product: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PendingChargesModal = ({ setShowModal, data, uf, product }: OwnProps) => {
  const context = useContext(LoggedUserContext);
  const [result, setResult] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState('');
  const [filteredData, setFilteredData] = useState<VoucherRecord[]>([]);
  const [completed, setCompleted] = useState(0);
  const [inprocess, setInprocess] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [processed, setProcessed] = useState(0); // Counter for processed items
  const [paid, setPaid] = useState(0);
  const [unpaid, setUnpaid] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [unpaidAmount, setUnpaidAmount] = useState(0);
  const [selectPeriod, setSelectPeriod] = useState<string>();
  const today = DateTime.local().toFormat('yyyy-MM-dd');
  const now = DateTime.local();
  const [inputPeriod, setInputPeriod] = useState<string>(now.toFormat('yyyy-MM'));
  const [paidVouchers, setPaidVouchers] = useState<VoucherRecord[]>([]);
  const [unpaidVouchers, setUnpaidVouchers] = useState<VoucherRecord[]>([]);
  const [batchId, setBatchId] = useState<string>('');

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (data) {
        setFilteredData(data.map((item) => item.records).flat());
      }
    }, 250);
    return () => clearTimeout(timeout);

  }, []);

  const startProcess = async () => {
    setInprocess(true);
    let processedCount = 0; // Counter for processed items
    const batch_id = DateTime.local().toFormat('yyyyMMdd_HHmm00');
    setBatchId(batch_id);
    try {
      console.log('filteredData',filteredData);
      await Bluebird.map(filteredData, async (voucher:VoucherRecord, index) => {
        console.log('voucher', voucher);
        try {
          const parameters = {
            voucher_id: voucher.voucherId,
            batch_id: batch_id,
          };
          //console.log('parameters',parameters);
          const result = await chargePendingPayment(context, parameters);
          //console.log('result',result);
          if (result && result.paid) {
            setPaid(prevPaid => prevPaid + 1);
            setPaidAmount(prevPaidAmount => prevPaidAmount + (voucher.total));
            setPaidVouchers(prevPaidVouchers => [...prevPaidVouchers, voucher]);
          } else {
            setUnpaid(prevUnpaid => prevUnpaid + 1); // Increase the unpaid counter
            setUnpaidAmount(prevUnpaidAmount => prevUnpaidAmount + (voucher.total));
            setUnpaidVouchers(prevUnpaidVouchers => [...prevUnpaidVouchers, voucher]);
          }
        } catch (error) {
          // Handle individual API call error here
          console.error(`Error processing professional ${index}:`, error);
          setUnpaid(prevUnpaid => prevUnpaid + 1); // Increase the unpaid counter
          setUnpaidAmount(prevUnpaidAmount => prevUnpaidAmount + (voucher.total));
          setUnpaidVouchers(prevUnpaidVouchers => [...prevUnpaidVouchers, voucher]);
        } finally {
          // Always run progress update, even if an error occurred
          processedCount += 1;
          setProcessed(processedCount);
          setCompleted((processedCount / filteredData.length) * 100);
        }
      }, { concurrency: 1 });
    } catch (error) {
      // Handle any other errors that occurred during the map operation
      console.error('Error in map operation:', error);
    } finally {
      setInprocess(false);
      setShowResults(true);
    }
  };

  const formatNumber = (value: number): string => {
    const parts = value.toFixed(0).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  const downloadCsv = (base64String) => {
    try {
      // Decode the base64 string
      const binaryString = atob(base64String);

      // Convert the binary string to a Uint8Array
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob from the bytes
      const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a blob URL and download the file
      const blobURL = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = blobURL;
      downloadLink.download = 'cobros-suscripcion.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobURL);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  };

  const download = () => {
    const voucherIds = filteredData.map((item) => item.voucherId);
    getPendingVouchersDownload(context, voucherIds).then(response => downloadCsv(response));
  };

  const transforResult = () => {
    const datosFormateados:{ voucherId: number,estado:string, count:number,totalUF:number,totalCLP:number, period:string  }[] = [];
    for(const voucher of unpaidVouchers){
      datosFormateados.push({
        voucherId: voucher.voucherId,
        period: voucher.period,
        estado:'No Pagado',
        count: 1,
        totalUF: voucher.price,
        totalCLP: voucher.total,
      });
    }
    for(const voucher of paidVouchers){
      datosFormateados.push({
        voucherId: voucher.voucherId,
        period: voucher.period,
        estado:'Pagado',
        count: 1,
        totalUF: voucher.price,
        totalCLP: voucher.total,
      });
    }
    /*
      if (datosOriginales[estado] !== undefined) {
        const estadoOriginal = datosOriginales[estado];
        // @ts-ignore
        datosFormateados.push({
          estado,
          count: estadoOriginal.count,
          totalUF: estadoOriginal.totalUF,
          totalCLP: estadoOriginal.totalCLP,
        });
      }
    */
    setResult(datosFormateados);
  };

  const columns = [
    {
      Cell: ({ value }) => value || 'Sin cantidad',
      Header: 'Voucher Id',
      HeaderWidth: 'w-[31%]',
      accessor: 'voucherId',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || 'Sin data',
      Header: 'Periodo',
      HeaderWidth: 'w-[31%]',
      accessor: 'period',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },    
    {
      Cell: ({ value }) => value || 'Sin data',
      Header: ' ',
      HeaderWidth: 'w-[31%]',
      accessor: 'estado',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value.toFixed(2) || 'Sin monto',
      Header: 'Monto en UF',
      HeaderWidth: 'w-[31%]',
      accessor: 'totalUF',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => formatNumber(value) || 'Sin monto',
      Header: 'Monto en CLP',
      HeaderWidth: 'w-[31%]',
      accessor: 'totalCLP',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
  ];

  useEffect(() => {
    if (!showResults) return;
    transforResult();
  }, [showResults, inputPeriod]);

  return (
    <div className={styles.container}>
      <div className={styles.professional_info}>

        {
          inprocess && <div className={'mt-5'}>
            <div className=" mt-5">
              <ProgressBar completed={completed.toFixed(1)} />
            </div>
            <div className=" mt-5 text-center">
              <div>En Proceso ({processed}/{filteredData.length})</div>
            </div>
          </div>
        }
        {
          !inprocess && !showResults && <div className={'mt-5 flex flex-col'}>
            <div className="w-full">
              <img src={image} alt="info" className={`${styles.image} mx-auto mb-4`} />
              <p className='mb-3 text-center text-lg text-blue-800'>Por favor confirmar la operación:</p>
              <li className='mb-3'>Realizar el cobro para {filteredData.length} vouchers pendientes.</li>
            </div>
            <div className='flex items-start'><InfoIcon /><p className='ml-2'>Esta operación puede tardar varios minutos, por favor no abandonar esta pantalla hasta finalizado el proceso.</p></div>

            <div className='flex justify-end items-center mt-5'>
              <Button type='ghost-primary' buttonType='button' onClick={() => setShowModal(false)}>Cancelar</Button>
              <Button type='secondary' buttonType='button' onClick={startProcess} className='ml-2'>Confirmar</Button>
            </div>
          </div>
        }


        {/* {
          (periodList) && <>
            <Select
              customClass=""
              customSize="m"
              items={periodList}
              value={periodList[3]}
              name="description"
              nameType="description"
              onChange={(e) => setSelectPeriod(e)}
              placeholder="Seleccione un periodo"
              title="Periodo"
              disabled
            />
          </>
        } */}

        {
          !inprocess && showResults && <div className={styles.buttons}>
            {/* stats */}
            <div className="">
              <div className="flex justify-between mb-4">
                <div className="py-4 flex flex-col mr-10">
                  <div className={styles.success}>
                    Cobro Exitoso
                  </div>
                  <div className='flex items-center'>
                    <div className="border-r-2 border-gray-300 pr-3">
                      <p className='text-lg text-gray-600'>Cantidad</p>
                      <p className='text-xl text-green-500 font-bold'>{paid}</p>
                    </div>
                    <div className="p-4">
                      <p className='text-lg text-gray-600'>Recaudado</p>
                      <p className='text-xl text-green-500 font-bold'>${formatNumber(paidAmount)}</p>
                    </div>
                  </div>
                </div>
                <div className="py-4 flex flex-col ml-10">
                  <p className={styles.fail}>
                    Cobro Rechazado
                  </p>
                  <div className='flex items-center'>
                    <div className="border-r-2 border-gray-300 pr-3">
                      <p className='text-lg text-gray-600'>Cantidad</p>
                      <p className='text-xl text-red-500 font-bold'>{unpaid}</p>
                    </div>
                    <div className="p-4">
                      <p className='text-lg text-gray-600'>Sin recaudar</p>
                      <p className='text-xl text-red-500 font-bold'>
                        ${formatNumber(unpaidAmount)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* period */}
            <div>
              <Select
                customClass="mb-3"
                customSize="m"
                items={[{
                    id: 1,
                    name: product.description
                  }]}
                value={{
                  id: 1,
                  name: product.description
                }}
                name="selectName"
                nameType="name"
                // onChange={(e) => console.log(e)}
                placeholder="Seleccione una opción"
                title="Producto"
                disabled
              />
            </div>
            <div className='flex justify-between items-end'>
              <div>
                <Input
                  focused
                  name="name"
                  value={inputPeriod}
                  placeholder="escribe un texto"
                  title="Periodo"
                  type="text"
                />
              </div>

              <div>
                <Input
                  focused
                  name="name"
                  value={today}
                  placeholder="escribe un texto"
                  title="Fecha de Cobro"
                  type="text"
                />
              </div>
              <div className='flex justify-end mt-4'>
                <Button type='primary' buttonType='button' onClick={download}>Detalle en excel</Button>
              </div>
            </div>

            {/* table */}
            {
              result && <DataTable2
                columns={columns}
                data={result}
                disableHeader
                orderBy="estado"
                perPage={10}
                totalResults={result?.length ?? 0}
                sortBy="asc"
                colorStyle="neutral"
              />
            }

            <div className="flex mt-4">
              <div className=" p-4 ml-auto">
                <Button type='secondary' buttonType='button' onClick={() => setShowModal(false)}>Terminado</Button>
              </div>
            </div>
          </div>
        }
      </div>

    </div>
  );
};

export default PendingChargesModal;
