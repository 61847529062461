import React, { useState } from 'react';
import { StepProvider } from './Store/step-context';
import LayoutFormContainer from './layout';
import EMFormContainer from './EM/layout';
import Modal from '../Modal';
import { Checkbox, Button } from '@imed_npm/design-system';
import styles from './style.module.scss';
import image from '../../../src/assets/warning.svg';
import BEFormContainer from './BE/layout';

import pendrive from '../../../src/assets/pendrive.svg';
import monitor from '../../../src/assets/monitor.svg';
import mouse from '../../../src/assets/mouse.svg';

const Form = (getType) => {

  const [allowFlow, setAllowFlow] = React.useState<boolean>(false);
  const [acceptTechnicalTerms, setAcceptTechnicalTerms] = React.useState<boolean>(false);
  const [nextStep, setNextStep] = useState<any>();
  const nextScreen = () => {
    if (acceptTechnicalTerms) {
      const { type } = getType;
      switch (type) {
        case 'lme':
          setNextStep(<LayoutFormContainer />);
          break;
        case 'be':
          setNextStep(<BEFormContainer />);
          break;
        case 'em':
          setNextStep(<EMFormContainer />);
          break;
        default:
          setNextStep(<LayoutFormContainer />);
          break;
      }
      setAllowFlow(true);
    }
  };
  return (
    <StepProvider>
      {!allowFlow &&
        <Modal title={''} modalClass="w-9/12" showModal={true} showCloseButton={true} functionCloseButton={() => { window.location.href = '/'; }}>
          <div className='flex flex-col px-12'>
            <div className='p-5 mx-auto'><img src={image} className={`${styles.image}`} /></div>
            <div className={`p-5 text-center w-10/12 mx-auto ${styles.title}`}>Recuerda que para operar en la plataforma debes contar con:  </div>

            {/* <div className='p-5'> La plataforma requiere: </div> */}
            <div className='p-5'>
              <ul className={`${styles.color} list-disc`}>
                <li className='py-3 flex'>
                  <div className="w-20 flex items-center justify-end pr-3 mr-1">
                    <img src={monitor} className='pl-2 mb-3' />
                  </div>
                  <p className="mb-0 grow pl-1">Sistema Operativo Windows 10 ó una versión superior. <span className='blue'>(La plataforma no es compatible con MacOs ó Linux)</span></p>
                </li>
                <li className='py-3 flex'>
                  <div className="w-16 flex items-center justify-center">
                    <img src={pendrive} />
                  </div>
                  <p className="mb-0 grow">Un puerto USB disponible para conectar un lector biométrico</p>
                </li>
                <li className='py-3 flex'>
                  <div className="w-16 flex items-center justify-center">
                    <img src={mouse} />
                  </div>
                  <p className='mb-0 grow'>Tener instalado el navegador <strong>Google Chrome o Microsoft Edge.</strong>
                  </p>
                </li>
              </ul>
            </div>
            <div className='p-5 border-t-2'>
              <div className='flex items-center'>
                <div className='pb-4'>
                  <Checkbox
                    id="checkbox"
                    name="checkbox"
                    onClick={() => { setAcceptTechnicalTerms(!acceptTechnicalTerms); }}
                    onChange={() => { setAcceptTechnicalTerms(!acceptTechnicalTerms); }}
                    value={acceptTechnicalTerms}
                    isChecked={acceptTechnicalTerms}
                  />
                </div>
                <p className={`${styles.agreed} select-none`} onClick={() => { setAcceptTechnicalTerms(!acceptTechnicalTerms); }}>Sí, de acuerdo. Poseo las especificaciones técnicas necesarias para proceder a la contratación de la plataforma.</p>
              </div>
            </div>
            <div className='py-5 flex justify-end'>
              <Button buttonType='button' type="secondary" className="" onClick={() => { nextScreen(); }} disabled={!acceptTechnicalTerms}>Continuar</Button>
            </div>
          </div>
        </Modal>
      }
      {
        allowFlow && nextStep
      }
    </StepProvider>
  );
};


export default Form;
