/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios, { type AxiosResponse } from 'axios';

import { URLS } from '../URLS';
import Swal from 'sweetalert2';

import getEnv from '../getEnv';
import { AppContextInterface } from '../context/loggedUsercontext.interface';
const BASE_URL = URLS.baseUrl;

const MIN_VALID_EXPIRATION = parseInt(getEnv('REACT_APP_MIN_VALID_EXPIRATION'), 10) ?? -1;

export const CANCEL_TOKEN = axios.CancelToken.source();
export const AXIOS = axios.create({
  baseURL: BASE_URL,
  timeout: 190000
});

const msgError = async (context: AppContextInterface) => {
  return await Swal.fire({
    title: 'Sesión expirada',
    text: 'La sesión ha expirado, redireccionando al login',
    didOpen: () => {
      Swal.showLoading();
      setTimeout(function () {
        if (context?.keycloak) {
          context.keycloak?.logout();
        }
      }, 3500);
    }
  }).then(() => {
    return false;
  });
};

interface ProxyConstructor {
  revocable: <T extends object, S extends object>(
    target: T,
    handler: ProxyHandler<S>,
  ) => { proxy: T; revoke: () => void };
  new <T extends object>(target: T, handler: ProxyHandler<T>): T;
  new <T extends object, S extends object>(target: S, handler: ProxyHandler<S>): T;
}
declare let Proxy: ProxyConstructor;

const target = {};
const handler = (context: AppContextInterface) => {
  return {
    get(target, name: string) {
      return Object.assign(
        {},
        ['get', 'head'].reduce(
          (o, method) =>
            Object.assign({}, o, {
              async [method](url = '', params = {}, headers = {}) {
                if (typeof url === 'object') {
                  params = url;
                  url = '';
                }
                return await new Promise((resolve, reject) => {
                  context.keycloak?.updateToken(MIN_VALID_EXPIRATION).then(() => {
                    AXIOS[method](`${name}${url}`, {
                      params,
                      headers: { ...headers, Authorization: `Bearer ${context.keycloak?.token}` }
                    }).then((response) => {
                      resolve(response);
                    }).catch((error) => {
                      if (error?.response?.status === 401) {
                        msgError(context);
                      }
                      reject(error);
                    });
                  })
                    .catch((err) => {
                      console.error(err);
                      msgError(context);
                      reject(err);
                    });
                });
              }
            }),
          {}
        ),
        ['delete'].reduce(
          (o, method) =>
            Object.assign({}, o, {
              async [method](url = '', data = {}, headers = {}) {
                if (typeof url === 'object') {
                  data = url;
                  url = '';
                }

                const config = {
                  method: method.toUpperCase(),
                  url: `${name}${url}`,
                  headers: {
                    ...headers,
                    Authorization: `Bearer ${context?.token}`
                  },
                  data
                };

                return await new Promise((resolve, reject) => {
                  context.keycloak?.updateToken(MIN_VALID_EXPIRATION).then(() => {
                    AXIOS.request(config)
                      .then((response) => {
                        resolve(response);
                      })
                      .catch((error) => {
                        if (error.response && error.response.status === 401) {
                          msgError(context);
                        }
                        reject(error);
                      });
                  })
                    .catch((err) => {
                      console.error(err);
                      msgError(context);
                      reject(err);
                    });
                });
              }
            }),
          {}
        ),
        ['post', 'put', 'patch'].reduce(
          (o, method) =>
            Object.assign({}, o, {
              async [method](url = '', body = {}, params = {}, headers = {}) {
                if (typeof url === 'object') {
                  params = body;
                  body = url;
                  url = '';
                }

                return await new Promise((resolve, reject) => {
                  context.keycloak?.updateToken(MIN_VALID_EXPIRATION).then(() => {
                    AXIOS[method](`${name}${url}`, body, {
                      params,
                      headers: { ...headers, Authorization: `Bearer ${context.keycloak?.token}` }
                    }).then((response) => resolve(response))
                      .catch((error) => {
                        if (error.response && error.response.status === 401) {
                          msgError(context);
                        }
                        reject(error);
                      });
                  })
                    .catch((err) => {
                      console.error(err);
                      msgError(context);
                      reject(err);
                    });
                });
              }
            }),
          {}
        )
      );
    }
  };
};

type IAxiosSecure = (context: AppContextInterface) => {
  patch: <T = any>(url: string, data?: any, header?: any) => Promise<AxiosResponse<T>>,
  post: <T = any>(url: string, data?: any, header?: any) => Promise<AxiosResponse<T>>,
  get: <T = any>(url: string, data?: any, header?: any) => Promise<AxiosResponse<T>>,
  put: <T = any>(url: string, data?: any, header?: any) => Promise<AxiosResponse<T>>,
  delete: <T = any>(url: string, data?: any, header?: any) => Promise<AxiosResponse<T>>
};

export const AXIOS_SECURE: IAxiosSecure = (context) => {
  return new Proxy(target, handler(context))[''];
};

const msgErrorApikey = async () => {
  return await Swal.fire({
    title: 'Sesión expirada',
    text: 'La sesión ha expirado, redireccionando a la pagina de inicio.',
    didOpen: () => {
      Swal.showLoading();
      window.sessionStorage.removeItem('flowkey');
      setTimeout(function () {
        window.location.href = '/';
      }, 4500);
    }
  }).then(() => {
    return false;
  });
};

const targetApikey = {};
const handlerApikey = (token: string | null) => {
  return {
    get(targetApikey, name: string) {
      return Object.assign(
        {},
        ['get', 'head'].reduce(
          (o, method) =>
            Object.assign({}, o, {
              async [method](url = '', params = {}, headers = {}) {
                if (typeof url === 'object') {
                  params = url;
                  url = '';
                }
                return await new Promise((resolve, reject) => {
                  AXIOS[method](`${name}${url}`, {
                    params,
                    headers: { ...headers, token }
                  }).then((response) => {
                    resolve(new Promise(resolve => {
                      resolve(response);
                    }));
                  }).catch(async (error) => {
                    if (error.response && error.response.status === 401) {
                      await msgErrorApikey();
                    }
                    reject(error);
                  });
                });
              }
            }),
          {}
        ),
        ['post', 'put', 'patch', 'delete'].reduce(
          (o, method) =>
            Object.assign({}, o, {
              async [method](url = '', body = {}, params = {}, headers = {}) {
                if (typeof url === 'object') {
                  params = body;
                  body = url;
                  url = '';
                }
                return await new Promise((resolve, reject) => {
                  AXIOS[method](`${name}${url}`, body, {
                    params,
                    headers: { ...headers, token }
                  }).then((response) => {
                    resolve(new Promise(resolve => {
                      resolve(response);
                    }));
                  }).catch((error) => {
                    if (error.response && error.response.status === 401) {
                      msgErrorApikey();
                    }
                    reject(error);
                  });
                });
              }
            }),
          {}
        )
      );
    }
  };
};

type IAxiosSecureApikey = (token?: string) => {
  patch: <T = any>(url: string, data?: any, header?: any) => Promise<AxiosResponse<T>>,
  post: <T = any>(url: string, data?: any, header?: any) => Promise<AxiosResponse<T>>,
  get: <T = any>(url: string, data?: any, header?: any) => Promise<AxiosResponse<T>>,
  put: <T = any>(url: string, data?: any, header?: any) => Promise<AxiosResponse<T>>,
  delete: <T = any>(url: string, data?: any, header?: any) => Promise<AxiosResponse<T>>
};

export const AXIOS_APIKEY: IAxiosSecureApikey = (flowkey?: string) => {
  const key = window.sessionStorage.getItem('flowkey') ?? flowkey;
  if (!key) {
    return msgErrorApikey();
  }
  return new Proxy(targetApikey, handlerApikey(key))[''];
};
