/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';

interface OwnProps {
  pdf: string;
  classes?: string;
  props?: {
    view?: string;
    toolbar?: number;
    navpanes?: number;
  }
} 
const PDFDataViewer = ({ pdf, props = {}, classes = '' }: OwnProps) => {
  const [ document, setDocument ] = useState<string>('');
  
  useEffect(() => {
    const _props = Object.keys(props).map( key => `${key}=${props[key]}`).join('&');
    const _document = `data:application/pdf;base64,${pdf}${_props ? `#${_props}` : ''}`;
    setDocument(_document);
  }, []);

  return (
    <>
      <object data={document} type="application/pdf" className={`${ classes
 ? classes : styles.contract_box }`}>
        <iframe src={`https://docs.google.com/viewer?url=${document}&embedded=true`}></iframe>
      </object>
    </>
  );
};

export default PDFDataViewer;