import React, { useState, useEffect, useContext, ChangeEvent } from 'react';
import { DataTable2, Skeleton, Select, Input, Button, LoadingImed, InfoIcon, UserHelp, IndexPointingUpEmoji } from '@imed_npm/design-system';
import { getBase64 } from '../../../../utils/base64';
import { formatJsDate } from '../../utils/utils';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import { GetPlansOptions, Product } from '../../utils/interfaces';
import { getAdminPlans, getAudit, patchEmailTemplate, patchFileTemplate } from '../../utils/actions';
import getEnv from '../../../../getEnv';
import Modal from '../../../Modal';
import PDFDataViewer from '../../../PdfViewer';
import image from './sendgrid_help.png';
import styles from './style.module.scss';

interface Props {
  type: any;
}

const APP_ID = getEnv('REACT_APP_ID');

const AdminPlans: React.FC<Props> = (props) => {
  const context = useContext(LoggedUserContext);
  const [data, setData] = useState<GetPlansOptions | undefined>();
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showButtonLoading, setShowButtonLoading] = useState<boolean>(false);
  const [showTemplateLoading, setShowTemplateLoading] = useState<boolean>(false);

  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();
  const [item, setItem] = useState<any>();

  const [showContract, setShowContract] = useState<boolean>(false);
  const [showChangeFile, setShowChangeFile] = useState<boolean>(false);
  const [showChangeEmail, setShowChangeEmail] = useState<boolean>(false);
  const [showAudit, setShowAudit] = useState<boolean>(false);

  const [contractFile, setContractFile] = useState();
  const [template, setTemplate] = useState('');
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [newTemplate, setNewTemplate] = useState();

  const [file, setFile] = useState('');
  const [newFile, setNewFile] = useState();

  const [auditData, setAuditData] = useState<any>();
  const [isHovering, setIsHovering] = useState(false);

  const [finished, finishStatus] = useState(false);

  const activeInstitution = context.userData.activeInstitution?.id;

  const dataInstitution = context.userData.institutions.find(o => o.id === activeInstitution);

  let findAppInstitution, resources;
  if (dataInstitution) {
    findAppInstitution = dataInstitution.branchOffices[0].apps.find(o => o?.id === APP_ID);
    if (findAppInstitution) {
      resources = findAppInstitution.resources;
    }
  }

  const isResourceEnabled = (resourceName: string) => resources.some((resource) => resource.resource_name === resourceName && resource.resource_enabled);


  const renderCheckingInfo = () => {
    return (
      <>
        {(showLoading) && (<LoadingImed text="Cargando información" />)}
      </>
    );
  };

  const remove = () => {
    setSelectedFile('');
    setFile('');
  };

  const formatAction = (e) => {
    switch (e) {
      case 'updateContractTemplate':
        return 'Modificado contrato';
      case 'updateEmailTemplate':
        return 'Modificado Email Template';
      default:
        return 'Sin información';
    }
  };

  const formatName = (e) => {
    switch (e) {
      case 'Premium':
        return 'text-primary font-bold';
      case 'Estándar':
        return 'text-green-600 font-bold';
      case 'LME':
        return 'text-blue-600 font-bold';
      default:
        return '';
    }
  };

  const getSelectItems = (row) => {
    return [
      {
        id: 1,
        name: 'Ver contrato',
        show: true
      },
      {
        id: 2,
        name: 'Modificar archivo',
        show: isResourceEnabled('Contrato-Profesional')
      },
      {
        id: 3,
        name: 'Modificar template email',
        show: isResourceEnabled('Contrato-Profesional')
      },
      {
        id: 4,
        name: 'Ver auditoría',
        show: true
      }
    ].filter(({ show }) => show);
  };

  const handleActions = (action, value) => {
    setItem(value.original);
    // setShowModal(false);
    switch (action.id) {
      case 1:
        setContractFile(value.original.contract_template_file);
        setShowContract(true);
        break;
      case 2:
        setShowChangeFile(true);
        break;
      case 3:
        setShowChangeEmail(true);
        break;
      case 4:
        getItemAudit(value.original.id);
        setShowAudit(true);
        break;
      default:
        break;
    }
  };

  const ActionCellRender = ({ row }: any) => {
    return (<Select
      customSize="s"
      items={getSelectItems(row)}
      name="selectName"
      nameType="name"
      onChange={(e) => handleActions(e, row)}
      placeholder="Seleccione"
    />);
  };

  const columns = [
    {
      Cell: ({ value }) => value || 'Sin código',
      Header: 'Código plan',
      HeaderWidth: 'w-[31%]',
      accessor: 'code',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => <div className={formatName(value)}>{value}</div> || 'Sin data',
      Header: 'Nombre Plan',
      HeaderWidth: 'w-[31%]',
      accessor: 'name',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Contrato (AWS)',
      HeaderWidth: 'w-[31%]',
      accessor: 'contract_template',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Template email Bienvenida',
      HeaderWidth: 'w-[31%]',
      accessor: 'mail_template',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ActionCellRender,
      Header: 'Acciones',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }
  ];

  const auditCols = [
    {
      Cell: ({ value }) => formatJsDate(value) || '00/00/0000',
      Header: 'Fecha de modificación',
      HeaderWidth: 'w-[31%]',
      accessor: 'updated_at',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => formatAction(value) || 'Sin data',
      Header: 'Acción',
      HeaderWidth: 'w-[31%]',
      accessor: 'action',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => <div className='w-40 truncate'>{value || 'Sin Data'}</div>,
      Header: 'Nuevo valor',
      HeaderWidth: 'w-[31%]',
      accessor: 'new_data',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => <div className='w-40 truncate'>{value || 'Sin Data'}</div>,
      Header: 'Valor anterior',
      HeaderWidth: 'w-[31%]',
      accessor: 'old_data',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || 'Sin usuario',
      Header: 'Responsable',
      HeaderWidth: 'w-[31%]',
      accessor: 'user',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
  ];

  const getItemAudit = (e) => {
    setShowLoading(true);
    getAudit(context, e).then(setAuditData).finally(() => setShowLoading(false));
  };

  const updateFile = () => {
    setShowButtonLoading(true);
    finishStatus(false);
    if (!item || !file) return;
    // @ts-ignore
    patchFileTemplate(context, item.id, file).then(setNewFile).finally(() => { setLoadingTable(true); setShowButtonLoading(false); setShowChangeFile(false); finishStatus(true); remove(); });
  };

  const updateEmail = () => {
    setShowTemplateLoading(true);
    finishStatus(false);
    if (!item || !template) return;
    // @ts-ignore
    patchEmailTemplate(context, item.id, template).then((e) => { setNewTemplate(e); setLoadingTable(true); }).finally(() => { setShowTemplateLoading(false); setShowChangeEmail(false); finishStatus(true); });
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && (file.type === 'application/pdf')) {
      // @ts-ignore
      const { name } = file;
      getBase64(file).then((base64: string | any) => {
        const base64WithoutHeader = base64.substring(base64.indexOf(',') + 1);
        setFile(base64WithoutHeader);
      });
      setSelectedFile(name);
    }
  };

  const handleMouseEnter: React.MouseEventHandler<HTMLDivElement> = () => {
    setIsHovering(true);
  };

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    if (finished) {
      const timeoutId = setTimeout(() => {
        const data: GetPlansOptions = {
          product: props.type.name
        };
        // @ts-ignore
        getAdminPlans(context, data).then(setData).finally(() => setShowLoading(false));
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [finished]);


  useEffect(() => {
    if (props.type) {
      setSelectedProduct(props.type);
      setShowLoading(true);

      const data: GetPlansOptions = {
        product: props.type.name
      };
      // @ts-ignore
      getAdminPlans(context, data).then(setData).finally(() => setShowLoading(false));
    }
  }, [props.type]);

  useEffect(() => {
    if (!showChangeEmail) {
      setShowTemplateLoading(false);
      setLoadingTable(false);
    }
    if (!showChangeFile) {
      setShowButtonLoading(false);
      setLoadingTable(false);
    }
  }, [showChangeEmail, showChangeFile]);

  return (
    <div>
      {renderCheckingInfo()}

      {
        (data && !loadingTable) ? (
          <>
            <h4 className='text-base font-semibold my-10'>
              Resultados de la búsqueda: <span className='text-blue-800'>{selectedProduct?.description}</span>
            </h4>

            <DataTable2
              columns={columns}
              //@ts-ignore
              data={data.filter(option => option.commercialized === 1)}
              // data={data}
              disableHeader
              orderBy="name"
              perPage={10}
              sortBy="asc"
              colorStyle="neutral"
            /></>
        ) : (
          <div className='w-full border flex flex-col p-20 justify-center items-center'>
            <InfoIcon size="m" />
            <p className='mt-2'>Todavía no selecciona un producto para administrar</p>
          </div>
        )
      }

      {/* modal */}
      {showContract &&
        <Modal title={'Contrato'} showModal={showContract} setShowModal={setShowContract} modalClass='h-screen' containerClass='w-4/5'>

          <div className='pb-6'>
            <p>
              <strong>Nombre Plan:</strong> {item?.name ?? ''};
            </p>
            <p>
              <strong>Código Plan:</strong> {item?.code ?? ''};
            </p>
          </div>
          {
            (contractFile) && (
              <PDFDataViewer pdf={contractFile} classes={'h-full w-full'} props={{ view: 'fitH', toolbar: 1, navpanes: 0 }} />
            )
          }
        </Modal>
      }
      {showChangeFile &&
        <Modal title={'Modificar contrato'} showModal={showChangeFile} setShowModal={setShowChangeFile} modalClass={styles.modalWidth} containerClass='my-6'>
          <div>
            <div className='pb-5'>
              <p>
                <strong>Nombre Plan:</strong> {item?.name ?? ''};
              </p>
              <p>
                <strong>Código Plan:</strong> {item?.code ?? ''};
              </p>
            </div>

            {(selectedFile && selectedFile !== '' && file !== '') ? <div className="mb-5 border p-4">
              <div className='flex w-full justify-between items-center'>
                <div className='flex items-center'>
                  <p><small>{selectedFile}</small></p>
                  <img className='ml-2' src="images/plan-standar.svg" />
                </div>
                <a type="button" onClick={remove} className={styles.removeFile}>Quitar documento</a>
              </div>
            </div> : <UserHelp
              colorStyle="green"
              icon={<IndexPointingUpEmoji svgClass="h-10 w-10" />}
              text={<div className='flex w-full'><p className='grow'>Para modificar el contrato del plan <strong>{item?.code ?? ''}</strong>, debes presionar el botón <strong>&apos;Adjuntar documento&apos;</strong> y seleccionar un archivo pdf</p>
                <div className='ml-1'>
                  <input type="file" accept=".pdf" className={`${styles.custom_file_input}`} onChange={handleFileUpload} />
                </div></div>}
              title=" "
            />}
            <div className="flex items-end justify-end">
              <Button id="save_contract" type='primary' buttonType='button' onClick={updateFile} disabled={selectedFile.length < 1}>
                {showButtonLoading ?
                  <><svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg> guardando
                  </>
                  : 'Guardar'}</Button>
            </div>
          </div>
        </Modal>
      }
      {showChangeEmail &&
        <Modal title={'Correo de termino de proceso de suscripción'} showModal={showChangeEmail} setShowModal={setShowChangeEmail} modalClass={styles.modalWidth} containerClass='my-6'>
          <div>
            <div className='pb-5'>
              <p>
                <strong>Nombre Plan:</strong> {item?.name ?? ''};
              </p>
              <p>
                <strong>Código Plan:</strong> {item?.code ?? ''};
              </p>
            </div>

            <div className="flex items-end justify-between">
              <div className='flex items-end'>
                <div>
                  <Input customSize="l" name="email" id="template_email" title='Template ID SendGrid' placeholder='Ingrese ID' onChange={({ target: { value } }) => { setTemplate(value); }} />
                </div>
                <div className='pb-4 ml-2' onMouseEnter={handleMouseEnter} >
                  <InfoIcon svgClass={styles.icon_color} />
                </div>
              </div>
              <Button id="save_template" type='primary' buttonType='button' onClick={updateEmail} disabled={!template}>
                {showTemplateLoading ?
                  <><svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg> guardando
                  </>
                  : 'Guardar'}</Button>
            </div>
            {
              isHovering && <div className='mt-10' onMouseLeave={handleMouseLeave}>
                <img src={image} />
              </div>
            }
          </div>
        </Modal>
      }
      {showAudit &&
        <Modal title={'Ver auditoría'} showModal={showAudit} setShowModal={setShowAudit} containerClass='w-4/5'>
          <div className='pb-5'>
            <p>
              <strong>Nombre Plan:</strong> {item?.name ?? ''};
            </p>
            <p>
              <strong>Código Plan:</strong> {item?.code ?? ''};
            </p>
          </div>
          {
            auditData ? <DataTable2
              columns={auditCols}
              data={auditData}
              disableHeader
              orderBy="updated_at"
              perPage={10}
              totalResults={auditData && auditData.length ? auditData.length : 0}
              sortBy="asc"
              colorStyle="neutral"
            /> : <div className='w-full border flex flex-col p-20 justify-center items-center'>
              <InfoIcon size="m" />
              <p className='mt-2'>Sin historial de Auditorías</p>
            </div>
          }

        </Modal>
      }

    </div>
  );
};

export default AdminPlans;
