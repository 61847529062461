import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { getBase64 } from '../../../../../../../../utils/base64';
import { UseStep } from '../../../../../../Store/step-context';
import { CheckCircleIcon } from '@imed_npm/design-system';
import icon from '../../../../../../../../../src/assets/plan-standar.svg';
import Compressor from 'compressorjs';

interface OwnProps {
  label: string
  urlImage?: string
  showPreview?: boolean
  inputName: string
  stepKey: string
}

const Attach = ({ urlImage, showPreview = false, inputName, stepKey, label }: OwnProps) => {
  const validTypes: string[] = ['image/jpeg', 'image/png'];
  const maxSize = 2400000;
  const { dispatch } = UseStep();
  const [error, setError] = useState<string>('');

  const handleConvertBase64 = async (event) => {
    setError('');
    const files: FileList = event.target.files;
    const { size, type } = files[0];

    //if ( !validTypes.includes(type) ) return setError('El tipo de archivo cargado no esta permitido');
    //if ( size > maxSize ) return setError('La imagen seleccionada supera el peso máximo permitido');
    new Compressor(files[0], {
      quality: 0.8,
      maxWidth: 800,
      maxHeight: 800,
      convertTypes: ['image/png', 'image/jpeg'],
      convertSize: 500000,
      success(result) {
        console.log('size', result.size);
        getBase64(result).then((base64) => {
          dispatch({ type: 'SET_FILE', payload: { key: event.target.name, base: base64, stepKey } });
        }).catch((err) => {
          console.log(err.message);
        });
      },
      error(err) {
        console.log(err.message);
      },
    });

    //const fileBase64 = await getBase64(files[0]);
    //dispatch({ type: 'SET_FILE', payload:{ key: event.target.name, base: fileBase64 , stepKey } });
  };

  const handleRemoveBase64 = async (event) => {
    dispatch({ type: 'REMOVE_FILE', payload: { key: event.target.dataset.name, stepKey } });
  };

  useEffect(() => {
    if (error) {
      dispatch({ type: 'SET_MODAL_ERROR', payload: error });
    }
  }, [error]);
  const renderAttachFile = () => {
    return (
      <div className={`${styles.attach_alert}`}>
        <p>Seleccione archivo JPG/PNG con un peso máximo de 2.4 MB.</p>
        <input name={inputName} type="file" accept=".png, .jpg, .jpeg" id="pictureInput" className={`${styles.custom_file_input}`} onChange={handleConvertBase64} />
      </div>
    );
  };

  const renderPreview = () => {
    return (<div className={styles.attach_preview}>
      <div className={styles.attach_preview_info}>
        <img src={urlImage} alt={'Preview image'} />
        <label className={styles.attach_preview_info__name}>{label}</label>
        <img src={icon} />
      </div>
      <div className={styles.attach_preview_remove_file}>
        <a onClick={handleRemoveBase64} data-name={inputName}>Quitar imagen</a>
      </div>
    </div>);
  };

  return (
    <div className={styles.attach}>
      <label className={styles.attach_title}>{label}</label>
      {
        showPreview ? renderPreview() : renderAttachFile()
      }
    </div>
  );
};


export default Attach;
