import React, { useEffect, useState } from 'react';
import { Input } from '@imed_npm/design-system';
import { formatRut, validFormat } from '../../../../utils/rut';
import { storeCenter } from '../../Store/actions';
import { UseStep } from '../../Store/step-context';
import AttachFile from '../ProfessionalInfo/Components/AttachFile';

const CVPbe = () => {
  const { dispatch, state: { adscription_id, product, steps: { step2: { nid }, step4: { center } } } } = UseStep();
  const [rut, setRut] = useState<string>();

  const setClientNid = ({ target: { value } }: { target: { value: string; } }) => {
    const rut = formatRut(value);
    setRut(rut);
  };
  // Store Center
  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     storeCenter({ dispatch, payload: { center, nid, product, adscription_id } });
  //   }, 250);
  //   return () => clearTimeout(timeout);
  // }, []);

  useEffect(() => {
    if (rut && (validFormat(rut))) {
      dispatch({ type: 'STORE_CVP_NID', payload: rut });
    }
  }, [rut]);

  return (
    <>
      <div className='mb-10'>
        <p className='mb-4'>Ingrese el Rut del convenio</p>
        <Input
          focused
          name="name"
          value={rut}
          onChange={setClientNid}
          placeholder="Ingrese un rut"
          title="Rut"
          type="text"
          customSize="l"
          stateMessage={rut ? (validFormat(rut) ? 'Rut válido' : 'Rut inválido') : ''}
          stateType={rut ? (validFormat(rut) ? 'success' : 'error') : undefined}
        />
        <div className='w-full border rounded-e p-6 mt-10'>
          <p className='mb-10'>Para habilitar el servicio de Bono Electrónico, debes contar con un convenio vigente con Fonasa o Isapre, e <strong>ingresar aquí un documento de Convenio de Venta Prestador</strong>, que respalde dicho convenio.</p>
          <div className='flex justify-center items-start'>
            <AttachFile type="be" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CVPbe;
