import React from 'react';
import { Button } from '@imed_npm/design-system';
import styles from './style.module.scss';
import icon from './assets/icon.svg';
import getEnv from '../../../../../../getEnv';

interface OwnProps {
  onToggleStatus: () => void,
  debtType: string,
}

const CustomerDebts = ({ onToggleStatus, debtType }: OwnProps) => {

  const {
      pathname
  } = window.location;
  
  const goExternalPage = () => {
    let url = '';
    switch (debtType) {
      case 'EM':
        url = getEnv('REACT_APP_URL_EM_FRONT');
        break;
      case 'PFP':
        url = getEnv('REACT_APP_URL_PFP_FRONT');
        break;
      case 'BOTH':
        url = getEnv('REACT_APP_URL_PFP_FRONT');
        break;
    }
    window.open(url, '_blank');
  };

  const getName = () => {
    let name = '';
    const pathSegments = pathname.split('/');

    switch (pathSegments[1]) {
      case 'formulario-em':
        name = 'Escritorio Médico';
        break;
      case 'formulario-lme':
        name = 'Licencia Médica electrónica';
        break;
      case 'formulario-be':
        name = 'Bono electrónico';
        break;
    }
    return name;
  };

  return (
    <div className={styles.customer_debts}>
      <img src={icon} alt={'imagen'} />
      <h2 className={styles.title}>
        Presenta períodos pendientes de pago
      </h2>
      <p className={styles.content}>
        Para que puedas seguir con la suscripción de {debtType ? getName() : 'este producto'} revisa y paga directamente acá.
      </p>
      <div className={styles.container_button}>
        <Button buttonType='button' type="secondary" onClick={goExternalPage}>
          Pagar ahora
        </Button>
      </div>
    </div>
  );
};


export default CustomerDebts;
