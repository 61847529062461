import { InitialState } from '../../../utils/interfaces';
import { initialState } from './step-context';

export interface Action {
  type: string;
  payload?;
  step?: string;
  stepEm?: string;
}

export const reducer = (state: InitialState, action: Action): InitialState => {
  switch (action.type) {
    case 'VALIDATION_NID':
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.step ?? 'step2']: {
            ...state.steps[action.step ?? 'step2'],
            validations: {
              ...state.steps[action.step ?? 'step2'].validations,
              nid: action.payload.key ? {
                ...initialState.steps[action.step ?? 'step2'].validations.nid,
                [action.payload.key]: true,
              } : {
                ...initialState.steps[action.step ?? 'step2'].validations.nid,
              },
            },
          }
        }
      };
    case 'CHECK_EXISTING_PROFESSIONAL':
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.step ?? 'step2']: {
            ...state.steps[action.step ?? 'step2'],
            validations: {
              ...state.steps[action.step ?? 'step2'].validations,
              existing: action.payload.key ? {
                ...initialState.steps[action.step ?? 'step2'].validations.existing,
                [action.payload.key]: true,
              } : {
                ...initialState.steps[action.step ?? 'step2'].validations.existing,
              },
            },
          }
        }
      };
    case 'SET_REGIONS':
      return {
        ...state,
        general: {
          regions: action.payload.regions
        }
      };
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.payload.token
      };
    case 'SET_ADSCRIPTION':
      return {
        ...state,
        adscription_id: action.payload.adscription_id
      };
    case 'SET_PRODUCT':
      return {
        ...state,
        product: action.payload.product
      };
    case 'SET_PERSON_CONTRACT_TYPE':
      return {
        ...state,
        form: {
          ...state.form,
          personContractType: action.payload.personContractType
        }
      };
    case 'SET_BUSINESS_RUT_TYPE':
      return {
        ...state,
        form: {
          ...state.form,
          rutRL: action.payload
        }
      };
    case 'SET_PERSON_PLAN_TYPE':
      return {
        ...state,
        form: {
          ...state.form,
          personPlanType: action.payload.personPlanType
        }
      };
    case 'UPDATE_STEP':
      return {
        ...state,
        step: action.payload.step
      };
    case 'RESET_STEP':
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.step]: initialState.steps[action.payload.step]
        }
      };
    case 'SET_MODAL_ERROR':
      return {
        ...state,
        steps: {
          ...state.steps,
          error: {
            showModal: true,
            message: action.payload,
          },
        }
      };
    case 'SET_MODAL_SUCCESS':
      return {
        ...state,
        steps: {
          ...state.steps,
          success: {
            showModal: true,
            message: action.payload,
          },
        }
      };
    case 'SET_CLEAN_MODAL':
      return {
        ...state,
        steps: {
          ...state.steps,
          error: {
            showModal: false,
            message: '',
          },
        }
      };
    case 'CHECK_SERIAL_SUCCESS':
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.step ?? 'step2']: {
            ...state.steps[action.step ?? 'step2'],
            nid: action.payload.nid,
            serial: action.payload.serial,
          }
        }
      };
    case 'GET_INFO':
      return {
        ...state,
        info: { ...action.payload.data }
      };
    case 'GET_SPECIALTIES':
      return {
        ...state,
        form: {
          ...state.form,
          specialties: action.payload.data
        }
      };
    case 'GET_SUSCRIPCION_STATUS':
      return {
        ...state,
        form: {
          ...state.form,
          suscripcionStatus: action.payload.data
        }
      };
    case 'SELECTED_SPECIALTIES':
      return {
        ...state,
        form: {
          ...state.form,
          selectedSpecialties: action.payload
        }
      };
    case 'PROFESSIONAL_HAS_DEBT':
      return {
        ...state,
        steps: {
          ...state.steps,
          step2: {
            ...state.steps.step2,
            validations: {
              ...state.steps.step2.validations,
            },
            professionalHasDebt: action.payload.debt,
            debtType: action.payload.debtType
          }
        }
      };
    case 'SET_FILE':
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.stepKey]: {
            ...state.steps[action.payload.stepKey],
            [action.payload.key]: action.payload.base
          }
        }
      };
    case 'REMOVE_FILE':
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.stepKey]: {
            ...state.steps[action.payload.stepKey],
            [action.payload.key]: ''
          }
        }
      };
    case 'ADDRESS_VALIDATION':
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.step ?? 'step22']: {
            ...state.steps[action.step ?? 'step4'],
            validations: {
              ...state.steps[action.step ?? 'step4'].validations,
              address: action.payload.key ? {
                ...initialState.steps[action.step ?? 'step4'].validations.address,
                [action.payload.key]: true,
              } : {
                ...initialState.steps[action.step ?? 'step4'].validations.address,
              },
            },
          }
        }
      };
    case 'CHECK_SERIAL_VALIDATION':
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.step ?? 'step2']: {
            ...state.steps[action.step ?? 'step2'],
            validations: {
              ...state.steps[action.step ?? 'step2'].validations,
              serial: action.payload.key ? {
                ...initialState.steps[action.step ?? 'step2'].validations.serial,
                [action.payload.key]: true,
              } : {
                ...initialState.steps[action.step ?? 'step2'].validations.serial,
              },
            },
          }
        }
      };
    case 'GET_INFO_VALIDATION':
      return {
        ...state,
        steps: {
          ...state.steps,
          step2: {
            ...state.steps.step2,
            validations: {
              ...state.steps.step2.validations,
              info: action.payload.key ? {
                ...initialState.steps.step2.validations.info,
                [action.payload.key]: true,
              } : {
                ...initialState.steps.step2.validations.info,
              },
            },
          }
        }
      };
    case 'CHECK_DEBT_VALIDATION':
      return {
        ...state,
        steps: {
          ...state.steps,
          step2: {
            ...state.steps.step2,
            validations: {
              ...state.steps.step2.validations,
              debt: action.payload.key ? {
                ...initialState.steps.step2.validations.debt,
                [action.payload.key]: true,
              } : {
                ...initialState.steps.step2.validations.debt,
              },
            },
          }
        }
      };
    case 'STORE_BILLING_INFO':
      return {
        ...state,
        steps: {
          ...state.steps,
          step2: {
            ...state.steps.step2,
            nid: action.payload.nid,
          },
          step22: {
            ...state.steps.step22,
            ...action.payload.data
          }
        },
      };
    case 'CLEAR_ADDRESS':
      return {
        ...state,
        steps: {
          ...state.steps,
          step4: {
            ...state.steps.step4,
            validations: {
              ...state.steps.step4.validations,
              address: {
                error: false,
                pending: false,
                success: false
              }
            }
          }
        }
      };

    case 'CLEAR_GENERAL_ADDRESS':
      return {
        ...state,
        general: {
          ...state.general,
          address: null
        }
      };
    case 'STORE_BILLING_COMMUNE':
      return {
        ...state,
        general: {
          ...state.general,
          address: {
            ...state.general.address,
            commune: action.payload
          }
        }
      };
    case 'STORE_BILLING_REGION':
      return {
        ...state,
        general: {
          ...state.general,
          address: {
            ...state.general.address,
            region: action.payload
          }
        }
      };
    case 'STORE_SHIPPING_COMMUNE':
      return {
        ...state,
        general: {
          ...state.general,
          shipping_locality: {
            ...state.general.shipping_locality,
            commune: action.payload
          }
        }
      };
    case 'STORE_SHIPPING_REGION':
      return {
        ...state,
        general: {
          ...state.general,
          shipping_locality: {
            ...state.general.shipping_locality,
            region: action.payload
          }
        }
      };
    case 'SET_SHIPPING':
      return {
        ...state,
        general: {
          ...state.general,
          shipping: action.payload
        }
      };
    case 'STORE_PROFESSIONAL_VALIDATION':
      return {
        ...state,
        steps: {
          ...state.steps,
          step3: {
            ...state.steps.step3,
            validations: {
              ...state.steps.step3.validations,
              professional: action.payload.key ? {
                ...initialState.steps.step3.validations.professional,
                [action.payload.key]: true,
              } : initialState.steps.step3.validations.professional,
            },
          }
        }
      };
    case 'STORE_CONTACT_INFO':
      return {
        ...state,
        steps: {
          ...state.steps,
          step3: {
            ...state.steps.step3,
            phone: action.payload.phone,
            phone_country_code: action.payload.phone_country_code,
            email: action.payload.email,
          }
        }
      };
    case 'STORE_PROFESSIONAL_RESULT':
      return {
        ...state,
        steps: {
          ...state.steps,
          step3: {
            ...state.steps.step3,
            stored: {
              ...state.steps.step3.stored,
              professional: action.payload.result
            }
          }
        }
      };
    case 'STORE_CI_RESULT':
      return {
        ...state,
        steps: {
          ...state.steps,
          step3: {
            ...state.steps.step3,
            stored: {
              ...state.steps.step3.stored,
              ci: action.payload.result
            }
          }
        }
      };
    case 'STORE_CI_VALIDATION':
      return {
        ...state,
        steps: {
          ...state.steps,
          step3: {
            ...state.steps.step3,
            validations: {
              ...state.steps.step3.validations,
              ci: action.payload.key ? {
                ...initialState.steps.step3.validations.ci,
                [action.payload.key]: true,
              } : initialState.steps.step3.validations.ci,
            },
          }
        }
      };
    case 'SEND_VERIFICATION_EMAIL':
      return {
        ...state,
        steps: {
          ...state.steps,
          step3: {
            ...state.steps.step3,
            validations: {
              ...state.steps.step3.validations,
              email: action.payload.key ? {
                ...initialState.steps.step3.validations.email,
                [action.payload.key]: true,
              } : initialState.steps.step3.validations.email,
            },
          }
        }
      };
    case 'VERICATION_EMAIL_SEND_RESULT':
      return {
        ...state,
        steps: {
          ...state.steps,
          step3: {
            ...state.steps.step3,
            verificationCodeSended: action.payload.sended
          }
        }
      };
    case 'CHECK_VERIFICATION_CODE':
      return {
        ...state,
        steps: {
          ...state.steps,
          step3: {
            ...state.steps.step3,
            validations: {
              ...state.steps.step3.validations,
              verificationCode: action.payload.key ? {
                ...initialState.steps.step3.validations.verificationCode,
                [action.payload.key]: true,
              } : initialState.steps.step3.validations.verificationCode,
            },
          }
        }
      };
    case 'CHECK_VERIFICATION_CODE_RESULT':
      return {
        ...state,
        steps: {
          ...state.steps,
          step3: {
            ...state.steps.step3,
            emailVerificated: action.payload.valid
          }
        }
      };
    // Step 4
    case 'STORE_CENTER':
      return {
        ...state,
        steps: {
          ...state.steps,
          step4: {
            ...state.steps.step4,
            validations: {
              ...state.steps.step4.validations,
              center: action.payload.key ? {
                ...initialState.steps.step4.validations.center,
                [action.payload.key]: true,
              } : initialState.steps.step4.validations.center,
            },
          }
        }
      };
    case 'STORE_CENTER_RESULT':
      return {
        ...state,
        steps: {
          ...state.steps,
          step4: {
            ...state.steps.step4,
            storedCenterInfo: action.payload.center
          }
        }
      };
    case 'ADD_CENTER':
      return {
        ...state,
        steps: {
          ...state.steps,
          step4: {
            ...state.steps.step4,
            addedCenters: [...state.steps.step4.addedCenters, action.payload.center]
          },
        },
      };
    case 'SET_FINISHED_CENTER':
      return {
        ...state,
        steps: {
          ...state.steps,
          step4: {
            ...state.steps.step4,
            finished: action.payload.finished,
          }
        },
      };
    case 'SELECT_CENTER':
      return {
        ...state,
        steps: {
          ...state.steps,
          step4: {
            ...state.steps.step4,
            center: action.payload.center
          }
        }
      };
    // Step 5
    case 'STORE_READERS':
      return {
        ...state,
        steps: {
          ...state.steps,
          step5: {
            ...state.steps.step5,
            readers: action.payload.readers
          }
        }
      };
    case 'STORE_PLAN':
      return {
        ...state,
        steps: {
          ...state.steps,
          step5: {
            ...state.steps.step5,
            plan: action.payload.plan
          }
        }
      };
    case 'STORE_PROMOCIONAL_CODE':
      return {
        ...state,
        steps: {
          ...state.steps,
          step5: {
            ...state.steps.step5,
            discount: action.payload.discount
          }
        }
      };
    case 'STORE_PROMOCIONAL_CODE_EM':
      return {
        ...state,
        steps: {
          ...state.steps,
          step5: {
            ...state.steps.step5,
            discount: action.payload.discount
          }
        }
      };
    case 'STORE_ADSCRIPTION':
      return {
        ...state,
        steps: {
          ...state.steps,
          step5: {
            ...state.steps.step5,
            validations: {
              ...state.steps.step3.validations,
              plans: action.payload.key ? {
                ...initialState.steps.step5.validations.plans,
                [action.payload.key]: true,
              } : initialState.steps.step5.validations.plans,
            },
          }
        }
      };
    // Step 6
    case 'ACCEPT_CONTRACT':
      return {
        ...state,
        steps: {
          ...state.steps,
          step6: {
            ...state.steps.step6,
            accepted: action.payload.accepted
          }
        }
      };
    case 'CHECK_SMS_CODE':
      return {
        ...state,
        steps: {
          ...state.steps,
          step6: {
            ...state.steps.step6,
            validations: {
              ...state.steps.step6.validations,
              checkSmsCode: action.payload.key ? {
                ...initialState.steps.step6.validations.checkSmsCode,
                [action.payload.key]: true,
              } : initialState.steps.step6.validations.checkSmsCode,
            },
          }
        }
      };
    case 'SEND_SMS_CODE':
      return {
        ...state,
        steps: {
          ...state.steps,
          step6: {
            ...state.steps.step6,
            validations: {
              ...state.steps.step6.validations,
              sendSmsCode: action.payload.key ? {
                ...initialState.steps.step6.validations.sendSmsCode,
                [action.payload.key]: true,
              } : initialState.steps.step6.validations.sendSmsCode,
            },
          }
        }
      };
    case 'STORE_SMS_OID':
      return {
        ...state,
        steps: {
          ...state.steps,
          step6: {
            ...state.steps.step6,
            oid: action.payload.oid
          }
        }
      };
    case 'STORE_SMS_CODE_CHECK':
      return {
        ...state,
        steps: {
          ...state.steps,
          step6: {
            ...state.steps.step6,
            validated: action.payload.validated
          }
        }
      };
    case 'STORE_CONTRACT':
      return {
        ...state,
        steps: {
          ...state.steps,
          step6: {
            ...state.steps.step6,
            validations: {
              ...state.steps.step6.validations,
              storeContract: action.payload.key ? {
                ...initialState.steps.step6.validations.storeContract,
                [action.payload.key]: true,
              } : initialState.steps.step6.validations.storeContract,
            },
          }
        }
      };
    case 'STORE_CONTRACT_RESULT':
      return {
        ...state,
        steps: {
          ...state.steps,
          step6: {
            ...state.steps.step6,
            storedContract: action.payload.storedContract
          }
        }
      };
    case 'SET_TYPE_PLAN':
      return {
        ...state,
        steps: {
          ...state.steps,
          step51: {
            ...state.steps.step51,
            plans: action.payload,
          },
        },
      };
    case 'STORE_PLAN_SELECTED':
      return {
        ...state,
        steps: {
          ...state.steps,
          step51: {
            ...state.steps.step51,
            plan_Selected: action.payload
          },
        },
      };
    case 'STORE_CVP':
      return {
        ...state,
        steps: {
          ...state.steps,
          step55: {
            ...state.steps.step55,
            cvp: action.payload
          },
        },
      };
    case 'STORE_CVP_NID':
      return {
        ...state,
        steps: {
          ...state.steps,
          step55: {
            ...state.steps.step55,
            nid: action.payload
          },
        },
      };

    case 'STORE_CVP_NAME':
      return {
        ...state,
        steps: {
          ...state.steps,
          step55: {
            ...state.steps.step55,
            name: action.payload
          },
        },
      };

    case 'SET_BANK_LIST':
      return {
        ...state,
        steps: {
          ...state.steps,
          step61: {
            ...state.steps.step61,
            bankList: action.payload,
          },
        },
      };
    case 'STORE_BANK':
      return {
        ...state,
        steps: {
          ...state.steps,
          step61: {
            ...state.steps.step61,
            bankSelected: {
              ...state.steps.step61.bankSelected,
              bank: action.payload,
            }
          },
        },
      };
    case 'STORE_BANKACCOUNT_TYPE':
      return {
        ...state,
        steps: {
          ...state.steps,
          step61: {
            ...state.steps.step61,
            bankSelected: {
              ...state.steps.step61.bankSelected,
              type: action.payload,
            }
          },
        },
      };
    case 'STORE_BANKACCOUNT_NUMBER':
      return {
        ...state,
        steps: {
          ...state.steps,
          step61: {
            ...state.steps.step61,
            bankSelected: {
              ...state.steps.step61.bankSelected,
              number: action.payload,
            }
          },
        },
      };
    case 'STORE_BANKACCOUNT_VALIDATE':
      return {
        ...state,
        steps: {
          ...state.steps,
          step61: {
            ...state.steps.step61,
            bankSelected: {
              ...state.steps.step61.bankSelected,
              validate: action.payload,
            }
          },
        },
      };
    case 'STORE_BANK_FILE':
      return {
        ...state,
        steps: {
          ...state.steps,
          step61: {
            ...state.steps.step61,
            file: action.payload
          },
        },
      };
    case 'VALIDATION_MERCADOPAGO':
      return {
        ...state,
        steps: {
          ...state.steps,
          step7: {
            ...state.steps.step7,
            validation: action.payload
          },
        },
      };
    case 'VALIDATION_CONTRACT':
      return {
        ...state,
        steps: {
          ...state.steps,
          step8: {
            ...state.steps.step8,
            validation: action.payload
          },
        },
      };
    default:
      throw new Error(`bad type: (${action.type})`);
  }
};
