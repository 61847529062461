import React, { useState } from 'react';
import { Input, Button, Select, PlusIcon } from '@imed_npm/design-system';
import styles from './styles.module.scss';
import { IResponseHomologateMedic } from '../../../../External/interfaces/subscriptionAdmin.interface';

interface SpecialtyRowProps {
  onDelete?: () => void;
  setPrice?: (price: number) => void;
  setSpecialtySelected?: (specialty: any) => void;
  especialidad?: IResponseHomologateMedic[];
  setEspecialidad?: IResponseHomologateMedic;
  onDataChange?: (data: { specialtyId: string, id: number }) => void;
  onAmountChange?: (data: { specialtyId: string, amount: number, id: number }) => void;
  id: number;
  type?: string;
  price?: number;
  isAdd?: boolean;
  editModal?: boolean;
}

function SpecialtyRow(props: SpecialtyRowProps): React.ReactElement {
  const {
    onDelete,
    setPrice,
    setSpecialtySelected,
    especialidad,
    setEspecialidad,
    onDataChange,
    onAmountChange,
    id,
    type,
    price,
    isAdd = false,
    editModal = false
  } = props;
  const [principalPrice, setPrincipalPrice] = useState<number | undefined>(price);
  const [principalSpecialty, setPrincipalSpecialty] = useState<string | undefined>(setEspecialidad?.homologation?.id);

  console.log('SpecialtyRow1', especialidad, principalPrice);
  console.log('SpecialtyRow2', setEspecialidad, setPrice);

  const handleDataChange = (value: any) => {
    setPrincipalSpecialty(value.homologation.id);
    if (!onDataChange) return;
    onDataChange({ specialtyId: value.homologation.id, id });
    setPrincipalPrice(undefined);
  };

  const handleAmountChange = (value: any) => {
    if (!onAmountChange) return;
    console.log('AAprincipalSpecialty',principalSpecialty);
    console.log('AAvalue',value);
    console.log('AAid',id);    
    onAmountChange({ specialtyId: principalSpecialty ?? '', amount: value, id });
  };

  return (
    <div className={`${type === 'backoffice' ? '' : 'flex items-end justify-between mt-5'}`}>
      <div className={`${type === 'backoffice' ? 'grid grid-cols-2 col-gap-2' : 'flex items-end w-full'}`}>
        {
          especialidad && especialidad?.length > 0 &&
          (
            <div>
              <Select
                customClass={type === 'backoffice' ? '' : styles.z0}
                customSize="l"
                items={especialidad}
                name="specialty"
                nameType="specialty"
                placeholder="Seleccione especialidad"
                title="Especialidad"
                value={setEspecialidad ?? ''}
                onChange={(value) => handleDataChange(value)}
              />
            </div>
          )
        }

        <div className={`${type === 'backoffice' ? styles.fullinput : 'w-full'} flex items-end justify-between`}>
          <div className={`${type === 'backoffice' ? '' : 'ml-5'}`}>
            <Input
              focused
              name="name"
              customSize="l"
              onChange={(e) => handleAmountChange(e.target.value)}
              onBlur={(e) => handleAmountChange(e.target.value)}
              placeholder="Ingresa monto consulta"
              title="Precio consulta particular"
              defaultValue={principalPrice ?? undefined}
              disabled={!principalSpecialty && !editModal}
              type="number"
            />
          </div>
          {isAdd ?
            ''
            // (specialties?.length > 1) &&
            // <Button
            //   className='ml-3'
            //   buttonType="button"
            //   type="secondary"
            //   disabled={AddAvailable}
            //   onClick={handleAgregarClick}>
            //   <PlusIcon />
            // </Button>
            :
            <Button className={`${type === 'backoffice' ? 'ml-3' : 'ml-5'}`} buttonType="button" type="secondary-two" onClick={onDelete}>
              {type === 'backoffice' ? 'Quitar' : 'Quitar especialidad'}
            </Button>}
        </div>
      </div>

    </div>
  );
}

export default SpecialtyRow;
