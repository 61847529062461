import { Professionals } from '../../../../interfaces/professional';
import { Services } from '../../../../interfaces/services';
import { Button, CheckMarkEmoji, ClockEmoji, UserHelp, FearfulFaceEmoji, WarningEmoji, LockEmoji } from '@imed_npm/design-system';
import { useState } from 'react';
import { activateService } from '../../../../utils/actions';
import { ServiceActivationResultError } from '../../Interfaces/responses';
import styles from './styles.module.scss';
import getEnv from '../../../../../../getEnv';
import { EServices } from '../../../../../../enum/services.enum';
import { Product } from '../../../../utils/interfaces';

interface OwnProps {
  professional: Professionals;
  service: Services;
  selectedProduct: Product | undefined;
  context: any;
  updateServices: (service: Services) => void;
  isAnyButtonLoading,
  setIsAnyButtonLoading
}

const isBlocked = (service_id, center_code, product) => {
  return service_id === EServices.LME && product.name.toLowerCase() === 'lme' && center_code <= parseInt(getEnv('REACT_APP_CENTER_CODE_LIMIT'), 10);
};

const ServiceItem = ({ professional, service, selectedProduct, context, updateServices, isAnyButtonLoading, setIsAnyButtonLoading }: OwnProps) => {
  const [activatingService, setActivatingService] = useState<boolean>();
  const [error, setError] = useState<ServiceActivationResultError>();

  const startActivation = () => {
    setError(undefined);

    setActivatingService(true);
    setIsAnyButtonLoading(true);

    activateService(context, { service_id: Number(service.professional_service_id), adscription_id: Number(professional.adscription_id) })
      .then(() => {
        updateServices({
          ...service,
          professional_service_status: 1
        });
      })
      .catch(({ response: { data } }) => setError(data))
      .finally(() => {
        setActivatingService(false);
        setIsAnyButtonLoading(false);
      });
  };

  const renderTextError = (err) => {
    if (typeof err === 'string') {
      return (<span>{String(err)}</span>);
    } else if (Array.isArray(err)) {
      return err.map(renderTextError);
    } else if (typeof err === 'object' && err !== null) {
      return err?.message ?? Object.values(err).map(renderTextError);
    } else {
      return null;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.service}>
        <div className={styles.icon}>
          {error && <WarningEmoji customHeight='48' customWidth='48' />}
          {!error && service.professional_service_status === 1 && <CheckMarkEmoji customHeight='48' customWidth='48' />}

          {!error && service.professional_service_status !== 1 && !isBlocked(service.service_id, professional.centers[0].code, selectedProduct) && <LockEmoji customHeight='48' customWidth='48' />}
          {!error && service.professional_service_status !== 1 && isBlocked(service.service_id, professional.centers[0].code, selectedProduct) && <WarningEmoji customHeight='48' customWidth='48' />}

        </div>
        <p className={[styles.text, error ? styles.error : ''].join(' ')}>
          {[service.service_name, service.professional_service_status === 1 ? 'Activado' : (error ? 'Error al activar' : '')].filter(Boolean).join(' - ')}
        </p>
        {service.professional_service_status !== 1 && !isBlocked(service.service_id, professional.centers[0].code, selectedProduct) && <Button
          buttonType='button'
          customClass={styles.button}
          size="s"
          type="secondary"
          loading={activatingService}
          disabled={isAnyButtonLoading}
          onClick={startActivation}
        >
          Activar
        </Button>}

      </div>
      {error && <div className={styles.error}>
        <UserHelp
          colorStyle="error"
          icon={<FearfulFaceEmoji customHeight='48' customWidth='48' />}
          text={renderTextError(error?.error)}
          title={error?.message}
        />
      </div>}
      {service.professional_service_status !== 1 && isBlocked(service.service_id, professional.centers[0].code, selectedProduct) && <div className={styles.error}>
        <UserHelp
          colorStyle="warning"
          icon={<WarningEmoji customHeight='48' customWidth='48' />}
          text={' Validar manualmente en LME. '}
          title={''}
        />
      </div>}
    </div>
  );
};

export default ServiceItem;
