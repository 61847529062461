import React, { useEffect, useState } from 'react';
import Header from '../../Header';
import Subtitle from '../Components/Subtitle';
import Footer from '../Components/Footer';
import styles from './style.module.scss';
import { UseStep } from '../Store/step-context';
import { FORM_STEPS, FORM_STEPS_BE, FORM_STEPS_EM } from '../constans';

interface OwnProps {
  headerTitle: string
  headerSubtitle: string
  subtitleText: string
  children?: React.ReactNode;
  actionNext?: () => void;
  actionPrevious?: () => void;
}

const LayoutForm = ({ headerTitle, headerSubtitle, subtitleText, children, actionNext, actionPrevious }: OwnProps) => {
  const { dispatch, state: { step, steps, form } } = UseStep();
  const [backDisable, setBackDisable] = useState(false);
  const [nextDisable, setNextDisable] = useState(false);

  useEffect(() => {
    const {
      pathname
    } = window.location;
    let type;
    const pathSegments = pathname.split('/'); // Divide la ruta en segmentos
    if (pathSegments[1] === 'formulario-em') {
      type = FORM_STEPS_EM;
    } else if (pathSegments[1] === 'formulario-be') {
      type = FORM_STEPS_BE;
    } else {
      type = FORM_STEPS;
    }

    const { backDisable, nextDisable } = type[step].checkNavigation(steps, form, dispatch);

    setBackDisable(backDisable);
    setNextDisable(nextDisable);
  }, [steps, step]);

  return (
    <div className={styles.layout}>
      <Header
        subtitle={headerSubtitle}
        title={headerTitle}
      />
      <Subtitle title={subtitleText} />
      <div className={styles.containerChildren}>
        {children}
      </div>
      {step != 'endScreen' && <Footer
        backText={'Anterior'}
        nextText={'Siguiente'}
        actionNext={actionNext}
        actionPrevious={actionPrevious}
        backDisable={backDisable}
        nextDisable={nextDisable} />}
    </div>);
};

export default LayoutForm;
