export const GET_DEC_LOGIN = '/dec/login';
export const GET_DEC_CHECK = '/dec/check';
export const GET_INFO = '/supersalud/getInfo';
export const GET_SPECIALTIES = '/supersalud/specialties/homologated';
export const VOUCHERS = '/vouchers';
export const CHECK_DEBT = `${VOUCHERS}/checkDebt`;
export const CHECK_PFP_DEBT = '/debt/:rut';

export const GET_PROFESSIONAL = '/professional';
export const STORE_PROFESSIONAL = '/professional';
export const PROSPECT = '/prospect';
export const STORE_CI = `${STORE_PROFESSIONAL}/storeCI`;


export const SEND_VERIFICATION_EMAIL = `${STORE_PROFESSIONAL}/sendVerificationCode`;
export const CHECK_VERIFICATION_CODE = `${STORE_PROFESSIONAL}/checkVerificationCode`;
export const STORE_ADSCRIPTION = `${STORE_PROFESSIONAL}/adscription`;
export const GET_PLANS = '/plans';
export const GET_LME_BRANCHES = '/lme/getBranches';
export const CREATE_CENTER = `${STORE_PROFESSIONAL}/centers`;
export const GET_REGIONS = '/regions';
export const GET_REGIONS_WITH_LOCALITIES = '/regions?withLocalities=1';
export const TRUST_SERVICE = '/trust';
export const TRUST_SEND_CODE = `${TRUST_SERVICE}/sendCode`;
export const TRUST_CHECK_CODE = `${TRUST_SERVICE}/checkCode`;
export const GOOGLE_PLACES_API = '/places';
export const VALIDATE_ADDRESS = `${GOOGLE_PLACES_API}/validate`;
export const STORE_CONTRACT = `${STORE_PROFESSIONAL}/contract`;
export const BILLING_INFO = '/billinginfo';
export const SEND_CONTRACT_EMAIL = `${STORE_PROFESSIONAL}/sendContractMail`;
export const PROSPECT_BACKOFFICE_STATUS = `${PROSPECT}/status/backoffice`;
export const REQUEST_INFO = '/requests';
export const GET_TOKEN = '/auth';


export const OPERATION_PATH = '/operations';
export const OPERATIONS_STATUSES = '/statuses';
export const GET_TABLES = `${OPERATIONS_STATUSES}/tables`;
export const STATUS_TRANSITIONS = `${OPERATIONS_STATUSES}/transitions`;
export const OPERATIONS_PRODUCTS = `${OPERATION_PATH}/products`;
export const OPERATIONS_SERVICES = `${OPERATION_PATH}/services`;
export const OPERATIONS_PROFESSIONALS = `${OPERATION_PATH}/professionals`;
export const GET_CI = `${STORE_PROFESSIONAL}/getCI`;
export const GET_DOCUMENTS = 'store/documents';
export const GET_CVP = `${OPERATION_PATH}/cvp`;
export const GET_PERIODICITY = `${OPERATION_PATH}/cvp/periodicity`;
export const OPERATIONS_PLANS = `${OPERATION_PATH}/plans`;
export const OPERATIONS_UF = `${OPERATION_PATH}/uf`;
export const OPERATIONS_PAYMENT_SUBSCRIBERS = `${OPERATION_PATH}/payments/subscribers`;
export const OPERATIONS_PLANS_CHANGES = `${OPERATIONS_PLANS}/change/history`;

export const ADSCRIPTIONS = '/adscriptions';
export const ADSCRIPTION_INIT = `${ADSCRIPTIONS}/init`;
export const ADSCRIPTION_BANK_INFO = `${ADSCRIPTIONS}/bank_info`;

export const BANKS = '/banks';
export const CVP = `${ADSCRIPTIONS}/cvp`;
export const CVP_OPERATIONS = `${OPERATION_PATH}/cvp`;
export const FILE_CVP_OPERATIONS = '/adscriptions/store/cvp';

export const DISCOUNT = '/discount';
export const DISCOUNT_USED = `${DISCOUNT}/used`;
export const DISCOUNT_VALIDATE = `${DISCOUNT}/validate`;

//editor suscripciones
export const ADSCRIPTIONS_PROFESSIONALS = `${ADSCRIPTIONS}/professionals`;
export const VERIFY_EM_TOKEN = '/auth/em';

//pagos - cobros
export const CHARGE_PAYMENT = `${OPERATION_PATH}/payments/voucher`;
export const PAYMENT_EXCLUSION = `${OPERATION_PATH}/payments/exclusions`;
export const VOUCHER_PERIOD = `${OPERATION_PATH}/payments/vouchers`;
export const VOUCHER_BATCH_RESULTS = `${OPERATION_PATH}/payments/periods/batches`;
export const PAYMENT_PENDING = `${OPERATION_PATH}/payments/pending`;
export const VOUCHER_PENDING = `${OPERATION_PATH}/payments/vouchers/pending/status/xls`;
export const ALL_VOUCHERS = '/vouchers/professionals';
export const VOUCHER_NID = `${ALL_VOUCHERS}/detail`;

//exclusion - profesional
export const PROFESSIONAL_EXCLUSION = '/professional/exclusion';

//retokenizacion
export const GET_RETOKENIZATION_INFO = '/cards/verify/';

// cold-data
export const COLDATA = '/cold-data';
