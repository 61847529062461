/* eslint-disable */
import React, {
  useState, useRef, useEffect
} from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Label } from '@imed_npm/design-system';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './index.scss';
import { CANCEL_TOKEN } from '../../../../utils/axios';
import { getAddressPredictions } from '../../Store/actions';
import { UseStep } from '../../Store/step-context';

interface OwnProps {
  label?: string;
  setAddress?: any;
  setPrediction?: any;
  defaultPrediction?: any;
  setClear?: any;
  onMenuToggleChange: (isOpen: boolean) => void;
  isOpen?: boolean;
  hasNid?: boolean;
  hideButton?: boolean;
}

const Typeahead = ({ label, setAddress, setPrediction, defaultPrediction = null, setClear, onMenuToggleChange, isOpen, hasNid, hideButton }: OwnProps) => {
  const { dispatch } = UseStep();
  const [IsLoadingSearch, setIsLoadingSearch] = useState(false);
  const [Options, setOptions] = useState(Array<any>);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [read, setReadOnly] = useState(false);

  const ref = useRef() as React.MutableRefObject<any>;
  const filterBy = () => true;

  useEffect(() => {
    if (isOpen) ref.current.blur();
  }, [isOpen]);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (hasNid) {
        setReadOnly(false);
      } else {
        setReadOnly(true)
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [hasNid]);

  const handleMenuToggle = (isOpen) => {
    setMenuOpen(isOpen);
    onMenuToggleChange(isOpen);
  };

  const handleSearch = (query) => {
    setIsLoadingSearch(true);
    setPrediction('');

    getAddressPredictions({ dispatch, payload: { input: query } })
      .then(predictions => {
        const { length } = predictions;
        if (!length) {
          setOptions([]);
          return;
        }

        const options = predictions.map((i, index) => ({
          id: index,
          description: i.description,
          name: i.structured_formatting.main_text,
          place_id: i.place_id,
        }));
        setOptions(options);

        return predictions;
      })
      .catch((error) => {
        setOptions([]);
      }).finally(() => setIsLoadingSearch(false));

    return () => {
      CANCEL_TOKEN.cancel();
    };
  };

  const handleChangeAhead = (selectedOptions) => {
    if (selectedOptions.length > 0) {
      const selectedOption = selectedOptions[0];
      if (setPrediction) {
        setPrediction(selectedOption);
      }
      if (setAddress) {
        setAddress(selectedOption.name);
      }
    } else {
      setOptions([]);
    }
  };

  const handleClear = (input) => {
    if (input === '') {
      setClear('Se ha presionado el botón de borrar');
    }
  }

  return (
    <div id="address-search">
      <Label title={label ?? 'Dirección'} />
      <div className="m-search flex justify-between items-end">
        <div className="grow">
          <AsyncTypeahead
            filterBy={filterBy}
            id="address"
            isLoading={IsLoadingSearch}
            labelKey="description"
            onMenuToggle={handleMenuToggle}
            minLength={3}
            onSearch={handleSearch}
            options={Options}
            placeholder="Ingresa Dirección"
            searchText="Buscando Dirección"
            emptyLabel="Búsqueda sin resultados"
            onChange={handleChangeAhead}
            onInputChange={handleClear}
            ref={ref}
            inputProps={{ required: true, autoComplete: 'off', list: 'autocompleteOff', readOnly: read }}
            className={isOpen ? 'disabled' : ''}
            useCache={false}
            clearButton={hideButton ? !hideButton : true}
            defaultSelected={defaultPrediction ? [defaultPrediction] : []}
          />
        </div>
      </div>
    </div>
  );
};

export default Typeahead;
