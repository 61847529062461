import { PDFDocument } from 'pdf-lib';
import { useEffect, useState } from 'react';
import PDFDataViewer from '../../../../Components/PdfViewer';
import { RequestInfo } from '../../utils/interfaces';
import { Checkbox, Button } from '@imed_npm/design-system';
import Modal from '../../../../Components/Modal';
import DocumentSigned from '../../../../Components/Form/Components/DealSignature/Components/DocumentsSignedCorrectly';
import SignContract from '../../../../Components/Form/Components/DealSignature/Components/SignContract';
import { CheckSmsCodeData, SendSmsCodeData } from '../../../../utils/interfaces';
import { checkSmsCode, sendSmsCode, storeContract } from '../../../../Components/Form/Store/actions';
import { useNavigate } from 'react-router-dom';
import styles from './style.module.scss';

interface OwnProps {
  info: RequestInfo;
  uuid: string | undefined;
}

const ContractSign = ({ info, uuid }: OwnProps) => {
  const [contractFilled, setContractFilled] = useState<string>();
  const [codeValidated, setCodeValidated] = useState<boolean>(false);
  const [contractStored, setContractStored] = useState<boolean>(false);
  const [checkingCode, setCheckingCode] = useState<boolean>(false);
  const [smsCode, setSmsCode] = useState<string>('');
  const [accepted, setAccepted] = useState<boolean>(false);
  const [oid, setOid] = useState<string>('');
  const navigate = useNavigate();

  const setupContract = () => {
    const { data: { contract, fullname, address, email, nid, phone }, active } = info;
    if (!active) return;

    PDFDocument.load(Uint8Array.from(atob(contract), c => c.charCodeAt(0))).then<string>(pdfDoc => {
      const form = pdfDoc.getForm();

      // Fill the form's fields
      form.getTextField('full_name').setText(fullname);
      form.getTextField('nid').setText(nid);
      form.getTextField('address').setText(address);
      form.getTextField('phone').setText(`+56 ${phone}`);
      form.getTextField('email').setText(email);

      // Flatten the form's fields
      form.flatten();

      return pdfDoc.saveAsBase64();
    }).then(filledContract => {
      setContractFilled(filledContract);
    });
  };

  const checkCode = () => {
    const data: CheckSmsCodeData = {
      code: smsCode,
      oid,
    };

    setCheckingCode(true);
    checkSmsCode({ dispatch: (...args) => args, payload: data }).then(setCodeValidated).finally(() => setCheckingCode(false));
  };

  const resendCode = () => {
    resetValidations();
    if (accepted) {
      const { data: { nid } } = info;
      const data: SendSmsCodeData = {
        nid,
      };
      sendSmsCode({ dispatch: (...args) => args, payload: data }).then(setOid);
    }
  };

  const resetValidations = () => {
    setOid('');
    setSmsCode('');
    setContractStored(false);
    setCodeValidated(false);
    setCheckingCode(false);
  };

  const codeStateMessage = (): { type: 'error' | 'success' | 'info' | 'warning' | 'default'; message: string; } => {
    if (smsCode) {
      if (codeValidated) return { type: 'success', message: 'Contrato firmado' };
      if (!checkingCode && codeValidated && !contractStored) return { type: 'error', message: 'Código incorrecto' };
    }
    return { type: 'default', message: '' };
  };

  const updateContract = () => {
    if (!codeValidated) return;
    const { data: { nid } } = info;
    storeContract({ dispatch: (...args) => args, payload: { nid, code: contractFilled, uuid } }).then(setContractStored);
  };

  useEffect(() => {
    const timer = setTimeout(setupContract, 250);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(updateContract, 250);

    return () => clearTimeout(timer);
  }, [codeValidated]);
  useEffect(() => {
    const timeout = setTimeout(resendCode, 500);
    return () => clearTimeout(timeout);
  }, [accepted]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Firma de contrato</div>
      <div className={styles.subtitle}>Contrato de adhesión</div>
      <div className={styles.contract}>
        {contractFilled &&
          <PDFDataViewer pdf={contractFilled} props={{ view: 'fitH', toolbar: 0, navpanes: 0 }} />
        }
      </div>
      <div className={styles.accpet}>
        <Checkbox
          id="checkbox"
          label="Acepto los términos y condiciones de este contrato"
          name="checkbox"
          disabled={contractStored}
          isChecked={accepted}
          onChange={() => setAccepted(!accepted)}
        />
      </div>
      <div className={styles.actions}>
        <Button buttonType='button' type="secondary" disabled={!contractStored} onClick={() => navigate('/')}>
          Finalizar
        </Button>
      </div>
      <Modal showModal={contractStored} >
        <DocumentSigned type="lme" />
      </Modal>
      <Modal showModal={accepted && !contractStored} containerClass='w-1/2' showCloseButton={true}>
        <SignContract
          setSmsCode={setSmsCode}
          phone={info.data.phone}
          checkSmsCode={checkCode}
          sendCode={resendCode}
          canResend={!contractStored}
          checking={checkingCode}
          codeStateMessage={codeStateMessage} />
      </Modal>
    </div>
  );
};

export default ContractSign;
