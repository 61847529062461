import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Skeleton, Select } from '@imed_npm/design-system';
import styles from './style.module.scss';
import { UseStep } from '../../Store/step-context';
import { getPlansArray, storeCenter, discountValidate } from '../../Store/actions';
import { Plan } from '../../../../utils/interfaces';
import standar from '../../../../assets/plan-standar.svg';
import premium from '../../../../assets/plan-premium.svg';

const EmPlans = () => {

  const params = useParams();

  const { dispatch, state: { adscription_id, form, product, steps: { success, step2: { nid }, step4, step4: { center, storedCenterInfo }, step5, step51,
    step5: { readersSelectOptions, readers, plan, validations } } } } = UseStep();
  const [readersQuantity, setReadersQuantity] = useState<number>(readers);

  const [plans, setPlans] = useState<Plan[]>([]);
  const [code, setCode] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState({ code: 'PC0' });

  const handleRadioSelect = (value) => {
    setSelectedOption(value);
  };

  useEffect(() => {
    if (step51) {
      const { plan_Selected } = step51;
      const clear = {
        id: 0,
        name: '',
        description: '',
        price: 0,
        transactions: 0,
        type: 0,
        status: 0,
        created_at: '',
        updated_at: '',
        image: '',
        transaction_cost: 0,
        code: '',
        contract_template: '',
        mail_template: '',
        commercialized: 0,
        items: '',
        product: '',
        feature_plan: [],
        discount_price: 0,
      };
      if (plan_Selected.code !== '') {
        dispatch({ type: 'STORE_PLAN_SELECTED', payload: clear });
      }
    }
  }, []);

  const nextStep = (type) => {
    dispatch({ type: 'SET_PERSON_PLAN_TYPE', payload: { personPlanType: type } });
    dispatch({ type: 'STORE_PLAN_SELECTED', payload: { type } });
  };

  // Store Center
  useEffect(() => {
    const timeout = setTimeout(() => {
      storeCenter({ dispatch, payload: { center, nid, product, adscription_id }, newShipping: true });
    }, 250);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    dispatch({ type: 'RESET_STEP', payload: { step: 'step6' } });
  }, []);

  const compareByOrder = (a, b) => {
    const orderA = parseInt(a.features.order);
    const orderB = parseInt(b.features.order);

    if (orderA < orderB) {
      return -1;
    }
    if (orderA > orderB) {
      return 1;
    }
    return 0;
  };

  // useEffect(() => {
  //   if (step51 && step51.plans.length > 0) {
  //     setPlans(step51.plans);
  //   } else {
  //     getPlansArray({ payload: { product: 'EM' } }).then((e) => {
  //       dispatch({ type: 'SET_TYPE_PLAN', payload: e });
  //     });
  //   }
  // }, [step51]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!step51) {
        if (params && params.code && params.code.length > 3) {
          const { code } = params;
          if (!code) return;
          setCode(code);
        }
        getPlansArray({ payload: { product: 'EM', discount: params && params.code && params.code.length > 3 ? params.code : null } }).then((e) => {
          dispatch({ type: 'SET_TYPE_PLAN', payload: e });
        });
      }
    }, 250);
    return () => clearTimeout(timeout);
  }, [step51]);

  useEffect(() => {
    if (readersQuantity > 0) {
      dispatch({ type: 'STORE_READERS', payload: { readers: readersQuantity } });
    }
  }, [readersQuantity]);

  useEffect(() => {
    if (params && params.code) {
      const { code } = params;
      if (!code) return;
      setCode(code);
    }
  }, []);

  // useEffect(() => {
  //   if (!params) return;
  //   validate();
  // }, [code]);

  useEffect(() => {
    // si ya se ha guardado planes
    if (step51 && step51.plans.length > 0) {
      setPlans(step51.plans);
    } else {
      if (params && params.code) {
        const timeout = setTimeout(() => {
          dispatch({ type: 'STORE_PROMOCIONAL_CODE', payload: { discount: params.code } });
          getPlansArray({ payload: { product: 'EM', discount: params.code } }).then((e) => {
            dispatch({ type: 'SET_TYPE_PLAN', payload: e });
          });
        }, 600);
        return () => clearTimeout(timeout);
      } else {
        // Flujo normal
        const timeout = setTimeout(() => {
          getPlansArray({ payload: { product: 'EM' } }).then((e) => {
            dispatch({ type: 'SET_TYPE_PLAN', payload: e });
          });
        }, 600);
        return () => clearTimeout(timeout);

      }
    }
  }, [code, step51]);

  useEffect(() => {
    if (!params) return;
    if (!code) return;
    if (step51 && step51.plans.length > 0) return;

    if (plans.length > 0) {
      plans.forEach(item => {
        discountValidate(dispatch, code, 'EM', item.id);
      });
    }
  }, [plans, code]);

  const RadioButtonDiv = ({ value, label, selected, onSelect }: any) => {
    const handleClick = () => {
      onSelect(value);
      nextStep(value);
    };

    return (
      <div
        className={`mb-5 ${value.code === 'PC71' ? styles.borderPremium : styles.borderStandar}`}
      ><div className={`flex ${styles.whiteCard}`}>
          {/* plan name and price */}
          <div className={`py-3 px-5 pr-2 flex flex-col justify-center w-2/5 border-inherit ${styles.bgCustom}`}>
            <div className='flex-grow w-3/4 mx-auto flex flex-col justify-center'>
              <h3 className={`${styles.subtitle} ${value.discount_applied ? 'text-center' : 'text-left'}`}>Plan</h3>
              <h1 className={`${styles.price} ${value.code}`}>{label || '-'}</h1>
              {
                (value.discount_applied) && <p className={styles.priceBefore}><small>Valor normal:</small><br />$<span>{value.price}</span>UF+IVA / mes</p>
              }
              {
                (value.discount_applied) ? <div className="w-full flex justify-center mt-4">
                  <div className={`${styles.cupon} flex items-center justify-between text-center `}>
                    <div className='flex justify-center grow'>
                      <span className='pl-2'>Ahora</span>
                    </div>
                    <div className={`${styles.cuponWhite} px-2 flex items-center`}>$<span className={styles.priceCupon}>{value.discount_price}</span> &nbsp;<small className='fs-xs font-normal'>UF+IVA / mes</small></div>

                  </div>
                </div> : <div className='mt-5 flex flex-col align-center'>
                  <p className={`${styles.featuresTitle} mb-2`}>Valor plan</p>
                  <div className={`${value.code === 'PC71' ? styles.borderPremium : styles.borderStandar} ${styles.square} py-3 px-4 text-center w-full`}>
                    <h2 className={`${styles.priceNormal}`}><span>$</span>{(value.discount_applied ? value.discount_price : value.price) || '-'} <small>UF+IVA / Mes</small></h2>
                  </div>
                </div>
              }

              {
                value.discount_applied && <p className='mt-5'>
                  <span className={styles.smallText}>*<strong>Por 24 meses</strong>, Luego ${value.price} UF+IVA / Mes</span>
                </p>
              }

              <button onClick={handleClick} className={`${styles.buttonWant} py-2 mb-4 mt-6 w-full rounded text-white font-bold`}>¡Lo quiero!</button>
            </div>
          </div>
          {/* features */}
          <div className='flex flex-col'>
            <h1 className={`${styles.featuresTitle} pb-3 pt-4`}>Características del plan {label || '-'}:</h1>
            <div className={`p-4 grid grid-cols-2 gap-2 ${styles.whiteCardBottom}`}>
              {value.feature_plan.sort(compareByOrder).map((lst, index) => (
                <div className='flex mb-2 items-center' key={index}>
                  <img
                    src={value.code === 'PC71' ? premium : standar}
                    className={`mr-3 ${styles.icon}`}
                  />
                  <small>{lst.features.name}</small>
                </div>
              ))}
            </div>
            <hr className={styles.separator} />
            <p className={`${styles.additional} px-3 py-6`}>*Además, con cada plan, te proporcionamos un lector de huellas dactilares.</p>
          </div>
          {/* lectores */}
          <div className={`flex flex-col items-center justify-center w-56 px-4 ${styles.planes} ${selectedOption === value ? styles.active : styles.disabled}`}>

            <h1 className={styles.selectTitle}>Ingresa el Nº de Planes</h1>
            <div className={`reader-select w-70[px] mt-5 pb-10 ${styles.whiteCardBottom}`}>
              <Select
                customSize="xs"
                items={readersSelectOptions}
                name="readers"
                nameType="name"
                onChange={(value) => setReadersQuantity(value)}
                value={readersQuantity}
                disabled={form.personContractType === 'natural'}
              />
            </div>
            <hr className={styles.separator2} />
            <span className={styles.selectTitle}>Monto fijo a pagar</span>
            <span className={`${styles.priceFinal} ${value.code}`}>{(value.discount_applied ? (readers * value.discount_price) : (readers * value.price)).toFixed(1)} <small>UF + IVA / mes</small></span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='p-6 flex flex-col'>
        {
          plans.length > 0 ? <>
            {plans.map((item) => (
              <RadioButtonDiv
                key={item.code}
                value={item}
                label={item.name}
                selected={selectedOption}
                onSelect={handleRadioSelect}
              />
            ))}</> : <Skeleton
            borderColor="default"
            customClass=""
            customSize="m"
          />
        }
      </div>
    </>
  );
};

export default EmPlans;
