import { useContext, useEffect, useState } from 'react';
import ProfileSelection from '../Components/ProfileSelection';
import ProfessionalInfo from '../Components/ProfessionalInfo';
import CompanyInfo from '../Components/CompanyInfo';
import ContactInformation from '../Components/ContactInformation';
import CenterInfo from '../Components/CenterInfo';
import BankInfo from '../Components/BankInfo';
import BePlans from '../Components/BePlans';
import CVPbe from '../Components/CVPbe';
import DealSignature from '../Components/DealSignature';
import Payment from '../Components/Payment';
import LayoutForm from '../LayoutForm';
import { initialState, UseStep } from '../Store/step-context';
import { FORM_STEPS_BE } from '../constans';
import ToastMessage from '../../../utils/ToastMessage';
import EndScreen from '../Components/EndScreen';

import { getToken, initAdscription } from '../Store/actions';
import PaymentMP from '../Components/Payment/components/indexMp';



const BEFormContainer = () => {

  const { state, state: { step, steps: { error } }, dispatch } = UseStep();
  const [stepSelected, setStepSelected] = useState<string>(initialState.step);

  const handleSetNextStep = () => {
    if (stepSelected === 'step1') {
      const { form } = state;
      setStepSelected('step2');
    }
    else {
      setStepSelected(FORM_STEPS_BE[stepSelected].nextStep);
    }
  };


  useEffect(() => {
    if (step != stepSelected) {
      setStepSelected(step);
    }
  }, [step]);

  const handleSetPreviousStep = () => {
    const { previousStep } = FORM_STEPS_BE[stepSelected];
    if (previousStep === 'step2') {
      const { form } = state;
      return setStepSelected('step2');
    }
    setStepSelected(previousStep);
  };

  useEffect(() => {
    if (stepSelected != step)
      dispatch({ type: 'UPDATE_STEP', payload: { step: stepSelected } });
  }, [stepSelected]);

  useEffect(() => {
    setStepSelected(step);
  }, [step]);

  useEffect(() => {
    const { message } = error;
    const type = typeof message;

    if (type == 'string') {
      ToastMessage.enviarToastError(message);
    } else {
      ToastMessage.enviarToastError((
        <>
          <ul>
            {message.map(msg => (<li key={msg}>{msg}</li>))}
          </ul>
        </>
      ));
    }
  }, [error]);



  useEffect(() => {

    const timer = setTimeout(() => {
      getToken(dispatch);
      dispatch({ type: 'SET_PRODUCT', payload: { product: 'BE' } });
    }, 250);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <LayoutForm
        headerTitle={FORM_STEPS_BE[stepSelected].headerTitle}
        headerSubtitle={FORM_STEPS_BE[stepSelected].headerSubtitle}
        subtitleText={FORM_STEPS_BE[stepSelected].subtitleText}
        actionNext={handleSetNextStep}
        actionPrevious={handleSetPreviousStep}
      >
        {
          stepSelected === 'step1' && <CompanyInfo type="BE" />
        }
        {
          stepSelected === 'step2' && <ContactInformation  type="BE" />
        }
        {
          stepSelected === 'step3' && <CenterInfo type="BE" />
        }
        {
          stepSelected === 'step4' && <BePlans type="BE" />
        }
        {
          stepSelected === 'step5' && <CVPbe />
        }
        {
          stepSelected === 'step6' && <BankInfo />
        }
        {
          stepSelected === 'step7' && <DealSignature type="BE" />
        }
        {
          stepSelected === 'endScreen' && <EndScreen />
        }
      </LayoutForm>
    </>

  );
};


export default BEFormContainer;
