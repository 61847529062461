import React, { useEffect } from 'react';
import styles from './style.module.scss';
import icon from './assets/icon.svg';

interface OwnProps {
  text?: string;
}

const SuccessModal = ({ text }: OwnProps) => {

  return (
    <div className={styles.customer_debts}>
      <img src={icon} alt={'imagen'} />
      <p className={styles.title}>
        {text || 'El proceso ha terminado correctamente'}
      </p>
       <p>
        Ya puedes cerrar la ventana
      </p>
    </div>
  );
};


export default SuccessModal;
