import { AXIOS_SECURE } from '../../../utils/axios';
import { OPERATION_PATH, GET_CVP, CVP_OPERATIONS,FILE_CVP_OPERATIONS, GET_CI, GET_DOCUMENTS, OPERATIONS_PRODUCTS, OPERATIONS_PROFESSIONALS, OPERATIONS_SERVICES, OPERATIONS_STATUSES, GET_TABLES, STATUS_TRANSITIONS, PROSPECT, GET_PERIODICITY, OPERATIONS_PLANS, OPERATIONS_PLANS_CHANGES, OPERATIONS_UF, OPERATIONS_PAYMENT_SUBSCRIBERS, CHARGE_PAYMENT, PAYMENT_EXCLUSION, VOUCHER_PERIOD, PAYMENT_PENDING, VOUCHER_PENDING, ALL_VOUCHERS, VOUCHER_NID, VOUCHER_BATCH_RESULTS, PROFESSIONAL_EXCLUSION, VOUCHERS, DISCOUNT, COLDATA } from '../../../utils/endpoints';
import { ServiceActivationResult } from '../Components/ServiceActivation/Interfaces/responses';
import { Professionals, Plans } from '../interfaces/professional';
import { Status, Periodicity } from '../interfaces/status';
import { ActivateServiceData, GetCIResponse, GetDocumentsResponse, EditDocumentsResponse, GetPlansOptions, GetProductsOptions, GetProfessionalsOptions, GroupedPeriodItem, ISendProspectData, Persons, Product, ProfessionalStatusHistory, UpdateStatusData, UpdateStatusResult } from './interfaces';
import { objToQueryParams } from './utils';
import ToastMessage from '../../../utils/ToastMessage';


export const chargePendingPayment = async (context, _data: any): Promise<any> => {
  try {
    const url = `${CHARGE_PAYMENT}`;
    const { data } = await AXIOS_SECURE(context).put<any>(url, {
      voucher_id: _data.voucher_id,
      batch_id: _data.batch_id,
    });
    return data;
  } catch (err: any) {
    throw new Error(`Error: ${err.message}`);
  }
};

export const chargePayment = async (context, _data: any): Promise<any> => {
  try {
    const url = `${CHARGE_PAYMENT}`;
    const { data } = await AXIOS_SECURE(context).post<any>(url, {
      client_id: _data.client_id,
      client_type_id: _data.client_type_id,
      uf: _data.uf,
      adscription_id: _data.adscription_id,
      batch_id: _data.batch_id,
    });
    return data;
  } catch (err: any) {
    throw new Error(`Error: ${err.message}`);
  }
};

export const deletePersonExclusion = async (context, persons: Persons[], period: string, reason: string): Promise<any> => {
  try {
    const url = `${PAYMENT_EXCLUSION}`;
    const { data } = await AXIOS_SECURE(context).delete<any>(url, {
      persons,
      period,
      reason
    });
    return data;
  } catch (err: any) {
    throw new Error(`Error: ${err.message}`);
  }
};

export const excludePerson = async (context, persons: Persons[], period: string, reason: string): Promise<any> => {
  try {
    const url = `${PAYMENT_EXCLUSION}`;
    const { data } = await AXIOS_SECURE(context).post<any>(url, {
      persons,
      period,
      reason,
    });
    return data;
  } catch (err: any) {
    throw new Error(`Error: ${err.message}`);
  }
};


export const getPendingPayments = async (context): Promise<GroupedPeriodItem[]> => {
  const url = `${PAYMENT_PENDING}`;
  const { data } = await AXIOS_SECURE(context).get<GroupedPeriodItem[]>(url);
  return data;
};

export const getVouchersPeriod = async (context, period: string): Promise<Periodicity[]> => {
  const url = `${VOUCHER_PERIOD}`;
  const { data } = await AXIOS_SECURE(context).get<Periodicity[]>(url + '/' + period);
  return data;
};

export const getSuccessfulVouchersPeriod = async (context, period: string): Promise<Periodicity[]> => {
  const url = `${VOUCHER_PERIOD}`;
  const { data } = await AXIOS_SECURE(context).get<Periodicity[]>(url + '/success/' + period);
  return data;
};

export const getPendingVouchersDownload = async (context, voucherIds: number[]): Promise<any> => {
  const url = `${VOUCHER_PENDING}`;
  const { data } = await AXIOS_SECURE(context).post<any>(url, { voucherIds });
  return data.file;
};

export const getVoucherClients = async (context): Promise<any> => {
  const url = `${ALL_VOUCHERS}`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);
  return data;
};

export const getVoucherClientNid = async (context, nid?: number[]): Promise<any> => {
  const url = `${VOUCHER_NID}`;
  const { data } = await AXIOS_SECURE(context).get<any>(url + '/' + nid);
  return data;
};

export const getVouchersDownload = async (context, batch_id: string, uf: number): Promise<any> => {
  //const url = `${VOUCHER_PERIOD}/status/${period}/${uf}/xls`;
  const url = `${VOUCHER_BATCH_RESULTS}/${batch_id}/${uf}/xls`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);
  return data.file;
};

export const getVouchersResult = async (context, batch_id: string, uf: number): Promise<any> => {
  //const url = `${VOUCHER_PERIOD}/status/${period}/${uf}`;
  const url = `${VOUCHER_BATCH_RESULTS}/${batch_id}/${uf}`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);
  return data;
};

export const getVouchersByDate = async (context, startDate: string, endDate: string): Promise<any> => {
  const url = `${VOUCHER_PERIOD}/date/${startDate}/${endDate}`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);
  return data;
};

export const getDownloadVouchersByDate = async (context, startDate: string, endDate: string): Promise<any> => {
  const url = `${VOUCHER_PERIOD}/date/${startDate}/${endDate}/xls`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);
  return data.file;
};


export const getDownloadVouchersPeriod = async (context, period: string): Promise<any> => {
  const url = `${VOUCHER_PERIOD}/${period}/xls`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);
  return data.file;
};

export const putVoucher = async (context, voucherID, _data: any): Promise<any> => {
  const url = `${VOUCHERS}/${voucherID}`;
  const { data } = await AXIOS_SECURE(context).put<any>(url, _data);
  return data;
};

export const getPeriodicity = async (context?): Promise<Periodicity[]> => {
  const url = `${GET_PERIODICITY}`;
  const { data } = await AXIOS_SECURE(context).get<Periodicity[]>(url);
  return data;
};

export const getStatuses = async (context?, table?: string, backoffice?: boolean): Promise<Status[]> => {
  const url = `${OPERATIONS_STATUSES}?table=${table}${backoffice ? '&backoffice=true' : ''}`;
  const { data } = await AXIOS_SECURE(context).get<Status[]>(url);
  // console.log('data',data);
  return data;
};

export const getTables = async (context): Promise<any> => {
  const url = `${GET_TABLES}`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);
  return data;
};

export const CreateStatus = async (context, _data: any): Promise<any> => {
  const url = `${OPERATIONS_STATUSES}`;
  const { data } = await AXIOS_SECURE(context).post<any>(url, _data);
  return data;
};

export const UpdateStatusInfo = async (context, _data: any): Promise<any> => {
  const url = `${OPERATIONS_STATUSES}`;
  const { data } = await AXIOS_SECURE(context).put<any>(url, _data);
  return data;
};

export const StatusTransition = async (context, _data: any): Promise<any> => {
  const url = `${STATUS_TRANSITIONS}`;
  const { data } = await AXIOS_SECURE(context).put<any>(url, _data);
  return data;
};

export const DeleteStatusInfo = async (context, _data: any): Promise<any> => {
  const url = `${OPERATIONS_STATUSES}`;
  const { data } = await AXIOS_SECURE(context).delete<any>(url, _data);
  return data;
};

export const getCvp = async (context?, adscriptionId?: number): Promise<{ cvp_file: string }> => {
  try {
    const url = `${GET_CVP}`;
    const { data } = await AXIOS_SECURE(context).get<{ cvp_file: string }>(url + '/' + adscriptionId);
    return data;
  } catch (err: any) {
    throw new Error(`Error: ${err.message}`);
  }
};

export const getCI = async (context?, nid?: string): Promise<GetCIResponse> => {
  try {
    const url = `${GET_CI}`;
    const { data } = await AXIOS_SECURE(context).post<GetCIResponse>(url, { nid });
    return data;
  } catch (err: any) {
    throw new Error(`Error: ${err.message}`);
  }
};

export const getDocuments = async (context?, adscription_id?: number): Promise<GetDocumentsResponse> => {
  try {
    const url = `${GET_DOCUMENTS}`;
    const { data } = await AXIOS_SECURE(context).post<GetDocumentsResponse>(url, { adscription_id });
    return data;
  } catch (err: any) {
    throw new Error(`Error: ${err.message}`);
  }
};

export const editDocuments =  async (context?, adscription_id?: number, type?: number, file_type?: string, file_code?: string): Promise<EditDocumentsResponse> => {
  try {
    const url = `${GET_DOCUMENTS}`;
    const { data } = await AXIOS_SECURE(context).put<EditDocumentsResponse>(url, { adscription_id, type, file_type, file_code });
    return data;
  } catch (err: any) {
    throw new Error(`Error: ${err.message}`);
  }
};

export const getProducts = async (context?, options?: GetProductsOptions): Promise<Product[]> => {
  const query = objToQueryParams(options);
  const url = query === null ? OPERATIONS_PRODUCTS : `${OPERATIONS_PRODUCTS}?${query}`;
  const { data } = await AXIOS_SECURE(context).get<Product[]>(url);
  return data;
};

export const getProfessionals = async (context, options: GetProfessionalsOptions): Promise<Professionals[]> => {
  const query = objToQueryParams(options);
  const url = `${OPERATIONS_PROFESSIONALS}?${query}`;
  const { data } = await AXIOS_SECURE(context).get<Professionals[]>(url);
  return data;
};

export const getProfessionalsNew = async (context, options: GetProfessionalsOptions): Promise<Professionals[]> => {
  const query = objToQueryParams(options);
  const url = `${OPERATIONS_PROFESSIONALS}/new?${query}`;
  const { data } = await AXIOS_SECURE(context).get<Professionals[]>(url);
  return data;
};

export const getAdminPlans = async (context, options: GetPlansOptions): Promise<Plans[]> => {
  const query = objToQueryParams(options);
  const url = `${OPERATIONS_PLANS}/more?${query}`;
  const { data } = await AXIOS_SECURE(context).get<Plans[]>(url);
  return data;
};

export const getAdminUF = async (context, _data: any): Promise<any> => {
  const url = `${OPERATIONS_UF}/${_data}`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);
  return data;
};

export const getAdminUFrun = async (context): Promise<any> => {
  const url = `${OPERATIONS_UF}/run`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);
  return data;
};

export const getAdminPaymentSubs = async (context, _data?: any): Promise<any> => {
  const url = `${OPERATIONS_PAYMENT_SUBSCRIBERS}/enabled${_data ? '?nid=' + _data : ''}`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);
  return data;
};

export const getAdminDownloadPaymentSubs = async (context, _data?: any): Promise<any> => {
  const url = `${OPERATIONS_PAYMENT_SUBSCRIBERS}/enabled/xls${_data ? '?nid=' + _data : ''}`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);
  console.log('data', data);
  return data.file;
};

export const patchFileTemplate = async (context, _data: any, template: string): Promise<any> => {
  try {
    const url = `${OPERATION_PATH}/${_data}/contract`;
    const { data } = await AXIOS_SECURE(context).patch<any>(url, { contract_template: template });
    ToastMessage.enviarToastSuccess('Contrato actualizado correctamente');
    return data;
  } catch (err: any) {
    ToastMessage.enviarToastError(`Error al subir archivo: ${err.message}`);
  }
};

export const patchEmailTemplate = async (context, _data: any, template: string): Promise<any> => {
  try {
    const url = `${OPERATION_PATH}/${_data}/email_template`;
    const { data } = await AXIOS_SECURE(context).patch<any>(url, { email_template: template });
    ToastMessage.enviarToastSuccess('Template de Email actualizado correctamente');
    return data;
  } catch (err: any) {
    ToastMessage.enviarToastError(`Error al actualizar template: ${err.message}`);
  }
};

export const getAudit = async (context, _data: any): Promise<any> => {
  const url = `${OPERATION_PATH}/${_data}/audit`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);
  return data;
};

export const enterCvp = async (context, _data: any): Promise<any> => {
  const { data } = await AXIOS_SECURE(context).post<any>(CVP_OPERATIONS, _data);
  return data;
};
export const storeCVPAdmin = async (context, _data: any): Promise<any> => {
  const { data } = await AXIOS_SECURE(context).post<any>(FILE_CVP_OPERATIONS, _data);
  return data;
};

export const activateService = async (context, _data: ActivateServiceData): Promise<ServiceActivationResult> => {
  const { data } = await AXIOS_SECURE(context).post<ServiceActivationResult>(OPERATIONS_SERVICES, _data);

  return data;
};

export const sendProspect = async (context, _data: ISendProspectData): Promise<ServiceActivationResult> => {
  const { data } = await AXIOS_SECURE(context).post<ServiceActivationResult>(PROSPECT, _data);

  return data;
};

export const updateStatus = async (context, _data: UpdateStatusData): Promise<UpdateStatusResult> => {
  try {
    const { adscriptionId } = _data;
    const url = `${OPERATIONS_PROFESSIONALS}/${adscriptionId}/status`;
    const { data } = await AXIOS_SECURE(context).put<UpdateStatusResult>(url, _data);
    return data;
  } catch (err: any) {
    throw new Error(`Error: ${err.message}`);
  }
};

export const getProfessionalStatusHistory = async (context, adscriptionId: number): Promise<ProfessionalStatusHistory[]> => {
  const url = `${OPERATIONS_PROFESSIONALS}/${adscriptionId}/status`;
  const { data } = await AXIOS_SECURE(context).get<ProfessionalStatusHistory[]>(url);

  return data;
};

export const excludeProfessional = async (context, nid: string): Promise<any> => {
  try {
    const url = `${PROFESSIONAL_EXCLUSION}`;
    const { data } = await AXIOS_SECURE(context).post<any>(url, { nid });
    return data;
  } catch (err: any) {
    const { response: { data: { error: { response } } } } = err;
    return response;
    // throw new Error(`Error: ${err.message}`);
  }
};

export const getProfessionalsExcluded = async (context): Promise<any> => {
  try {
    const url = `${PROFESSIONAL_EXCLUSION}`;
    const { data } = await AXIOS_SECURE(context).get<any>(url);
    return data;
  } catch (err: any) {
    throw new Error(`Error: ${err.message}`);
  }
};

export const excludeProfessionalDisable = async (context, nid: string, active: boolean): Promise<any> => {
  try {
    const url = `${PROFESSIONAL_EXCLUSION}`;
    const { data } = await AXIOS_SECURE(context).put<any>(url, { nid, active });
    return data;
  } catch (err: any) {
    throw new Error(`Error: ${err.message}`);
  }
};

export const getAllDiscounts = async (context, productId): Promise<any> => {
  const url = `${DISCOUNT}/product?productId=${productId}`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);
  return data;
};

export const postDiscount = async (context, _data: any): Promise<any> => {
  try {
    const url = `${DISCOUNT}`;
    const { data } = await AXIOS_SECURE(context).post<any>(url, _data);
    return data;
  } catch (err: any) {
    ToastMessage.enviarToastError('Error al crear campaña');
    throw new Error(`Error: ${err.message}`);
  }
};

export const editDiscount = async (context, _data: any): Promise<any> => {
  try {
    const url = `${DISCOUNT}`;
    const { data } = await AXIOS_SECURE(context).put<any>(url, _data);
    return data;
  } catch (err: any) {
    ToastMessage.enviarToastError('Error al editar campaña');
    throw new Error(`Error: ${err.message}`);
  }
};

export const changeAdscriptionPlan = async (context, _data: any): Promise<any> => {
  try {
    const url = `${OPERATIONS_PLANS}/change`;
    const { data } = await AXIOS_SECURE(context).post<any>(url, _data);
    return data;
  } catch (err: any) {
    ToastMessage.enviarToastError('Error al modificar plan');
    throw new Error(`Error: ${err.message}`);
  }
};

export const getProfessionalPlanHistory = async (context, adscriptionId: number): Promise<any[]> => {
  const url = `${OPERATIONS_PLANS_CHANGES}/${adscriptionId}`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);

  return data.data;
};

export const getColdData = async (context): Promise<any> => {
  const url = `${COLDATA}`;
  const { data } = await AXIOS_SECURE(context).get<any>(url);
  return data;
};
