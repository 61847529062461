import React, { useEffect, useState } from 'react';
import { RadioButton } from '@imed_npm/design-system';
import { UseStep } from '../../Store/step-context';
import { RADIO_OPTION } from './constants';

const ProfileSelection = () => {
  const { state: { form }, dispatch } = UseStep();
  const [value, setValue] = useState(form.personContractType);

  const handleSelectOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SET_PERSON_CONTRACT_TYPE', payload: { personContractType: e.target.value } });
    setValue(e.target.value);
  };

  useEffect(() => {
    dispatch({ type: 'SET_PERSON_CONTRACT_TYPE', payload: { personContractType: value ?? RADIO_OPTION.nature.value } });
    dispatch({ type: 'RESET_STEP', payload: { step: 'step2' } });
    dispatch({ type: 'RESET_STEP', payload: { step: 'step22' } });
  }, [value, dispatch]);

  return (
    <div className="flex">
      <RadioButton
        id={RADIO_OPTION.nature.id}
        checked={value === RADIO_OPTION.nature.value}
        name={RADIO_OPTION.nature.name}
        label={RADIO_OPTION.nature.label}
        className="mr-8"
        value={RADIO_OPTION.nature.value}
        onChange={handleSelectOption}
      />
      <RadioButton
        id={RADIO_OPTION.legal.id}
        checked={value === RADIO_OPTION.legal.value}
        name={RADIO_OPTION.legal.name}
        label={RADIO_OPTION.legal.label}
        value={RADIO_OPTION.legal.value}
        onChange={handleSelectOption}
      />
    </div>
  );
};

export default ProfileSelection;
