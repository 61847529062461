import React from 'react';
import styles from './style.module.scss';
import { CheckCircleIcon } from '@imed_npm/design-system';
import icon from '../../../../../../assets/plan-standar.svg';

interface OwnProps {
  frontCode: string,
  backCode: string,
  handleRemoveBase64: (event) => Promise<void>,
}
const PreviewAttach = ({ backCode, frontCode, handleRemoveBase64 }: OwnProps) => {

  const renderPreviewFile = () => {

    return (
      <>
        <div className={styles.container}>
          <img src={frontCode} alt={'Front JPG'} />
          <div>
            <span className={styles.file_name}>CI Frontal.jpg</span>
            <img src={icon} />
          </div>
          <a onClick={handleRemoveBase64} data-name={'frontCode'} href={'#'} className={styles.remove_file}>Quitar imagen</a>
        </div>
        <div className={styles.container}>
          <img src={backCode} alt={'Back JPG'} />
          <div>
            <span className={styles.file_name}>CI Trasera.jpg</span>
            <img src={icon} />
          </div>
          <a onClick={handleRemoveBase64} data-name={'backCode'} href={'#'} className={styles.remove_file}>Quitar imagen</a>
        </div>
      </>
    );
  };

  return (
    <div className={styles.preview_attach}>
      {renderPreviewFile()}
    </div>
  );
};


export default PreviewAttach;
