import React, { useState, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@imed_npm/design-system';
import style from './style.module.scss';

interface AccordionItem {
  title: string;
  content: any;
}

interface AccordionProps {
  items: AccordionItem[];
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(0);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    if (items && items.length === 1) {
      setOpenIndex(0);
    } else {
      setOpenIndex(null);
    }
  }, [items]);

  return (
    <div>
      {items.map((item, index) => (
        <div key={index} className="mb-4">
          <div
            className={`${style.background} p-5 cursor-pointer flex items-center justify-between`}
            onClick={() => handleToggle(index)}
          >
            <h2 className="text-lg font-semibold">{item.title}</h2>
            {
              (openIndex === index) ? <ChevronUpIcon /> : <ChevronDownIcon />
            }
          </div>
          {openIndex === index && (
            <div className="px-4 bg-white border">
              {item.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;

