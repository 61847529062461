import React, { useState, useEffect, useContext, ChangeEvent } from 'react';
import { Input, Button, Switch, TextArea } from '@imed_npm/design-system';
import LoggedUserContext from '../../../../context/LoggedUserContext';

import { CreateStatus } from '../../utils/actions';
import Modal from '../../../Modal';
import styles from './style.module.scss';

interface Props {
  type: any;
  setShowModalCreate?: any;
  showModalCreate: boolean;
  finishStatus: any;
}

const CreateStatuses: React.FC<Props> = ({ type, setShowModalCreate, showModalCreate, finishStatus }: Props) => {

  const context = useContext(LoggedUserContext);

  const [modalContent, setModalContent] = useState<any>({
    table: type.name,
    active: false,
    backoffice: false,
    external: false,
    value: 0,
    name: '',
    description: ''
  });

  const [external, setExternal] = useState(false);
  const [backoffice, setBackoffice] = useState(false);
  const [estado, setEstado] = useState(false);

  const createStatus = () => {
    const newData = {
      data: [modalContent]
    };
    CreateStatus(context, newData).then(clear).finally(() => setShowModalCreate(false));
  };

  const clear = () => {
    finishStatus(true);
    setModalContent({
      table: type.name,
      active: false,
      backoffice: false,
      external: false,
      value: 0,
      name: '',
      description: ''
    });
  };

  return (
    <>
      <Modal title={'Crear estado'} showModal={showModalCreate} setShowModal={setShowModalCreate} containerClass='w-4/5'>
        <div className='grid grid-cols-2 gap-4'>
          <div>
            <div className='mb-5'>
              <Input
                focused
                name="value"
                onChange={(e) => { setModalContent({ ...modalContent, value: parseInt(e.target.value) }); }}
                placeholder="Escribe un valor numérico"
                title="Valor"
                type="number"
              /></div>
            <div className='mb-5'>
              <Input
                focused
                name="name"
                onChange={(e) => { setModalContent({ ...modalContent, name: e.target.value }); }}
                placeholder="Escribe un nombre"
                title="Nombre"
                type="text"
              /></div>
            <div className='pb-6 max-w-sm'>
              <TextArea
                maxLength={255}
                name="description"
                onChange={(e) => { setModalContent({ ...modalContent, description: e.target.value }); }}
                placeholder="Escribe una descripción"
                title="Descripción"
              />
            </div>

            <Switch
              label="Estado"
              className="pb-6"
              customSize="l"
              checked={estado}
              onClick={() => { setModalContent({ ...modalContent, active: !estado }); setEstado(!estado); }}
            />

            <Switch
              label="Usable en BackOffice"
              className="pb-6"
              customSize="l"
              checked={backoffice}
              onClick={() => { setModalContent({ ...modalContent, backoffice: !backoffice }); setBackoffice(!backoffice); }}
            />

            <Switch
              label="Usable en External"
              className="pb-6"
              customSize="l"
              checked={external}
              onClick={() => { setModalContent({ ...modalContent, external: !external }); setExternal(!external); }}
            />
          </div>

          <div className='flex flex-col items-end justify-end'>
            <Button size="m" type='secondary' buttonType='button' onClick={createStatus}>Guardar</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateStatuses;
