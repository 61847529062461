import { useContext, useEffect, useState } from 'react';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import { Professionals } from '../../interfaces/professional';
import styles from './styles.module.scss';
import { enterCvp } from '../../utils/actions';
import { EProfessionalStatus } from '../../../../enum/professional.enum';
import { Input, Select, Button, InfoIcon, TooltipGlobal, Skeleton, CheckMarkEmoji, ClockEmoji, UserHelp, FearfulFaceEmoji, WarningEmoji } from '@imed_npm/design-system';
import { Periodicity } from '../../interfaces/status';

interface OwnProps {
  professional: Professionals;
  setShowModal: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  periodicityList: Periodicity[];
  nextStep?: (() => void) | null;
}

const SendCvp = ({ professional: _professional, periodicityList: _periodicityList, nextStep }: OwnProps) => {
  const context = useContext(LoggedUserContext);
  const [professional, setProfessional] = useState<Professionals>(_professional);
  const [periodicites, setPeriodicites] = useState<Periodicity[]>(_periodicityList);
  const [selectedPeriodicity, setSelectedPeriodicity] = useState<Periodicity | undefined>(_professional.adscription_cvp_periodicity_id ? periodicites[periodicites.findIndex(periodicity => periodicity.id === Number(professional.adscription_cvp_periodicity_id))] : undefined);

  const [enabling, setEnabling] = useState<boolean>();
  const [cvp, setCvp] = useState<string>(_professional.adscription_cvp_code ?? '');
  const [successCvp, setSuccessCvp] = useState<boolean>(false);
  const [corr, setCorr] = useState<number | null>(1);
  const [error, setError] = useState<{
    statusCode: number;
    message: string;
    error: {
      message: string;
    }
  }>();

  const handleButtonClick = () => {
    if (nextStep) nextStep();
  };

  const sendCvp = () => {
    setEnabling(true);
    setSuccessCvp(false);
    setError(undefined);
    const regex = /^0\d{3,}$/;

    if (!cvp || (cvp && cvp.length !== 7)) {
      setError({ statusCode: 400, message: 'Error de formato de código CVP', error: { message: 'CVP debe tener 7 caracteres' } });
      setEnabling(false);
      return;
    }
    enterCvp(context, { adscription_id: professional.adscription_id, cvp_code: cvp, periodicity_id: selectedPeriodicity?.id, corr: corr ? corr : 1 }).then(result => {
      const { status } = result;
      console.log(result);
      if (status) {
        setSuccessCvp(true);
      } else {
        setError(result);
      }
    }).catch(({ response: { data } }) => {
      setError(data);
    }).finally(() => {
      setEnabling(false);
    });
  };

  const renderTextError = (err) => {
    if (typeof err === 'string') {
      return (<span>{String(err)}</span>);
    } else if (Array.isArray(err)) {
      return err.map(renderTextError);
    } else if (typeof err === 'object' && err !== null) {
      return err?.message ?? Object.values(err).map(renderTextError);
    } else {
      return null;
    }
  };

  const handleActions = (e) => {
    setSelectedPeriodicity(e);
  };

  useEffect(() => {
    setSelectedPeriodicity(periodicites[periodicites.findIndex(periodicity => periodicity.id === 6)]);
  }, []);

  useEffect(() => {
    if (nextStep && professional.adscription_cvp_code) setSuccessCvp(true);
  }, [professional]);


  const handleOnChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setCvp(e.target.value);
    } else {
      setCvp(e.target.value.replace(/[^0-9]/g, ''));
    }
  };

  const handleCorrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCorr(value ? parseInt(value) : null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.professional_info}>
        <p>
          <span>Prestador:</span> {professional.nid}
        </p>
        <p>
          <span>Código de lugar:</span> {professional.centers[0].code}
        </p>
        <p>
          <span>Id Adscripcion interno:</span> {professional.adscription_id}
        </p>
      </div>

      <div className='pt-5'>
        {
          professional && !professional.adscription_cvp_periodicity_id && !successCvp && <Select
            customClass={styles.z1000}
            customSize="l"
            items={periodicites}
            name="selectPeriodicity"
            nameType="description"
            onChange={(e) => handleActions(e)}
            placeholder="Seleccione"
            value={selectedPeriodicity || periodicites[5]}
            title="Periodicidad"
          />
        }
        {
          (professional && professional.adscription_cvp_periodicity_id) ? 'Periodicidad: ' + periodicites[periodicites.findIndex(periodicity => periodicity.id === Number(professional.adscription_cvp_periodicity_id))].description : ''
        }
        {
          (successCvp) ? 'Periodicidad: ' + selectedPeriodicity?.description : ''
        }
      </div>
      <div className='pt-5'  >
        {
          professional && !professional.adscription_cvp_code && !successCvp && <Input
            customSize="l"
            name="name"
            value={cvp}
            onChange={(e) => { handleOnChange(e); }}
            placeholder="Ingresar CVP"
            title={professional.centers[0].center_address}
            type="text"
            disabled={enabling}
          />
        }
        {
          (professional && professional.adscription_cvp_code) ? 'CVP: ' + professional.adscription_cvp_code : ''
        }
        {/* <br />
        {
          (successCvp) ? 'CVP: ' + cvp : ''
        } */}
      </div>
      <div className="pt-5">
        {!professional.adscription_cvp_code &&
          <Input
            customSize="l"
            name="name"
            value={corr !== null ? corr : ''}
            onChange={handleCorrChange}
            placeholder="Ingresar Correlativo numérico"
            title="Correlativo sucursal"
            type="number"
            stateMessage={(corr && corr.toString().length > 6) ? 'El máximo de caracteres es 6' : ''}
            stateType={corr ? (corr.toString().length > 6 ? 'error' : 'default') : 'default'}
          />
        }
      </div>
      {successCvp && <div className={styles.prospect}>
        <UserHelp
          colorStyle="success"
          icon={<CheckMarkEmoji customHeight='48' customWidth='48' />}
          text={'CVP ingresado correctamente'}
          title={'Exito'}
        />
      </div>}
      {error && <div className={styles.error}>
        <UserHelp
          colorStyle="error"
          icon={<FearfulFaceEmoji customHeight='48' customWidth='48' />}
          text={renderTextError(error?.error)}
          title={error && error.message ? error.message : 'Error'}
        />
      </div>}
      <div className={styles.footer + ' pt-5'}>
        {
          professional && !professional.adscription_cvp_code && !successCvp && <Button
            buttonType='button'
            key={'btn-change-status'}
            type='secondary'
            loading={enabling}
            disabled={enabling || (corr && corr.toString().length > 6)}
            onClick={sendCvp}>
            Ingresar CVP
          </Button>
        }
      </div>
      {
        successCvp && <div className='flex justify-end items-center'>
          <Button buttonType="button" type="primary" IconLeft="CheckCircleIcon" onClick={handleButtonClick}>
            Siguiente
          </Button>
        </div>
      }
    </div>
  );
};

export default SendCvp;
