import React, { useContext, useEffect, useState, ChangeEvent } from 'react';
import { Button } from '@imed_npm/design-system';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import { Professionals } from '../../interfaces/professional';
import { getCvp, storeCVPAdmin } from '../../utils/actions';
import styles from './styles.module.scss';
import ToastMessage from '../../../../utils/ToastMessage';
import LoadingComponent from '../../../Form/Components/LoadingComponent/LoadingComponent';
import PDFDataViewer from '../../../PdfViewer';
import { getBase64 } from '../../../../utils/base64';

interface OwnProps {
  setShowChangeStatusModal: (value: boolean) => void;
  professional: Professionals;
  nextStep?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ShowCvp = ({ setShowChangeStatusModal, professional, nextStep }: OwnProps) => {
  const context = useContext(LoggedUserContext);
  const [cvpFile, setCvpFile] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<string>('');

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && (file.type === 'application/pdf')) {
      const { name } = file;

      getBase64(file).then((base64: string | any) => {
        const base64WithoutHeader = base64.substring(base64.indexOf(',') + 1);
        storeCVPAdmin(context, { cvp: base64WithoutHeader, adscription_id: professional.adscription_id })
          .then(() => {
            setSelectedFile(name);
            setCvpFile(base64WithoutHeader);
          })
          .catch((error) => {
            ToastMessage.enviarToastError('Error al leer el archivo:', error.message);
          });
      });
    } else {
      setSelectedFile('');
      ToastMessage.enviarToastError('Por favor, seleccione un archivo de tipo .pdf');
    }
  };

  const remove = () => {
    setSelectedFile('');
  };

  useEffect(() => {
    if (!professional) return;
    if (!professional.adscription_cvp_file) return;
    const timer = setTimeout(() => {
      setLoading(true);
      getCvp(context, Number(professional.adscription_id)).then((response) => {
        setCvpFile(response.cvp_file);
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        setCvpFile('empty');
        ToastMessage.enviarToastError(`Error al obtener el CVP: ${error.message}`);
      });
    }, 500);
    return () => clearTimeout(timer);
  }, []);
  //        <PDFDataViewer pdf={cvpFile} props={{ view: 'fitH', toolbar: 1, navpanes: 0 }} />
  //        <PDFDataViewerV2 pdf={cvpFile} />

  const handleButtonClick = () => {
    if (nextStep) nextStep();
  };

  return (
    <div className={styles.container}>
      <div className={styles.professional_info}>
        <p>
          <span>Prestador:</span> {professional.nid}
        </p>
        <p>
          <span>Código de lugar:</span> {professional.centers[0].code}
        </p>
      </div>
      {loading && <LoadingComponent />}

      {(cvpFile && cvpFile !== 'empty') && <div className='flex flex-col h-full'>
        <PDFDataViewer pdf={cvpFile} classes={'h-screen'} props={{ view: 'fitH', toolbar: 1, navpanes: 0 }} />
      </div>
      }
      {
        (!loading && ((cvpFile && cvpFile === 'empty') || cvpFile === '')) && <div className='flex flex-col w-full'>
          {
            selectedFile === '' && <div className="flex items-center">
              <div className='flex items-center justify-start'>
                <input id="file_cvp" type="file" accept=".pdf" className={`${styles.custom_file_input}`} onChange={handleFileUpload} />
              </div>
            </div>
          }

          {/* file name */}
          {(selectedFile !== '') && <div className="mt-5 border p-4">
            <div className='flex w-full justify-between items-center'>
              <div className='flex items-center'>
                <p><small>{selectedFile}</small></p>
                <img className='ml-2' src="images/plan-standar.svg" />
              </div>
              <a type="button" id="remove_cvp" onClick={remove} className={styles.removeFile}>Quitar documento</a>
            </div>
          </div>}

        </div>
      }

      <div className='flex justify-end'>
        {/* <Button buttonType="button" type="primary" IconLeft="CheckCircleIcon" onClick={handleButtonClick} disabled={!cvpFile || cvpFile === 'empty'}> */}
        <Button buttonType="button" type="primary" IconLeft="CheckCircleIcon" onClick={handleButtonClick} >
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default ShowCvp;
