import React from 'react';
import icon from '../DealSignature/Components/DocumentsSignedCorrectly/assets/card-approve.svg';
import styles from '../DealSignature/style.module.scss';

function Payment() {
  return (
    <div className='flex items-start w-4/5 mx-auto'>
      <img src={icon} alt={'imagen'} />
      <div className='px-10'>
        <p className='mt-5'>
          <strong>¡Estás a un paso de activar tu servicio!</strong>
        </p>
        <p className='my-5'>
          Para finalizar tu suscripción y habilitar tu servicio de Escritorio Médico, debes ingresar un método de pago.
        </p>
        <p>
          Una vez ingresado, para resguardar la seguridad de la transacción, <strong className={styles.blue}>se realizará un cargo temporal de $1.000.-, el que será reembolsado inmediatamente.</strong>
        </p>
      </div>
    </div>
  );
}

export default Payment;
