import React from 'react';
import styles from './style.module.scss';
const EndScreen = () => {
  return (
    <>
      <div className={styles.end_screen}>
        hemos acabao ;]
      </div>
    </>
  );
};

export default EndScreen;