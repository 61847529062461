import { AXIOS_APIKEY, AXIOS } from '../../../utils/axios';
import { ADSCRIPTIONS, ADSCRIPTIONS_PROFESSIONALS, VERIFY_EM_TOKEN, GET_TOKEN, GET_RETOKENIZATION_INFO }
  from '../../../utils/endpoints';
import ToastMessage from '../../../utils/ToastMessage';
import { ResponseVerifyEmToken } from './interfaces';


export const getRetokenizationInfo = async (token?: string): Promise<any> => {
  try {
    const url = `${GET_RETOKENIZATION_INFO}${token}`;
    const { data } = await AXIOS.get<any>(url);
    return data;
  } catch (err) {
    const msg = 'Error al verificar el token';
    ToastMessage.enviarToastError(msg);
    throw new Error(msg);
  }
};


export const verifyEmToken = async (token?: string): Promise<ResponseVerifyEmToken> => {
  try {
    const url = `${VERIFY_EM_TOKEN}`;
    const { data } = await AXIOS.post<any>(url, { token });
    return data;
  } catch (err) {
    const msg = 'Error al verificar el token';
    ToastMessage.enviarToastError(msg);
    throw new Error(msg);
  }
};

export const getAdscriptionProfessionals = async (apikey: string, nid?: string, adscriptionId?: string): Promise<any> => {
  try {
    const url = `${ADSCRIPTIONS_PROFESSIONALS}?param=1${nid ? '&nid=' + nid : ''}${adscriptionId ? '&adscription_id=' + adscriptionId : ''}`;
    const { data } = await AXIOS_APIKEY(apikey).get<any>(url);
    return data;
  } catch (err) {
    const msg = 'Error al obtener los profesionales';
    ToastMessage.enviarToastError(msg);
    throw new Error(msg);
  }
};

export const createAdscriptionProfessionals = async (apikey: string, adscriptionId?: string, _data?: any): Promise<any> => {
  try {
    const url = `${ADSCRIPTIONS}/${adscriptionId}/professional`;
    const response = await AXIOS_APIKEY(apikey).post<any>(url, _data);
    ToastMessage.enviarToastSuccess('Profesional creado exitosamente');
    return response;
  } catch (err) {
    const msg = 'Error al intentar crear profesional';
    ToastMessage.enviarToastError(msg);
    throw new Error(msg);
  }
};

export const editAdscriptionProfessionals = async (apikey: string, adscriptionId?: string, _data?: any): Promise<any> => {
  try {
    const url = `${ADSCRIPTIONS}/${adscriptionId}/professional`;
    const response = await AXIOS_APIKEY(apikey).put<any>(url, _data);
    ToastMessage.enviarToastSuccess('Profesional modificado exitosamente');
    return response;
  } catch (err) {
    const msg = 'Error al intentar editar profesional';
    ToastMessage.enviarToastError(msg);
    throw new Error(msg);
  }
};

export const enableAdscriptionProfessionals = async (apikey: string, adscriptionId?: string, nid?: string, type?: string): Promise<any> => {
  try {
    const url = `${ADSCRIPTIONS}/${adscriptionId}/professional/${nid}/${type}`;
    const response = await AXIOS_APIKEY(apikey).patch<any>(url);
    ToastMessage.enviarToastSuccess('Profesional modificado exitosamente');
    return response;
  } catch (err) {
    const msg = 'Error al intentar editar profesional';
    ToastMessage.enviarToastError(msg);
    throw new Error(msg);
  }
};

export const getAuthToken = async () => {
  const errMessage = 'no se pudo crear la sesión';
  try {
    const response = await AXIOS.post(GET_TOKEN);
    window.sessionStorage.setItem('flowkey', response.data.data);
    return response.data.data;
  } catch (error) {
    ToastMessage.enviarToastError(errMessage);
    throw new Error(errMessage);
  }
};


