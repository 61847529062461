import styles from './styles.module.scss';
import { IResponseAdscriptionProfAdscription } from '../../interfaces/subscriptionAdmin.interface';
import { formatDotsRut } from '@imed_npm/valida-rut';

interface OwnProps {
  adscriptions: IResponseAdscriptionProfAdscription
}

const SubscriptionInfo = ({ adscriptions }: OwnProps) => {
  const center = adscriptions.centers;
  const companyInfo = adscriptions.client;

  return (
    <div className={styles.container}>
      <div className={styles.professional_info}>
        <p><strong>Lugar de atención:</strong></p>
        <p>{center.center_code} - {companyInfo.forenames}</p>
      </div>
      {
        <div className='flex flex-col h-full'>

        </div>
      }
    </div>
  );
};

export default SubscriptionInfo;
