/* eslint react/prop-types: 0 */
import React, { useEffect, useState, useContext } from 'react';
import { DataTable2, Skeleton, Select, Input, InfoIcon, Checkbox, Button, Label, LoadingImed, DataTabs, Tab } from '@imed_npm/design-system';
// import dummy from './dummy/data.json';
import { formatRut, validFormat, clearRut } from '../../../../../utils/rut';
import { DateTime } from 'luxon';
import LoggedUserContext from '../../../../../context/LoggedUserContext';
import Modal from '../../../../Modal';
import { Professionals } from '../../../interfaces/professional';
import { getPeriodicity, getProducts, getProfessionals, getStatuses, getAdminUF, getAdminPaymentSubs, getAdminDownloadPaymentSubs, excludePerson, deletePersonExclusion, getVouchersDownload, getPendingPayments, getDownloadVouchersPeriod, getVouchersByDate, getDownloadVouchersByDate } from '../../../utils/actions';
import { GetProfessionalsOptions, GroupedPeriodItem, Product } from '../../../utils/interfaces';
import { formatJsDate } from '../../../utils/utils';
import { Periodicity, Status } from '../../../interfaces/status';
import getEnv from '../../../../../getEnv';
import styles from './style.module.scss';
import ChargeModal from '../ChargeModal';
import DatePickerInputComponent from './Components/DatePickerInputComponent';
import { start } from 'repl';

const APP_ID = getEnv('REACT_APP_ID');

function ChargesByDate() {
  const context = useContext(LoggedUserContext);
  const [data, setData] = useState<any[]>([]);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);

  const [dataTable, setDataTable] = useState<any>();

  const [products, setProducts] = useState<Product[]>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [periodicityList, setPeriodicityList] = useState<Periodicity[]>();

  const [showFilters, setShowFilters] = useState(false);
  const [filterRut, setFilterRut] = useState<string>();

  const [showModal, setShowModal] = useState(false);

  const [modalData, setModalData] = useState(null);

  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [UFvalue, setUFValue] = useState(0);
  const [UFformated, setUfFormated] = useState<any>(0);

  const todayFormatted = DateTime.local().toFormat('yyyy-MM-dd');

  const todayDate = DateTime.fromISO(DateTime.local().toISODate() || '');
  const yesterdayDate = DateTime.fromISO(DateTime.local().minus({ days: 1 }).toISODate() || '');
  const yesterdayFormatted = yesterdayDate.toFormat('yyyy-MM-dd');

  // Convert them to JavaScript Date objects
  const today = todayDate.toJSDate();
  const yesterday = yesterdayDate.toJSDate();

  const [totalClients, setTotalClients] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [validatePeriod, setValidatePeriod] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);

  const [selectedRow, setSelectedRow] = useState<GroupedPeriodItem>();
  const [selectedGroups, setSelectedGroups] = useState<GroupedPeriodItem[]>([]);

  const [startDate, setStartDate] = useState(yesterdayFormatted);
  const [endDate, setEndDate] = useState(todayFormatted);

  const [errorStartDate, setErrorStartDate] = useState('');
  const [errorEndDate, setErrorEndDate] = useState('');

  const getMonthNameFromNumber = (monthNumber: number) => {
    const monthNames = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre',
    ];
    return monthNames[monthNumber - 1];
  };
  const getCurrentYear = () => {
    const today = DateTime.local();
    return {
      'id': today.year.toString(),
      'name': today.year.toString(),
    };
  };

  const getCurrentMonth = () => {
    const today = DateTime.local();
    const monthNumber = today.month.toString().padStart(2, '0');
    return {
      'id': monthNumber,
      'name': getMonthNameFromNumber(today.month),
    };
  };
  const [month, setMonth] = useState(getCurrentMonth());
  const [year, setYear] = useState(getCurrentYear());



  const getCurrentPeriod = () => {
    const today = DateTime.local();
    const month = today.month.toString().padStart(2, '0');
    const year = today.year;
    return `${year}-${month}`;
  };

  const handleCheckboxChange = (id: number) => {
    const updatedSelectedGroups = [...selectedGroups];
    const groupIndex = updatedSelectedGroups.findIndex(group => group.id === id.toString());
    if (groupIndex !== -1) {
      updatedSelectedGroups.splice(groupIndex, 1); // Remove from the array
    } else {
      const groupToAdd = data.find(item => item.id === id);
      if (groupToAdd) {
        updatedSelectedGroups.push(groupToAdd); // Add to the array
      }
    }
    setSelectedGroups(updatedSelectedGroups);
  };

  useEffect(() => {
    const timeout = setTimeout(async () => {
      const [initProducts] = await Promise.all(
        [
          getProducts(context),
        ]);
      setProducts(initProducts.filter((product) => product.name === 'EM'));
      setSelectedProduct(initProducts.filter((product) => product.name === 'EM')[0]);
      setShowLoading(false);
    }, 250);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!todayFormatted) return;
    getAdminUF(context, todayFormatted).then((e) => setUFValue(e.uf));
    const period = year.id + '-' + month.id;
    getVouchersByDate(context, startDate, endDate).then((response) => {
      console.log('response', response);
      //setValidatePeriod()
      if (response && response.length) {
        setValidatePeriod(true);
      } else {
        setValidatePeriod(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!UFvalue) return;
    // Formatear la UF
    const ufFormateada = UFvalue.toLocaleString('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 2,
    });
    setUfFormated(ufFormateada);
  }, [UFvalue]);

  useEffect(() => {
    const timeout = setTimeout(getData, 250);

    return () => clearTimeout(timeout);
  }, [filterRut]);

  useEffect(() => {
    if (!selectedProduct) return;

    setLoadingTable(true);
    getData();
    setFilterRut(undefined);
  }, [selectedProduct]);

  const validateDate = (startDate, endDate) => {
    if (!startDate) {
      setErrorStartDate('La fecha de inicio no es valida');
      return false;
    }
    if (!endDate) {
      setErrorEndDate('La fecha de fin no es valida');
      return false;
    }
    if (startDate > endDate) {
      setErrorStartDate('La fecha de inicio no puede ser mayor a la fecha de término');
      return false;
    }
    setErrorStartDate('');
    setErrorEndDate('');
    return true;
  };

  const download = () => {
    if (!validateDate(startDate, endDate)) return;
    //getAdminDownloadPaymentSubs(context, filterRut).then(response => downloadCsv(response));
    const period = year.id + '-' + month.id;
    getDownloadVouchersByDate(context, startDate, endDate).then(response => downloadCsv(response));

  };

  const charge = () => {
    if (!selectedGroups.length) return;
    setShowModal(true);
  };

  const downloadCsv = (base64String) => {
    console.log('base64String', base64String);
    try {
      // Decode the base64 string
      const binaryString = atob(base64String);

      // Convert the binary string to a Uint8Array
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob from the bytes
      const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a blob URL and download the file
      const blobURL = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = blobURL;
      downloadLink.download = 'reporte-periodo.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobURL);
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  };


  const getData = async () => {
    if (!selectedProduct) return;
    setShowLoading(true);

    //const result = await getAdminPaymentSubs(context, filterRut);
    //return result.list[0];
    console.log('year', year);
    console.log('month', month);
    getVouchersByDate(context, startDate, endDate).then((result) => {
      console.log('result', result);
      setDataTable(result);
      setData(result);
      if (result && result.length) {
        setValidatePeriod(true);
      } else {
        setValidatePeriod(false);
      }
    }).finally(() => {
      setShowLoading(false);
      setLoadingTable(false);
    });
  };

  useEffect(() => {
    if (modalData) {
      setShowModal(true);
    }
  }, [modalData]);

  useEffect(() => {
    if (!dataTable) return;
    //setTotalClients(calculateTotalClients());
    //setTotalAmount(calculateTotalAmount());
  }, [data]);

  useEffect(() => {
    if (!showLoading && !loadingTable) {
      setTimeout(() => {
        setShowFilters(true);
      }
        , 2000);
    }
  }, [showLoading]);

  const triggerSearch = (select) => {
    setSelectedProduct(select);
  };


  const DetailCellRender = (row: any) => {
    return (
      <div>
        <Button type='secondary' buttonType='button' onClick={() => {
          console.log('original', row.original);
          setSelectedRow(row.original);
          setShowDetailModal(true);
        }}>Detalle</Button>
      </div>
    );
  };

  const priceUfFormat = (value, row) => {
    return value ? (value).toFixed(2) : '-';
  };

  const priceFormat = (value: number): string => {
    const parts = value.toFixed(0).toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  const columnsDetail = [
    {
      Cell: ({ value, row }) => value ?? '-',
      Header: 'Id Voucher',
      HeaderWidth: 'w-[25%]',
      accessor: 'voucherId',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || 'Sin Periodo',
      Header: 'Periodo',
      HeaderWidth: 'w-[25%]',
      accessor: 'period',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value, row }) => priceUfFormat(value, row) ?? '-',
      Header: 'Monto en UF',
      HeaderWidth: 'w-[25%]',
      accessor: 'price',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Estado Voucher',
      HeaderWidth: 'w-[25%]',
      accessor: 'statusName',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }
  ];

  const selectAll = () => {
    if (!selectedGroups.length) {
      setSelectedGroups(data);
    } else {
      setSelectedGroups([]);
    }
  };

  const columns = [
    {
      Cell: ({ value }) => value || 'Sin Id',
      Header: 'Id',
      HeaderWidth: 'w-[5%]',
      accessor: 'id',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || 'Sin Periodo',
      Header: 'Periodo',
      HeaderWidth: 'w-[6%]',
      accessor: 'period',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Estado Voucher',
      HeaderWidth: 'w-[10%]',
      accessor: 'statusName',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value, row }) => priceUfFormat(value, row) ?? '-',
      Header: 'Monto en UF',
      HeaderWidth: 'w-[10%]',
      accessor: 'price',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value, row }) => `$${priceFormat(value)}` ?? '-',
      Header: 'Monto en CLP',
      HeaderWidth: 'w-[10%]',
      accessor: 'total',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Fecha de cobro',
      HeaderWidth: 'w-[11%]',
      accessor: 'paymentDate',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Canal',
      HeaderWidth: 'w-[10%]',
      accessor: 'channelName',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Nid Cliente',
      HeaderWidth: 'w-[10%]',
      accessor: 'nidClient',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Codigo de plan',
      HeaderWidth: 'w-[4%]',
      accessor: 'planCode',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },

  ];

  const renderCheckingInfo = () => {
    return (
      <>
        {(showLoading) && (<LoadingImed text="Cargando información" />)}
      </>
    );
  };

  useEffect(() => {
    if (!context) return;
    if (!dataTable) return;

    //fetchData().then(() => { setShowLoading(false); });
    setShowLoading(false);
  }, [dataTable]);

  return (
    <>
      <div className="bg-white px-6 text-center">

        {renderCheckingInfo()}
        <div className='w-full mx-auto'>
          {
            // JSON.stringify(loadingTable)
          }
          {
            // JSON.stringify(showFilters)
            // <h1 className="text-secondary-imed-60 text-title tracking-[0.1px] font-normal my-4 mt-0">Administrador de Cobros</h1>
          }
          <div className="bg-white py-6">
            <h4 className='text-base font-semibold mb-10 text-left'>
              Resultados de la búsqueda: <span className='text-blue-800'>{selectedProduct?.description}</span>
            </h4>
            <div className="mt-5">
              {
                false && showFilters &&
                <div className='flex justify-between items-center'>
                  <Select
                    customClass={`${styles.z0} mb-4`}
                    customSize="l"
                    items={products}
                    name="selectName"
                    nameType="description"
                    onChange={(e) => triggerSearch(e)}
                    defaultValue={selectedProduct?.description}
                    value={selectedProduct?.id}
                    placeholder="Seleccione una opción"
                    title="Producto"
                    disabled
                  />
                  <Button type='secondary' buttonType='button' onClick={download}>Descargar en Excel</Button>
                </div>
              }

              <div className='flex '>
                {
                  showFilters &&
                  <div className='flex items-start grow'>
                    <div>
                      <DatePickerInputComponent
                        calendarClassName=""
                        stateMessage={errorStartDate || ''}
                        stateType={errorStartDate ? 'error' : 'default'}
                        onChange={(e) => { console.log('e.value', e); setStartDate(e.target.value); }}
                        value={startDate}
                        setFieldTouched={function (name: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined): void {
                          console.log('name');
                        }} />
                    </div>

                    <div className='pl-4'>
                      <DatePickerInputComponent
                        stateMessage={errorEndDate || ''}
                        stateType={errorEndDate ? 'error' : 'default'}
                        calendarClassName=""
                        onChange={(e) => { setEndDate(e.target.value); }}
                        value={endDate}
                        setFieldTouched={function (name: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined): void {
                          console.log('name');
                        }} />
                    </div>

                    <div className='pl-4 pt-2'>

                      <Button type='secondary' buttonType='button' onClick={
                        () => {
                          if (!validateDate(startDate, endDate)) return;
                          getData();
                        }}>Buscar</Button>
                    </div>
                  </div>
                }
                {
                  showFilters && validatePeriod &&
                  <div className='pl-4 pt-2'>

                    <Button type='secondary-two' buttonType='button' onClick={download}>Descargar en Excel</Button>
                  </div>
                }
                {
                  !showFilters && <>
                    {
                      showLoading && <div className='grid grid-cols-4 gap-4 pt-2 mb-6'>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </div>
                    }
                  </>
                }
              </div>



              <div className='flex justify-between items-center'>
                {
                  false && showFilters &&
                  <div>
                    <Input
                      focused
                      name="name"
                      value={filterRut}
                      onChange={({ target: { value } }) => { setFilterRut(clearRut(value)); }}
                      placeholder="Ingresa un rut"
                      title="Buscar por rut"
                      type="text"
                    />
                  </div>
                }

                {
                  !showFilters && <>
                    {
                      showLoading && <div className='grid grid-cols-4 gap-4 pt-2 mb-6'>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </div>
                    }
                  </>
                }
              </div>

              {showDetailModal &&
                <Modal title={'Detalle'} showModal={showDetailModal} setShowModal={setShowDetailModal} showCloseButton={true}>
                  {selectedRow && <div className='grid gap-4'>
                    <div>
                      <p className='text-base font-semibold my-10'>
                        <p>Detalle del periodo {selectedRow.period}</p>
                        <p>Estado {selectedRow?.statusName}</p>
                        <p>Dia de Cobro {selectedRow?.creationDate}</p>
                      </p>
                      <DataTable2
                        columns={columnsDetail}
                        data={selectedRow.records}
                        disableHeader
                        orderBy="period"
                        perPage={10}
                        totalResults={selectedRow.records.length}
                        sortBy="desc"
                        colorStyle="neutral"
                      />
                      <div>
                        <div className='mt-5'>
                          <div className='flex items-baseline'>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
                </Modal>}

              {
                //(data && !loadingTable) ? (
                (!loadingTable) ? (
                  <div>
                    <DataTable2
                      columns={columns}
                      data={data}
                      disableHeader
                      orderBy="paymentDate"
                      perPage={10}
                      totalResults={data && data.length ? data.length : 0}
                      sortBy="desc"
                      colorStyle="neutral"
                    />
                    <div>
                      <div className='mt-5'>
                        <div className='flex items-baseline'>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='w-full border flex flex-col p-20 justify-center items-center'>
                    <InfoIcon size="m" />
                    <p className='mt-2'>Todavía no selecciona un producto para administrar</p>
                  </div>
                )
              }
            </div>
          </div>

        </div>


      </div>
    </>);
}


export default ChargesByDate;
