import { Provider, useContext, useEffect, useState } from 'react';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import { Professionals } from '../../interfaces/professional';
import { Services } from '../../interfaces/services';
import ServiceItem from './Components/ServiceItem';
import styles from './styles.module.scss';
import { Button, UserHelp, ThumbsUpEmoji } from '@imed_npm/design-system';
import { updateStatus } from '../../utils/actions';
import { EPersonNidType, EPersonType, EProfessionalStatus } from '../../../../enum/professional.enum';
import { Product } from '../../utils/interfaces';
import { EServices } from '../../../../enum/services.enum';
import getEnv from '../../../../getEnv';
import ServiceActivationLegalPerson from './Components/ServiceActivationLegalPerson';

interface OwnProps {
  professional: Professionals;
  selectedProduct: Product | undefined;
  setShowModal: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  nextStep?: () => void;
  setRefresh: any;
  clearFilter: any;
  caseNumber: number;
}

const ServiceActivation = ({
  professional: _professional,
  selectedProduct: _selectedProduct,
  nextStep,
  setRefresh,
  clearFilter,
  caseNumber,
}: OwnProps) => {
  const context = useContext(LoggedUserContext);
  const [isAnyButtonLoading, setIsAnyButtonLoading] = useState(false);

  if (!context) {
    console.error('LoggedUserContext no está disponible');
    return null;
  }

  const [professional, setProfessional] = useState<Professionals>(_professional);
  const [services, setServices] = useState<Services[]>([
    ...(_professional.legal_services || []),
    ...(_professional.professional_services || []),
  ]);
  const [enabling, setEnabling] = useState<boolean>(false);

  const renderServiceLegalPerson = (services: Services[]) => (
    <ServiceActivationLegalPerson
      professional={_professional}
      services={services}
      selectedProduct={_selectedProduct}
      context={context}
      updateServices={updateService}
      setRefresh={setRefresh}
      caseNumber={caseNumber}
    />
  );

  const renderServiceItem = (service: Services) => (
    <ServiceItem
      key={service.professional_service_id}
      professional={professional}
      service={service}
      selectedProduct={_selectedProduct}
      context={context}
      updateServices={updateService}
      isAnyButtonLoading={isAnyButtonLoading}
      setIsAnyButtonLoading={setIsAnyButtonLoading}
    />
  );


  const updateService = (service: Services) => {
    setServices((prevServices) => [
      ...prevServices.filter(({ professional_service_id }) => service.professional_service_id !== professional_service_id),
      service,
    ]);
    if (setRefresh) setRefresh(true);
  };

  const enable = () => {
    setEnabling(true);

    updateStatus(context, { adscriptionId: Number(professional.adscription_id), status: 11 })
      .then((result) => {
        const { status } = result;
        setProfessional((prevProfessional) => ({
          ...prevProfessional,
          adscription_status: status,
        }));
      })
      .catch((err) => {
        console.error({ err });
      })
      .finally(() => {
        setEnabling(false);
        clearFilter(professional.nid);
      });
  };

  const areAllServicesEnabled = (services: Services[], professional: Professionals, selectedProduct: Product | undefined) => {
    const filteredServices = services.filter((service) => service.service_place === caseNumber);

    console.log('servicios filtrados: ', filteredServices);
    console.log('service_place: ', caseNumber);
    const limit = parseInt(getEnv('REACT_APP_CENTER_CODE_LIMIT'), 10);

    const foundException = filteredServices.find(
      ({ professional_service_status, service_id }) =>
        professional_service_status !== 1 &&
        Number(service_id) === EServices.LME &&
        selectedProduct &&
        selectedProduct.name.toLowerCase() === 'lme' &&
        professional.centers[0].code <= limit
    );

    const areOthersEnabled = !filteredServices.find(
      ({ professional_service_status, service_id }) =>
        professional_service_status !== 1 && Number(service_id) !== EServices.LME
    );

    const areAllEnabled = !filteredServices.find(({ professional_service_status }) => professional_service_status !== 1);

    return (foundException && areOthersEnabled) || (!foundException && areAllEnabled);
  };

  const handleButtonClick = () => {
    if (nextStep) nextStep();
  };

  return (
    <div className={styles.container}>
      <div className={styles.services}>
        {_professional.adscription_person_type === EPersonType.JURIDICA &&
          _selectedProduct &&
          _selectedProduct.name.toLowerCase() === 'em'
          ? renderServiceLegalPerson(services)
          : services
            .filter((service) => service.service_place === caseNumber)
            .sort((a, b) => Number(a.service_id) - Number(b.service_id))
            .map(renderServiceItem)}
      </div>
      {
        professional.adscription_status === EProfessionalStatus.SERVICES_ENABLED && (professional.plans.products.name === 'BE' ? (caseNumber === 2) : (caseNumber === 1) )  && <div className="w-full">
          <UserHelp
            colorStyle="primary"
            text="Los servicios se han habilitado correctamente. Ya puede cerrar este modal"
            icon={<ThumbsUpEmoji svgClass="h-10 w-10" />}
            title="Habilitación terminada"
          />
        </div>
      }
      {areAllServicesEnabled(services, professional, _selectedProduct) && services.length > 0 && (
        <div className={styles.footer}>
          {professional.adscription_status === EProfessionalStatus.INSTALLATION && (
            <Button buttonType="button" type="secondary" loading={enabling} disabled={enabling} onClick={enable}>
              Habilitar
            </Button>
          )}

        </div>
      )}
      {/* {professional.adscription_status === EProfessionalStatus.SERVICES_ENABLED && (
            <Button buttonType="button" type="primary" IconLeft="CheckCircleIcon" onClick={handleButtonClick}>
              Siguiente
            </Button>
         )} */}
    </div>
  );
};

export default ServiceActivation;
