// src/utils/fileCompressor.ts
import imageCompression from 'browser-image-compression';
import { PDFDocument } from 'pdf-lib';

interface CompressedFile {
  file: File;
  success: boolean;
  error?: string;
}

export const compressFile = async (file: File): Promise<CompressedFile> => {
  const maxSizeInMB = 5;
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

  try {
    if (file.type.startsWith('image/')) {
      const options = {
        maxSizeMB: maxSizeInMB,
        useWebWorker: true,
      };

      const compressedBlob = await imageCompression(file, options);
      const compressedFile = new File([compressedBlob], file.name, {
        type: file.type,
      });

      if (compressedFile.size > maxSizeInBytes) {
        return { file: compressedFile, success: false, error: 'Unable to compress below 5MB' };
      }

      return { file: compressedFile, success: true };
    } else if (file.type === 'application/pdf') {
      const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
      const pdfBytes = await pdfDoc.save({ useObjectStreams: false });

      if (pdfBytes.length > maxSizeInBytes) {
        return { file: new File([pdfBytes], file.name, { type: 'application/pdf' }), success: false, error: 'Unable to compress below 5MB' };
      }

      const compressedPdfFile = new File([pdfBytes], file.name, {
        type: 'application/pdf',
      });

      return { file: compressedPdfFile, success: true };
    } else {
      return { file, success: false, error: 'Unsupported file type' };
    }
  } catch (error) {
    //@ts-ignore
    return { file, success: false, error: error };
  }
};
