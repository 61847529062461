import { Professionals } from '../../../../interfaces/professional';
import { Button, CheckMarkEmoji, ClockEmoji, UserHelp, FearfulFaceEmoji, WarningEmoji } from '@imed_npm/design-system';
import { useState } from 'react';
import { sendProspect } from '../../../../utils/actions';
import { ServiceActivationResultError } from '../../Interfaces/responses';
import styles from './styles.module.scss';

interface OwnProps {
  professional: Professionals;
  context: any;
  prospect: boolean
  updateProspect: (status: boolean) => void;
}

const ProspectItem = ({ professional, context, prospect, updateProspect }: OwnProps) => {
  const [activatingService, setActivatingService] = useState<boolean>();
  const [error, setError] = useState<ServiceActivationResultError>();

  const startActivation = () => {
    setError(undefined);

    setActivatingService(true);
    sendProspect(context, { id: Number(professional.adscription_id) }).then(() => {
      prospect = true;
      updateProspect(prospect);
    }).catch(({ response: { data } }) => setError(data)).finally(() => {
      setActivatingService(false);
    });
  };

  const renderTextError = (err) => {
    if (typeof err === 'string') {
      return (<span>{String(err)}</span>);
    } else if (Array.isArray(err)) {
      return err.map(renderTextError);
    } else if (typeof err === 'object' && err !== null) {
      return err?.message ?? Object.values(err).map(renderTextError);
    } else {
      return null;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.service}>
        <div className={styles.icon}>
          {error && <WarningEmoji customHeight='48' customWidth='48' />}
          {!error && prospect && <CheckMarkEmoji customHeight='48' customWidth='48' />}
          {!error && !prospect && <ClockEmoji customHeight='48' customWidth='48' />}
        </div>
        <p className={[styles.text, error ? styles.error : ''].join(' ')}>
          {['Volver a enviar prospecto a QBiz', prospect ? 'Activado' : (error ? 'Error al activar' : null)].filter(Boolean).join(' - ')}
        </p>
        {!prospect && <Button
          buttonType='button'
          size="s"
          type="secondary"
          loading={activatingService}
          disabled={activatingService}
          onClick={startActivation}
        >
          Enviar
        </Button>}
      </div>
      {error && <div className={styles.error}>
        <UserHelp
          colorStyle="error"
          icon={<FearfulFaceEmoji customHeight='48' customWidth='48' />}
          text={renderTextError(error?.error)}
          title={error?.message}
        />
      </div>}
    </div>
  );
};

export default ProspectItem;
