export enum EUsersSubscriptionAdmin {
  MEDICO = 'MEDICO',
  CALLCENTER = 'CALLCENTER'
}

export type IRolesUsersSubscriptionAdmin = {
  id: number,
  name: string,
  alternative: EUsersSubscriptionAdmin,
}

export const TRolesUsersSubscriptionAdmin: IRolesUsersSubscriptionAdmin[] = [
  {
    id: 1,
    name: 'Médico',
    alternative: EUsersSubscriptionAdmin.MEDICO,
  },
  {
    id: 2,
    name: 'Asistente',
    alternative: EUsersSubscriptionAdmin.CALLCENTER,
  }
];
export type TSpecialtiesSubscriptionAdmin = { specialtyId: string; amount?: number, specialty?: object;};

export interface IResponseHomologateMedic {
  specialty: string;
  homologation: IResponseHomologateMedicHomologation;
}

export interface IResponseHomologateMedicHomologation {
  id: string;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  code: string;
}

export interface IResponseAdscriptionProf {
  adscriptions: IResponseAdscriptionProfAdscription[];
  centers: IResponseAdscriptionProfCenter[];
}

export interface IResponseAdscriptionProfAdscription {
  adscription_id: string;
  professionals: IResponseAdscriptionProfAdscriptionLegalPerson[];
  legal_person: IResponseAdscriptionProfAdscriptionLegalPerson;
  client: IResponseAdscriptionProfAdscriptionLegalPerson;
  centers: IResponseAdscriptionProfCenter;
}

export interface IResponseAdscriptionProfCenter {
  id: string;
  representative_professional_id: null;
  name: string;
  address: string;
  phone: string;
  locality_id: string;
  status: number;
  created_at: string;
  updated_at: string;
  center_code: number;
  cvp: null;
  address_detail: string;
}

export interface IResponseAdscriptionProfAdscriptionLegalPerson {
  id: string;
  customer_id: string;
  nid_type: string;
  nid: string;
  forenames: string;
  surnames: string;
  email: string;
  phone: string;
  title: null | string;
  study: null | string;
  birthday: null | string;
  gender: number | null;
  bank_id: number;
  account_type_id: number;
  account: string;
  created_at: string;
  updated_at: string;
  confirmation_code: string;
  fonasa_validity: number;
  payment_type: number;
  status_changed_at: string;
  enabled_at: null | string;
  submitted_at: null | string;
  debt_notification: number;
  ci_code_front: string;
  ci_code_back: string;
  person_type: number;
  phone_country_code: string;
  user_type_id: null | string;
  representative_professional_specialty?: IResponseAdscriptionProfAdscriptionLegalPRProfSpecialty[];
  userTypeName?: string;
  userRoles?: string[];
}

export interface IResponseAdscriptionProfAdscriptionLegalPRProfSpecialty {
  id: string;
  representative_professional_id: string;
  specialty_id: string;
  amount: number;
  status: number;
  created_at: string;
  updated_at: string;
  adscription_id: string;
  specialties: Specialties;
}

export interface Specialties {
  id: string;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  code: string;
}
