import React from 'react';
import styles from './style.module.scss';

interface OwnProps {
    title: string
    subtitle?: string
}

const Header = ({ title, subtitle }: OwnProps) => {

  return <div className={styles.header}>
    <h1 className={styles.title}>{title}</h1>
    {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
  </div>;
};

export default Header;
