import React, { useState, useEffect, useContext } from 'react';
import { DataTable2, Skeleton, Select, Input, DualPickList, Button, LoadingImed, InfoIcon, XIcon, CheckIcon, Switch, TextArea, Label } from '@imed_npm/design-system';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import { Status } from '../../interfaces/status';

import { getTables, getStatuses, UpdateStatusInfo, StatusTransition, DeleteStatusInfo } from '../../utils/actions';
import Modal from '../../../Modal';
import CreateStatuses from './createStatus';
import styles from './style.module.scss';

const AdminStatuses = (): JSX.Element => {

  const context = useContext(LoggedUserContext);
  const [tableData, setTableData] = useState();
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false);

  const [data, setData] = useState<Status[]>();
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [selectedTable, setSelectedTable] = useState();

  const [modalContent, setModalContent] = useState<any>(undefined);
  const [removeDuplicate, setRemoveDuplicate] = useState<any>(undefined);
  const [transition, setTransition] = useState<any>(undefined);
  const [transitionList, setTransitionlist] = useState<any>(undefined);
  const [providersOptions, setProvidersOptions] = useState<any>(undefined);
  const [optSelect, setOptSelect] = useState<any>(undefined);

  const [external, setExternal] = useState(true);
  const [backoffice, setBackoffice] = useState(true);
  const [estado, setEstado] = useState(true);
  const [finished, finishStatus] = useState(false);


  const save = () => {
    setLoadingTable(true);
    finishStatus(false);

    const newData = {
      // @ts-ignore
      table: selectedTable.name,
      value: modalContent.value,
      statuses: optSelect
    };
    UpdateStatusInfo(context, modalContent).then(() => finishStatus(true)).finally(() => setShowModal(false));
    StatusTransition(context, newData);
  };

  useEffect(() => {
    if (finished) {
      const timeoutId = setTimeout(() => {
        // @ts-ignore
        getStatuses(context, selectedTable.name).then(setData).finally(() => setLoadingTable(false));
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [finished]);

  const modify = (action, value) => {
    const { original } = value;
    setModalContent({
      // @ts-ignore
      table: selectedTable.name,
      value: original.value,
      data: {
        name: original.name,
        description: original.description,
        backoffice: original.backoffice,
        external: original.external
      }
    });

    // @ts-ignore
    const resultArray = data.filter(item => item.id !== original.id);
    setRemoveDuplicate(resultArray);
    setTransition(original.status_transitions_status_transitions_base_status_idTostatuses.map((item) => {
      return {
        ...item,
        label: item.statuses_status_transitions_next_status_idTostatuses.name
      };
    }));

    setBackoffice(original.backoffice);
    setExternal(original.external);
    setEstado(original.active);
    setShowModal(true);

  };

  const ActionCellRender = ({ row }: any) => {
    return (<Button size="s" type='primary' buttonType='button' onClick={(e) => modify(e, row)}>Modificar</Button>);
  };

  const columns = [
    {
      Cell: ({ value }: any) => {
        return <div>
          <div className={value ? styles.bgSuccess : styles.bgWarning}></div>
        </div>;
      },
      Header: 'Activo',
      accessor: 'active',
      filter: 'exact',
      skeleton: <Skeleton customClass="m-auto mt-1 mb-1" />,
    },
    {
      Cell: ({ value }) => value,
      Header: 'Valor',
      accessor: 'value',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => <p className='w-40 break-word whitespace-normal'>{value || 'Sin nombre'}</p>,
      Header: 'Nombre',
      HeaderWidth: 'w-[31%]',
      accessor: 'name',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => <p className='w-40 break-word whitespace-normal'>{value || 'Sin descripción'}</p>,
      Header: 'Descripción',
      HeaderWidth: 'w-[31%]',
      accessor: 'description',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || '-',
      Header: 'Estado',
      HeaderWidth: 'w-[31%]',
      accessor: 'id_table',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => value ? <CheckIcon size="s" pathClass="stroke-success" /> : <XIcon size="s" pathClass="stroke-error" />,
      Header: 'BackOffice',
      HeaderWidth: 'w-[31%]',
      accessor: 'backoffice',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => value ? <CheckIcon size="s" pathClass="stroke-success" /> : <XIcon size="s" pathClass="stroke-error" />,
      Header: 'External',
      HeaderWidth: 'w-[31%]',
      accessor: 'external',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => {
        const nextStatusesList = value.map((item) => item.statuses_status_transitions_next_status_idTostatuses.name);
        return <p className='w-40 break-word whitespace-normal'>{(nextStatusesList.length > 0) ? `${nextStatusesList}, ` : '-'}</p>;
      },
      Header: 'Transita a:',
      HeaderWidth: 'w-[31%]',
      accessor: 'status_transitions_status_transitions_base_status_idTostatuses',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ActionCellRender,
      Header: 'Acciones',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }
  ];

  useEffect(() => {
    getTables(context).then(setTableData).finally(() => setShowLoading(false));
  }, []);

  useEffect(() => {
    if (!selectedTable) return;
    // @ts-ignore
    getStatuses(context, selectedTable.name).then(setData).finally(() => setLoadingTable(false));

  }, [selectedTable]);

  const triggerSearch = (select) => {
    setLoadingTable(true);
    setSelectedTable(select);
  };

  const renderCheckingInfo = () => {
    return (
      <>
        {(showLoading) && (<LoadingImed text="Cargando información" />)}
      </>
    );
  };

  useEffect(() => {
    if (!removeDuplicate) return;
    setProvidersOptions(removeDuplicate.map((item) => {
      return {
        ...item,
        label: item.name
      };
    }));
  }, [removeDuplicate]);

  useEffect(() => {
    if (!providersOptions) return;
    if (!transition) return;
    const resultArray = providersOptions.filter(itemA => !transition.some(itemB => itemB.next_status_id === itemA.id));
    setTransitionlist(resultArray);
  }, [providersOptions, transition]);

  const changeSt = (_e) => {
    const deleteData = {
      // @ts-ignore
      table: selectedTable.name,
      value: parseInt(modalContent.value),
    };
    DeleteStatusInfo(context, deleteData);
  };

  return (
    <>
      {renderCheckingInfo()}

      {showModal &&
        <Modal title={'Modificar estado'} showModal={showModal} setShowModal={setShowModal} containerClass='w-4/5'>
          <div className='grid grid-cols-2 gap-4'>
            <div>
              <div className='mb-5'>
                <Input
                  focused
                  name="value"
                  onChange={(e) => { setModalContent({ ...modalContent, value: parseInt(e.target.value) }); }}
                  placeholder="Escribe un nombre"
                  title="Valor"
                  value={modalContent?.value}
                  type="number"
                  disabled
                /></div>
              <div className='mb-5'>
                <Input
                  focused
                  name="name"
                  onChange={(e) => { setModalContent({ ...modalContent, data: { ...modalContent.data, name: e.target.value } }); }}
                  placeholder="Escribe un nombre"
                  title="Nombre"
                  value={modalContent?.data?.name}
                  type="text"
                /></div>
              <div className='pb-6 max-w-sm'>
                <TextArea
                  value={modalContent?.data?.description}
                  maxLength={255}
                  name="description"
                  onChange={(e) => { setModalContent({ ...modalContent, data: { ...modalContent.data, description: e.target.value } }); }}
                  placeholder="Escribe una descripción"
                  title="Descripción"
                />
              </div>

              <Switch
                label="Estado habilitado"
                className="pb-6"
                customSize="l"
                checked={estado}
                onClick={() => { changeSt(!estado); setEstado(!estado); }}
              />

              <Switch
                label="Usable en BackOffice"
                className="pb-6"
                customSize="l"
                checked={backoffice}
                onClick={() => { setModalContent({ ...modalContent, data: { ...modalContent.data, backoffice: !backoffice } }); setBackoffice(!backoffice); }}
              />

              <Switch
                label="Usable en External"
                className="pb-6"
                customSize="l"
                checked={external}
                onClick={() => { setModalContent({ ...modalContent, data: { ...modalContent.data, external: !external } }); setExternal(!external); }}
              />
            </div>

            <div className='flex flex-col items-end justify-between'>
              <div className='mb-5'>

                <Label
                  customClass="mb-4"
                  htmlFor="base-input"
                  title="Transita a:"
                />
                {(transitionList) &&
                  <DualPickList
                    error="Debes marcar una opción"
                    initialList={transitionList}
                    selectedList={transition ?? []}
                    addTextButton="Agregar estado"
                    removeTextButton="Quitar estado"
                    setOptionsDeselected={(e) => { console.log(e); }}
                    setOptionsSelected={(e) => { setOptSelect(e.map((item) => parseInt(item.id))); }}
                    touched
                  />}
              </div>
              <Button size="m" type='secondary' buttonType='button' onClick={save}>Guardar</Button>
            </div>
          </div>
        </Modal>
      }

      {showModalCreate &&
        <CreateStatuses type={selectedTable} showModalCreate={showModalCreate} setShowModalCreate={setShowModalCreate} finishStatus={finishStatus} />
      }

      <div className='w-full mx-auto'>
        <div className="bg-white p-6">
          <h1 className="text-secondary-imed-60 text-title tracking-[0.1px] font-normal my-4 mt-0">Administrador de estados</h1>
          <p className={`${styles.subtitle} pb-5`}>Seleccione una tabla y administre la opción seleccionada</p>
          <hr />
          <div className="mt-5">
            <div className='flex justify-between items-center'>
              <Select
                customClass={`${styles.z0} mb-4`}
                customSize="l"
                items={tableData}
                name="name"
                nameType="name"
                onChange={(e) => triggerSearch(e)}
                placeholder="Seleccione una tabla"
                title="Tabla"
              />
              {
                selectedTable && (
                  <Button size="m" type='secondary' buttonType='button' onClick={() => { setShowModalCreate(true); }}>Crear nuevo estado</Button>
                )
              }
            </div>
          </div>
          {
            (data && !loadingTable) ? (
              <DataTable2
                columns={columns}
                data={data}
                disableHeader
                orderBy="name"
                perPage={10}
                totalResults={data && data.length ? data.length : 0}
                colorStyle="neutral"
              />
            ) : (
              <div className='w-full border flex flex-col p-20 justify-center items-center'>
                <InfoIcon size="m" />
                <p className='mt-2'>Todavía no selecciona una tabla para administrar</p>
              </div>
            )
          }
        </div>
      </div>
    </>
  );
};

export default AdminStatuses;
