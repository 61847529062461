import React, { useState, useEffect } from 'react';
import { Input, Button, InfoIcon, Skeleton } from '@imed_npm/design-system';
import Modal from '../../../Modal';
import WarningModal from '../ProfessionalInfo/Components/WarningMessage';
import Typeahead from '../Typeahead';
import SelectRegion from '../../../RegionSelect';
import './index.scss';
import styles from './style.module.scss';
import { City, GooglePredictionSelectItem, LMECenter, Locality, Region } from '../../../../utils/interfaces';
import ToastMessage from '../../../../utils/ToastMessage';
import { UseStep } from '../../Store/step-context';
import { getAddressValidation } from '../../Store/actions';

interface OwnProps {
  align?: string;
  direction?: string;
  setShowModal?: any;
  setFinished?: any;
  addCenter: React.Dispatch<React.SetStateAction<LMECenter | undefined>>;
  defaultAddress?: any;
  hideName?: boolean;
  hideButton?: boolean;
  hideRegion?: boolean;
  blockItems?: boolean;
  newShipping?: boolean;
}

const defaultGeneral: any = {
  address: {
    commune: { city_id: '', code: '', created_at: '', id: '', name: '', updated_at: '' },
    region: { cities: [], code: '', created_at: '', id: '', name: '', updated_at: '' },
  },
};

const AddCenter = ({ align, direction, addCenter, setShowModal, defaultAddress, hideName, hideButton, hideRegion, blockItems, newShipping }: OwnProps) => {
  const { dispatch, state, state: { general, steps: { step4: { validations: validations_step4 } } } } = UseStep();

  const [canAddCenter, setCanAddCenter] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>(defaultAddress?.address ?? '');
  const [address_detail, setAddressDetail] = useState<string>(defaultAddress?.address_detail ?? '');
  const [prediction, setPrediction] = useState<GooglePredictionSelectItem>();
  // const [locality, setLocality] = useState<Locality>({ city_id: '', code: '', created_at: '', id: '', name: '', updated_at: '' });
  const [locality, setLocality] = useState<Locality>(general?.address?.commune ?? defaultGeneral.address.commune);

  const [region, setRegion] = useState<Region>(general?.address?.region ?? defaultGeneral.address.region);
  // const [region, setRegion] = useState<Region>({ cities: [], code: '', created_at: '', id: '', name: '', updated_at: '' });
  const [city, setCity] = useState<City>({ region_id: '', localities: [], code: '', created_at: '', id: '', name: '', updated_at: '' });

  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [addressValidationType, setAddressValidationType] = useState<string>('default');
  const [addressValidationMessage, setAddressValidationMessage] = useState<string>('');

  const [blockType, setBlockType] = useState(false);

  const addSNIfNoNumber = (address) => {
    const numberPattern = /\d+/;
    const snPattern = /S\/N/i;

    if (address.trim() === '') {
      return address;
    }
    if (address.trim() === '') {
      return address;
    }

    if (!numberPattern.test(address) && !snPattern.test(address)) {
      return `${address} S/N`;
    }
    if (!numberPattern.test(address) && !snPattern.test(address)) {
      return `${address} S/N`;
    }

    return address;
  };

  useEffect(() => {
    if (defaultAddress && (general?.shipping_locality?.commune?.id === general.shipping.locality_id)) {
      const timeout = setTimeout(() => {
        setRegion(general?.shipping_locality?.region);
        setLocality(general?.shipping_locality?.commune);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [defaultAddress]);

  useEffect(() => {
    if (!region) return;
    if (region && region.id !== '') {
      // console.log(general?.shipping_locality?.region);
      if (newShipping) {
        dispatch({ type: 'STORE_SHIPPING_REGION', payload: region });
      } else {
        dispatch({ type: 'STORE_BILLING_REGION', payload: region });
      }
    }
  }, [region]);

  useEffect(() => {
    if (!locality) return;
    if (locality && locality.id !== '') {
      if (newShipping) {
        dispatch({ type: 'STORE_SHIPPING_COMMUNE', payload: locality });
      } else {
        dispatch({ type: 'STORE_BILLING_COMMUNE', payload: locality });
      }
    }
  }, [locality]);

  useEffect(() => {
    if (address) {
      setBlockType(true);
    } else {
      setBlockType(false);
    }
  }, [address]);

  const save = () => {
    const data: LMECenter = {
      name,
      address: addSNIfNoNumber(address),
      address_detail: address_detail ? address_detail.trim() : '',
      locality: locality.name,
      locality_id: locality.id,
      region: region.name,
      region_id: region.id,
      city: city.name,
      city_id: city.id,
      center_code: '',
      phone: '',
    };
    addCenter(data);
    setClear();
    setShowModal(!canAddCenter);
    ToastMessage.enviarToastInfo('Dirección agregada');
  };

  const handleMenuToggleChange = (isOpen) => {
    setIsMenuOpen(isOpen);
  };

  useEffect(() => {
    if (defaultAddress) return;
    if (region.name) reset(1);
  }, [region]);

  useEffect(() => {
    if (!locality || (locality && !locality.name) || !region || (region && !region.name)) return;
    const _city = region.cities.find(({ id }) => id == locality.city_id);

    if (_city) setCity(_city);
  }, [locality]);

  useEffect(() => {
    if (blockItems) return;
    if (validations_step4.address.error) {
      setAddressValidationType('warning');
      setAddressValidationMessage('Dirección ingresada no contiene número');
      setShowWarningModal(true);
    } else if (validations_step4.address.success && address !== '') {
      setAddressValidationType('success');
      setAddressValidationMessage('Dirección válida');
    } else if (validations_step4.address.pending) {
      setAddressValidationType('pending');
      setAddressValidationMessage('Comprobando dirección');
    }
  }, [validations_step4]);

  useEffect(() => {
    if (prediction) {
      getAddressValidation(dispatch, { address: prediction?.description, placeId: prediction?.place_id }, 'step4');
    }
  }, [prediction]);

  useEffect(() => {
    if (hideName) {
      setCanAddCenter(!!address && !!region.name && !!city.name && !!locality.name);
    } else {
      setCanAddCenter(!!name && !!address && !!region.name && !!city.name && !!locality.name);
    }
  }, [locality, region, city, name, address]);

  useEffect(() => {
    if (!defaultAddress) return;
    const timeout = setTimeout(() => {
      if (defaultAddress.address && defaultAddress.locality && defaultAddress.region) {
        const predicted = { name: defaultAddress.address, description: defaultAddress.address + ',' + defaultAddress.locality + ',' + defaultAddress.region, place_id: 'asd123ads' };
        const tempAddress = { ...predicted, id: 123 };
        setPrediction(tempAddress);
      } else if (defaultAddress.address && general.address) {
        const predicted = { name: defaultAddress.address, description: defaultAddress.address + ',' + general.address.commune.name + ',' + general.address.region.name, place_id: 'asd123ads' };
        const tempAddress = { ...predicted, id: 123 };
        setPrediction(tempAddress);
      }
    }, 2000);
    return () => clearTimeout(timeout);
  }, [defaultAddress]);

  const setClear = () => {
    setAddress('');
    setAddressValidationType('warning');
    setAddressValidationMessage('Ingrese una dirección');
    dispatch({ type: 'CLEAR_ADDRESS' });
    setCanAddCenter(false);
  };

  const reset = (option = 0) => {
    const _defaultRegion = { cities: [], code: '', created_at: '', id: '', name: '', updated_at: '' };
    const _defaultCity = { region_id: '', localities: [], code: '', created_at: '', id: '', name: '', updated_at: '' };
    const _defaultLocality = { city_id: '', code: '', created_at: '', id: '', name: '', updated_at: '' };

    switch (option) {
      case 0:
        setRegion(_defaultRegion);
        reset(1);
        break;
      case 1:
        setCity(_defaultCity);
        reset(2);
        break;
      case 2:
        setLocality(_defaultLocality);
        break;
    }
  };

  return (
    <div className={direction ? 'flex flex-row' : 'flex flex-col'}>
      {!hideName && (
        <div className={`flex mb-5 ${align ? 'full-size' : 'w-3/4'}`}>
          <div className={`${align ? 'w-540' : 'w-full'}`}>
            <Input
              focused
              name="name"
              onChange={(e) => { setName(e.target.value); }}
              placeholder="Ingresa Lugar de atención"
              title="Nombre Lugar de atención"
              type="text"
              customSize="l"
              maxLength={63}
            />
          </div>
        </div>
      )}
      <div className={`flex w-full mb-5 ${(align && align === 'center') ? 'flex-col' : 'flex-col'}`}>
        <div className={`flex ${(align && align === 'center') ? 'full-size items-baseline flex-grow' : 'full-size flex-col'}`}>
          <div className={`flex ${(align && align === 'center') ? 'full-size items-baseline flex-grow' : 'full-size flex-col'}`}>
            <div className='flex-grow'>
              <Typeahead setClear={setClear} setAddress={setAddress}
                setPrediction={setPrediction} onMenuToggleChange={handleMenuToggleChange} isOpen={blockType} hasNid={true}
                hideButton={hideButton ? true : false}
                defaultPrediction={
                  (general?.shipping_locality?.commune || defaultGeneral?.address?.commune || general?.address?.commune) && defaultAddress?.address
                    ? {
                      name: defaultAddress.address,
                      description: `${defaultAddress.address}, ${general?.shipping_locality?.commune?.name || defaultGeneral?.address?.commune?.name || general?.address?.commune?.name}`,
                      place_id: 'asd123ads'
                    }
                    : null
                }
              // defaultPrediction={defaultAddress?.address && locality ? { name: defaultAddress.address, description: `${defaultAddress.address}, ${locality.name}`, place_id: 'asd123ads' } : null}
              />
              {
                address && <div className={`${styles[addressValidationType]} flex items-center mt-2`}>
                  {addressValidationType === 'warning' && <InfoIcon customWidth="20" pathClass="stroke-warning" svgClass='mr-1' />}
                  <span>{addressValidationMessage}</span>
                </div>
              }
            </div>
          </div>
          <div className={`${(align && align === 'center') ? 'ml-4' : 'mt-4'} ${blockItems && styles.block}`}>
            <Input
              focused
              name="address_detail"
              onChange={(e) => { setAddressDetail(e.target.value); }}
              value={address_detail}
              placeholder={blockItems ? 'Sin información adicional ingresada' : 'Ingresa Departamento, Bloque, Torre'}
              title="Departamento/Bloque/Torre (Opcional)"
              type="text"
              customSize="l"
              maxLength={63}
            />
          </div>
        </div>
        <div className={`flex ${align && 'w-full mt-4'} ${styles.flex} ${blockItems && styles.block}`}>
          {(prediction && !hideRegion) && <SelectRegion setLocality={setLocality} setRegion={setRegion}
            address={prediction}
            customClass={showWarningModal ? 'w-full' : styles.z1000} />
          }
        </div>
      </div>
      {!direction && (
        <div className="flex justify-end">
          <Button buttonType="button" type="secondary" onClick={save} disabled={!canAddCenter}>Guardar</Button>
        </div>
      )}
      <Modal title="Dirección sin numeración" showModal={showWarningModal} setShowModal={setShowWarningModal} parentClass={isMenuOpen ? styles.z1005 : ''}>
        <WarningModal />
        <div className="w-full flex justify-end">
          <Button type="secondary" buttonType="button" onClick={() => setShowWarningModal(false)}>Cerrar</Button>
        </div>
      </Modal>
    </div>
  );
};

export default AddCenter;
