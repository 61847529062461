import { useContext, useEffect, useState } from 'react';
import ProfileSelection from '../Components/ProfileSelection';
import ProfessionalInfo from '../Components/ProfessionalInfo';
import CompanyInfo from '../Components/CompanyInfo';
import ContactInformation from '../Components/ContactInformation';
import CenterInfo from '../Components/CenterInfo';
import BankInfo from '../Components/BankInfo';
import EmPlans from '../Components/EmPlans';
import CVP from '../Components/CVP';
import DealSignature from '../Components/DealSignature';
import Payment from '../Components/Payment';
import LayoutForm from '../LayoutForm';
import { initialState, UseStep } from '../Store/step-context';
import { FORM_STEPS_EM } from '../constans';
import ToastMessage from '../../../utils/ToastMessage';
import EndScreen from '../Components/EndScreen';

import { getToken, initAdscription } from '../Store/actions';
import PaymentMP from '../Components/Payment/components/indexMp';



const EMFormContainer = () => {

  const { state, state: { step, steps: { error, step7 } }, dispatch } = UseStep();
  const [stepSelected, setStepSelected] = useState<string>(initialState.step);

  const handleSetNextStep = () => {
    if (stepSelected === 'step1') {
      const { form } = state;
      setStepSelected(form.personContractType === 'natural' ? 'step2.1' : 'step2.2');
    } else if (stepSelected === 'step5') {
        const { form } = state;
        // @ts-ignore
        return setStepSelected(form.personPlanType.code === 'PC71' ? 'step5.5' : 'step6');
    } else if (stepSelected === 'step6') {
      const { validation } = step7;
      console.log(step7);
        // @ts-ignore
        return setStepSelected(validation ? 'step9' : 'step7');
    }
    else {
      setStepSelected(FORM_STEPS_EM[stepSelected].nextStep);
    }
  };

  useEffect(() => {
    if (step != stepSelected) {
      setStepSelected(step);
    }
  }, [step]);

  const handleSetPreviousStep = () => {
    const { previousStep } = FORM_STEPS_EM[stepSelected];
    if (previousStep === 'step2') {
      const { form } = state;
      return setStepSelected(form.personContractType === 'natural' ? 'step2.1' : 'step2.2');
    }
    setStepSelected(previousStep);
  };

  useEffect(() => {
    if (stepSelected != step)
      dispatch({ type: 'UPDATE_STEP', payload: { step: stepSelected } });
  }, [stepSelected]);

  useEffect(() => {
    setStepSelected(step);
  }, [step]);

  useEffect(() => {
    const { message } = error;
    const type = typeof message;

    if (type == 'string') {
      ToastMessage.enviarToastError(message);
    } else {
      ToastMessage.enviarToastError((
        <>
          <ul>
            {message.map(msg => (<li key={msg}>{msg}</li>))}
          </ul>
        </>
      ));
    }
  }, [error]);



  useEffect(() => {

    const timer = setTimeout(() => {
      getToken(dispatch);
      dispatch({ type: 'SET_PRODUCT', payload: { product: 'EM' } });
    }, 250);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <LayoutForm
        headerTitle={FORM_STEPS_EM[stepSelected].headerTitle}
        headerSubtitle={FORM_STEPS_EM[stepSelected].headerSubtitle}
        subtitleText={FORM_STEPS_EM[stepSelected].subtitleText}
        actionNext={handleSetNextStep}
        actionPrevious={handleSetPreviousStep}
      >
        {
          stepSelected === 'step1' && <ProfileSelection />
        }
        {
          stepSelected === 'step2.1' && <ProfessionalInfo type="EM" />
        }
        {
          stepSelected === 'step2.2' && <CompanyInfo type="EM" />
        }
        {
          stepSelected === 'step3' && <ContactInformation  type="EM"/>
        }
        {
          stepSelected === 'step4' && <CenterInfo type="EM" />
        }
        {
          stepSelected === 'step5' && <EmPlans />
        }
        {
          stepSelected === 'step5.5' && <CVP />
        }
        {
          stepSelected === 'step6' && <BankInfo />
        }
        {
          stepSelected === 'step7' && <Payment />
        }
        {
          stepSelected === 'step8' && <PaymentMP />
        }
        {
          stepSelected === 'step9' && <DealSignature type="EM" />
        }
        {
          stepSelected === 'endScreen' && <EndScreen />
        }
      </LayoutForm>
    </>

  );
};


export default EMFormContainer;
