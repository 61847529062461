import React, { useEffect } from 'react';
import styles from './style.module.scss';
import image from '../../../../../../assets/warning.svg';

interface OwnProps {
  text?: string;
}

const WarningModal = ({ text }: OwnProps) => {

  return (
    <div className='flex flex-col px-12'>
      <div className='p-5 mx-auto'><img src={image} className={`${styles.image}`} /></div>
      <div className={`p-5 text-center w-10/12 mx-auto ${styles.title}`}>{text || 'Has ingresado una dirección sin número.'}</div>

      <ul className={`${styles.color} list-none`}>
        <li className="py-3">Se necesita una dirección válida para entregar el lector biométrico.</li>
        <li className='py-3'>Por favor, verifica la dirección y asegúrate de incluir el número correspondiente.</li>
        <li className='py-3'>Si estás seguro de que la dirección es correcta, entonces puedes continuar con el proceso de suscripción.</li>
      </ul>
    </div>
  );
};


export default WarningModal;
