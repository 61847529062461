import { useEffect, useState } from 'react';
import { DataTable2, Skeleton, Select, LoadingImed, Badge } from '@imed_npm/design-system';
import { formatRut } from '../../../../utils/rut';
import styles from './styles.module.scss';
import LoadingComponent from '../../../Form/Components/LoadingComponent/LoadingComponent';
import { enableAdscriptionProfessionals } from '../../utils/actions';
import { IResponseAdscriptionProfAdscription, IResponseAdscriptionProfAdscriptionLegalPerson } from '../../interfaces/subscriptionAdmin.interface';
import { formatDotsRut } from '@imed_npm/valida-rut';
import { ETypeOfActionToPerson, ETypeOfActionToProfessional } from '../../utils/interfaces';


interface OwnProps {
  adscription: IResponseAdscriptionProfAdscription,
  permission?: string,
  setShowModalDisable: (value: boolean) => void,
  setShowModalEdit: (value: boolean) => void,
  setAdscriptionData: (value: IResponseAdscriptionProfAdscriptionLegalPerson) => void,
  setLoadingParent: (value: boolean) => void,
  setClear: (value?: string) => void,
  setModalDisableData: (value: any) => void
}

const SubscriptionProfessionals = ({ adscription, permission, setShowModalDisable, setShowModalEdit, setAdscriptionData, setLoadingParent, setClear, setModalDisableData }: OwnProps) => {
  const [subscribedProfessionals, setSubscribedProfessionals] = useState<IResponseAdscriptionProfAdscriptionLegalPerson[]>([]);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setSubscribedProfessionals(adscription.professionals);
    setLoadingTable(false);

  }, [adscription]);

  const getSelectItems = (row) => {
    const { enabled_at } = row;
    let toReturn = [
      {
        id: 1,
        name: 'Deshabilitar'
      },
      {
        id: 2,
        name: 'Habilitar'
      },
      {
        id: 3,
        name: 'Editar usuario'
      }
    ];
    if (enabled_at && enabled_at.length > 0) {
      toReturn = [
        {
          id: 1,
          name: 'Deshabilitar'
        },
        {
          id: 3,
          name: 'Editar usuario'
        }
      ];
    } else {
      toReturn = [
        {
          id: 2,
          name: 'Habilitar'
        },
        {
          id: 3,
          name: 'Editar usuario'
        }
      ];
    }

    return toReturn;

  };

  const handleActions = (action, value) => {
    const { original } = value;
    // setShowModal(false);
    switch (action.id) {
      case 1:
        setShowModalDisable(true);
        setModalDisableData({ ...original, adscription_id: adscription.adscription_id });
        //setAdscriptionData(original);
        setIsLoading(false);
        break;
      case 2:
        enableAdscriptionProfessionals(permission ?? '', adscription.adscription_id, formatRut(original.nid), ETypeOfActionToProfessional.ENABLE)
          .then(() => {
            setClear(ETypeOfActionToPerson.EDIT);
            setLoadingParent(true);
            setIsLoading(false);
          }
          );
        break;
      case 3:
        setAdscriptionData(original);
        setShowModalEdit(true);
        setIsLoading(false);
        break;
      default:
        break;
    }
  };

  const renderCheckingInfo = () => {
    return (
      <>
        {(loadingTable) && (<LoadingImed text="Cargando información" />)}
      </>
    );
  };

  const ActionCellRender = ({ row }: any) => {
    // console.log(row.original);
    return (<Select
      customClass={styles.z0}
      customSize="s"
      items={getSelectItems(row.original)}
      name="selectName"
      nameType="name"
      onChange={(e) => {
        setIsLoading(true);
        handleActions(e, row);
      }}
      placeholder="Seleccione"
    />);
  };

  const columns = [
    {
      Cell: ({ value }) => formatDotsRut(value) ?? 'Sin rut',
      Header: 'RUT',
      HeaderWidth: 'w-[31%]',
      accessor: 'nid',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value, row }: any) => {
        return <p className='mb-0'>{row.original.forenames} {value}</p>;
      },
      Header: 'Nombre',
      accessor: 'surnames',
      filter: 'exact',
      skeleton: <Skeleton customClass="m-auto mt-1 mb-1" />,
    },
    {
      Cell: ({ value }) => value || 'Sin rol',
      Header: 'Rol',
      HeaderWidth: 'w-[31%]',
      accessor: 'userTypeName',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => {
        if (value) {
          return <Badge
            colorStyle="green"
            label="Habilitado"
          />;
        } else {
          return <Badge
            colorStyle="red"
            label="Deshabilitado"
          />;
        }
      },
      Header: 'Estado',
      accessor: 'enabled_at',
      filter: 'exact',
      skeleton: <Skeleton customClass="m-auto mt-1 mb-1" />,
    },
    {
      Cell: ActionCellRender,
      Header: 'Acciones',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }
  ];

  return (
    <>
      {
        (isLoading) && (<LoadingImed text="Espere un momento..." />)
      }
      <div className={styles.container}>
        {renderCheckingInfo()}
        {loadingTable ? <LoadingComponent /> : <DataTable2
          columns={columns}
          data={subscribedProfessionals}
          disableHeader
          statusName="enabled_at"
          orderBy="nid"
          perPage={10}
          totalResults={subscribedProfessionals.length}
          sortBy="asc"
          colorStyle="neutral"
          showUpperFilter
          textPagination="Usuarios"
        />}
      </div>
    </>
  );
};

export default SubscriptionProfessionals;
