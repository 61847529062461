import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@imed_npm/design-system';
import styles from './style.module.scss';

interface OwnProps {
  title: string;
  description?: string;
  icon?;
  linkPath: string;
  external?: boolean;
  linkText?: string;
  className?: string;
  action?: () => void;
}

const Card = ({ title, description, icon, linkPath, linkText, className, external }: OwnProps) => {

  const navigate = useNavigate();

  const goToPath = () => {
    if (linkPath && !external) {
      navigate(linkPath);
    } else if (linkPath && external) {
      window.open(linkPath, '_blank')?.focus();
    } else {
      return false;
    }
  };

  return <div className={`${styles.card}  ${className ? className : ''}`} onClick={goToPath}>
    <div className="flex flex-col">
      <div className={'flex flex-row'}>
        {icon &&
          <div className="mr-4">
            <img src={icon} alt={'logo'} />
          </div>
        }
        {title &&
          <h5 className="mb-4 text-base font-medium text-secondary-imed-60">{title}</h5>
        }
      </div>
      <div className={'flex-row'}>
        {description &&
          <p className="text-sm font-normal leading-6 text-neutral-60 mb-4">{description}</p>
        }
      </div>
      {
        linkText && (
          <div className={`flex items-end ${styles.link}`}>
            <ChevronRightIcon size="s" /> <span>{linkText}</span>
          </div>
        )
      }
    </div>
  </div>;
};

export default Card;
