import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, IndexPointingUpEmoji, UserHelp, Select, InfoIcon, Input, CalculatorEmoji, Skeleton } from '@imed_npm/design-system';
import styles from './style.module.scss';
import getEnv from '../../../../getEnv';
import { UseStep } from '../../Store/step-context';
import { getPlans, storeCenter, discountValidate } from '../../Store/actions';
import { GetPlansData } from '../../../../utils/interfaces';
import Modal from '../../../Modal';
import ModalSuccess from './ModalSuccess';
import AddCenter from '../CenterInfo/addCenter';

interface OwnProps {
  type: string;
}
const BePlans = ({ type }: OwnProps) => {
  const params = useParams();

  const [code, setCode] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [price, setPrice] = useState<any>();
  const [message, setModalMessage] = useState<string>('');
  const [finished, setFinished] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dataCenter, setDataCenter] = useState<any>();

  const [defaultAddress, setDefaultAddress] = useState<any>();

  // const { dispatch, state } = UseStep();
  const { dispatch, state, state: { adscription_id, product, general, steps: { success, step2: { nid }, step4, step4: { center, storedCenterInfo }, step5,
    step5: { readersSelectOptions, readers, plan, validations } } } } = UseStep();
  const [readersQuantity, setReadersQuantity] = useState<number>(readers);

  // Store Center
  useEffect(() => {
    // if (center && center.address !== '') return;
    const timeout = setTimeout(() => {
      storeCenter({ dispatch, payload: { center, nid, product, adscription_id }, newShipping: true }).then(() => setRefresh(false));
    }, 250);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!dataCenter) return;
    setFinished(true);
    const { dataCenter: internalDataCenter, ...rest } = dataCenter;
    const mergedData = { ...rest, ...internalDataCenter };

    setDefaultAddress(mergedData);
    dispatch({ type: 'SET_SHIPPING', payload: mergedData });
  }, [dataCenter]);


  useEffect(() => {
    if (!general) return;
    if (general.shipping) {
      setDefaultAddress(general.shipping);
      setFinished(false);
    }
  }, [general]);

  // useEffect(() => {
  //   if (!general) return;
  //   if (general.shipping_locality && general.shipping_locality !== '') {
  //     setDefaultAddress(general.shipping_locality);
  //     setFinished(false);
  //   }
  // }, [general]);

  useEffect(() => {
    if (finished) {
      setRefresh(true);
    } else {
      setRefresh(false);
    }
  }, [finished]);

  useEffect(() => {
    if (readersQuantity > 0) {
      dispatch({ type: 'STORE_READERS', payload: { readers: readersQuantity } });
    }
  }, [readersQuantity]);

  useEffect(() => {
    if (params && params.code) return;
    const timeout = setTimeout(() => {
      if (plan.id) return;
      const payload: GetPlansData = { product: 'BE' };
      getPlans({ dispatch, payload });
    }, 600);
    return () => clearTimeout(timeout);
  }, []);

  const calculatePrice = (value, rates) => {
    if (value === null || value === undefined) return;
    if (rates === null || rates === undefined) return;
    const quantity = parseInt(value, 10);
    const feeAplicable = rates.find(fee => fee.min <= quantity && (fee.max === null || quantity <= fee.max));
    setPrice(feeAplicable ? (quantity * feeAplicable.cost).toFixed(2) : null);
  };

  useEffect(() => {
    if (!plan) return;
    if (!readersQuantity) return;
    const { plans_reader_cost } = plan;
    if (!plans_reader_cost) return;
    calculatePrice(readersQuantity, plans_reader_cost);

  }, [plan, readersQuantity]);

  const validate = () => {
    if (code) {
      discountValidate(dispatch, code, 'LME', plan.id);
    }
  };

  return <div>
    {Boolean(plan.id) && <div className={styles.product_plan}>
      <div className={styles.uf}>
        <span>Seleccione número de lectores</span>
        <div className='reader-select'>
          <Select
            customSize="m"
            items={readersSelectOptions}
            name="readers"
            nameType="name"
            onChange={(value) => setReadersQuantity(value)}
            value={readersQuantity}
          />
        </div>
        <span>=</span>
        <span className={styles.border}>Monto fijo a pagar</span>
        {
          plan.discount_applied ?
            <div className='flex flex-col'>
              <span className={styles.priceBefore}>antes {plan.price} {plan.discount_unit_name}+IVA / mes</span>
              <span className={styles.priceNow}>{(readers * plan.discount_price).toFixed(1)}UF + IVA/mensual </span>
            </div>
            :
            <span>{price || 0}UF + IVA/mensual </span>
        }
      </div>

      {Boolean(plan.id) && <div className='grid grid-cols-2 gap-2'>
        <div className={styles.table_info}>
          <div className={styles.header}>
            <span>Lector de huella </span>
            <span>UF mes + IVA*</span>
          </div>
          {/* <div className={styles.body}>
            <span>Costo Unitario</span>
            <span>{plan?.discount_amount}</span>
          </div> */}
          {plan?.plans_reader_cost.map((dato, index) => (
            <div className={styles.body} key={index}>
              <span>{dato.description}</span>
              <span>{dato.cost}</span>
            </div>
          ))}
        </div>
        <div className={styles.table_info}>
          <div className={styles.header}>
            <span>Costo por transacción </span>
            <span>UF + IVA / mensual</span>
          </div>
          {plan?.plans_transaction_cost.map((dato, index) => (
            <div className={styles.body} key={index}>
              <span>{dato.description}</span>
              <span>{dato.cost}</span>
            </div>
          ))}
        </div>
      </div>}
      <p className={`${styles.table_text} mt-4`}>*Incluye lector, Licencia de uso y mantención</p>
    </div>}
    <div className="w-full">
      <UserHelp
        colorStyle="green"
        icon={<CalculatorEmoji svgClass="h-10 w-10" />}
        text={<>¿Cómo se calcula el monto total a pagar?: éste corresponde al <strong>monto fijo más el monto variable transaccional:</strong></>}
      />
    </div>
    {
      (getEnv('REACT_APP_SHIPPING_VALIDATION') === 'true' || getEnv('REACT_APP_SHIPPING_VALIDATION') === true) && defaultAddress && <div>
        <p className={styles.subtitle}>B. Dirección de entrega de lector de huellas </p>
        <p className='mb-5'>Para asegurar la entrega adecuada de los lectores biométricos, verifica que la dirección de envío sea la correcta. <u>En caso contrario, ajústala para garantizar su entrega en el lugar correcto.</u></p>
        <div className="flex items-center">
          <div className="w-3/4">
            {
              refresh ? <Skeleton
                borderColor="default"
                customClass=""
                customSize="m"
              /> :
                <AddCenter align="center" direction="row" setFinished={setFinished} addCenter={setDataCenter} defaultAddress={defaultAddress} hideButton={true} hideName={true} hideRegion={true} blockItems={true} />
            }
          </div>
          <div className='w-1/4 flex justify-end items-end mb-5'>
            <Button buttonType='button' onClick={() => { setShowModal(true); }} type="secondary">
              Modificar
            </Button>
          </div>
        </div>
      </div>
    }
    <Modal title={'Modificar dirección de envío'} showModal={showModal} setShowModal={setShowModal} containerClass='w-4/12'>
      <AddCenter align="full" setShowModal={setShowModal} setFinished={setFinished} addCenter={setDataCenter} hideName={true} newShipping={true} />
    </Modal>
  </div>;
};

export default BePlans;
