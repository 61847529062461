

const LoadingComponent = () => (
  <div>
    <div className="w-full flex items-center justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" style={{ margin:'auto',background:'none',display:'block',shapeRendering:'auto' }} width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="rotate(0 50 50)">
          <rect x="47" y="11" rx="0" ry="0" width="6" height="24" fill="#092f6d">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.875s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(45 50 50)">
          <rect x="47" y="11" rx="0" ry="0" width="6" height="24" fill="#092f6d">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(90 50 50)">
          <rect x="47" y="11" rx="0" ry="0" width="6" height="24" fill="#092f6d">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.625s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(135 50 50)">
          <rect x="47" y="11" rx="0" ry="0" width="6" height="24" fill="#092f6d">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(180 50 50)">
          <rect x="47" y="11" rx="0" ry="0" width="6" height="24" fill="#092f6d">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.375s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(225 50 50)">
          <rect x="47" y="11" rx="0" ry="0" width="6" height="24" fill="#092f6d">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(270 50 50)">
          <rect x="47" y="11" rx="0" ry="0" width="6" height="24" fill="#092f6d">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.125s" repeatCount="indefinite"></animate>
          </rect>
        </g><g transform="rotate(315 50 50)">
          <rect x="47" y="11" rx="0" ry="0" width="6" height="24" fill="#092f6d">
            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
          </rect>
        </g></svg>
    </div>
  </div>
);


LoadingComponent.propTypes = {};

LoadingComponent.defaultProps = {};

export default LoadingComponent;
