import React from 'react';
import styles from './style.module.scss';

interface OwnProps {
  title: string
}


const  Subtitle = ({ title }: OwnProps) => {
  return (
    <>
      <h3 className={styles.subtitle}>{title}</h3>
      <hr className={styles.separator}/>
    </>
  );
};


export default Subtitle;
