import getEnv from '../getEnv';

const CHECK_CI = String(getEnv('REACT_APP_SERIAL_VALIDATION')) === 'true';

const COUNTRIES = [
  {
    name: 'Afganistán',
    nameOfficialIso: 'Afganistán',
    codeAlfa_2: 'AF',
    codeAlfa_3: 'AFG',
    codeNumeric: '004',
    topLevelDomain: 'af',
    FIPS: 'AF',
    geoNameID: 1149361,
    E164: 93,
    phoneCode: '93',
    continent: 'Asia',
    capital: 'Kabul',
    timeZoneInCapital: 'Asia/Kabul',
    currency: 'Afghani',
    languageCodes: 'fa-AF,ps,uz-AF,tk',
  },
  {
    name: 'Albania',
    nameOfficialIso: 'Albania',
    codeAlfa_2: 'AL',
    codeAlfa_3: 'ALB',
    codeNumeric: '008',
    topLevelDomain: 'al',
    FIPS: 'AL',
    geoNameID: 783754,
    E164: 355,
    phoneCode: '355',
    continent: 'Europe',
    capital: 'Tirana',
    timeZoneInCapital: 'Europe/Tirane',
    currency: 'Lek',
    languageCodes: 'sq,el',
  },
  {
    name: 'Alemania',
    nameOfficialIso: 'Alemania',
    codeAlfa_2: 'DE',
    codeAlfa_3: 'DEU',
    codeNumeric: '276',
    topLevelDomain: 'de',
    FIPS: 'GM',
    geoNameID: 2921044,
    E164: 49,
    phoneCode: '49',
    continent: 'Europe',
    capital: 'Berlin',
    timeZoneInCapital: 'Europe/Berlin',
    currency: 'Euro',
    languageCodes: 'de',
  },
  {
    name: 'Andorra',
    nameOfficialIso: 'Andorra',
    codeAlfa_2: 'AD',
    codeAlfa_3: 'AND',
    codeNumeric: '020',
    topLevelDomain: 'ad',
    FIPS: 'AN',
    geoNameID: 3041565,
    E164: 376,
    phoneCode: '376',
    continent: 'Europe',
    capital: 'Andorra la Vella',
    timeZoneInCapital: 'Europe/Andorra',
    currency: 'Euro',
    languageCodes: 'ca',
  },
  {
    name: 'Angola',
    nameOfficialIso: 'Angola',
    codeAlfa_2: 'AO',
    codeAlfa_3: 'AGO',
    codeNumeric: '024',
    topLevelDomain: 'ao',
    FIPS: 'AO',
    geoNameID: 3351879,
    E164: 244,
    phoneCode: '244',
    continent: 'Africa',
    capital: 'Luanda',
    timeZoneInCapital: 'Africa/Lagos',
    currency: 'Kwanza',
    languageCodes: 'pt-AO',
  },
  {
    name: 'Anguila',
    nameOfficialIso: 'Anguila',
    codeAlfa_2: 'AI',
    codeAlfa_3: 'AIA',
    codeNumeric: '660',
    topLevelDomain: 'ai',
    FIPS: 'AV',
    geoNameID: 3573511,
    E164: 1,
    phoneCode: '1-264',
    continent: 'North America',
    capital: 'The Valley',
    timeZoneInCapital: 'America/Port_of_Spain',
    currency: 'Dollar',
    languageCodes: 'en-AI',
  },
  {
    name: 'Antártida',
    nameOfficialIso: 'Antártida',
    codeAlfa_2: 'AQ',
    codeAlfa_3: 'ATA',
    codeNumeric: '010',
    topLevelDomain: 'aq',
    FIPS: 'AY',
    geoNameID: 6697173,
    E164: 672,
    phoneCode: '672',
    continent: 'Antarctica',
    capital: '',
    timeZoneInCapital: 'Antarctica/Troll',
    currency: '',
    languageCodes: '',
  },
  {
    name: 'Antigua y Barbuda',
    nameOfficialIso: 'Antigua y Barbuda',
    codeAlfa_2: 'AG',
    codeAlfa_3: 'ATG',
    codeNumeric: '028',
    topLevelDomain: 'ag',
    FIPS: 'AC',
    geoNameID: 3576396,
    E164: 1,
    phoneCode: '1-268',
    continent: 'North America',
    capital: "St. John's",
    timeZoneInCapital: 'America/Antigua',
    currency: 'Dollar',
    languageCodes: 'en-AG',
  },
  {
    name: 'Arabia Saudita',
    nameOfficialIso: 'Arabia Saudita',
    codeAlfa_2: 'SA',
    codeAlfa_3: 'SAU',
    codeNumeric: '682',
    topLevelDomain: 'sa',
    FIPS: 'SA',
    geoNameID: 102358,
    E164: 966,
    phoneCode: '966',
    continent: 'Asia',
    capital: 'Riyadh',
    timeZoneInCapital: 'Asia/Riyadh',
    currency: 'Rial',
    languageCodes: 'ar-SA',
  },
  {
    name: 'Argelia',
    nameOfficialIso: 'Argelia',
    codeAlfa_2: 'DZ',
    codeAlfa_3: 'DZA',
    codeNumeric: '012',
    topLevelDomain: 'dz',
    FIPS: 'AG',
    geoNameID: 2589581,
    E164: 213,
    phoneCode: '213',
    continent: 'Africa',
    capital: 'Algiers',
    timeZoneInCapital: 'Africa/Algiers',
    currency: 'Dinar',
    languageCodes: 'ar-DZ',
  },
  {
    name: 'Argentina',
    nameOfficialIso: 'Argentina',
    codeAlfa_2: 'AR',
    codeAlfa_3: 'ARG',
    codeNumeric: '032',
    topLevelDomain: 'ar',
    FIPS: 'AR',
    geoNameID: 3865483,
    E164: 54,
    phoneCode: '54',
    continent: 'South America',
    capital: 'Buenos Aires',
    timeZoneInCapital: 'America/Argentina/Buenos_Aires',
    currency: 'Peso',
    languageCodes: 'es-AR,en,it,de,fr,gn',
  },
  {
    name: 'Armenia',
    nameOfficialIso: 'Armenia',
    codeAlfa_2: 'AM',
    codeAlfa_3: 'ARM',
    codeNumeric: '051',
    topLevelDomain: 'am',
    FIPS: 'AM',
    geoNameID: 174982,
    E164: 374,
    phoneCode: '374',
    continent: 'Asia',
    capital: 'Yerevan',
    timeZoneInCapital: 'Asia/Yerevan',
    currency: 'Dram',
    languageCodes: 'hy',
  },
  {
    name: 'Aruba',
    nameOfficialIso: 'Aruba',
    codeAlfa_2: 'AW',
    codeAlfa_3: 'ABW',
    codeNumeric: '533',
    topLevelDomain: 'aw',
    FIPS: 'AA',
    geoNameID: 3577279,
    E164: 297,
    phoneCode: '297',
    continent: 'North America',
    capital: 'Oranjestad',
    timeZoneInCapital: 'America/Curacao',
    currency: 'Guilder',
    languageCodes: 'nl-AW,es,en',
  },
  {
    name: 'Australia',
    nameOfficialIso: 'Australia',
    codeAlfa_2: 'AU',
    codeAlfa_3: 'AUS',
    codeNumeric: '036',
    topLevelDomain: 'au',
    FIPS: 'AS',
    geoNameID: 2077456,
    E164: 61,
    phoneCode: '61',
    continent: 'Oceania',
    capital: 'Canberra',
    timeZoneInCapital: 'Australia/Sydney',
    currency: 'Dollar',
    languageCodes: 'en-AU',
  },
  {
    name: 'Austria',
    nameOfficialIso: 'Austria',
    codeAlfa_2: 'AT',
    codeAlfa_3: 'AUT',
    codeNumeric: '040',
    topLevelDomain: 'at',
    FIPS: 'AU',
    geoNameID: 2782113,
    E164: 43,
    phoneCode: '43',
    continent: 'Europe',
    capital: 'Vienna',
    timeZoneInCapital: 'Europe/Vienna',
    currency: 'Euro',
    languageCodes: 'de-AT,hr,hu,sl',
  },
  {
    name: 'Azerbaiyán',
    nameOfficialIso: 'Azerbaiyán',
    codeAlfa_2: 'AZ',
    codeAlfa_3: 'AZE',
    codeNumeric: '031',
    topLevelDomain: 'az',
    FIPS: 'AJ',
    geoNameID: 587116,
    E164: 994,
    phoneCode: '994',
    continent: 'Asia',
    capital: 'Baku',
    timeZoneInCapital: 'Asia/Baku',
    currency: 'Manat',
    languageCodes: 'az,ru,hy',
  },
  {
    name: 'Bahamas',
    nameOfficialIso: 'Bahamas (las)',
    codeAlfa_2: 'BS',
    codeAlfa_3: 'BHS',
    codeNumeric: '044',
    topLevelDomain: 'bs',
    FIPS: 'BF',
    geoNameID: 3572887,
    E164: 1,
    phoneCode: '1-242',
    continent: 'North America',
    capital: 'Nassau',
    timeZoneInCapital: 'America/Nassau',
    currency: 'Dollar',
    languageCodes: 'en-BS',
  },
  {
    name: 'Bangladés',
    nameOfficialIso: 'Bangladés',
    codeAlfa_2: 'BD',
    codeAlfa_3: 'BGD',
    codeNumeric: '050',
    topLevelDomain: 'bd',
    FIPS: 'BG',
    geoNameID: 1210997,
    E164: 880,
    phoneCode: '880',
    continent: 'Asia',
    capital: 'Dhaka',
    timeZoneInCapital: 'Asia/Dhaka',
    currency: 'Taka',
    languageCodes: 'bn-BD,en',
  },
  {
    name: 'Barbados',
    nameOfficialIso: 'Barbados',
    codeAlfa_2: 'BB',
    codeAlfa_3: 'BRB',
    codeNumeric: '052',
    topLevelDomain: 'bb',
    FIPS: 'BB',
    geoNameID: 3374084,
    E164: 1,
    phoneCode: '1-246',
    continent: 'North America',
    capital: 'Bridgetown',
    timeZoneInCapital: 'America/Barbados',
    currency: 'Dollar',
    languageCodes: 'en-BB',
  },
  {
    name: 'Baréin',
    nameOfficialIso: 'Baréin',
    codeAlfa_2: 'BH',
    codeAlfa_3: 'BHR',
    codeNumeric: '048',
    topLevelDomain: 'bh',
    FIPS: 'BA',
    geoNameID: 290291,
    E164: 973,
    phoneCode: '973',
    continent: 'Asia',
    capital: 'Manama',
    timeZoneInCapital: 'Asia/Bahrain',
    currency: 'Dinar',
    languageCodes: 'ar-BH,en,fa,ur',
  },
  {
    name: 'Bélgica',
    nameOfficialIso: 'Bélgica',
    codeAlfa_2: 'BE',
    codeAlfa_3: 'BEL',
    codeNumeric: '056',
    topLevelDomain: 'be',
    FIPS: 'BE',
    geoNameID: 2802361,
    E164: 32,
    phoneCode: '32',
    continent: 'Europe',
    capital: 'Brussels',
    timeZoneInCapital: 'Europe/Brussels',
    currency: 'Euro',
    languageCodes: 'nl-BE,fr-BE,de-BE',
  },
  {
    name: 'Belice',
    nameOfficialIso: 'Belice',
    codeAlfa_2: 'BZ',
    codeAlfa_3: 'BLZ',
    codeNumeric: '084',
    topLevelDomain: 'bz',
    FIPS: 'BH',
    geoNameID: 3582678,
    E164: 501,
    phoneCode: '501',
    continent: 'North America',
    capital: 'Belmopan',
    timeZoneInCapital: 'America/Belize',
    currency: 'Dollar',
    languageCodes: 'en-BZ,es',
  },
  {
    name: 'Benín',
    nameOfficialIso: 'Benín',
    codeAlfa_2: 'BJ',
    codeAlfa_3: 'BEN',
    codeNumeric: '204',
    topLevelDomain: 'bj',
    FIPS: 'BN',
    geoNameID: 2395170,
    E164: 229,
    phoneCode: '229',
    continent: 'Africa',
    capital: 'Porto-Novo',
    timeZoneInCapital: 'Africa/Lagos',
    currency: 'Franc',
    languageCodes: 'fr-BJ',
  },
  {
    name: 'Bermudas',
    nameOfficialIso: 'Bermudas',
    codeAlfa_2: 'BM',
    codeAlfa_3: 'BMU',
    codeNumeric: '060',
    topLevelDomain: 'bm',
    FIPS: 'BD',
    geoNameID: 3573345,
    E164: 1,
    phoneCode: '1-441',
    continent: 'North America',
    capital: 'Hamilton',
    timeZoneInCapital: 'Atlantic/Bermuda',
    currency: 'Dollar',
    languageCodes: 'en-BM,pt',
  },
  {
    name: 'Bielorrusia',
    nameOfficialIso: 'Belarús',
    codeAlfa_2: 'BY',
    codeAlfa_3: 'BLR',
    codeNumeric: '112',
    topLevelDomain: 'by',
    FIPS: 'BO',
    geoNameID: 630336,
    E164: 375,
    phoneCode: '375',
    continent: 'Europe',
    capital: 'Minsk',
    timeZoneInCapital: 'Europe/Minsk',
    currency: 'Ruble',
    languageCodes: 'be,ru',
  },
  {
    name: 'Bolivia',
    nameOfficialIso: 'Bolivia (Estado Plurinacional de)',
    codeAlfa_2: 'BO',
    codeAlfa_3: 'BOL',
    codeNumeric: '068',
    topLevelDomain: 'bo',
    FIPS: 'BL',
    geoNameID: 3923057,
    E164: 591,
    phoneCode: '591',
    continent: 'South America',
    capital: 'Sucre',
    timeZoneInCapital: 'America/La_Paz',
    currency: 'Boliviano',
    languageCodes: 'es-BO,qu,ay',
  },
  {
    name: 'Bosnia y Herzegovina',
    nameOfficialIso: 'Bosnia y Herzegovina',
    codeAlfa_2: 'BA',
    codeAlfa_3: 'BIH',
    codeNumeric: '070',
    topLevelDomain: 'ba',
    FIPS: 'BK',
    geoNameID: 3277605,
    E164: 387,
    phoneCode: '387',
    continent: 'Europe',
    capital: 'Sarajevo',
    timeZoneInCapital: 'Europe/Belgrade',
    currency: 'Marka',
    languageCodes: 'bs,hr-BA,sr-BA',
  },
  {
    name: 'Botsuana',
    nameOfficialIso: 'Botsuana',
    codeAlfa_2: 'BW',
    codeAlfa_3: 'BWA',
    codeNumeric: '072',
    topLevelDomain: 'bw',
    FIPS: 'BC',
    geoNameID: 933860,
    E164: 267,
    phoneCode: '267',
    continent: 'Africa',
    capital: 'Gaborone',
    timeZoneInCapital: 'Africa/Maputo',
    currency: 'Pula',
    languageCodes: 'en-BW,tn-BW',
  },
  {
    name: 'Brasil',
    nameOfficialIso: 'Brasil',
    codeAlfa_2: 'BR',
    codeAlfa_3: 'BRA',
    codeNumeric: '076',
    topLevelDomain: 'br',
    FIPS: 'BR',
    geoNameID: 3469034,
    E164: 55,
    phoneCode: '55',
    continent: 'South America',
    capital: 'Brasilia',
    timeZoneInCapital: 'America/Sao_Paulo',
    currency: 'Real',
    languageCodes: 'pt-BR,es,en,fr',
  },
  {
    name: 'Brunéi',
    nameOfficialIso: 'Brunéi Darussalam',
    codeAlfa_2: 'BN',
    codeAlfa_3: 'BRN',
    codeNumeric: '096',
    topLevelDomain: 'bn',
    FIPS: 'BX',
    geoNameID: 1820814,
    E164: 673,
    phoneCode: '673',
    continent: 'Asia',
    capital: 'Bandar Seri Begawan',
    timeZoneInCapital: 'Asia/Brunei',
    currency: 'Dollar',
    languageCodes: 'ms-BN,en-BN',
  },
  {
    name: 'Bulgaria',
    nameOfficialIso: 'Bulgaria',
    codeAlfa_2: 'BG',
    codeAlfa_3: 'BGR',
    codeNumeric: '100',
    topLevelDomain: 'bg',
    FIPS: 'BU',
    geoNameID: 732800,
    E164: 359,
    phoneCode: '359',
    continent: 'Europe',
    capital: 'Sofia',
    timeZoneInCapital: 'Europe/Sofia',
    currency: 'Lev',
    languageCodes: 'bg,tr-BG',
  },
  {
    name: 'Burkina Faso',
    nameOfficialIso: 'Burkina Faso',
    codeAlfa_2: 'BF',
    codeAlfa_3: 'BFA',
    codeNumeric: '854',
    topLevelDomain: 'bf',
    FIPS: 'UV',
    geoNameID: 2361809,
    E164: 226,
    phoneCode: '226',
    continent: 'Africa',
    capital: 'Ouagadougou',
    timeZoneInCapital: 'Africa/Abidjan',
    currency: 'Franc',
    languageCodes: 'fr-BF',
  },
  {
    name: 'Burundi',
    nameOfficialIso: 'Burundi',
    codeAlfa_2: 'BI',
    codeAlfa_3: 'BDI',
    codeNumeric: '108',
    topLevelDomain: 'bi',
    FIPS: 'BY',
    geoNameID: 433561,
    E164: 257,
    phoneCode: '257',
    continent: 'Africa',
    capital: 'Bujumbura',
    timeZoneInCapital: 'Africa/Maputo',
    currency: 'Franc',
    languageCodes: 'fr-BI,rn',
  },
  {
    name: 'Bután',
    nameOfficialIso: 'Bhután',
    codeAlfa_2: 'BT',
    codeAlfa_3: 'BTN',
    codeNumeric: '064',
    topLevelDomain: 'bt',
    FIPS: 'BT',
    geoNameID: 1252634,
    E164: 975,
    phoneCode: '975',
    continent: 'Asia',
    capital: 'Thimphu',
    timeZoneInCapital: 'Asia/Thimphu',
    currency: 'Ngultrum',
    languageCodes: 'dz',
  },
  {
    name: 'Cabo Verde',
    nameOfficialIso: 'Cabo Verde',
    codeAlfa_2: 'CV',
    codeAlfa_3: 'CPV',
    codeNumeric: '132',
    topLevelDomain: 'cv',
    FIPS: 'CV',
    geoNameID: 3374766,
    E164: 238,
    phoneCode: '238',
    continent: 'Africa',
    capital: 'Praia',
    timeZoneInCapital: 'Atlantic/Cape_Verde',
    currency: 'Escudo',
    languageCodes: 'pt-CV',
  },
  {
    name: 'Camboya',
    nameOfficialIso: 'Camboya',
    codeAlfa_2: 'KH',
    codeAlfa_3: 'KHM',
    codeNumeric: '116',
    topLevelDomain: 'kh',
    FIPS: 'CB',
    geoNameID: 1831722,
    E164: 855,
    phoneCode: '855',
    continent: 'Asia',
    capital: 'Phnom Penh',
    timeZoneInCapital: 'Asia/Phnom_Penh',
    currency: 'Riels',
    languageCodes: 'km,fr,en',
  },
  {
    name: 'Camerún',
    nameOfficialIso: 'Camerún',
    codeAlfa_2: 'CM',
    codeAlfa_3: 'CMR',
    codeNumeric: '120',
    topLevelDomain: 'cm',
    FIPS: 'CM',
    geoNameID: 2233387,
    E164: 237,
    phoneCode: '237',
    continent: 'Africa',
    capital: 'Yaounde',
    timeZoneInCapital: 'Africa/Lagos',
    currency: 'Franc',
    languageCodes: 'en-CM,fr-CM',
  },
  {
    name: 'Canadá',
    nameOfficialIso: 'Canadá',
    codeAlfa_2: 'CA',
    codeAlfa_3: 'CAN',
    codeNumeric: '124',
    topLevelDomain: 'ca',
    FIPS: 'CA',
    geoNameID: 6251999,
    E164: 1,
    phoneCode: '1',
    continent: 'North America',
    capital: 'Ottawa',
    timeZoneInCapital: 'America/Toronto',
    currency: 'Dollar',
    languageCodes: 'en-CA,fr-CA,iu',
  },
  {
    name: 'Chile',
    nameOfficialIso: 'Chile',
    codeAlfa_2: 'CL',
    codeAlfa_3: 'CHL',
    codeNumeric: '152',
    topLevelDomain: 'cl',
    FIPS: 'CI',
    geoNameID: 3895114,
    E164: 56,
    phoneCode: '56',
    continent: 'South America',
    capital: 'Santiago',
    timeZoneInCapital: 'America/Santiago',
    currency: 'Peso',
    languageCodes: 'es-CL',
  },
  {
    name: 'China',
    nameOfficialIso: 'China',
    codeAlfa_2: 'CN',
    codeAlfa_3: 'CHN',
    codeNumeric: '156',
    topLevelDomain: 'cn',
    FIPS: 'CH',
    geoNameID: 1814991,
    E164: 86,
    phoneCode: '86',
    continent: 'Asia',
    capital: 'Beijing',
    timeZoneInCapital: 'Asia/Shanghai',
    currency: 'Yuan Renminbi',
    languageCodes: 'zh-CN,yue,wuu,dta,ug,za',
  },
  {
    name: 'Chad',
    nameOfficialIso: 'Chad',
    codeAlfa_2: 'TD',
    codeAlfa_3: 'TCD',
    codeNumeric: '148',
    topLevelDomain: 'td',
    FIPS: 'CD',
    geoNameID: 2434508,
    E164: 235,
    phoneCode: '235',
    continent: 'Africa',
    capital: "N'Djamena",
    timeZoneInCapital: 'Africa/Ndjamena',
    currency: 'Franc',
    languageCodes: 'fr-TD,ar-TD,sre',
  },
  {
    name: 'Corea del Norte',
    nameOfficialIso: 'Corea (la República Popular Democrática de)',
    codeAlfa_2: 'KP',
    codeAlfa_3: 'PRK',
    codeNumeric: '408',
    topLevelDomain: 'kp',
    FIPS: 'KN',
    geoNameID: 1873107,
    E164: 850,
    phoneCode: '850',
    continent: 'Asia',
    capital: 'Pyongyang',
    timeZoneInCapital: 'Asia/Pyongyang',
    currency: 'Won',
    languageCodes: 'ko-KP',
  },
  {
    name: 'Corea del Sur',
    nameOfficialIso: 'Corea (la República de)',
    codeAlfa_2: 'KR',
    codeAlfa_3: 'KOR',
    codeNumeric: '410',
    topLevelDomain: 'kr',
    FIPS: 'KS',
    geoNameID: 1835841,
    E164: 82,
    phoneCode: '82',
    continent: 'Asia',
    capital: 'Seoul',
    timeZoneInCapital: 'Asia/Seoul',
    currency: 'Won',
    languageCodes: 'ko-KR,en',
  },
  {
    name: 'Chipre',
    nameOfficialIso: 'Chipre',
    codeAlfa_2: 'CY',
    codeAlfa_3: 'CYP',
    codeNumeric: '196',
    topLevelDomain: 'cy',
    FIPS: 'CY',
    geoNameID: 146669,
    E164: 357,
    phoneCode: '357',
    continent: 'Europe',
    capital: 'Nicosia',
    timeZoneInCapital: 'Asia/Nicosia',
    currency: 'Euro',
    languageCodes: 'el-CY,tr-CY,en',
  },
  {
    name: 'Catar',
    nameOfficialIso: 'Qatar',
    codeAlfa_2: 'QA',
    codeAlfa_3: 'QAT',
    codeNumeric: '634',
    topLevelDomain: 'qa',
    FIPS: 'QA',
    geoNameID: 289688,
    E164: 974,
    phoneCode: '974',
    continent: 'Asia',
    capital: 'Doha',
    timeZoneInCapital: 'Asia/Qatar',
    currency: 'Rial',
    languageCodes: 'ar-QA,es',
  },
  {
    name: 'Colombia',
    nameOfficialIso: 'Colombia',
    codeAlfa_2: 'CO',
    codeAlfa_3: 'COL',
    codeNumeric: '170',
    topLevelDomain: 'co',
    FIPS: 'CO',
    geoNameID: 3686110,
    E164: 57,
    phoneCode: '57',
    continent: 'South America',
    capital: 'Bogota',
    timeZoneInCapital: 'America/Bogota',
    currency: 'Peso',
    languageCodes: 'es-CO',
  },
  {
    name: 'Comoras',
    nameOfficialIso: 'Comoras (las)',
    codeAlfa_2: 'KM',
    codeAlfa_3: 'COM',
    codeNumeric: '174',
    topLevelDomain: 'km',
    FIPS: 'CN',
    geoNameID: 921929,
    E164: 269,
    phoneCode: '269',
    continent: 'Africa',
    capital: 'Moroni',
    timeZoneInCapital: 'Indian/Comoro',
    currency: 'Franc',
    languageCodes: 'ar,fr-KM',
  },
  {
    name: 'Costa de Marfil',
    nameOfficialIso: "Côte d'Ivoire",
    codeAlfa_2: 'CI',
    codeAlfa_3: 'CIV',
    codeNumeric: '384',
    topLevelDomain: 'ci',
    FIPS: 'IV',
    geoNameID: 2287781,
    E164: 225,
    phoneCode: '225',
    continent: 'Africa',
    capital: 'Yamoussoukro',
    timeZoneInCapital: 'Africa/Abidjan',
    currency: 'Franc',
    languageCodes: 'fr-CI',
  },
  {
    name: 'Costa Rica',
    nameOfficialIso: 'Costa Rica',
    codeAlfa_2: 'CR',
    codeAlfa_3: 'CRI',
    codeNumeric: '188',
    topLevelDomain: 'cr',
    FIPS: 'CS',
    geoNameID: 3624060,
    E164: 506,
    phoneCode: '506',
    continent: 'North America',
    capital: 'San Jose',
    timeZoneInCapital: 'America/Costa_Rica',
    currency: 'Colon',
    languageCodes: 'es-CR,en',
  },
  {
    name: 'Croacia',
    nameOfficialIso: 'Croacia',
    codeAlfa_2: 'HR',
    codeAlfa_3: 'HRV',
    codeNumeric: '191',
    topLevelDomain: 'hr',
    FIPS: 'HR',
    geoNameID: 3202326,
    E164: 385,
    phoneCode: '385',
    continent: 'Europe',
    capital: 'Zagreb',
    timeZoneInCapital: 'Europe/Belgrade',
    currency: 'Kuna',
    languageCodes: 'hr-HR,sr',
  },
  {
    name: 'Cuba',
    nameOfficialIso: 'Cuba',
    codeAlfa_2: 'CU',
    codeAlfa_3: 'CUB',
    codeNumeric: '192',
    topLevelDomain: 'cu',
    FIPS: 'CU',
    geoNameID: 3562981,
    E164: 53,
    phoneCode: '53',
    continent: 'North America',
    capital: 'Havana',
    timeZoneInCapital: 'America/Havana',
    currency: 'Peso',
    languageCodes: 'es-CU',
  },
  {
    name: 'Curazao',
    nameOfficialIso: 'Curaçao',
    codeAlfa_2: 'CW',
    codeAlfa_3: 'CUW',
    codeNumeric: '531',
    topLevelDomain: 'cw',
    FIPS: 'UC',
    geoNameID: 7626836,
    E164: 599,
    phoneCode: '599',
    continent: 'North America',
    capital: 'Willemstad',
    timeZoneInCapital: 'America/Curacao',
    currency: 'Guilder',
    languageCodes: 'nl,pap',
  },
  {
    name: 'Dinamarca',
    nameOfficialIso: 'Dinamarca',
    codeAlfa_2: 'DK',
    codeAlfa_3: 'DNK',
    codeNumeric: '208',
    topLevelDomain: 'dk',
    FIPS: 'DA',
    geoNameID: 2623032,
    E164: 45,
    phoneCode: '45',
    continent: 'Europe',
    capital: 'Copenhagen',
    timeZoneInCapital: 'Europe/Copenhagen',
    currency: 'Krone',
    languageCodes: 'da-DK,en,fo,de-DK',
  },
  {
    name: 'Dominica',
    nameOfficialIso: 'Dominica',
    codeAlfa_2: 'DM',
    codeAlfa_3: 'DMA',
    codeNumeric: '212',
    topLevelDomain: 'dm',
    FIPS: 'DO',
    geoNameID: 3575830,
    E164: 1,
    phoneCode: '1-767',
    continent: 'North America',
    capital: 'Roseau',
    timeZoneInCapital: 'America/Port_of_Spain',
    currency: 'Dollar',
    languageCodes: 'en-DM',
  },
  {
    name: 'Ecuador',
    nameOfficialIso: 'Ecuador',
    codeAlfa_2: 'EC',
    codeAlfa_3: 'ECU',
    codeNumeric: '218',
    topLevelDomain: 'ec',
    FIPS: 'EC',
    geoNameID: 3658394,
    E164: 593,
    phoneCode: '593',
    continent: 'South America',
    capital: 'Quito',
    timeZoneInCapital: 'America/Guayaquil',
    currency: 'Dollar',
    languageCodes: 'es-EC',
  },
  {
    name: 'Egipto',
    nameOfficialIso: 'Egipto',
    codeAlfa_2: 'EG',
    codeAlfa_3: 'EGY',
    codeNumeric: '818',
    topLevelDomain: 'eg',
    FIPS: 'EG',
    geoNameID: 357994,
    E164: 20,
    phoneCode: '20',
    continent: 'Africa',
    capital: 'Cairo',
    timeZoneInCapital: 'Africa/Cairo',
    currency: 'Pound',
    languageCodes: 'ar-EG,en,fr',
  },
  {
    name: 'El Salvador',
    nameOfficialIso: 'El Salvador',
    codeAlfa_2: 'SV',
    codeAlfa_3: 'SLV',
    codeNumeric: '222',
    topLevelDomain: 'sv',
    FIPS: 'ES',
    geoNameID: 3585968,
    E164: 503,
    phoneCode: '503',
    continent: 'North America',
    capital: 'San Salvador',
    timeZoneInCapital: 'America/El_Salvador',
    currency: 'Dollar',
    languageCodes: 'es-SV',
  },
  {
    name: 'Emiratos Árabes Unidos',
    nameOfficialIso: 'Emiratos Árabes Unidos (los)',
    codeAlfa_2: 'AE',
    codeAlfa_3: 'ARE',
    codeNumeric: '784',
    topLevelDomain: 'ae',
    FIPS: 'AE',
    geoNameID: 290557,
    E164: 971,
    phoneCode: '971',
    continent: 'Asia',
    capital: 'Abu Dhabi',
    timeZoneInCapital: 'Asia/Dubai',
    currency: 'Dirham',
    languageCodes: 'ar-AE,fa,en,hi,ur',
  },
  {
    name: 'Eritrea',
    nameOfficialIso: 'Eritrea',
    codeAlfa_2: 'ER',
    codeAlfa_3: 'ERI',
    codeNumeric: '232',
    topLevelDomain: 'er',
    FIPS: 'ER',
    geoNameID: 338010,
    E164: 291,
    phoneCode: '291',
    continent: 'Africa',
    capital: 'Asmara',
    timeZoneInCapital: 'Africa/Asmara',
    currency: 'Nakfa',
    languageCodes: 'aa-ER,ar,tig,kun,ti-ER',
  },
  {
    name: 'Eslovaquia',
    nameOfficialIso: 'Eslovaquia',
    codeAlfa_2: 'SK',
    codeAlfa_3: 'SVK',
    codeNumeric: '703',
    topLevelDomain: 'sk',
    FIPS: 'LO',
    geoNameID: 3057568,
    E164: 421,
    phoneCode: '421',
    continent: 'Europe',
    capital: 'Bratislava',
    timeZoneInCapital: 'Europe/Prague',
    currency: 'Euro',
    languageCodes: 'sk,hu',
  },
  {
    name: 'Eslovenia',
    nameOfficialIso: 'Eslovenia',
    codeAlfa_2: 'SI',
    codeAlfa_3: 'SVN',
    codeNumeric: '705',
    topLevelDomain: 'si',
    FIPS: 'SI',
    geoNameID: 3190538,
    E164: 386,
    phoneCode: '386',
    continent: 'Europe',
    capital: 'Ljubljana',
    timeZoneInCapital: 'Europe/Belgrade',
    currency: 'Euro',
    languageCodes: 'sl,sh',
  },
  {
    name: 'España',
    nameOfficialIso: 'España',
    codeAlfa_2: 'ES',
    codeAlfa_3: 'ESP',
    codeNumeric: '724',
    topLevelDomain: 'es',
    FIPS: 'SP',
    geoNameID: 2510769,
    E164: 34,
    phoneCode: '34',
    continent: 'Europe',
    capital: 'Madrid',
    timeZoneInCapital: 'Europe/Madrid',
    currency: 'Euro',
    languageCodes: 'es-ES,ca,gl,eu,oc',
  },
  {
    name: 'Estonia',
    nameOfficialIso: 'Estonia',
    codeAlfa_2: 'EE',
    codeAlfa_3: 'EST',
    codeNumeric: '233',
    topLevelDomain: 'ee',
    FIPS: 'EN',
    geoNameID: 453733,
    E164: 372,
    phoneCode: '372',
    continent: 'Europe',
    capital: 'Tallinn',
    timeZoneInCapital: 'Europe/Tallinn',
    currency: 'Euro',
    languageCodes: 'et,ru',
  },
  {
    name: 'Estados Unidos',
    nameOfficialIso: 'Estados Unidos de América (los)',
    codeAlfa_2: 'US',
    codeAlfa_3: 'USA',
    codeNumeric: '840',
    topLevelDomain: 'us',
    FIPS: 'US',
    geoNameID: 6252001,
    E164: 1,
    phoneCode: '1',
    continent: 'North America',
    capital: 'Washington',
    timeZoneInCapital: 'America/New_York',
    currency: 'Dollar',
    languageCodes: 'en-US,es-US,haw,fr',
  },
  {
    name: 'Etiopía',
    nameOfficialIso: 'Etiopía',
    codeAlfa_2: 'ET',
    codeAlfa_3: 'ETH',
    codeNumeric: '231',
    topLevelDomain: 'et',
    FIPS: 'ET',
    geoNameID: 337996,
    E164: 251,
    phoneCode: '251',
    continent: 'Africa',
    capital: 'Addis Ababa',
    timeZoneInCapital: 'Africa/Addis_Ababa',
    currency: 'Birr',
    languageCodes: 'am,en-ET,om-ET,ti-ET,so-ET,sid',
  },
  {
    name: 'Fiyi',
    nameOfficialIso: 'Fiji',
    codeAlfa_2: 'FJ',
    codeAlfa_3: 'FJI',
    codeNumeric: '242',
    topLevelDomain: 'fj',
    FIPS: 'FJ',
    geoNameID: 2205218,
    E164: 679,
    phoneCode: '679',
    continent: 'Oceania',
    capital: 'Suva',
    timeZoneInCapital: 'Pacific/Fiji',
    currency: 'Dollar',
    languageCodes: 'en-FJ,fj',
  },
  {
    name: 'Filipinas',
    nameOfficialIso: 'Filipinas (las)',
    codeAlfa_2: 'PH',
    codeAlfa_3: 'PHL',
    codeNumeric: '608',
    topLevelDomain: 'ph',
    FIPS: 'RP',
    geoNameID: 1694008,
    E164: 63,
    phoneCode: '63',
    continent: 'Asia',
    capital: 'Manila',
    timeZoneInCapital: 'Asia/Manila',
    currency: 'Peso',
    languageCodes: 'tl,en-PH,fil',
  },
  {
    name: 'Finlandia',
    nameOfficialIso: 'Finlandia',
    codeAlfa_2: 'FI',
    codeAlfa_3: 'FIN',
    codeNumeric: '246',
    topLevelDomain: 'fi',
    FIPS: 'FI',
    geoNameID: 660013,
    E164: 358,
    phoneCode: '358',
    continent: 'Europe',
    capital: 'Helsinki',
    timeZoneInCapital: 'Europe/Helsinki',
    currency: 'Euro',
    languageCodes: 'fi-FI,sv-FI,smn',
  },
  {
    name: 'Francia',
    nameOfficialIso: 'Francia',
    codeAlfa_2: 'FR',
    codeAlfa_3: 'FRA',
    codeNumeric: '250',
    topLevelDomain: 'fr',
    FIPS: 'FR',
    geoNameID: 3017382,
    E164: 33,
    phoneCode: '33',
    continent: 'Europe',
    capital: 'Paris',
    timeZoneInCapital: 'Europe/Paris',
    currency: 'Euro',
    languageCodes: 'fr-FR,frp,br,co,ca,eu,oc',
  },
  {
    name: 'Gabón',
    nameOfficialIso: 'Gabón',
    codeAlfa_2: 'GA',
    codeAlfa_3: 'GAB',
    codeNumeric: '266',
    topLevelDomain: 'ga',
    FIPS: 'GB',
    geoNameID: 2400553,
    E164: 241,
    phoneCode: '241',
    continent: 'Africa',
    capital: 'Libreville',
    timeZoneInCapital: 'Africa/Lagos',
    currency: 'Franc',
    languageCodes: 'fr-GA',
  },
  {
    name: 'Gambia',
    nameOfficialIso: 'Gambia (la)',
    codeAlfa_2: 'GM',
    codeAlfa_3: 'GMB',
    codeNumeric: '270',
    topLevelDomain: 'gm',
    FIPS: 'GA',
    geoNameID: 2413451,
    E164: 220,
    phoneCode: '220',
    continent: 'Africa',
    capital: 'Banjul',
    timeZoneInCapital: 'Africa/Abidjan',
    currency: 'Dalasi',
    languageCodes: 'en-GM,mnk,wof,wo,ff',
  },
  {
    name: 'Georgia',
    nameOfficialIso: 'Georgia',
    codeAlfa_2: 'GE',
    codeAlfa_3: 'GEO',
    codeNumeric: '268',
    topLevelDomain: 'ge',
    FIPS: 'GG',
    geoNameID: 614540,
    E164: 995,
    phoneCode: '995',
    continent: 'Asia',
    capital: 'Tbilisi',
    timeZoneInCapital: 'Asia/Tbilisi',
    currency: 'Lari',
    languageCodes: 'ka,ru,hy,az',
  },
  {
    name: 'Ghana',
    nameOfficialIso: 'Ghana',
    codeAlfa_2: 'GH',
    codeAlfa_3: 'GHA',
    codeNumeric: '288',
    topLevelDomain: 'gh',
    FIPS: 'GH',
    geoNameID: 2300660,
    E164: 233,
    phoneCode: '233',
    continent: 'Africa',
    capital: 'Accra',
    timeZoneInCapital: 'Africa/Accra',
    currency: 'Cedi',
    languageCodes: 'en-GH,ak,ee,tw',
  },
  {
    name: 'Gibraltar',
    nameOfficialIso: 'Gibraltar',
    codeAlfa_2: 'GI',
    codeAlfa_3: 'GIB',
    codeNumeric: '292',
    topLevelDomain: 'gi',
    FIPS: 'GI',
    geoNameID: 2411586,
    E164: 350,
    phoneCode: '350',
    continent: 'Europe',
    capital: 'Gibraltar',
    timeZoneInCapital: 'Europe/Gibraltar',
    currency: 'Pound',
    languageCodes: 'en-GI,es,it,pt',
  },
  {
    name: 'Granada',
    nameOfficialIso: 'Granada',
    codeAlfa_2: 'GD',
    codeAlfa_3: 'GRD',
    codeNumeric: '308',
    topLevelDomain: 'gd',
    FIPS: 'GJ',
    geoNameID: 3580239,
    E164: 1,
    phoneCode: '1-473',
    continent: 'North America',
    capital: "St. George's",
    timeZoneInCapital: 'America/Port_of_Spain',
    currency: 'Dollar',
    languageCodes: 'en-GD',
  },
  {
    name: 'Grecia',
    nameOfficialIso: 'Grecia',
    codeAlfa_2: 'GR',
    codeAlfa_3: 'GRC',
    codeNumeric: '300',
    topLevelDomain: 'gr',
    FIPS: 'GR',
    geoNameID: 390903,
    E164: 30,
    phoneCode: '30',
    continent: 'Europe',
    capital: 'Athens',
    timeZoneInCapital: 'Europe/Athens',
    currency: 'Euro',
    languageCodes: 'el-GR,en,fr',
  },
  {
    name: 'Groenlandia',
    nameOfficialIso: 'Groenlandia',
    codeAlfa_2: 'GL',
    codeAlfa_3: 'GRL',
    codeNumeric: '304',
    topLevelDomain: 'gl',
    FIPS: 'GL',
    geoNameID: 3425505,
    E164: 299,
    phoneCode: '299',
    continent: 'North America',
    capital: 'Nuuk',
    timeZoneInCapital: 'America/Godthab',
    currency: 'Krone',
    languageCodes: 'kl,da-GL,en',
  },
  {
    name: 'Guam',
    nameOfficialIso: 'Guam',
    codeAlfa_2: 'GU',
    codeAlfa_3: 'GUM',
    codeNumeric: '316',
    topLevelDomain: 'gu',
    FIPS: 'GQ',
    geoNameID: 4043988,
    E164: 1,
    phoneCode: '1-671',
    continent: 'Oceania',
    capital: 'Hagatna',
    timeZoneInCapital: 'Pacific/Guam',
    currency: 'Dollar',
    languageCodes: 'en-GU,ch-GU',
  },
  {
    name: 'Guatemala',
    nameOfficialIso: 'Guatemala',
    codeAlfa_2: 'GT',
    codeAlfa_3: 'GTM',
    codeNumeric: '320',
    topLevelDomain: 'gt',
    FIPS: 'GT',
    geoNameID: 3595528,
    E164: 502,
    phoneCode: '502',
    continent: 'North America',
    capital: 'Guatemala City',
    timeZoneInCapital: 'America/Guatemala',
    currency: 'Quetzal',
    languageCodes: 'es-GT',
  },
  {
    name: 'Guernsey',
    nameOfficialIso: 'Guernsey',
    codeAlfa_2: 'GG',
    codeAlfa_3: 'GGY',
    codeNumeric: '831',
    topLevelDomain: 'gg',
    FIPS: 'GK',
    geoNameID: 3042362,
    E164: 44,
    phoneCode: '44-1481',
    continent: 'Europe',
    capital: 'St Peter Port',
    timeZoneInCapital: 'Europe/London',
    currency: 'Pound',
    languageCodes: 'en,fr',
  },
  {
    name: 'Guinea',
    nameOfficialIso: 'Guinea',
    codeAlfa_2: 'GN',
    codeAlfa_3: 'GIN',
    codeNumeric: '324',
    topLevelDomain: 'gn',
    FIPS: 'GV',
    geoNameID: 2420477,
    E164: 224,
    phoneCode: '224',
    continent: 'Africa',
    capital: 'Conakry',
    timeZoneInCapital: 'Africa/Abidjan',
    currency: 'Franc',
    languageCodes: 'fr-GN',
  },
  {
    name: 'Guinea-Bisáu',
    nameOfficialIso: 'Guinea Bissau',
    codeAlfa_2: 'GW',
    codeAlfa_3: 'GNB',
    codeNumeric: '624',
    topLevelDomain: 'gw',
    FIPS: 'PU',
    geoNameID: 2372248,
    E164: 245,
    phoneCode: '245',
    continent: 'Africa',
    capital: 'Bissau',
    timeZoneInCapital: 'Africa/Bissau',
    currency: 'Franc',
    languageCodes: 'pt-GW,pov',
  },
  {
    name: 'Guinea Ecuatorial',
    nameOfficialIso: 'Guinea Ecuatorial',
    codeAlfa_2: 'GQ',
    codeAlfa_3: 'GNQ',
    codeNumeric: '226',
    topLevelDomain: 'gq',
    FIPS: 'EK',
    geoNameID: 2309096,
    E164: 240,
    phoneCode: '240',
    continent: 'Africa',
    capital: 'Malabo',
    timeZoneInCapital: 'Africa/Lagos',
    currency: 'Franc',
    languageCodes: 'es-GQ,fr',
  },
  {
    name: 'Guyana',
    nameOfficialIso: 'Guyana',
    codeAlfa_2: 'GY',
    codeAlfa_3: 'GUY',
    codeNumeric: '328',
    topLevelDomain: 'gy',
    FIPS: 'GY',
    geoNameID: 3378535,
    E164: 592,
    phoneCode: '592',
    continent: 'South America',
    capital: 'Georgetown',
    timeZoneInCapital: 'America/Guyana',
    currency: 'Dollar',
    languageCodes: 'en-GY',
  },
  {
    name: 'Haití',
    nameOfficialIso: 'Haití',
    codeAlfa_2: 'HT',
    codeAlfa_3: 'HTI',
    codeNumeric: '332',
    topLevelDomain: 'ht',
    FIPS: 'HA',
    geoNameID: 3723988,
    E164: 509,
    phoneCode: '509',
    continent: 'North America',
    capital: 'Port-au-Prince',
    timeZoneInCapital: 'America/Port-au-Prince',
    currency: 'Gourde',
    languageCodes: 'ht,fr-HT',
  },
  {
    name: 'Honduras',
    nameOfficialIso: 'Honduras',
    codeAlfa_2: 'HN',
    codeAlfa_3: 'HND',
    codeNumeric: '340',
    topLevelDomain: 'hn',
    FIPS: 'HO',
    geoNameID: 3608932,
    E164: 504,
    phoneCode: '504',
    continent: 'North America',
    capital: 'Tegucigalpa',
    timeZoneInCapital: 'America/Tegucigalpa',
    currency: 'Lempira',
    languageCodes: 'es-HN',
  },
  {
    name: 'Hong Kong',
    nameOfficialIso: 'Hong Kong',
    codeAlfa_2: 'HK',
    codeAlfa_3: 'HKG',
    codeNumeric: '344',
    topLevelDomain: 'hk',
    FIPS: 'HK',
    geoNameID: 1819730,
    E164: 852,
    phoneCode: '852',
    continent: 'Asia',
    capital: 'Hong Kong',
    timeZoneInCapital: 'Asia/Hong_Kong',
    currency: 'Dollar',
    languageCodes: 'zh-HK,yue,zh,en',
  },
  {
    name: 'Hungría',
    nameOfficialIso: 'Hungría',
    codeAlfa_2: 'HU',
    codeAlfa_3: 'HUN',
    codeNumeric: '348',
    topLevelDomain: 'hu',
    FIPS: 'HU',
    geoNameID: 719819,
    E164: 36,
    phoneCode: '36',
    continent: 'Europe',
    capital: 'Budapest',
    timeZoneInCapital: 'Europe/Budapest',
    currency: 'Forint',
    languageCodes: 'hu-HU',
  },
  {
    name: 'India',
    nameOfficialIso: 'India',
    codeAlfa_2: 'IN',
    codeAlfa_3: 'IND',
    codeNumeric: '356',
    topLevelDomain: 'in',
    FIPS: 'IN',
    geoNameID: 1269750,
    E164: 91,
    phoneCode: '91',
    continent: 'Asia',
    capital: 'New Delhi',
    timeZoneInCapital: 'Asia/Kolkata',
    currency: 'Rupee',
    languageCodes: 'en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc',
  },
  {
    name: 'Indonesia',
    nameOfficialIso: 'Indonesia',
    codeAlfa_2: 'ID',
    codeAlfa_3: 'IDN',
    codeNumeric: '360',
    topLevelDomain: 'id',
    FIPS: 'ID',
    geoNameID: 1643084,
    E164: 62,
    phoneCode: '62',
    continent: 'Asia',
    capital: 'Jakarta',
    timeZoneInCapital: 'Asia/Jakarta',
    currency: 'Rupiah',
    languageCodes: 'id,en,nl,jv',
  },
  {
    name: 'Irak',
    nameOfficialIso: 'Irak',
    codeAlfa_2: 'IQ',
    codeAlfa_3: 'IRQ',
    codeNumeric: '368',
    topLevelDomain: 'iq',
    FIPS: 'IZ',
    geoNameID: 99237,
    E164: 964,
    phoneCode: '964',
    continent: 'Asia',
    capital: 'Baghdad',
    timeZoneInCapital: 'Asia/Baghdad',
    currency: 'Dinar',
    languageCodes: 'ar-IQ,ku,hy',
  },
  {
    name: 'Irán',
    nameOfficialIso: 'Irán (República Islámica de)',
    codeAlfa_2: 'IR',
    codeAlfa_3: 'IRN',
    codeNumeric: '364',
    topLevelDomain: 'ir',
    FIPS: 'IR',
    geoNameID: 130758,
    E164: 98,
    phoneCode: '98',
    continent: 'Asia',
    capital: 'Tehran',
    timeZoneInCapital: 'Asia/Tehran',
    currency: 'Rial',
    languageCodes: 'fa-IR,ku',
  },
  {
    name: 'Irlanda',
    nameOfficialIso: 'Irlanda',
    codeAlfa_2: 'IE',
    codeAlfa_3: 'IRL',
    codeNumeric: '372',
    topLevelDomain: 'ie',
    FIPS: 'EI',
    geoNameID: 2963597,
    E164: 353,
    phoneCode: '353',
    continent: 'Europe',
    capital: 'Dublin',
    timeZoneInCapital: 'Europe/Dublin',
    currency: 'Euro',
    languageCodes: 'en-IE,ga-IE',
  },
  {
    name: 'Isla de Man',
    nameOfficialIso: 'Isla de Man',
    codeAlfa_2: 'IM',
    codeAlfa_3: 'IMN',
    codeNumeric: '833',
    topLevelDomain: 'im',
    FIPS: 'IM',
    geoNameID: 3042225,
    E164: 44,
    phoneCode: '44-1624',
    continent: 'Europe',
    capital: 'Douglas, Isle of Man',
    timeZoneInCapital: 'Europe/London',
    currency: 'Pound',
    languageCodes: 'en,gv',
  },
  {
    name: 'Isla de Navidad',
    nameOfficialIso: 'Navidad, Isla de',
    codeAlfa_2: 'CX',
    codeAlfa_3: 'CXR',
    codeNumeric: '162',
    topLevelDomain: 'cx',
    FIPS: 'KT',
    geoNameID: 2078138,
    E164: 61,
    phoneCode: '61',
    continent: 'Asia',
    capital: 'Flying Fish Cove',
    timeZoneInCapital: 'Indian/Christmas',
    currency: 'Dollar',
    languageCodes: 'en,zh,ms-CC',
  },
  {
    name: 'Islandia',
    nameOfficialIso: 'Islandia',
    codeAlfa_2: 'IS',
    codeAlfa_3: 'ISL',
    codeNumeric: '352',
    topLevelDomain: 'is',
    FIPS: 'IC',
    geoNameID: 2629691,
    E164: 354,
    phoneCode: '354',
    continent: 'Europe',
    capital: 'Reykjavik',
    timeZoneInCapital: 'Atlantic/Reykjavik',
    currency: 'Krona',
    languageCodes: 'is,en,de,da,sv,no',
  },
  {
    name: 'Islas Caimán',
    nameOfficialIso: 'Caimán, (las) Islas',
    codeAlfa_2: 'KY',
    codeAlfa_3: 'CYM',
    codeNumeric: '136',
    topLevelDomain: 'ky',
    FIPS: 'CJ',
    geoNameID: 3580718,
    E164: 1,
    phoneCode: '1-345',
    continent: 'North America',
    capital: 'George Town',
    timeZoneInCapital: 'America/Cayman',
    currency: 'Dollar',
    languageCodes: 'en-KY',
  },
  {
    name: 'Islas Cocos',
    nameOfficialIso: 'Cocos / Keeling, (las) Islas',
    codeAlfa_2: 'CC',
    codeAlfa_3: 'CCK',
    codeNumeric: '166',
    topLevelDomain: 'cc',
    FIPS: 'CK',
    geoNameID: 1547376,
    E164: 61,
    phoneCode: '61',
    continent: 'Asia',
    capital: 'West Island',
    timeZoneInCapital: 'Indian/Cocos',
    currency: 'Dollar',
    languageCodes: 'ms-CC,en',
  },
  {
    name: 'Islas Cook',
    nameOfficialIso: 'Cook, (las) Islas',
    codeAlfa_2: 'CK',
    codeAlfa_3: 'COK',
    codeNumeric: '184',
    topLevelDomain: 'ck',
    FIPS: 'CW',
    geoNameID: 1899402,
    E164: 682,
    phoneCode: '682',
    continent: 'Oceania',
    capital: 'Avarua',
    timeZoneInCapital: 'Pacific/Rarotonga',
    currency: 'Dollar',
    languageCodes: 'en-CK,mi',
  },
  {
    name: 'Islas Feroe',
    nameOfficialIso: 'Feroe, (las) Islas',
    codeAlfa_2: 'FO',
    codeAlfa_3: 'FRO',
    codeNumeric: '234',
    topLevelDomain: 'fo',
    FIPS: 'FO',
    geoNameID: 2622320,
    E164: 298,
    phoneCode: '298',
    continent: 'Europe',
    capital: 'Torshavn',
    timeZoneInCapital: 'Atlantic/Faroe',
    currency: 'Krone',
    languageCodes: 'fo,da-FO',
  },
  {
    name: 'Islas Malvinas',
    nameOfficialIso: 'Malvinas [Falkland], (las) Islas',
    codeAlfa_2: 'FK',
    codeAlfa_3: 'FLK',
    codeNumeric: '238',
    topLevelDomain: 'fk',
    FIPS: 'FK',
    geoNameID: 3474414,
    E164: 500,
    phoneCode: '500',
    continent: 'South America',
    capital: 'Stanley',
    timeZoneInCapital: 'Atlantic/Stanley',
    currency: 'Pound',
    languageCodes: 'en-FK',
  },
  {
    name: 'Islas Marianas del Norte',
    nameOfficialIso: 'Marianas del Norte, (las) Islas',
    codeAlfa_2: 'MP',
    codeAlfa_3: 'MNP',
    codeNumeric: '580',
    topLevelDomain: 'mp',
    FIPS: 'CQ',
    geoNameID: 4041468,
    E164: 1,
    phoneCode: '1-670',
    continent: 'Oceania',
    capital: 'Saipan',
    timeZoneInCapital: 'Pacific/Saipan',
    currency: 'Dollar',
    languageCodes: 'fil,tl,zh,ch-MP,en-MP',
  },
  {
    name: 'Islas Marshall',
    nameOfficialIso: 'Marshall, (las) Islas',
    codeAlfa_2: 'MH',
    codeAlfa_3: 'MHL',
    codeNumeric: '584',
    topLevelDomain: 'mh',
    FIPS: 'RM',
    geoNameID: 2080185,
    E164: 692,
    phoneCode: '692',
    continent: 'Oceania',
    capital: 'Majuro',
    timeZoneInCapital: 'Pacific/Majuro',
    currency: 'Dollar',
    languageCodes: 'mh,en-MH',
  },
  {
    name: 'Islas Pitcairn',
    nameOfficialIso: 'Pitcairn',
    codeAlfa_2: 'PN',
    codeAlfa_3: 'PCN',
    codeNumeric: '612',
    topLevelDomain: 'pn',
    FIPS: 'PC',
    geoNameID: 4030699,
    E164: 64,
    phoneCode: '64',
    continent: 'Oceania',
    capital: 'Adamstown',
    timeZoneInCapital: 'Pacific/Pitcairn',
    currency: 'Dollar',
    languageCodes: 'en-PN',
  },
  {
    name: 'Islas Salomón',
    nameOfficialIso: 'Salomón, Islas',
    codeAlfa_2: 'SB',
    codeAlfa_3: 'SLB',
    codeNumeric: '090',
    topLevelDomain: 'sb',
    FIPS: 'BP',
    geoNameID: 2103350,
    E164: 677,
    phoneCode: '677',
    continent: 'Oceania',
    capital: 'Honiara',
    timeZoneInCapital: 'Pacific/Guadalcanal',
    currency: 'Dollar',
    languageCodes: 'en-SB,tpi',
  },
  {
    name: 'Islas Turcas y Caicos',
    nameOfficialIso: 'Turcas y Caicos, (las) Islas',
    codeAlfa_2: 'TC',
    codeAlfa_3: 'TCA',
    codeNumeric: '796',
    topLevelDomain: 'tc',
    FIPS: 'TK',
    geoNameID: 3576916,
    E164: 1,
    phoneCode: '1-649',
    continent: 'North America',
    capital: 'Cockburn Town',
    timeZoneInCapital: 'America/Grand_Turk',
    currency: 'Dollar',
    languageCodes: 'en-TC',
  },
  {
    name: 'Islas Vírgenes Británicas',
    nameOfficialIso: 'Vírgenes británicas, Islas',
    codeAlfa_2: 'VG',
    codeAlfa_3: 'VGB',
    codeNumeric: '092',
    topLevelDomain: 'vg',
    FIPS: 'VI',
    geoNameID: 3577718,
    E164: 1,
    phoneCode: '1-284',
    continent: 'North America',
    capital: 'Road Town',
    timeZoneInCapital: 'America/Port_of_Spain',
    currency: 'Dollar',
    languageCodes: 'en-VG',
  },
  {
    name: 'Islas Vírgenes de los Estados Unidos',
    nameOfficialIso: 'Vírgenes de los Estados Unidos, Islas',
    codeAlfa_2: 'VI',
    codeAlfa_3: 'VIR',
    codeNumeric: '850',
    topLevelDomain: 'vi',
    FIPS: 'VQ',
    geoNameID: 4796775,
    E164: 1,
    phoneCode: '1-340',
    continent: 'North America',
    capital: 'Charlotte Amalie',
    timeZoneInCapital: 'America/Port_of_Spain',
    currency: 'Dollar',
    languageCodes: 'en-VI',
  },
  {
    name: 'Israel',
    nameOfficialIso: 'Israel',
    codeAlfa_2: 'IL',
    codeAlfa_3: 'ISR',
    codeNumeric: '376',
    topLevelDomain: 'il',
    FIPS: 'IS',
    geoNameID: 294640,
    E164: 972,
    phoneCode: '972',
    continent: 'Asia',
    capital: 'Jerusalem',
    timeZoneInCapital: 'Asia/Jerusalem',
    currency: 'Shekel',
    languageCodes: 'he,ar-IL,en-IL,',
  },
  {
    name: 'Italia',
    nameOfficialIso: 'Italia',
    codeAlfa_2: 'IT',
    codeAlfa_3: 'ITA',
    codeNumeric: '380',
    topLevelDomain: 'it',
    FIPS: 'IT',
    geoNameID: 3175395,
    E164: 39,
    phoneCode: '39',
    continent: 'Europe',
    capital: 'Rome',
    timeZoneInCapital: 'Europe/Rome',
    currency: 'Euro',
    languageCodes: 'it-IT,de-IT,fr-IT,sc,ca,co,sl',
  },
  {
    name: 'Jamaica',
    nameOfficialIso: 'Jamaica',
    codeAlfa_2: 'JM',
    codeAlfa_3: 'JAM',
    codeNumeric: '388',
    topLevelDomain: 'jm',
    FIPS: 'JM',
    geoNameID: 3489940,
    E164: 1,
    phoneCode: '1-876',
    continent: 'North America',
    capital: 'Kingston',
    timeZoneInCapital: 'America/Jamaica',
    currency: 'Dollar',
    languageCodes: 'en-JM',
  },
  {
    name: 'Japón',
    nameOfficialIso: 'Japón',
    codeAlfa_2: 'JP',
    codeAlfa_3: 'JPN',
    codeNumeric: '392',
    topLevelDomain: 'jp',
    FIPS: 'JA',
    geoNameID: 1861060,
    E164: 81,
    phoneCode: '81',
    continent: 'Asia',
    capital: 'Tokyo',
    timeZoneInCapital: 'Asia/Tokyo',
    currency: 'Yen',
    languageCodes: 'ja',
  },
  {
    name: 'Jersey',
    nameOfficialIso: 'Jersey',
    codeAlfa_2: 'JE',
    codeAlfa_3: 'JEY',
    codeNumeric: '832',
    topLevelDomain: 'je',
    FIPS: 'JE',
    geoNameID: 3042142,
    E164: 44,
    phoneCode: '44-1534',
    continent: 'Europe',
    capital: 'Saint Helier',
    timeZoneInCapital: 'Europe/London',
    currency: 'Pound',
    languageCodes: 'en,pt',
  },
  {
    name: 'Jordania',
    nameOfficialIso: 'Jordania',
    codeAlfa_2: 'JO',
    codeAlfa_3: 'JOR',
    codeNumeric: '400',
    topLevelDomain: 'jo',
    FIPS: 'JO',
    geoNameID: 248816,
    E164: 962,
    phoneCode: '962',
    continent: 'Asia',
    capital: 'Amman',
    timeZoneInCapital: 'Asia/Amman',
    currency: 'Dinar',
    languageCodes: 'ar-JO,en',
  },
  {
    name: 'Kirguistán',
    nameOfficialIso: 'Kirguistán',
    codeAlfa_2: 'KG',
    codeAlfa_3: 'KGZ',
    codeNumeric: '417',
    topLevelDomain: 'kg',
    FIPS: 'KG',
    geoNameID: 1527747,
    E164: 996,
    phoneCode: '996',
    continent: 'Asia',
    capital: 'Bishkek',
    timeZoneInCapital: 'Asia/Bishkek',
    currency: 'Som',
    languageCodes: 'ky,uz,ru',
  },
  {
    name: 'Kiribati',
    nameOfficialIso: 'Kiribati',
    codeAlfa_2: 'KI',
    codeAlfa_3: 'KIR',
    codeNumeric: '296',
    topLevelDomain: 'ki',
    FIPS: 'KR',
    geoNameID: 4030945,
    E164: 686,
    phoneCode: '686',
    continent: 'Oceania',
    capital: 'Tarawa',
    timeZoneInCapital: 'Pacific/Tarawa',
    currency: 'Dollar',
    languageCodes: 'en-KI,gil',
  },
  {
    name: 'Kuwait',
    nameOfficialIso: 'Kuwait',
    codeAlfa_2: 'KW',
    codeAlfa_3: 'KWT',
    codeNumeric: '414',
    topLevelDomain: 'kw',
    FIPS: 'KU',
    geoNameID: 285570,
    E164: 965,
    phoneCode: '965',
    continent: 'Asia',
    capital: 'Kuwait City',
    timeZoneInCapital: 'Asia/Kuwait',
    currency: 'Dinar',
    languageCodes: 'ar-KW,en',
  },
  {
    name: 'Kenia',
    nameOfficialIso: 'Kenia',
    codeAlfa_2: 'KE',
    codeAlfa_3: 'KEN',
    codeNumeric: '404',
    topLevelDomain: 'ke',
    FIPS: 'KE',
    geoNameID: 192950,
    E164: 254,
    phoneCode: '254',
    continent: 'Africa',
    capital: 'Nairobi',
    timeZoneInCapital: 'Africa/Nairobi',
    currency: 'Shilling',
    languageCodes: 'en-KE,sw-KE',
  },
  {
    name: 'Laos',
    nameOfficialIso: 'Lao, (la) República Democrática Popular',
    codeAlfa_2: 'LA',
    codeAlfa_3: 'LAO',
    codeNumeric: '418',
    topLevelDomain: 'la',
    FIPS: 'LA',
    geoNameID: 1655842,
    E164: 856,
    phoneCode: '856',
    continent: 'Asia',
    capital: 'Vientiane',
    timeZoneInCapital: 'Asia/Vientiane',
    currency: 'Kip',
    languageCodes: 'lo,fr,en',
  },
  {
    name: 'Kazajistán',
    nameOfficialIso: 'Kazajistán',
    codeAlfa_2: 'KZ',
    codeAlfa_3: 'KAZ',
    codeNumeric: '398',
    topLevelDomain: 'kz',
    FIPS: 'KZ',
    geoNameID: 1522867,
    E164: 7,
    phoneCode: '7',
    continent: 'Asia',
    capital: 'Astana',
    timeZoneInCapital: 'Asia/Almaty',
    currency: 'Tenge',
    languageCodes: 'kk,ru',
  },
  {
    name: 'Lesoto',
    nameOfficialIso: 'Lesoto',
    codeAlfa_2: 'LS',
    codeAlfa_3: 'LSO',
    codeNumeric: '426',
    topLevelDomain: 'ls',
    FIPS: 'LT',
    geoNameID: 932692,
    E164: 266,
    phoneCode: '266',
    continent: 'Africa',
    capital: 'Maseru',
    timeZoneInCapital: 'Africa/Johannesburg',
    currency: 'Loti',
    languageCodes: 'en-LS,st,zu,xh',
  },
  {
    name: 'Letonia',
    nameOfficialIso: 'Letonia',
    codeAlfa_2: 'LV',
    codeAlfa_3: 'LVA',
    codeNumeric: '428',
    topLevelDomain: 'lv',
    FIPS: 'LG',
    geoNameID: 458258,
    E164: 371,
    phoneCode: '371',
    continent: 'Europe',
    capital: 'Riga',
    timeZoneInCapital: 'Europe/Riga',
    currency: 'Euro',
    languageCodes: 'lv,ru,lt',
  },
  {
    name: 'Líbano',
    nameOfficialIso: 'Líbano',
    codeAlfa_2: 'LB',
    codeAlfa_3: 'LBN',
    codeNumeric: '422',
    topLevelDomain: 'lb',
    FIPS: 'LE',
    geoNameID: 272103,
    E164: 961,
    phoneCode: '961',
    continent: 'Asia',
    capital: 'Beirut',
    timeZoneInCapital: 'Asia/Beirut',
    currency: 'Pound',
    languageCodes: 'ar-LB,fr-LB,en,hy',
  },
  {
    name: 'Liberia',
    nameOfficialIso: 'Liberia',
    codeAlfa_2: 'LR',
    codeAlfa_3: 'LBR',
    codeNumeric: '430',
    topLevelDomain: 'lr',
    FIPS: 'LI',
    geoNameID: 2275384,
    E164: 231,
    phoneCode: '231',
    continent: 'Africa',
    capital: 'Monrovia',
    timeZoneInCapital: 'Africa/Monrovia',
    currency: 'Dollar',
    languageCodes: 'en-LR',
  },
  {
    name: 'Libia',
    nameOfficialIso: 'Libia',
    codeAlfa_2: 'LY',
    codeAlfa_3: 'LBY',
    codeNumeric: '434',
    topLevelDomain: 'ly',
    FIPS: 'LY',
    geoNameID: 2215636,
    E164: 218,
    phoneCode: '218',
    continent: 'Africa',
    capital: 'Tripolis',
    timeZoneInCapital: 'Africa/Tripoli',
    currency: 'Dinar',
    languageCodes: 'ar-LY,it,en',
  },
  {
    name: 'Liechtenstein',
    nameOfficialIso: 'Liechtenstein',
    codeAlfa_2: 'LI',
    codeAlfa_3: 'LIE',
    codeNumeric: '438',
    topLevelDomain: 'li',
    FIPS: 'LS',
    geoNameID: 3042058,
    E164: 423,
    phoneCode: '423',
    continent: 'Europe',
    capital: 'Vaduz',
    timeZoneInCapital: 'Europe/Zurich',
    currency: 'Franc',
    languageCodes: 'de-LI',
  },
  {
    name: 'Lituania',
    nameOfficialIso: 'Lituania',
    codeAlfa_2: 'LT',
    codeAlfa_3: 'LTU',
    codeNumeric: '440',
    topLevelDomain: 'lt',
    FIPS: 'LH',
    geoNameID: 597427,
    E164: 370,
    phoneCode: '370',
    continent: 'Europe',
    capital: 'Vilnius',
    timeZoneInCapital: 'Europe/Vilnius',
    currency: 'Euro',
    languageCodes: 'lt,ru,pl',
  },
  {
    name: 'Luxemburgo',
    nameOfficialIso: 'Luxemburgo',
    codeAlfa_2: 'LU',
    codeAlfa_3: 'LUX',
    codeNumeric: '442',
    topLevelDomain: 'lu',
    FIPS: 'LU',
    geoNameID: 2960313,
    E164: 352,
    phoneCode: '352',
    continent: 'Europe',
    capital: 'Luxembourg',
    timeZoneInCapital: 'Europe/Luxembourg',
    currency: 'Euro',
    languageCodes: 'lb,de-LU,fr-LU',
  },
  {
    name: 'Macao',
    nameOfficialIso: 'Macao',
    codeAlfa_2: 'MO',
    codeAlfa_3: 'MAC',
    codeNumeric: '446',
    topLevelDomain: 'mo',
    FIPS: 'MC',
    geoNameID: 1821275,
    E164: 853,
    phoneCode: '853',
    continent: 'Asia',
    capital: 'Macao',
    timeZoneInCapital: 'Asia/Macau',
    currency: 'Pataca',
    languageCodes: 'zh,zh-MO,pt',
  },
  {
    name: 'Macedonia del Norte',
    nameOfficialIso: 'Macedonia del Norte',
    codeAlfa_2: 'MK',
    codeAlfa_3: 'MKD',
    codeNumeric: '807',
    topLevelDomain: 'mk',
    FIPS: 'MK',
    geoNameID: 718075,
    E164: 389,
    phoneCode: '389',
    continent: 'Europe',
    capital: 'Skopje',
    timeZoneInCapital: 'Europe/Belgrade',
    currency: 'Denar',
    languageCodes: 'mk,sq,tr,rmm,sr',
  },
  {
    name: 'Madagascar',
    nameOfficialIso: 'Madagascar',
    codeAlfa_2: 'MG',
    codeAlfa_3: 'MDG',
    codeNumeric: '450',
    topLevelDomain: 'mg',
    FIPS: 'MA',
    geoNameID: 1062947,
    E164: 261,
    phoneCode: '261',
    continent: 'Africa',
    capital: 'Antananarivo',
    timeZoneInCapital: 'Indian/Antananarivo',
    currency: 'Ariary',
    languageCodes: 'fr-MG,mg',
  },
  {
    name: 'Malasia',
    nameOfficialIso: 'Malasia',
    codeAlfa_2: 'MY',
    codeAlfa_3: 'MYS',
    codeNumeric: '458',
    topLevelDomain: 'my',
    FIPS: 'MY',
    geoNameID: 1733045,
    E164: 60,
    phoneCode: '60',
    continent: 'Asia',
    capital: 'Kuala Lumpur',
    timeZoneInCapital: 'Asia/Kuala_Lumpur',
    currency: 'Ringgit',
    languageCodes: 'ms-MY,en,zh,ta,te,ml,pa,th',
  },
  {
    name: 'Maldivas',
    nameOfficialIso: 'Maldivas',
    codeAlfa_2: 'MV',
    codeAlfa_3: 'MDV',
    codeNumeric: '462',
    topLevelDomain: 'mv',
    FIPS: 'MV',
    geoNameID: 1282028,
    E164: 960,
    phoneCode: '960',
    continent: 'Asia',
    capital: 'Male',
    timeZoneInCapital: 'Indian/Maldives',
    currency: 'Rufiyaa',
    languageCodes: 'dv,en',
  },
  {
    name: 'Malaui',
    nameOfficialIso: 'Malawi',
    codeAlfa_2: 'MW',
    codeAlfa_3: 'MWI',
    codeNumeric: '454',
    topLevelDomain: 'mw',
    FIPS: 'MI',
    geoNameID: 927384,
    E164: 265,
    phoneCode: '265',
    continent: 'Africa',
    capital: 'Lilongwe',
    timeZoneInCapital: 'Africa/Maputo',
    currency: 'Kwacha',
    languageCodes: 'ny,yao,tum,swk',
  },
  {
    name: 'Malí',
    nameOfficialIso: 'Malí',
    codeAlfa_2: 'ML',
    codeAlfa_3: 'MLI',
    codeNumeric: '466',
    topLevelDomain: 'ml',
    FIPS: 'ML',
    geoNameID: 2453866,
    E164: 223,
    phoneCode: '223',
    continent: 'Africa',
    capital: 'Bamako',
    timeZoneInCapital: 'Africa/Abidjan',
    currency: 'Franc',
    languageCodes: 'fr-ML,bm',
  },
  {
    name: 'Malta',
    nameOfficialIso: 'Malta',
    codeAlfa_2: 'MT',
    codeAlfa_3: 'MLT',
    codeNumeric: '470',
    topLevelDomain: 'mt',
    FIPS: 'MT',
    geoNameID: 2562770,
    E164: 356,
    phoneCode: '356',
    continent: 'Europe',
    capital: 'Valletta',
    timeZoneInCapital: 'Europe/Malta',
    currency: 'Euro',
    languageCodes: 'mt,en-MT',
  },
  {
    name: 'Marruecos',
    nameOfficialIso: 'Marruecos',
    codeAlfa_2: 'MA',
    codeAlfa_3: 'MAR',
    codeNumeric: '504',
    topLevelDomain: 'ma',
    FIPS: 'MO',
    geoNameID: 2542007,
    E164: 212,
    phoneCode: '212',
    continent: 'Africa',
    capital: 'Rabat',
    timeZoneInCapital: 'Africa/Casablanca',
    currency: 'Dirham',
    languageCodes: 'ar-MA,fr',
  },
  {
    name: 'Mauricio',
    nameOfficialIso: 'Mauricio',
    codeAlfa_2: 'MU',
    codeAlfa_3: 'MUS',
    codeNumeric: '480',
    topLevelDomain: 'mu',
    FIPS: 'MP',
    geoNameID: 934292,
    E164: 230,
    phoneCode: '230',
    continent: 'Africa',
    capital: 'Port Louis',
    timeZoneInCapital: 'Indian/Mauritius',
    currency: 'Rupee',
    languageCodes: 'en-MU,bho,fr',
  },
  {
    name: 'Mauritania',
    nameOfficialIso: 'Mauritania',
    codeAlfa_2: 'MR',
    codeAlfa_3: 'MRT',
    codeNumeric: '478',
    topLevelDomain: 'mr',
    FIPS: 'MR',
    geoNameID: 2378080,
    E164: 222,
    phoneCode: '222',
    continent: 'Africa',
    capital: 'Nouakchott',
    timeZoneInCapital: 'Africa/Abidjan',
    currency: 'Ouguiya',
    languageCodes: 'ar-MR,fuc,snk,fr,mey,wo',
  },
  {
    name: 'Mayotte',
    nameOfficialIso: 'Mayotte',
    codeAlfa_2: 'YT',
    codeAlfa_3: 'MYT',
    codeNumeric: '175',
    topLevelDomain: 'yt',
    FIPS: 'MF',
    geoNameID: 1024031,
    E164: 262,
    phoneCode: '262',
    continent: 'Africa',
    capital: 'Mamoudzou',
    timeZoneInCapital: 'Indian/Mayotte',
    currency: 'Euro',
    languageCodes: 'fr-YT',
  },
  {
    name: 'México',
    nameOfficialIso: 'México',
    codeAlfa_2: 'MX',
    codeAlfa_3: 'MEX',
    codeNumeric: '484',
    topLevelDomain: 'mx',
    FIPS: 'MX',
    geoNameID: 3996063,
    E164: 52,
    phoneCode: '52',
    continent: 'North America',
    capital: 'Mexico City',
    timeZoneInCapital: 'America/Mexico_City',
    currency: 'Peso',
    languageCodes: 'es-MX',
  },
  {
    name: 'Micronesia',
    nameOfficialIso: 'Micronesia (Estados Federados de)',
    codeAlfa_2: 'FM',
    codeAlfa_3: 'FSM',
    codeNumeric: '583',
    topLevelDomain: 'fm',
    FIPS: 'FM',
    geoNameID: 2081918,
    E164: 691,
    phoneCode: '691',
    continent: 'Oceania',
    capital: 'Palikir',
    timeZoneInCapital: 'Pacific/Pohnpei',
    currency: 'Dollar',
    languageCodes: 'en-FM,chk,pon,yap,kos,uli,woe,nkr,kpg',
  },
  {
    name: 'Moldavia',
    nameOfficialIso: 'Moldova (la República de)',
    codeAlfa_2: 'MD',
    codeAlfa_3: 'MDA',
    codeNumeric: '498',
    topLevelDomain: 'md',
    FIPS: 'MD',
    geoNameID: 617790,
    E164: 373,
    phoneCode: '373',
    continent: 'Europe',
    capital: 'Chisinau',
    timeZoneInCapital: 'Europe/Chisinau',
    currency: 'Leu',
    languageCodes: 'ro,ru,gag,tr',
  },
  {
    name: 'Mónaco',
    nameOfficialIso: 'Mónaco',
    codeAlfa_2: 'MC',
    codeAlfa_3: 'MCO',
    codeNumeric: '492',
    topLevelDomain: 'mc',
    FIPS: 'MN',
    geoNameID: 2993457,
    E164: 377,
    phoneCode: '377',
    continent: 'Europe',
    capital: 'Monaco',
    timeZoneInCapital: 'Europe/Monaco',
    currency: 'Euro',
    languageCodes: 'fr-MC,en,it',
  },
  {
    name: 'Mongolia',
    nameOfficialIso: 'Mongolia',
    codeAlfa_2: 'MN',
    codeAlfa_3: 'MNG',
    codeNumeric: '496',
    topLevelDomain: 'mn',
    FIPS: 'MG',
    geoNameID: 2029969,
    E164: 976,
    phoneCode: '976',
    continent: 'Asia',
    capital: 'Ulan Bator',
    timeZoneInCapital: 'Asia/Ulaanbaatar',
    currency: 'Tugrik',
    languageCodes: 'mn,ru',
  },
  {
    name: 'Montenegro',
    nameOfficialIso: 'Montenegro',
    codeAlfa_2: 'ME',
    codeAlfa_3: 'MNE',
    codeNumeric: '499',
    topLevelDomain: 'me',
    FIPS: 'MJ',
    geoNameID: 3194884,
    E164: 382,
    phoneCode: '382',
    continent: 'Europe',
    capital: 'Podgorica',
    timeZoneInCapital: 'Europe/Belgrade',
    currency: 'Euro',
    languageCodes: 'sr,hu,bs,sq,hr,rom',
  },
  {
    name: 'Montserrat',
    nameOfficialIso: 'Montserrat',
    codeAlfa_2: 'MS',
    codeAlfa_3: 'MSR',
    codeNumeric: '500',
    topLevelDomain: 'ms',
    FIPS: 'MH',
    geoNameID: 3578097,
    E164: 1,
    phoneCode: '1-664',
    continent: 'North America',
    capital: 'Plymouth',
    timeZoneInCapital: 'America/Port_of_Spain',
    currency: 'Dollar',
    languageCodes: 'en-MS',
  },
  {
    name: 'Mozambique',
    nameOfficialIso: 'Mozambique',
    codeAlfa_2: 'MZ',
    codeAlfa_3: 'MOZ',
    codeNumeric: '508',
    topLevelDomain: 'mz',
    FIPS: 'MZ',
    geoNameID: 1036973,
    E164: 258,
    phoneCode: '258',
    continent: 'Africa',
    capital: 'Maputo',
    timeZoneInCapital: 'Africa/Maputo',
    currency: 'Metical',
    languageCodes: 'pt-MZ,vmw',
  },
  {
    name: 'Birmania',
    nameOfficialIso: 'Myanmar',
    codeAlfa_2: 'MM',
    codeAlfa_3: 'MMR',
    codeNumeric: '104',
    topLevelDomain: 'mm',
    FIPS: 'BM',
    geoNameID: 1327865,
    E164: 95,
    phoneCode: '95',
    continent: 'Asia',
    capital: 'Nay Pyi Taw',
    timeZoneInCapital: 'Asia/Rangoon',
    currency: 'Kyat',
    languageCodes: 'my',
  },
  {
    name: 'Namibia',
    nameOfficialIso: 'Namibia',
    codeAlfa_2: 'NA',
    codeAlfa_3: 'NAM',
    codeNumeric: '516',
    topLevelDomain: 'na',
    FIPS: 'WA',
    geoNameID: 3355338,
    E164: 264,
    phoneCode: '264',
    continent: 'Africa',
    capital: 'Windhoek',
    timeZoneInCapital: 'Africa/Windhoek',
    currency: 'Dollar',
    languageCodes: 'en-NA,af,de,hz,naq',
  },
  {
    name: 'Nauru',
    nameOfficialIso: 'Nauru',
    codeAlfa_2: 'NR',
    codeAlfa_3: 'NRU',
    codeNumeric: '520',
    topLevelDomain: 'nr',
    FIPS: 'NR',
    geoNameID: 2110425,
    E164: 674,
    phoneCode: '674',
    continent: 'Oceania',
    capital: 'Yaren',
    timeZoneInCapital: 'Pacific/Nauru',
    currency: 'Dollar',
    languageCodes: 'na,en-NR',
  },
  {
    name: 'Nepal',
    nameOfficialIso: 'Nepal',
    codeAlfa_2: 'NP',
    codeAlfa_3: 'NPL',
    codeNumeric: '524',
    topLevelDomain: 'np',
    FIPS: 'NP',
    geoNameID: 1282988,
    E164: 977,
    phoneCode: '977',
    continent: 'Asia',
    capital: 'Kathmandu',
    timeZoneInCapital: 'Asia/Kathmandu',
    currency: 'Rupee',
    languageCodes: 'ne,en',
  },
  {
    name: 'Nicaragua',
    nameOfficialIso: 'Nicaragua',
    codeAlfa_2: 'NI',
    codeAlfa_3: 'NIC',
    codeNumeric: '558',
    topLevelDomain: 'ni',
    FIPS: 'NU',
    geoNameID: 3617476,
    E164: 505,
    phoneCode: '505',
    continent: 'North America',
    capital: 'Managua',
    timeZoneInCapital: 'America/Managua',
    currency: 'Cordoba',
    languageCodes: 'es-NI,en',
  },
  {
    name: 'Níger',
    nameOfficialIso: 'Níger (el)',
    codeAlfa_2: 'NE',
    codeAlfa_3: 'NER',
    codeNumeric: '562',
    topLevelDomain: 'ne',
    FIPS: 'NG',
    geoNameID: 2440476,
    E164: 227,
    phoneCode: '227',
    continent: 'Africa',
    capital: 'Niamey',
    timeZoneInCapital: 'Africa/Lagos',
    currency: 'Franc',
    languageCodes: 'fr-NE,ha,kr,dje',
  },
  {
    name: 'Nigeria',
    nameOfficialIso: 'Nigeria',
    codeAlfa_2: 'NG',
    codeAlfa_3: 'NGA',
    codeNumeric: '566',
    topLevelDomain: 'ng',
    FIPS: 'NI',
    geoNameID: 2328926,
    E164: 234,
    phoneCode: '234',
    continent: 'Africa',
    capital: 'Abuja',
    timeZoneInCapital: 'Africa/Lagos',
    currency: 'Naira',
    languageCodes: 'en-NG,ha,yo,ig,ff',
  },
  {
    name: 'Niue',
    nameOfficialIso: 'Niue',
    codeAlfa_2: 'NU',
    codeAlfa_3: 'NIU',
    codeNumeric: '570',
    topLevelDomain: 'nu',
    FIPS: 'NE',
    geoNameID: 4036232,
    E164: 683,
    phoneCode: '683',
    continent: 'Oceania',
    capital: 'Alofi',
    timeZoneInCapital: 'Pacific/Niue',
    currency: 'Dollar',
    languageCodes: 'niu,en-NU',
  },
  {
    name: 'Noruega',
    nameOfficialIso: 'Noruega',
    codeAlfa_2: 'NO',
    codeAlfa_3: 'NOR',
    codeNumeric: '578',
    topLevelDomain: 'no',
    FIPS: 'NO',
    geoNameID: 3144096,
    E164: 47,
    phoneCode: '47',
    continent: 'Europe',
    capital: 'Oslo',
    timeZoneInCapital: 'Europe/Oslo',
    currency: 'Krone',
    languageCodes: 'no,nb,nn,se,fi',
  },
  {
    name: 'Nueva Caledonia',
    nameOfficialIso: 'Nueva Caledonia',
    codeAlfa_2: 'NC',
    codeAlfa_3: 'NCL',
    codeNumeric: '540',
    topLevelDomain: 'nc',
    FIPS: 'NC',
    geoNameID: 2139685,
    E164: 687,
    phoneCode: '687',
    continent: 'Oceania',
    capital: 'Noumea',
    timeZoneInCapital: 'Pacific/Noumea',
    currency: 'Franc',
    languageCodes: 'fr-NC',
  },
  {
    name: 'Nueva Zelanda',
    nameOfficialIso: 'Nueva Zelandia',
    codeAlfa_2: 'NZ',
    codeAlfa_3: 'NZL',
    codeNumeric: '554',
    topLevelDomain: 'nz',
    FIPS: 'NZ',
    geoNameID: 2186224,
    E164: 64,
    phoneCode: '64',
    continent: 'Oceania',
    capital: 'Wellington',
    timeZoneInCapital: 'Pacific/Auckland',
    currency: 'Dollar',
    languageCodes: 'en-NZ,mi',
  },
  {
    name: 'Omán',
    nameOfficialIso: 'Omán',
    codeAlfa_2: 'OM',
    codeAlfa_3: 'OMN',
    codeNumeric: '512',
    topLevelDomain: 'om',
    FIPS: 'MU',
    geoNameID: 286963,
    E164: 968,
    phoneCode: '968',
    continent: 'Asia',
    capital: 'Muscat',
    timeZoneInCapital: 'Asia/Muscat',
    currency: 'Rial',
    languageCodes: 'ar-OM,en,bal,ur',
  },
  {
    name: 'Países Bajos',
    nameOfficialIso: 'Países Bajos (los)',
    codeAlfa_2: 'NL',
    codeAlfa_3: 'NLD',
    codeNumeric: '528',
    topLevelDomain: 'nl',
    FIPS: 'NL',
    geoNameID: 2750405,
    E164: 31,
    phoneCode: '31',
    continent: 'Europe',
    capital: 'Amsterdam',
    timeZoneInCapital: 'Europe/Amsterdam',
    currency: 'Euro',
    languageCodes: 'nl-NL,fy-NL',
  },
  {
    name: 'Pakistán',
    nameOfficialIso: 'Pakistán',
    codeAlfa_2: 'PK',
    codeAlfa_3: 'PAK',
    codeNumeric: '586',
    topLevelDomain: 'pk',
    FIPS: 'PK',
    geoNameID: 1168579,
    E164: 92,
    phoneCode: '92',
    continent: 'Asia',
    capital: 'Islamabad',
    timeZoneInCapital: 'Asia/Karachi',
    currency: 'Rupee',
    languageCodes: 'ur-PK,en-PK,pa,sd,ps,brh',
  },
  {
    name: 'Palaos',
    nameOfficialIso: 'Palau',
    codeAlfa_2: 'PW',
    codeAlfa_3: 'PLW',
    codeNumeric: '585',
    topLevelDomain: 'pw',
    FIPS: 'PS',
    geoNameID: 1559582,
    E164: 680,
    phoneCode: '680',
    continent: 'Oceania',
    capital: 'Melekeok',
    timeZoneInCapital: 'Pacific/Palau',
    currency: 'Dollar',
    languageCodes: 'pau,sov,en-PW,tox,ja,fil,zh',
  },
  {
    name: 'Palestina',
    nameOfficialIso: 'Palestina, Estado de',
    codeAlfa_2: 'PS',
    codeAlfa_3: 'PSE',
    codeNumeric: '275',
    topLevelDomain: 'ps',
    FIPS: 'WE',
    geoNameID: 6254930,
    E164: 970,
    phoneCode: '970',
    continent: 'Asia',
    capital: 'East Jerusalem',
    timeZoneInCapital: 'Asia/Hebron',
    currency: 'Shekel',
    languageCodes: 'ar-PS',
  },
  {
    name: 'Panamá',
    nameOfficialIso: 'Panamá',
    codeAlfa_2: 'PA',
    codeAlfa_3: 'PAN',
    codeNumeric: '591',
    topLevelDomain: 'pa',
    FIPS: 'PM',
    geoNameID: 3703430,
    E164: 507,
    phoneCode: '507',
    continent: 'North America',
    capital: 'Panama City',
    timeZoneInCapital: 'America/Panama',
    currency: 'Balboa',
    languageCodes: 'es-PA,en',
  },
  {
    name: 'Perú',
    nameOfficialIso: 'Perú',
    codeAlfa_2: 'PE',
    codeAlfa_3: 'PER',
    codeNumeric: '604',
    topLevelDomain: 'pe',
    FIPS: 'PE',
    geoNameID: 3932488,
    E164: 51,
    phoneCode: '51',
    continent: 'South America',
    capital: 'Lima',
    timeZoneInCapital: 'America/Lima',
    currency: 'Sol',
    languageCodes: 'es-PE,qu,ay',
  },
  {
    name: 'Polinesia Francesa',
    nameOfficialIso: 'Polinesia Francesa',
    codeAlfa_2: 'PF',
    codeAlfa_3: 'PYF',
    codeNumeric: '258',
    topLevelDomain: 'pf',
    FIPS: 'FP',
    geoNameID: 4030656,
    E164: 689,
    phoneCode: '689',
    continent: 'Oceania',
    capital: 'Papeete',
    timeZoneInCapital: 'Pacific/Tahiti',
    currency: 'Franc',
    languageCodes: 'fr-PF,ty',
  },
  {
    name: 'Paraguay',
    nameOfficialIso: 'Paraguay',
    codeAlfa_2: 'PY',
    codeAlfa_3: 'PRY',
    codeNumeric: '600',
    topLevelDomain: 'py',
    FIPS: 'PA',
    geoNameID: 3437598,
    E164: 595,
    phoneCode: '595',
    continent: 'South America',
    capital: 'Asuncion',
    timeZoneInCapital: 'America/Asuncion',
    currency: 'Guarani',
    languageCodes: 'es-PY,gn',
  },
  {
    name: 'Papúa Nueva Guinea',
    nameOfficialIso: 'Papua Nueva Guinea',
    codeAlfa_2: 'PG',
    codeAlfa_3: 'PNG',
    codeNumeric: '598',
    topLevelDomain: 'pg',
    FIPS: 'PP',
    geoNameID: 2088628,
    E164: 675,
    phoneCode: '675',
    continent: 'Oceania',
    capital: 'Port Moresby',
    timeZoneInCapital: 'Pacific/Port_Moresby',
    currency: 'Kina',
    languageCodes: 'en-PG,ho,meu,tpi',
  },
  {
    name: 'Polonia',
    nameOfficialIso: 'Polonia',
    codeAlfa_2: 'PL',
    codeAlfa_3: 'POL',
    codeNumeric: '616',
    topLevelDomain: 'pl',
    FIPS: 'PL',
    geoNameID: 798544,
    E164: 48,
    phoneCode: '48',
    continent: 'Europe',
    capital: 'Warsaw',
    timeZoneInCapital: 'Europe/Warsaw',
    currency: 'Zloty',
    languageCodes: 'pl',
  },
  {
    name: 'Puerto Rico',
    nameOfficialIso: 'Puerto Rico',
    codeAlfa_2: 'PR',
    codeAlfa_3: 'PRI',
    codeNumeric: '630',
    topLevelDomain: 'pr',
    FIPS: 'RQ',
    geoNameID: 4566966,
    E164: 1,
    phoneCode: '1-787, 1-939',
    continent: 'North America',
    capital: 'San Juan',
    timeZoneInCapital: 'America/Puerto_Rico',
    currency: 'Dollar',
    languageCodes: 'en-PR,es-PR',
  },
  {
    name: 'Portugal',
    nameOfficialIso: 'Portugal',
    codeAlfa_2: 'PT',
    codeAlfa_3: 'PRT',
    codeNumeric: '620',
    topLevelDomain: 'pt',
    FIPS: 'PO',
    geoNameID: 2264397,
    E164: 351,
    phoneCode: '351',
    continent: 'Europe',
    capital: 'Lisbon',
    timeZoneInCapital: 'Europe/Lisbon',
    currency: 'Euro',
    languageCodes: 'pt-PT,mwl',
  },
  {
    name: 'Reino Unido',
    nameOfficialIso: 'Reino Unido de Gran Bretaña e Irlanda del Norte (el)',
    codeAlfa_2: 'GB',
    codeAlfa_3: 'GBR',
    codeNumeric: '826',
    topLevelDomain: 'uk',
    FIPS: 'UK',
    geoNameID: 2635167,
    E164: 44,
    phoneCode: '44',
    continent: 'Europe',
    capital: 'London',
    timeZoneInCapital: 'Europe/London',
    currency: 'Pound',
    languageCodes: 'en-GB,cy-GB,gd',
  },
  {
    name: 'República Árabe Saharaui Democrática',
    nameOfficialIso: 'Sahara Occidental',
    codeAlfa_2: 'EH',
    codeAlfa_3: 'ESH',
    codeNumeric: '732',
    topLevelDomain: 'eh',
    FIPS: 'WI',
    geoNameID: 2461445,
    E164: 212,
    phoneCode: '212',
    continent: 'Africa',
    capital: 'El-Aaiun',
    timeZoneInCapital: 'Africa/El_Aaiun',
    currency: 'Dirham',
    languageCodes: 'ar,mey',
  },
  {
    name: 'República Centroafricana',
    nameOfficialIso: 'República Centroafricana (la)',
    codeAlfa_2: 'CF',
    codeAlfa_3: 'CAF',
    codeNumeric: '140',
    topLevelDomain: 'cf',
    FIPS: 'CT',
    geoNameID: 239880,
    E164: 236,
    phoneCode: '236',
    continent: 'Africa',
    capital: 'Bangui',
    timeZoneInCapital: 'Africa/Lagos',
    currency: 'Franc',
    languageCodes: 'fr-CF,sg,ln,kg',
  },
  {
    name: 'República Checa',
    nameOfficialIso: 'Chequia',
    codeAlfa_2: 'CZ',
    codeAlfa_3: 'CZE',
    codeNumeric: '203',
    topLevelDomain: 'cz',
    FIPS: 'EZ',
    geoNameID: 3077311,
    E164: 420,
    phoneCode: '420',
    continent: 'Europe',
    capital: 'Prague',
    timeZoneInCapital: 'Europe/Prague',
    currency: 'Koruna',
    languageCodes: 'cs,sk',
  },
  {
    name: 'República del Congo',
    nameOfficialIso: 'Congo (el)',
    codeAlfa_2: 'CG',
    codeAlfa_3: 'COG',
    codeNumeric: '178',
    topLevelDomain: 'cg',
    FIPS: 'CF',
    geoNameID: 2260494,
    E164: 242,
    phoneCode: '242',
    continent: 'Africa',
    capital: 'Brazzaville',
    timeZoneInCapital: 'Africa/Lagos',
    currency: 'Franc',
    languageCodes: 'fr-CG,kg,ln-CG',
  },
  {
    name: 'República Democrática del Congo',
    nameOfficialIso: 'Congo (la República Democrática del)',
    codeAlfa_2: 'CD',
    codeAlfa_3: 'COD',
    codeNumeric: '180',
    topLevelDomain: 'cd',
    FIPS: 'CG',
    geoNameID: 203312,
    E164: 243,
    phoneCode: '243',
    continent: 'Africa',
    capital: 'Kinshasa',
    timeZoneInCapital: 'Africa/Lagos',
    currency: 'Franc',
    languageCodes: 'fr-CD,ln,kg',
  },
  {
    name: 'República Dominicana',
    nameOfficialIso: 'Dominicana, (la) República',
    codeAlfa_2: 'DO',
    codeAlfa_3: 'DOM',
    codeNumeric: '214',
    topLevelDomain: 'do',
    FIPS: 'DR',
    geoNameID: 3508796,
    E164: 1,
    phoneCode: '1-809, 1-829, 1-849',
    continent: 'North America',
    capital: 'Santo Domingo',
    timeZoneInCapital: 'America/Santo_Domingo',
    currency: 'Peso',
    languageCodes: 'es-DO',
  },
  {
    name: 'Reunión',
    nameOfficialIso: 'Reunión',
    codeAlfa_2: 'RE',
    codeAlfa_3: 'REU',
    codeNumeric: '638',
    topLevelDomain: 're',
    FIPS: 'RE',
    geoNameID: 935317,
    E164: 262,
    phoneCode: '262',
    continent: 'Africa',
    capital: 'Saint-Denis',
    timeZoneInCapital: 'Indian/Reunion',
    currency: 'Euro',
    languageCodes: 'fr-RE',
  },
  {
    name: 'Ruanda',
    nameOfficialIso: 'Ruanda',
    codeAlfa_2: 'RW',
    codeAlfa_3: 'RWA',
    codeNumeric: '646',
    topLevelDomain: 'rw',
    FIPS: 'RW',
    geoNameID: 49518,
    E164: 250,
    phoneCode: '250',
    continent: 'Africa',
    capital: 'Kigali',
    timeZoneInCapital: 'Africa/Maputo',
    currency: 'Franc',
    languageCodes: 'rw,en-RW,fr-RW,sw',
  },
  {
    name: 'Rumania',
    nameOfficialIso: 'Rumania',
    codeAlfa_2: 'RO',
    codeAlfa_3: 'ROU',
    codeNumeric: '642',
    topLevelDomain: 'ro',
    FIPS: 'RO',
    geoNameID: 798549,
    E164: 40,
    phoneCode: '40',
    continent: 'Europe',
    capital: 'Bucharest',
    timeZoneInCapital: 'Europe/Bucharest',
    currency: 'Leu',
    languageCodes: 'ro,hu,rom',
  },
  {
    name: 'Rusia',
    nameOfficialIso: 'Rusia, (la) Federación de',
    codeAlfa_2: 'RU',
    codeAlfa_3: 'RUS',
    codeNumeric: '643',
    topLevelDomain: 'ru',
    FIPS: 'RS',
    geoNameID: 2017370,
    E164: 7,
    phoneCode: '7',
    continent: 'Europe',
    capital: 'Moscow',
    timeZoneInCapital: 'Europe/Moscow',
    currency: 'Ruble',
    languageCodes: 'ru,tt,xal,cau,ady,kv,ce,tyv,cv,udm,tut,mns,bua,myv,mdf,chm,ba,inh,tut,kbd,krc,ava,sah,nog',
  },
  {
    name: 'Samoa',
    nameOfficialIso: 'Samoa',
    codeAlfa_2: 'WS',
    codeAlfa_3: 'WSM',
    codeNumeric: '882',
    topLevelDomain: 'ws',
    FIPS: 'WS',
    geoNameID: 4034894,
    E164: 685,
    phoneCode: '685',
    continent: 'Oceania',
    capital: 'Apia',
    timeZoneInCapital: 'Pacific/Apia',
    currency: 'Tala',
    languageCodes: 'sm,en-WS',
  },
  {
    name: 'Samoa Americana',
    nameOfficialIso: 'Samoa Americana',
    codeAlfa_2: 'AS',
    codeAlfa_3: 'ASM',
    codeNumeric: '016',
    topLevelDomain: 'as',
    FIPS: 'AQ',
    geoNameID: 5880801,
    E164: 1,
    phoneCode: '1-684',
    continent: 'Oceania',
    capital: 'Pago Pago',
    timeZoneInCapital: 'Pacific/Pago_Pago',
    currency: 'Dollar',
    languageCodes: 'en-AS,sm,to',
  },
  {
    name: 'San Bartolomé',
    nameOfficialIso: 'Saint Barthélemy',
    codeAlfa_2: 'BL',
    codeAlfa_3: 'BLM',
    codeNumeric: '652',
    topLevelDomain: 'gp',
    FIPS: 'TB',
    geoNameID: 3578476,
    E164: 590,
    phoneCode: '590',
    continent: 'North America',
    capital: 'Gustavia',
    timeZoneInCapital: 'America/Port_of_Spain',
    currency: 'Euro',
    languageCodes: 'fr',
  },
  {
    name: 'San Cristóbal y Nieves',
    nameOfficialIso: 'San Cristóbal y Nieves',
    codeAlfa_2: 'KN',
    codeAlfa_3: 'KNA',
    codeNumeric: '659',
    topLevelDomain: 'kn',
    FIPS: 'SC',
    geoNameID: 3575174,
    E164: 1,
    phoneCode: '1-869',
    continent: 'North America',
    capital: 'Basseterre',
    timeZoneInCapital: 'America/Port_of_Spain',
    currency: 'Dollar',
    languageCodes: 'en-KN',
  },
  {
    name: 'San Marino',
    nameOfficialIso: 'San Marino',
    codeAlfa_2: 'SM',
    codeAlfa_3: 'SMR',
    codeNumeric: '674',
    topLevelDomain: 'sm',
    FIPS: 'SM',
    geoNameID: 3168068,
    E164: 378,
    phoneCode: '378',
    continent: 'Europe',
    capital: 'San Marino',
    timeZoneInCapital: 'Europe/Rome',
    currency: 'Euro',
    languageCodes: 'it-SM',
  },
  {
    name: 'San Martín',
    nameOfficialIso: 'Saint Martin (parte francesa)',
    codeAlfa_2: 'MF',
    codeAlfa_3: 'MAF',
    codeNumeric: '663',
    topLevelDomain: 'gp',
    FIPS: 'RN',
    geoNameID: 3578421,
    E164: 1,
    phoneCode: '590',
    continent: 'North America',
    capital: 'Marigot',
    timeZoneInCapital: 'America/Port_of_Spain',
    currency: 'Euro',
    languageCodes: 'fr',
  },
  {
    name: 'San Pedro y Miquelón',
    nameOfficialIso: 'San Pedro y Miquelón',
    codeAlfa_2: 'PM',
    codeAlfa_3: 'SPM',
    codeNumeric: '666',
    topLevelDomain: 'pm',
    FIPS: 'SB',
    geoNameID: 3424932,
    E164: 508,
    phoneCode: '508',
    continent: 'North America',
    capital: 'Saint-Pierre',
    timeZoneInCapital: 'America/Miquelon',
    currency: 'Euro',
    languageCodes: 'fr-PM',
  },
  {
    name: 'San Vicente y las Granadinas',
    nameOfficialIso: 'San Vicente y las Granadinas',
    codeAlfa_2: 'VC',
    codeAlfa_3: 'VCT',
    codeNumeric: '670',
    topLevelDomain: 'vc',
    FIPS: 'VC',
    geoNameID: 3577815,
    E164: 1,
    phoneCode: '1-784',
    continent: 'North America',
    capital: 'Kingstown',
    timeZoneInCapital: 'America/Port_of_Spain',
    currency: 'Dollar',
    languageCodes: 'en-VC,fr',
  },
  {
    name: 'Santa Elena, Ascensión y Tristán de Acuña',
    nameOfficialIso: 'Santa Helena, Ascensión y Tristán de Acuña',
    codeAlfa_2: 'SH',
    codeAlfa_3: 'SHN',
    codeNumeric: '654',
    topLevelDomain: 'sh',
    FIPS: 'SH',
    geoNameID: 3370751,
    E164: 290,
    phoneCode: '290',
    continent: 'Africa',
    capital: 'Jamestown',
    timeZoneInCapital: 'Africa/Abidjan',
    currency: 'Pound',
    languageCodes: 'en-SH',
  },
  {
    name: 'Santa Lucía',
    nameOfficialIso: 'Santa Lucía',
    codeAlfa_2: 'LC',
    codeAlfa_3: 'LCA',
    codeNumeric: '662',
    topLevelDomain: 'lc',
    FIPS: 'ST',
    geoNameID: 3576468,
    E164: 1,
    phoneCode: '1-758',
    continent: 'North America',
    capital: 'Castries',
    timeZoneInCapital: 'America/Port_of_Spain',
    currency: 'Dollar',
    languageCodes: 'en-LC',
  },
  {
    name: 'Santo Tomé y Príncipe',
    nameOfficialIso: 'Santo Tomé y Príncipe',
    codeAlfa_2: 'ST',
    codeAlfa_3: 'STP',
    codeNumeric: '678',
    topLevelDomain: 'st',
    FIPS: 'TP',
    geoNameID: 2410758,
    E164: 239,
    phoneCode: '239',
    continent: 'Africa',
    capital: 'Sao Tome',
    timeZoneInCapital: 'Africa/Abidjan',
    currency: 'Dobra',
    languageCodes: 'pt-ST',
  },
  {
    name: 'Senegal',
    nameOfficialIso: 'Senegal',
    codeAlfa_2: 'SN',
    codeAlfa_3: 'SEN',
    codeNumeric: '686',
    topLevelDomain: 'sn',
    FIPS: 'SG',
    geoNameID: 2245662,
    E164: 221,
    phoneCode: '221',
    continent: 'Africa',
    capital: 'Dakar',
    timeZoneInCapital: 'Africa/Abidjan',
    currency: 'Franc',
    languageCodes: 'fr-SN,wo,fuc,mnk',
  },
  {
    name: 'Serbia',
    nameOfficialIso: 'Serbia',
    codeAlfa_2: 'RS',
    codeAlfa_3: 'SRB',
    codeNumeric: '688',
    topLevelDomain: 'rs',
    FIPS: 'RI',
    geoNameID: 6290252,
    E164: 381,
    phoneCode: '381',
    continent: 'Europe',
    capital: 'Belgrade',
    timeZoneInCapital: 'Europe/Belgrade',
    currency: 'Dinar',
    languageCodes: 'sr,hu,bs,rom',
  },
  {
    name: 'Seychelles',
    nameOfficialIso: 'Seychelles',
    codeAlfa_2: 'SC',
    codeAlfa_3: 'SYC',
    codeNumeric: '690',
    topLevelDomain: 'sc',
    FIPS: 'SE',
    geoNameID: 241170,
    E164: 248,
    phoneCode: '248',
    continent: 'Africa',
    capital: 'Victoria',
    timeZoneInCapital: 'Indian/Mahe',
    currency: 'Rupee',
    languageCodes: 'en-SC,fr-SC',
  },
  {
    name: 'Sierra Leona',
    nameOfficialIso: 'Sierra leona',
    codeAlfa_2: 'SL',
    codeAlfa_3: 'SLE',
    codeNumeric: '694',
    topLevelDomain: 'sl',
    FIPS: 'SL',
    geoNameID: 2403846,
    E164: 232,
    phoneCode: '232',
    continent: 'Africa',
    capital: 'Freetown',
    timeZoneInCapital: 'Africa/Abidjan',
    currency: 'Leone',
    languageCodes: 'en-SL,men,tem',
  },
  {
    name: 'Singapur',
    nameOfficialIso: 'Singapur',
    codeAlfa_2: 'SG',
    codeAlfa_3: 'SGP',
    codeNumeric: '702',
    topLevelDomain: 'sg',
    FIPS: 'SN',
    geoNameID: 1880251,
    E164: 65,
    phoneCode: '65',
    continent: 'Asia',
    capital: 'Singapore',
    timeZoneInCapital: 'Asia/Singapore',
    currency: 'Dollar',
    languageCodes: 'cmn,en-SG,ms-SG,ta-SG,zh-SG',
  },
  {
    name: 'San Martín',
    nameOfficialIso: 'Sint Maarten (parte neerlandesa)',
    codeAlfa_2: 'SX',
    codeAlfa_3: 'SXM',
    codeNumeric: '534',
    topLevelDomain: 'sx',
    FIPS: 'NN',
    geoNameID: 7609695,
    E164: 1,
    phoneCode: '1-721',
    continent: 'North America',
    capital: 'Philipsburg',
    timeZoneInCapital: 'America/Curacao',
    currency: 'Guilder',
    languageCodes: 'nl,en',
  },
  {
    name: 'Siria',
    nameOfficialIso: 'República Árabe Siria',
    codeAlfa_2: 'SY',
    codeAlfa_3: 'SYR',
    codeNumeric: '760',
    topLevelDomain: 'sy',
    FIPS: 'SY',
    geoNameID: 163843,
    E164: 963,
    phoneCode: '963',
    continent: 'Asia',
    capital: 'Damascus',
    timeZoneInCapital: 'Asia/Damascus',
    currency: 'Pound',
    languageCodes: 'ar-SY,ku,hy,arc,fr,en',
  },
  {
    name: 'Somalia',
    nameOfficialIso: 'Somalia',
    codeAlfa_2: 'SO',
    codeAlfa_3: 'SOM',
    codeNumeric: '706',
    topLevelDomain: 'so',
    FIPS: 'SO',
    geoNameID: 51537,
    E164: 252,
    phoneCode: '252',
    continent: 'Africa',
    capital: 'Mogadishu',
    timeZoneInCapital: 'Africa/Mogadishu',
    currency: 'Shilling',
    languageCodes: 'so-SO,ar-SO,it,en-SO',
  },
  {
    name: 'Sri Lanka',
    nameOfficialIso: 'Sri Lanka',
    codeAlfa_2: 'LK',
    codeAlfa_3: 'LKA',
    codeNumeric: '144',
    topLevelDomain: 'lk',
    FIPS: 'CE',
    geoNameID: 1227603,
    E164: 94,
    phoneCode: '94',
    continent: 'Asia',
    capital: 'Colombo',
    timeZoneInCapital: 'Asia/Colombo',
    currency: 'Rupee',
    languageCodes: 'si,ta,en',
  },
  {
    name: 'Suazilandia',
    nameOfficialIso: 'Suazilandia',
    codeAlfa_2: 'SZ',
    codeAlfa_3: 'SWZ',
    codeNumeric: '748',
    topLevelDomain: 'sz',
    FIPS: 'WZ',
    geoNameID: 934841,
    E164: 268,
    phoneCode: '268',
    continent: 'Africa',
    capital: 'Mbabane',
    timeZoneInCapital: 'Africa/Johannesburg',
    currency: 'Lilangeni',
    languageCodes: 'en-SZ,ss-SZ',
  },
  {
    name: 'Sudáfrica',
    nameOfficialIso: 'Sudáfrica',
    codeAlfa_2: 'ZA',
    codeAlfa_3: 'ZAF',
    codeNumeric: '710',
    topLevelDomain: 'za',
    FIPS: 'SF',
    geoNameID: 953987,
    E164: 27,
    phoneCode: '27',
    continent: 'Africa',
    capital: 'Pretoria',
    timeZoneInCapital: 'Africa/Johannesburg',
    currency: 'Rand',
    languageCodes: 'zu,xh,af,nso,en-ZA,tn,st,ts,ss,ve,nr',
  },
  {
    name: 'Sudán',
    nameOfficialIso: 'Sudán (el)',
    codeAlfa_2: 'SD',
    codeAlfa_3: 'SDN',
    codeNumeric: '729',
    topLevelDomain: 'sd',
    FIPS: 'SU',
    geoNameID: 366755,
    E164: 249,
    phoneCode: '249',
    continent: 'Africa',
    capital: 'Khartoum',
    timeZoneInCapital: 'Africa/Khartoum',
    currency: 'Pound',
    languageCodes: 'ar-SD,en,fia',
  },
  {
    name: 'Sudán del Sur',
    nameOfficialIso: 'Sudán del Sur',
    codeAlfa_2: 'SS',
    codeAlfa_3: 'SSD',
    codeNumeric: '728',
    topLevelDomain: 'ss',
    FIPS: 'OD',
    geoNameID: 7909807,
    E164: 211,
    phoneCode: '211',
    continent: 'Africa',
    capital: 'Juba',
    timeZoneInCapital: 'Africa/Khartoum',
    currency: 'Pound',
    languageCodes: 'en',
  },
  {
    name: 'Suecia',
    nameOfficialIso: 'Suecia',
    codeAlfa_2: 'SE',
    codeAlfa_3: 'SWE',
    codeNumeric: '752',
    topLevelDomain: 'se',
    FIPS: 'SW',
    geoNameID: 2661886,
    E164: 46,
    phoneCode: '46',
    continent: 'Europe',
    capital: 'Stockholm',
    timeZoneInCapital: 'Europe/Stockholm',
    currency: 'Krona',
    languageCodes: 'sv-SE,se,sma,fi-SE',
  },
  {
    name: 'Suiza',
    nameOfficialIso: 'Suiza',
    codeAlfa_2: 'CH',
    codeAlfa_3: 'CHE',
    codeNumeric: '756',
    topLevelDomain: 'ch',
    FIPS: 'SZ',
    geoNameID: 2658434,
    E164: 41,
    phoneCode: '41',
    continent: 'Europe',
    capital: 'Berne',
    timeZoneInCapital: 'Europe/Zurich',
    currency: 'Franc',
    languageCodes: 'de-CH,fr-CH,it-CH,rm',
  },
  {
    name: 'Surinam',
    nameOfficialIso: 'Suriname',
    codeAlfa_2: 'SR',
    codeAlfa_3: 'SUR',
    codeNumeric: '740',
    topLevelDomain: 'sr',
    FIPS: 'NS',
    geoNameID: 3382998,
    E164: 597,
    phoneCode: '597',
    continent: 'South America',
    capital: 'Paramaribo',
    timeZoneInCapital: 'America/Paramaribo',
    currency: 'Dollar',
    languageCodes: 'nl-SR,en,srn,hns,jv',
  },
  {
    name: 'Svalbard y Jan Mayen',
    nameOfficialIso: 'Svalbard y Jan Mayen',
    codeAlfa_2: 'SJ',
    codeAlfa_3: 'SJM',
    codeNumeric: '744',
    topLevelDomain: 'sj',
    FIPS: 'SV',
    geoNameID: 607072,
    E164: 47,
    phoneCode: '47',
    continent: 'Europe',
    capital: 'Longyearbyen',
    timeZoneInCapital: 'Europe/Oslo',
    currency: 'Krone',
    languageCodes: 'no,ru',
  },
  {
    name: 'Tailandia',
    nameOfficialIso: 'Tailandia',
    codeAlfa_2: 'TH',
    codeAlfa_3: 'THA',
    codeNumeric: '764',
    topLevelDomain: 'th',
    FIPS: 'TH',
    geoNameID: 1605651,
    E164: 66,
    phoneCode: '66',
    continent: 'Asia',
    capital: 'Bangkok',
    timeZoneInCapital: 'Asia/Bangkok',
    currency: 'Baht',
    languageCodes: 'th,en',
  },
  {
    name: 'Taiwán (República de China)',
    nameOfficialIso: 'Taiwán (Provincia de China)',
    codeAlfa_2: 'TW',
    codeAlfa_3: 'TWN',
    codeNumeric: '158',
    topLevelDomain: 'tw',
    FIPS: 'TW',
    geoNameID: 1668284,
    E164: 886,
    phoneCode: '886',
    continent: 'Asia',
    capital: 'Taipei',
    timeZoneInCapital: 'Asia/Taipei',
    currency: 'Dollar',
    languageCodes: 'zh-TW,zh,nan,hak',
  },
  {
    name: 'Tanzania',
    nameOfficialIso: 'Tanzania, República Unida de',
    codeAlfa_2: 'TZ',
    codeAlfa_3: 'TZA',
    codeNumeric: '834',
    topLevelDomain: 'tz',
    FIPS: 'TZ',
    geoNameID: 149590,
    E164: 255,
    phoneCode: '255',
    continent: 'Africa',
    capital: 'Dodoma',
    timeZoneInCapital: 'Africa/Dar_es_Salaam',
    currency: 'Shilling',
    languageCodes: 'sw-TZ,en,ar',
  },
  {
    name: 'Tayikistán',
    nameOfficialIso: 'Tayikistán',
    codeAlfa_2: 'TJ',
    codeAlfa_3: 'TJK',
    codeNumeric: '762',
    topLevelDomain: 'tj',
    FIPS: 'TI',
    geoNameID: 1220409,
    E164: 992,
    phoneCode: '992',
    continent: 'Asia',
    capital: 'Dushanbe',
    timeZoneInCapital: 'Asia/Dushanbe',
    currency: 'Somoni',
    languageCodes: 'tg,ru',
  },
  {
    name: 'Territorio Británico del Océano Índico',
    nameOfficialIso: 'Territorio Británico del Océano Índico (el)',
    codeAlfa_2: 'IO',
    codeAlfa_3: 'IOT',
    codeNumeric: '086',
    topLevelDomain: 'io',
    FIPS: 'IO',
    geoNameID: 1282588,
    E164: 246,
    phoneCode: '246',
    continent: 'Asia',
    capital: 'Diego Garcia',
    timeZoneInCapital: 'Indian/Chagos',
    currency: 'Dollar',
    languageCodes: 'en-IO',
  },
  {
    name: 'Timor Oriental',
    nameOfficialIso: 'Timor-Leste',
    codeAlfa_2: 'TL',
    codeAlfa_3: 'TLS',
    codeNumeric: '626',
    topLevelDomain: 'tl',
    FIPS: 'TT',
    geoNameID: 1966436,
    E164: 670,
    phoneCode: '670',
    continent: 'Oceania',
    capital: 'Dili',
    timeZoneInCapital: 'Asia/Dili',
    currency: 'Dollar',
    languageCodes: 'tet,pt-TL,id,en',
  },
  {
    name: 'Tokelau',
    nameOfficialIso: 'Tokelau',
    codeAlfa_2: 'TK',
    codeAlfa_3: 'TKL',
    codeNumeric: '772',
    topLevelDomain: 'tk',
    FIPS: 'TL',
    geoNameID: 4031074,
    E164: 690,
    phoneCode: '690',
    continent: 'Oceania',
    capital: '',
    timeZoneInCapital: 'Pacific/Fakaofo',
    currency: 'Dollar',
    languageCodes: 'tkl,en-TK',
  },
  {
    name: 'Tonga',
    nameOfficialIso: 'Tonga',
    codeAlfa_2: 'TO',
    codeAlfa_3: 'TON',
    codeNumeric: '776',
    topLevelDomain: 'to',
    FIPS: 'TN',
    geoNameID: 4032283,
    E164: 676,
    phoneCode: '676',
    continent: 'Oceania',
    capital: "Nuku'alofa",
    timeZoneInCapital: 'Pacific/Tongatapu',
    currency: "Pa'anga",
    languageCodes: 'to,en-TO',
  },
  {
    name: 'Túnez',
    nameOfficialIso: 'Túnez',
    codeAlfa_2: 'TN',
    codeAlfa_3: 'TUN',
    codeNumeric: '788',
    topLevelDomain: 'tn',
    FIPS: 'TS',
    geoNameID: 2464461,
    E164: 216,
    phoneCode: '216',
    continent: 'Africa',
    capital: 'Tunis',
    timeZoneInCapital: 'Africa/Tunis',
    currency: 'Dinar',
    languageCodes: 'ar-TN,fr',
  },
  {
    name: 'Trinidad y Tobago',
    nameOfficialIso: 'Trinidad y Tobago',
    codeAlfa_2: 'TT',
    codeAlfa_3: 'TTO',
    codeNumeric: '780',
    topLevelDomain: 'tt',
    FIPS: 'TD',
    geoNameID: 3573591,
    E164: 1,
    phoneCode: '1-868',
    continent: 'North America',
    capital: 'Port of Spain',
    timeZoneInCapital: 'America/Port_of_Spain',
    currency: 'Dollar',
    languageCodes: 'en-TT,hns,fr,es,zh',
  },
  {
    name: 'Togo',
    nameOfficialIso: 'Togo',
    codeAlfa_2: 'TG',
    codeAlfa_3: 'TGO',
    codeNumeric: '768',
    topLevelDomain: 'tg',
    FIPS: 'TO',
    geoNameID: 2363686,
    E164: 228,
    phoneCode: '228',
    continent: 'Africa',
    capital: 'Lome',
    timeZoneInCapital: 'Africa/Abidjan',
    currency: 'Franc',
    languageCodes: 'fr-TG,ee,hna,kbp,dag,ha',
  },
  {
    name: 'Turkmenistán',
    nameOfficialIso: 'Turkmenistán',
    codeAlfa_2: 'TM',
    codeAlfa_3: 'TKM',
    codeNumeric: '795',
    topLevelDomain: 'tm',
    FIPS: 'TX',
    geoNameID: 1218197,
    E164: 993,
    phoneCode: '993',
    continent: 'Asia',
    capital: 'Ashgabat',
    timeZoneInCapital: 'Asia/Ashgabat',
    currency: 'Manat',
    languageCodes: 'tk,ru,uz',
  },
  {
    name: 'Turquía',
    nameOfficialIso: 'Turquía',
    codeAlfa_2: 'TR',
    codeAlfa_3: 'TUR',
    codeNumeric: '792',
    topLevelDomain: 'tr',
    FIPS: 'TU',
    geoNameID: 298795,
    E164: 90,
    phoneCode: '90',
    continent: 'Asia',
    capital: 'Ankara',
    timeZoneInCapital: 'Europe/Istanbul',
    currency: 'Lira',
    languageCodes: 'tr-TR,ku,diq,az,av',
  },
  {
    name: 'Tuvalu',
    nameOfficialIso: 'Tuvalu',
    codeAlfa_2: 'TV',
    codeAlfa_3: 'TUV',
    codeNumeric: '798',
    topLevelDomain: 'tv',
    FIPS: 'TV',
    geoNameID: 2110297,
    E164: 688,
    phoneCode: '688',
    continent: 'Oceania',
    capital: 'Funafuti',
    timeZoneInCapital: 'Pacific/Funafuti',
    currency: 'Dollar',
    languageCodes: 'tvl,en,sm,gil',
  },
  {
    name: 'Ucrania',
    nameOfficialIso: 'Ucrania',
    codeAlfa_2: 'UA',
    codeAlfa_3: 'UKR',
    codeNumeric: '804',
    topLevelDomain: 'ua',
    FIPS: 'UP',
    geoNameID: 690791,
    E164: 380,
    phoneCode: '380',
    continent: 'Europe',
    capital: 'Kiev',
    timeZoneInCapital: 'Europe/Kiev',
    currency: 'Hryvnia',
    languageCodes: 'uk,ru-UA,rom,pl,hu',
  },
  {
    name: 'Uganda',
    nameOfficialIso: 'Uganda',
    codeAlfa_2: 'UG',
    codeAlfa_3: 'UGA',
    codeNumeric: '800',
    topLevelDomain: 'ug',
    FIPS: 'UG',
    geoNameID: 226074,
    E164: 256,
    phoneCode: '256',
    continent: 'Africa',
    capital: 'Kampala',
    timeZoneInCapital: 'Africa/Kampala',
    currency: 'Shilling',
    languageCodes: 'en-UG,lg,sw,ar',
  },
  {
    name: 'Uruguay',
    nameOfficialIso: 'Uruguay',
    codeAlfa_2: 'UY',
    codeAlfa_3: 'URY',
    codeNumeric: '858',
    topLevelDomain: 'uy',
    FIPS: 'UY',
    geoNameID: 3439705,
    E164: 598,
    phoneCode: '598',
    continent: 'South America',
    capital: 'Montevideo',
    timeZoneInCapital: 'America/Montevideo',
    currency: 'Peso',
    languageCodes: 'es-UY',
  },
  {
    name: 'Vanuatu',
    nameOfficialIso: 'Vanuatu',
    codeAlfa_2: 'VU',
    codeAlfa_3: 'VUT',
    codeNumeric: '548',
    topLevelDomain: 'vu',
    FIPS: 'NH',
    geoNameID: 2134431,
    E164: 678,
    phoneCode: '678',
    continent: 'Oceania',
    capital: 'Port Vila',
    timeZoneInCapital: 'Pacific/Efate',
    currency: 'Vatu',
    languageCodes: 'bi,en-VU,fr-VU',
  },
  {
    name: 'Ciudad del Vaticano',
    nameOfficialIso: 'Santa Sede (la)',
    codeAlfa_2: 'VA',
    codeAlfa_3: 'VAT',
    codeNumeric: '336',
    topLevelDomain: 'va',
    FIPS: 'VT',
    geoNameID: 3164670,
    E164: 39,
    phoneCode: '379',
    continent: 'Europe',
    capital: 'Vatican City',
    timeZoneInCapital: 'Europe/Rome',
    currency: 'Euro',
    languageCodes: 'la,it,fr',
  },
  {
    name: 'Uzbekistán',
    nameOfficialIso: 'Uzbekistán',
    codeAlfa_2: 'UZ',
    codeAlfa_3: 'UZB',
    codeNumeric: '860',
    topLevelDomain: 'uz',
    FIPS: 'UZ',
    geoNameID: 1512440,
    E164: 998,
    phoneCode: '998',
    continent: 'Asia',
    capital: 'Tashkent',
    timeZoneInCapital: 'Asia/Tashkent',
    currency: 'Som',
    languageCodes: 'uz,ru,tg',
  },
  {
    name: 'Venezuela',
    nameOfficialIso: 'Venezuela (República Bolivariana de)',
    codeAlfa_2: 'VE',
    codeAlfa_3: 'VEN',
    codeNumeric: '862',
    topLevelDomain: 've',
    FIPS: 'VE',
    geoNameID: 3625428,
    E164: 58,
    phoneCode: '58',
    continent: 'South America',
    capital: 'Caracas',
    timeZoneInCapital: 'America/Caracas',
    currency: 'Bolivar',
    languageCodes: 'es-VE',
  },
  {
    name: 'Vietnam',
    nameOfficialIso: 'Viet Nam',
    codeAlfa_2: 'VN',
    codeAlfa_3: 'VNM',
    codeNumeric: '704',
    topLevelDomain: 'vn',
    FIPS: 'VM',
    geoNameID: 1562822,
    E164: 84,
    phoneCode: '84',
    continent: 'Asia',
    capital: 'Hanoi',
    timeZoneInCapital: 'Asia/Ho_Chi_Minh',
    currency: 'Dong',
    languageCodes: 'vi,en,fr,zh,km',
  },
  {
    name: 'Wallis y Futuna',
    nameOfficialIso: 'Wallis y Futuna',
    codeAlfa_2: 'WF',
    codeAlfa_3: 'WLF',
    codeNumeric: '876',
    topLevelDomain: 'wf',
    FIPS: 'WF',
    geoNameID: 4034749,
    E164: 681,
    phoneCode: '681',
    continent: 'Oceania',
    capital: 'Mata Utu',
    timeZoneInCapital: 'Pacific/Wallis',
    currency: 'Franc',
    languageCodes: 'wls,fud,fr-WF',
  },
  {
    name: 'Yemen',
    nameOfficialIso: 'Yemen',
    codeAlfa_2: 'YE',
    codeAlfa_3: 'YEM',
    codeNumeric: '887',
    topLevelDomain: 'ye',
    FIPS: 'YM',
    geoNameID: 69543,
    E164: 967,
    phoneCode: '967',
    continent: 'Asia',
    capital: 'Sanaa',
    timeZoneInCapital: 'Asia/Aden',
    currency: 'Rial',
    languageCodes: 'ar-YE',
  },
  {
    name: 'Yibuti',
    nameOfficialIso: 'Yibuti',
    codeAlfa_2: 'DJ',
    codeAlfa_3: 'DJI',
    codeNumeric: '262',
    topLevelDomain: 'dj',
    FIPS: 'DJ',
    geoNameID: 223816,
    E164: 253,
    phoneCode: '253',
    continent: 'Africa',
    capital: 'Djibouti',
    timeZoneInCapital: 'Africa/Djibouti',
    currency: 'Franc',
    languageCodes: 'fr-DJ,ar,so-DJ,aa',
  },
  {
    name: 'Zambia',
    nameOfficialIso: 'Zambia',
    codeAlfa_2: 'ZM',
    codeAlfa_3: 'ZMB',
    codeNumeric: '894',
    topLevelDomain: 'zm',
    FIPS: 'ZA',
    geoNameID: 895949,
    E164: 260,
    phoneCode: '260',
    continent: 'Africa',
    capital: 'Lusaka',
    timeZoneInCapital: 'Africa/Maputo',
    currency: 'Kwacha',
    languageCodes: 'en-ZM,bem,loz,lun,lue,ny,toi',
  },
  {
    name: 'Zimbabue',
    nameOfficialIso: 'Zimbabue',
    codeAlfa_2: 'ZW',
    codeAlfa_3: 'ZWE',
    codeNumeric: '716',
    topLevelDomain: 'zw',
    FIPS: 'ZI',
    geoNameID: 878675,
    E164: 263,
    phoneCode: '263',
    continent: 'Africa',
    capital: 'Harare',
    timeZoneInCapital: 'Africa/Maputo',
    currency: 'Dollar',
    languageCodes: 'en-ZW,sn,nr,nd',
  },
  {
    name: 'Åland',
    nameOfficialIso: 'Åland, Islas',
    codeAlfa_2: 'AX',
    codeAlfa_3: 'ALA',
    codeNumeric: '248',
    topLevelDomain: null,
    FIPS: null,
    geoNameID: 0,
    E164: 0,
    phoneCode: '358',
    continent: '',
    capital: '',
    timeZoneInCapital: '',
    currency: '',
    languageCodes: '',
  },
  {
    name: 'Bonaire, San Eustaquio y Saba',
    nameOfficialIso: 'Bonaire, San Eustaquio y Saba',
    codeAlfa_2: 'BQ',
    codeAlfa_3: 'BES',
    codeNumeric: '535',
    topLevelDomain: null,
    FIPS: null,
    geoNameID: 0,
    E164: 0,
    phoneCode: '599',
    continent: '',
    capital: '',
    timeZoneInCapital: '',
    currency: '',
    languageCodes: '',
  },
  {
    name: 'Guadalupe',
    nameOfficialIso: 'Guadeloupe',
    codeAlfa_2: 'GP',
    codeAlfa_3: 'GLP',
    codeNumeric: '312',
    topLevelDomain: null,
    FIPS: null,
    geoNameID: 0,
    E164: 0,
    phoneCode: '590',
    continent: '',
    capital: '',
    timeZoneInCapital: '',
    currency: '',
    languageCodes: '',
  },
  {
    name: 'Guayana Francesa',
    nameOfficialIso: 'Guayana Francesa',
    codeAlfa_2: 'GF',
    codeAlfa_3: 'GUF',
    codeNumeric: '254',
    topLevelDomain: null,
    FIPS: null,
    geoNameID: 0,
    E164: 0,
    phoneCode: '594',
    continent: '',
    capital: '',
    timeZoneInCapital: '',
    currency: '',
    languageCodes: '',
  },
  {
    name: 'Isla Bouvet',
    nameOfficialIso: 'Bouvet, Isla',
    codeAlfa_2: 'BV',
    codeAlfa_3: 'BVT',
    codeNumeric: '074',
    topLevelDomain: null,
    FIPS: null,
    geoNameID: 0,
    E164: 0,
    phoneCode: '55',
    continent: '',
    capital: '',
    timeZoneInCapital: '',
    currency: '',
    languageCodes: '',
  },
  {
    name: 'Islas Georgias del Sur y Sandwich del Sur',
    nameOfficialIso: 'Georgia del Sur (la) y las Islas Sandwich del Sur',
    codeAlfa_2: 'GS',
    codeAlfa_3: 'SGS',
    codeNumeric: '239',
    topLevelDomain: null,
    FIPS: null,
    geoNameID: 0,
    E164: 0,
    phoneCode: '500',
    continent: '',
    capital: '',
    timeZoneInCapital: '',
    currency: '',
    languageCodes: '',
  },
  {
    name: 'Islas Heard y McDonald',
    nameOfficialIso: 'Heard (Isla) e Islas McDonald',
    codeAlfa_2: 'HM',
    codeAlfa_3: 'HMD',
    codeNumeric: '334',
    topLevelDomain: null,
    FIPS: null,
    geoNameID: 0,
    E164: 0,
    phoneCode: '500',
    continent: '',
    capital: '',
    timeZoneInCapital: '',
    currency: '',
    languageCodes: '',
  },
  {
    name: 'Islas Ultramarinas Menores de los Estados Unidos',
    nameOfficialIso: 'Islas Ultramarinas Menores de los Estados Unidos (las)',
    codeAlfa_2: 'UM',
    codeAlfa_3: 'UMI',
    codeNumeric: '581',
    topLevelDomain: null,
    FIPS: null,
    geoNameID: 0,
    E164: 0,
    phoneCode: '1',
    continent: '',
    capital: '',
    timeZoneInCapital: '',
    currency: '',
    languageCodes: '',
  },
  {
    name: 'Martinica',
    nameOfficialIso: 'Martinique',
    codeAlfa_2: 'MQ',
    codeAlfa_3: 'MTQ',
    codeNumeric: '474',
    topLevelDomain: null,
    FIPS: null,
    geoNameID: 0,
    E164: 0,
    phoneCode: '596',
    continent: '',
    capital: '',
    timeZoneInCapital: '',
    currency: '',
    languageCodes: '',
  },
  {
    name: 'Isla Norfolk',
    nameOfficialIso: 'Norfolk, Isla',
    codeAlfa_2: 'NF',
    codeAlfa_3: 'NFK',
    codeNumeric: '574',
    topLevelDomain: null,
    FIPS: null,
    geoNameID: 0,
    E164: 0,
    phoneCode: '672',
    continent: '',
    capital: '',
    timeZoneInCapital: '',
    currency: '',
    languageCodes: '',
  },
  {
    name: 'Tierras Australes y Antárticas Francesas',
    nameOfficialIso: 'Tierras Australes Francesas (las)',
    codeAlfa_2: 'TF',
    codeAlfa_3: 'ATF',
    codeNumeric: '260',
    topLevelDomain: null,
    FIPS: null,
    geoNameID: 0,
    E164: 0,
    phoneCode: '33',
    continent: '',
    capital: '',
    timeZoneInCapital: '',
    currency: '',
    languageCodes: '',
  },
];
export {
  CHECK_CI, COUNTRIES
};
