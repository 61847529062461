import React, { useEffect, useState, useContext } from 'react';
import styles from './style.module.scss';
import Modal from '../../../Modal';
import DocumentSigned from './Components/DocumentsSignedCorrectly';
import SignContract from './Components/SignContract';
import { CheckSmsCodeData, SendSmsCodeData, StoreAdscriptionData, StoreContractData } from '../../../../utils/interfaces';
import { UseStep } from '../../Store/step-context';
import LoggedUserContext from '../../../../context/LoggedUserContext';
import { checkSmsCode, sendSmsCode, postBankInfo, storeAdscription, storeContract } from '../../Store/actions';
import { Checkbox, LoadingImed } from '@imed_npm/design-system';
import PDFDataViewer from '../../../PdfViewer';
import { PDFDocument } from 'pdf-lib';
import { RADIO_OPTION } from '../ProfileSelection/constants';
import ToastMessage from '../../../../utils/ToastMessage';

const ProductPlan = (getType) => {
  const { dispatch, state: { general, steps: { step2, step3, step4, step5, step55, step22, step6, step61 }, form: { personContractType, personPlanType, selectedSpecialties, rutRL }, info, adscription_id } } = UseStep();
  const [accepted, setAccepted] = useState<boolean>(step6.accepted);
  const [adscriptionId, setAdscriptionId] = useState<number>();

  const [contract, setContract] = useState<string>(step5.plan.contract_template || (personPlanType && personPlanType.contract_template ? personPlanType.contract_template : ''));
  const [contractFilled, setContractFilled] = useState<boolean>(false);
  const [smsCode, setSmsCode] = useState<string>('');

  const context = useContext(LoggedUserContext);

  useEffect(() => {
    const { bankSelected } = step61;
    const { nombres, apellido_paterno, apellido_materno } = info;

    if (bankSelected.bank.id !== 0) {
      const data = {
        adscription_id: adscription_id,
        // @ts-ignore
        name: rutRL ? (rutRL.id === 1 ? step22.social : step22.name) : `${nombres} ${apellido_paterno} ${apellido_materno}`,
        // @ts-ignore
        nid: rutRL ? (rutRL.id === 1 ? step2.nid : step22.nid) : step2.nid,
        account: bankSelected.number,
        account_type_id: bankSelected.type.id,
        bank_id: bankSelected.bank.id,
        file_code: step61.file
      };
      postBankInfo(context, data);
    }

  }, [step61]);
  const renderCheckingInfo = () => {
    const { plans } = step5.validations;
    if (plans.pending)
      return (
        <>
          {(plans.pending) && (<LoadingImed text="Almacenando adscripción" />)}
        </>
      );

    return;
  };

  const renderError = () => {
    const { plans } = step5.validations;
    if (plans.error) {
      ToastMessage.enviarToastError('Ha ocurrido un problema al momento de crear tu adscripción por favor intentar mas tarde');
      if (getType.type.toLowerCase() === 'em') {
        dispatch({ type: 'UPDATE_STEP', payload: { step: 'step6' } });
      } else {
        dispatch({ type: 'UPDATE_STEP', payload: { step: 'step5' } });
      }
    }
  };

  const checkCode = () => {
    const data: CheckSmsCodeData = {
      code: smsCode,
      oid: step6.oid,
    };

    checkSmsCode({ dispatch, payload: data });
  };

  const resendCode = () => {
    resetValidations();

    if (accepted) {
      const data: SendSmsCodeData = {
        nid: step2.nid,
      };
      sendSmsCode({ dispatch, payload: data });
    }
  };

  const acceptContract = () => {
    setAccepted(!accepted);
  };

  const goToEndScreen = (show) => {
    if (!show) {
      window.location.href = '/';
    }
  };

  const codeStateMessage = (): { type: 'error' | 'success' | 'info' | 'warning' | 'default'; message: string; } => {
    if (smsCode) {
      const { validations: { checkSmsCode: { error, success } } } = step6;
      if (success) return { type: 'success', message: 'Contrato firmado' };
      if (error) return { type: 'error', message: 'Código incorrecto' };
    }
    return { type: 'default', message: '' };
  };

  const resetValidations = () => {
    dispatch({ type: 'CHECK_SMS_CODE', payload: { key: '' } });
  };
  useEffect(() => {
    const timeout = setTimeout(() => {

      const { storedCenterInfo } = step4;
      const { plan, readers } = step5;
      const { frontCode, backCode } = personContractType == RADIO_OPTION.nature.value ? step2 : step22;


      if ((Boolean(plan.id) && readers > 0 && storedCenterInfo) || (typeof personPlanType !== 'undefined' && Boolean(personPlanType.id) && storedCenterInfo)) {
        const data: StoreAdscriptionData = {
          nid: step2.nid,
          plan_id: plan.id || (personPlanType && personPlanType.id ? personPlanType.id : -1),
          readers: readers ?? 1,
          center_id: storedCenterInfo.id,
          adscription_id: adscription_id
        };

        if (personContractType === RADIO_OPTION.legal.value) {
          data.billing_info = {
            nid: step22.nid ? step22.nid : '',
            // nid: rutRL ? (step22.nid ?? '') : (step2.nid ?? ''),
            social: step22.name ? step22.name : '',
            address: step22.address ? step22.address : '',
            address_detail: step22.address_detail ? step22.address_detail : '',
            locality_id: parseInt(step22.commune.id, 10),
            activity_code: '',
            classification: '',
            email: step22.email ? step22.email : '',
            ci_code_front: frontCode.split('base64,')[1],
            ci_code_back: backCode.split('base64,')[1],
          };
        }
        storeAdscription({ dispatch, payload: data }).then((adscription_id) => {
          if (adscription_id) {
            setAdscriptionId(adscription_id);
          } else {
            ToastMessage.enviarToastError('Ha ocurrido un problema al momento de crear tu adscripción por favor intentar mas tarde');
          }
        });

      }

    }, 250);
    return () => clearTimeout(timeout);

  }, []);

  useEffect(() => {
    dispatch({ type: 'ACCEPT_CONTRACT', payload: { accepted } });
    resendCode();
  }, [accepted]);

  useEffect(resetValidations, [smsCode]);
  useEffect(() => {
    const { nid } = step2;
    const { phone, email } = step3;
    const { nombres, apellido_paterno, apellido_materno } = info;
    PDFDocument.load(Uint8Array.from(atob(contract), c => c.charCodeAt(0))).then<string>(pdfDoc => {
      return pdfDoc.saveAsBase64();
    }).then(filledContract => {
      setContract(filledContract);
      setContractFilled(true);
    });
  }, []);

  useEffect(() => {
    if (!step6.validated) return;
    const { nid } = step2;
    const { discount } = step5;
    const { shipping } = general;
    const payload = {
      adscription_id: adscriptionId, discount_code: discount, shipping:
        { address: shipping?.address, address_detail: shipping?.address_detail, locality_id: shipping?.locality_id }, nid, code: contract, plan_id: personPlanType ? +personPlanType.id : +step5.plan.id
    } as StoreContractData;

    if (step55.cvp) {
      payload.cvp_file = step55.cvp;
      payload.cvp_nid = step55.nid ?? null;
    }
    if (shipping) {
      payload.shipping = { address: shipping?.address, address_detail: shipping?.address_detail, locality_id: shipping?.locality_id };
    }
    if (selectedSpecialties) {
      payload.specialties = selectedSpecialties;
    }
    storeContract({ dispatch, payload: payload });
    console.log('storeContract: ', payload);
  }, [step6.validated]);

  return (
    <>
      {renderCheckingInfo()}
      {renderError()}
      {(getType.type !== 'EM' ? step5.validations.plans.success : (getType.type === 'LME' ? personPlanType : true)) &&
        <div className={styles.deal_signature}>
          <div className="contract">
            {contractFilled &&
              <PDFDataViewer pdf={contract} props={{ view: 'fitH', toolbar: 1, navpanes: 0 }} />
            }
          </div>
          <div className="accpet">
            <Checkbox
              id="checkbox"
              label="Acepto los términos y condiciones de este contrato"
              name="checkbox"
              isChecked={accepted}
              onChange={acceptContract}
            />
          </div>
          {
            step6.validated && !step6.storedContract && step6.validations.storeContract.pending && <LoadingImed text="Almacenando Contrato" />
          }
          <Modal showModal={step6.validated && !!step6.storedContract} setShowModal={goToEndScreen}>
            <DocumentSigned type={getType.type} />
          </Modal>
          <Modal showModal={accepted && !step6.validated} containerClass='w-1/2' showCloseButton={true}>
            <SignContract
              setSmsCode={setSmsCode}
              phone_country_code={step3.phone_country_code}
              phone={step3.phone}
              checkSmsCode={checkCode}
              sendCode={resendCode}
              canResend={step6.validations.sendSmsCode.success}
              checking={step6.validations.checkSmsCode.pending}
              codeStateMessage={codeStateMessage} />
          </Modal>
        </div>
      }
    </>
  );
};

export default ProductPlan;
