import React, { useEffect } from 'react';
import { UseStep } from '../../../../Store/step-context'; 
import styles from './style.module.scss';
import icon from './assets/icon.svg';

interface OwnProps {
  text?: string;
}

const SuccessModal = ({ text }: OwnProps) => {

  const { dispatch } = UseStep();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch({ type: 'UPDATE_STEP', payload: { step: 'step9' } });
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.customer_debts}>
      <img src={icon} alt={'imagen'} />
      <p className={styles.title}>
        {text || 'El proceso ha terminado correctamente'}
      </p>
    </div>
  );
};


export default SuccessModal;
