import React, { ChangeEvent, useState, useEffect } from 'react';
import { Button, TooltipGlobal, InfoIcon } from '@imed_npm/design-system';
import { compressFile } from '../../../../../../utils/fileCompressor'; // Asegúrate de que la ruta sea correcta
import ToastMessage from '../../../../../../utils/ToastMessage';
import { UseStep } from '../../../../Store/step-context';
import { storeCVP } from '../../../../Store/actions';
import Modal from '../../../../../Modal';
import styles from './style.module.scss';

interface OwnProps {
  type?: string;
}

const AttachFile: React.FC<OwnProps> = ({ type }) => {
  const { dispatch, state: { steps: { step22, step3, step55: { cvp, name } } } } = UseStep();
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalCVP, setShowModalCVP] = useState<boolean>(false);

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && (file.type === 'application/pdf')) {
      const { name } = file;
      dispatch({ type: 'STORE_CVP_NAME', payload: name });

      try {
        const result = await compressFile(file);
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64String = reader.result as string;
          const base64WithoutHeader = base64String.substring(base64String.indexOf(',') + 1);

          storeCVP({
            dispatch, payload: {
              cvp: base64WithoutHeader
            }
          }).catch((error) => {
            ToastMessage.enviarToastError('Error al almacenar el archivo:', error.message);
          });
        };

        reader.readAsDataURL(result.file);
      } catch (error) {
        ToastMessage.enviarToastError('Error al comprimir el archivo:', error);
      }
    } else {
      setSelectedFile('');
      dispatch({ type: 'STORE_CVP_NAME', payload: '' });
      ToastMessage.enviarToastError('Por favor, seleccione un archivo de tipo .pdf');
    }
  };

  const nextStep = () => {
    dispatch({ type: 'UPDATE_STEP', payload: { step: 'step6' } });
  };

  const remove = () => {
    setSelectedFile('');
    dispatch({ type: 'STORE_CVP_NAME', payload: '' });
    storeCVP({ dispatch, payload: '' });
  };

  const pending = () => {
    setSelectedFile('');
    dispatch({ type: 'STORE_CVP_NAME', payload: '' });
    storeCVP({ dispatch, payload: '' });
    setShowModal(true);
  };

  useEffect(() => {
    if (cvp && name) {
      setSelectedFile(name);
    }
  }, [name, cvp]);

  return (
    <div className='flex flex-col w-full'>
      {
        selectedFile === '' && <div className={`${type === 'be' ? 'justify-center' : 'justify-between'} flex items-center`}>
          <div className='flex items-center justify-start'>
            <input id="file_cvp" type="file" accept=".pdf" className={`${styles.custom_file_input}`} onChange={handleFileUpload} />

            {
              type !== 'be' && <div className={'self-center cursor-pointer'} onClick={() => { setShowModalCVP(true); }}>
                <InfoIcon svgClass={styles.icon_color} />
              </div>
            }

          </div>
          <button id="pending_cvp" onClick={pending} className="text-sm bg-cyan-400 hover:bg-blue-700 text-white py-2 px-4 rounded h-[40px]">
            Dejar pendiente y continuar.
          </button>
        </div>
      }

      {/* file name */}
      {(selectedFile !== '') && <div className="mt-5 border p-4">
        <div className='flex w-full justify-between items-center'>
          <div className='flex items-center'>
            <p><small>{selectedFile}</small></p>
            <img className='ml-2' src="images/plan-standar.svg" />
          </div>
          <a type="button" id="remove_cvp" onClick={remove} className={styles.removeFile}>Quitar documento</a>
        </div>
      </div>}
      {/* modal */}
      <Modal title={''} modalClass="w-9/12" showModal={showModal} showCloseButton={true} functionCloseButton={() => { setShowModal(false); }}>
        <div className='flex flex-col pl-20 pr-20'>
          <div className='p-5 mx-auto'><img src='images/warning.svg' className='w-3/4 d-block mx-auto' /></div>
          <div className='p-5'>
            <p className='text-justify mb-6'>
              Tienes la posibilidad de continuar con el proceso sin disponer del documento. Al hacerlo, aceptas que es tu responsabilidad gestionar el convenio con el Financiador.
            </p>
            <p className='text-justify'>
              ¿No sabes cómo hacerlo?, ¡no hay problema!. Te enviaremos una guía a tu correo electrónico <strong>{(step3 && step3.email !== '') ? step3.email : step22.email}</strong> ya registrado con anterioridad.
            </p>
          </div>
          <div className='py-5 flex justify-end'>
            <Button buttonType='button' id="continue_cvp" type="secondary" className="" onClick={nextStep}>Continuar, sin documento</Button>
          </div>
        </div>
      </Modal>

      {/* modal info cvp */}
      <Modal title={''} modalClass="w-9/12" showModal={showModalCVP} showCloseButton={true} functionCloseButton={() => { setShowModalCVP(false); }}>
        <img src='images/cvp.png' />
      </Modal>
    </div>
  );
};

export default AttachFile;
