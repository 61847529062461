
import styles from './style.module.scss';
import icon from '../DealSignature/Components/DocumentsSignedCorrectly/assets/icon.svg';
import { Button } from '@imed_npm/design-system';

const ModalSuccess = ({ text, setShowModal }: any) => {
  return (
    <div className={styles.documents_signed}>
      <img src={icon} alt={'imagen'} />
      <h2 className={styles.title}>
        ¡Buenas noticias!
      </h2><br /><br />
      {text}
      <div className={styles.button_close}>
        <Button buttonType='button' type="secondary" onClick={() => { setShowModal(false); }}>
          Ok! entendido
        </Button>
      </div>
    </div>
  );
};


export default ModalSuccess;
