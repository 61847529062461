import React, { useEffect, useState } from 'react';
import { Input, Select, Button, InfoIcon, TooltipGlobal, Skeleton } from '@imed_npm/design-system';
import { DateTime } from 'luxon';
import styles from './style.module.scss';
import Modal from '../../../Modal';
import AttachCI from '../ProfessionalInfo/Components/AttachCI';
import { checkDebt, getAddressValidation, getCheck, getInfo, getProfessionalProductStatus, getRegions } from '../../Store/actions';
import { initialState, UseStep } from '../../Store/step-context';
import { Region, Locality, GooglePredictionSelectItem } from '../../../../utils/interfaces';
import PreviewAttach from '../ProfessionalInfo/Components/PreviewAttach';
import CustomerDebts from '../ProfessionalInfo/Components/CustomerDebts';
import { useNavigate } from 'react-router-dom';
import { formatRut, validFormat } from '../../../../utils/rut';
import { validateEmail } from '../../../../utils/email';
import Typeahead from '../Typeahead';
import IncompatibleDegree from '../ProfessionalInfo/Components/IncompatibleDegree';
import WarningModal from '../ProfessionalInfo/Components/WarningMessage';
import SelectRegion from '../../../RegionSelect';
import image from '../../../../assets/serial_help.jpg';
import alerta from '../../../../assets/alerta.svg';

interface InputState {
  type: 'error' | 'success' | 'info' | 'warning' | 'default';
  message: string;
}
interface OwnProps {
  type: string;
}
const CompanyInfo = ({ type }: OwnProps) => {
  const { dispatch, state, state: { form: { suscripcionStatus }, steps: { step22: { validations: validations_step22 }, step2: { professionalHasDebt, debtType, validations: { existing: { warning } } }, step2, step22, error }, } } = UseStep();
  const [showModal, setShowModal] = useState(false);
  const [showIncompatibleDegreeModal, setShowIncompatibleDegreeModal] = useState(false);
  const [nid, setNid] = useState<string>(step2.nid);
  const [email, setEmail] = useState<string>(step22.email);
  const [address, setAddress] = useState<string>(step22.address);
  const [address_detail, setAddressDetail] = useState<string>(step22.address_detail);
  const [existentNid, setExistentNid] = useState<boolean>(false);
  const [nidStatus, setNidStatus] = useState({ type: 'default', message: '' } as InputState);

  const [nid2Status, setNid2Status] = useState({ type: 'default', message: '' } as InputState);


  //const [predictedLocality, setPredictedLocality] = useState<string>();
  const [tempAddress, setTempAddress] = useState<GooglePredictionSelectItem>();
  //const [commune, setCommune] = useState<Locality>(step22?.commune?.id ? step22.commune : initialState.steps.step22.commune);
  //const [region, setRegion] = useState<Region>(step22?.region?.id ? step22.region : initialState.steps.step22.region);
  const [commune, setCommune] = useState<Locality>(initialState.steps.step22.commune);
  const [region, setRegion] = useState<Region>(initialState.steps.step22.region);
  const [nid2, setNid2] = useState<string>(step22.nid);
  const [serial, setSerial] = useState<string>(step22.serial);
  const [name, setName] = useState<string>(step22.name);
  const [tempName, setTempName] = useState<string>(step22.name);
  //const [regions, setRegions] = useState<Region[]>([]);
  const [social, setSocial] = useState<string>(step22.social);
  const [tempSocial, setTempSocial] = useState<string>(step22.social);
  const [showCustomerDebtsModal, setCustomerDebtsShowModal] = useState<boolean>(false);
  const [showCIForm, setShowCIForm] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const [defaultLocalty, setDefaultLocalty] = useState<any>(step22?.commune?.id ? step22.commune : null);
  const [defaultRegion, setDefaultRegion] = useState<any>(step22?.region?.id ? step22.region : null);

  const [showAlert, setShowAlert] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);

  const [contractDate, setContractDate] = useState<any>();
  const [status, setStatus] = useState<any>();

  //const [defaultLocalty, setDefaultLocalty] = useState<any>();
  //const [defaultRegion, setDefaultRegion] = useState<any>();

  const [localities, setLocalities] = useState<any>();
  const [addressValidationType, setAddressValidationType] = useState<string>('');
  const [addressValidationMessage, setAddressValidationMessage] = useState<string>('');
  const [initialAddress, setInitialAddress] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

  const [productFind, setProductFind] = useState<any>();
  const [productStatus, setProductStatus] = useState<any>();
  const [products, setProducts] = useState<any>();
  const [validInput, setValidInput] = useState<any>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [blockType, setBlockType] = useState(false);

  const navigate = useNavigate();

  const setPrediction = (prediction: GooglePredictionSelectItem) => {
    setTempAddress(prediction);
    processTempAddress(prediction);
  };

  const handleToggleStatusCustomerDebtsModal = () => navigate('/');

  const handleToggleStatusModal = () => {
    setShowModal(!showModal);
  };

  const handleRemoveBase64 = async (event) => {
    dispatch({ type: 'REMOVE_FILE', payload: { key: event.target.dataset.name, stepKey: 'step22' } });
  };

  useEffect(() => {
    if (state.steps.step22.region.id === '') {
      if (state.general.address && state.general.address.region) {
        setRegion(state.general.address.region);
      }
      if (state.general.address && state.general.address.commune) {
        //@ts-ignore
        setLocalities(state.general.address.region.cities.reduce<Locality[]>((a, { localities }) => [...a, ...localities], []));
        setDefaultLocalty(state.general.address.commune);
        setCommune(state.general.address.commune);
      }
    }
  }, [showAlert2, showAlert]);

  useEffect(() => {
    if (!commune) return;
    if (commune.id !== '') {
      dispatch({ type: 'STORE_BILLING_COMMUNE', payload: commune });
    }
  }, [commune]);

  useEffect(() => {
    if (!region) return;
    if (region.id !== '') {
      dispatch({ type: 'STORE_BILLING_REGION', payload: region });
    }
  }, [region]);

  useEffect(() => {
    if (state && state.general.address) {
      setBlockType(true);
    } else {
      setBlockType(false);
    }
  }, [step22, state]);

  const addSNIfNoNumber = (address) => {
    const numberPattern = /\d+/;
    const snPattern = /S\/N/i;

    if (address.trim() === '') {
      return address;
    }

    if (!numberPattern.test(address) && !snPattern.test(address)) {
      return `${address} S/N`;
    }

    return address;
  };

  const storeStateBillingInfo = async () => {
    const data = {
      nid: nid2,
      serial,
      address: addSNIfNoNumber(address),
      address_detail: address_detail ? address_detail.trim() : '',
      commune,
      region,
      email,
      name,
      social
    };
    dispatch({ type: 'STORE_BILLING_INFO', payload: { data, nid } });
    dispatch({ type: 'GET_INFO', payload: { data: { nombres: social } } });
  };

  const emailState = (): InputState => {
    const valid = validateEmail(email);
    if (email) {
      if (!valid) return { type: 'error', message: 'Formato inválido' };
      else return { type: 'success', message: 'Formato válido' };
    }

    return { type: 'default', message: '' };
  };


  useEffect(() => {

    const timeout = setTimeout(() => {
      if (nid2.length >= 9 && serial.length >= 8 && validFormat(nid2)) {
        getCheck(dispatch, { nid: nid2, serial }, 'step22');
      }
    }, 750);
    return () => clearTimeout(timeout);
  }, [nid2, serial]);


  const resetValidations = () => {
    //dispatch({ type: 'RESET_STEP', payload: { step: 'step2' } });
    dispatch({ type: 'CHECK_SERIAL_VALIDATION', payload: { key: '' }, step: 'step22' });
  };

  const setSerialValue = ({ target: { value } }) => {
    resetValidations();
    setSerial(value);
  };
  /*
  const serialState = (): InputState => {
    if (serial) {
      const { length } = serial;
      const { validations: { serial: { pending, error, success } } } = step22;

      if (length < 9) return { type: 'error', message: 'Largo mínimo de 9 caracteres' };

      if (success) return { type: 'success', message: 'Nº de Serie validado' };
      else if (error) return { type: 'error', message: 'Combinación serie + RUT no coincide' };
      else if (pending) return { type: 'info', message: 'Comprobando serie + RUT' };
    }

    return { type: 'default', message: '' };
  };*/

  const setClientNid = ({ target: { value } }: { target: { value: string; } }) => {
    const rut = formatRut(value);
    setNid(rut);
  };
  const setNid2Value = ({ target: { value } }: { target: { value: string; } }) => {
    const rut = formatRut(value);

    setNid2(rut);
  };
  const resetAddress = (step: number) => {
    switch (step) {
      case 1:
        setAddress('');
        setAddressDetail(address_detail || '');
        resetAddress(2);
        break;
      case 2:
        setRegion(initialState.steps.step22.region);
        resetAddress(3);
        break;
      case 3:
        setCommune(initialState.steps.step22.commune);
        break;
    }
  };

  const handleMenuToggleChange = (isOpen) => {
    setIsMenuOpen(isOpen);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (step22.address && step22.commune.name && step22.region.name) {
        const predicted = { name: step22.address, description: step22.address + ',' + step22.commune.name + ',' + step22.region.name, place_id: 'asd123ads' };
        const tempAddress = { ...predicted, id: 123 };
        setTempAddress(tempAddress);
        processTempAddress(predicted);
      } else if (step22.address && state.general.address) {
        const predicted = { name: step22.address, description: step22.address + ',' + state.general.address.commune.name + ',' + state.general.address.region.name, place_id: 'asd123ads' };
        const tempAddress = { ...predicted, id: 123 };
        setTempAddress(tempAddress);
        processTempAddress(predicted);
      }
    }, 2000);
    return () => clearTimeout(timeout);
  }, [initialAddress, step22.address]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (state.token && type !== 'BE') {
        getRegions().then(
          (regions) => {
            if (state.general.regions.length === 0) {
              // console.log('regions00', regions);
              dispatch({ type: 'SET_REGIONS', payload: { regions } });
            }
          }
        ).finally(() => {
          // console.log('SET REGIONS02');
          if (step22.address && step22.commune.name && step22.region.name) {
            setInitialAddress(true);
          }
        });
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (state.token && type === 'BE') {
        getRegions().then(
          (regions) => {
            if (state.general.regions.length === 0) {
              // console.log('regions00', regions);
              dispatch({ type: 'SET_REGIONS', payload: { regions } });
            }
          }
        ).finally(() => {
          // console.log('SET REGIONS02');
          if (step22.address && step22.commune.name && step22.region.name) {
            setInitialAddress(true);
          }
        });
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [state.token]);


  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     if(nid.length >= 9 && validFormat(nid)) getInfo(dispatch, nid);
  //   }, 750);
  //   return () => clearTimeout(timeout);
  // }, [nid]);

  useEffect(() => {
    if (validFormat(nid)) {
      setExistentNid(!step2.validations.existing.success);
    }
  }, [step2.validations.existing]);

  useEffect(() => {
    if (step22.validations.address.error && !state.general.address) {
      setAddressValidationType('warning');
      setAddressValidationMessage('Dirección ingresada no contiene número');
      setShowWarningModal(true);
    } else if (step22.validations.address.success && step22.address !== '') {
      setAddressValidationType('success');
      setAddressValidationMessage('Dirección válida');
    } else if (step22.validations.address.pending) {
      setAddressValidationType('pending');
      setAddressValidationMessage('Comprobando dirección');
    } else {
      setAddressValidationMessage('');
    }
  }, [step22.validations.address]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (nid.length > 0 && !validFormat(nid)) {
        setNidStatus({ type: 'error', message: 'RUT inválido' });
        dispatch({ type: 'VALIDATION_NID', payload: { key: 'error' }, step: 'step2' });

      } else if (nid.length > 0 && validFormat(nid)) {
        dispatch({ type: 'VALIDATION_NID', payload: { key: 'success' }, step: 'step2' });

        setNidStatus({ type: 'success', message: 'RUT Válido' });
        getProfessionalProductStatus({ dispatch, payload: { nid, product: type }, step: 'step2' });
      }
    }, 750);
    return () => clearTimeout(timeout);
  }, [nid]);


  useEffect(() => {
    if (nid) {
      const { validations: { existing: { pending, error, success } } } = step2;
      const validNid = validFormat(nid);
      if (!validNid) {
        dispatch({ type: 'VALIDATION_NID', payload: { key: 'error' }, step: 'step2' });
        setNidStatus({ type: 'error', message: 'RUT inválido' });
      }
      if (validNid) {
        if (success) {
          dispatch({ type: 'VALIDATION_NID', payload: { key: 'success' }, step: 'step2' });
          setNidStatus({ type: 'success', message: 'Rut Cliente Validado' });
        } else if (pending) {
          setNidStatus({ type: 'info', message: 'Buscando si usuario esta suscrito' });
        } else if (error) {
          dispatch({ type: 'VALIDATION_NID', payload: { key: 'error' }, step: 'step2' });
          setNidStatus({ type: 'error', message: 'Rut ya tiene este producto contratado' });
        }
      }
    }

  }, [step2.validations.existing]);

  useEffect(() => {
    const validId = validFormat(nid);
    if (validId) {
      getInfo(dispatch, nid, 'company', type);
      if (error && error.showModal && type !== 'BE') {
        setShowIncompatibleDegreeModal(true);
      }
    }
  }, [nid, error]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      /*if(nid2.length >= 9 && serial.length >= 9 && validFormat(nid2)) {
        checkSerial({ dispatch, payload: { nid: nid2, serial }, step: 'step22' });
      }*/
      const validNid = validFormat(nid2);
      if (nid2.length && validNid) {
        dispatch({ type: 'VALIDATION_NID', payload: { key: 'success' }, step: 'step22' });
        setNid2Status({ type: 'success', message: 'RUT Válido' });
      }
      else if (nid2.length && !validNid) {
        dispatch({ type: 'VALIDATION_NID', payload: { key: 'error' }, step: 'step22' });
        setNid2Status({ type: 'error', message: 'RUT Inválido' });
      }
    }, 750);
    return () => clearTimeout(timeout);
  }, [nid2, serial]);

  useEffect(() => {
    if (step2.validations.existing.success && validFormat(nid)) {
      checkDebt(dispatch, { nid, callback: setCustomerDebtsShowModal });
    }
  }, [step2.validations.existing.success]);

  useEffect(() => {
    if (showCIForm && !!step22.frontCode && !!step22.backCode) {
      storeStateBillingInfo();
    }
  }, [showCIForm, step22.frontCode, step22.backCode]);

  useEffect(() => {
    if (commune?.id && region?.id) {
      storeStateBillingInfo();
    }
  }, [commune, region, showAlert2]);


  useEffect(() => {
    setSocial(tempSocial);
    const timeout = setTimeout(() => {
      setSocial(tempSocial.trim());
      storeStateBillingInfo();
    }, 1000);

    return () => clearTimeout(timeout);
  }, [tempSocial]);

  //useEffect(() => {
  const processTempAddress = (prediction) => {
    resetAddress(1);
    if (!prediction) return;

    setLoader(true);

    const { name, description, place_id } = prediction;
    // console.log('prediction', prediction);
    const [address, locality, commune] = description.split(',').map(text => text.trim());
    // validar direccion
    // console.log('addres locality commune',address,locality, commune);
    getAddressValidation(dispatch, { address, placeId: place_id }, 'step22');

    if (!commune) {
      setTimeout(() => {
        setPredictedLocality(address.toLowerCase());
      }, 1000);

    } else {
      setTimeout(() => {
        setPredictedLocality(locality.toLowerCase());
      }, 1000);
    }

    setAddress(name);

  };
  //}, [tempAddress]);

  const setPredictedLocality = (predictedLocality) => {
    if (predictedLocality) {
      // console.log('predictedLocality1', predictedLocality);

      const matchingRegion = state.general.regions.find(({ cities }) =>
        cities.some(({ localities }) =>
          localities.some(({ name }) => name.toLowerCase() === predictedLocality)
        )
      );
      // console.log('matchingRegion2', matchingRegion, state.general.regions);

      if (matchingRegion) {

        setDefaultRegion(matchingRegion);
        setRegion(matchingRegion);
        const matchingCity = matchingRegion.cities.find(({ localities }) =>
          localities.some(({ name }) => name.toLowerCase() === predictedLocality)
        );
        // console.log('matchingCity3', matchingCity);

        if (matchingCity) {
          const matchingLocality = matchingCity.localities.find(
            ({ name }) => name.toLowerCase() === predictedLocality
          );
          setDefaultLocalty(matchingLocality);
          // console.log('matchingLocality4', matchingLocality);

          if (matchingLocality) {
            setCommune(matchingLocality);
          }
          setLocalities(matchingCity.localities);
          setLoader(false);
        } else {
          setLoader(false);
        }

      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };
  //, [predictedLocality]);

  useEffect(() => {
    setName(tempName);
    const timeout = setTimeout(() => {
      setName(tempName.trim());
    }, 1000);

    return () => clearTimeout(timeout);
  }, [tempName]);

  useEffect(() => {
    setShowCIForm(validFormat(nid) && validFormat(nid2) && validateEmail(email) && !!nid && !!email && !!address
      && !!region && !!commune && !!nid2 && !!serial && !!name && !!social && (validInput ? validInput : !existentNid));
    storeStateBillingInfo();

  }, [nid, email, address, region, commune, nid2, serial, name, social, existentNid]);

  function compareConditions(e) {
    const statusAllowed = new Set([1, 2, 3, 4, 5, 7, 10, 11, 20]);
    return e.plans?.products?.name === type && statusAllowed.has(e.status);
  }

  function compareStatus(e) {
    const statusAllowed = new Set([1, 2, 3, 4, 5, 6, 7, 10, 11, 20]);
    return statusAllowed.has(e.status);
  }

  useEffect(() => {
    if (!suscripcionStatus || suscripcionStatus.length < 1) return;

    const firstElementConditions = suscripcionStatus.find(compareConditions);
    const firstElementStatus = suscripcionStatus.find(compareStatus);

    setProductFind(firstElementConditions);
    setProductStatus(firstElementStatus);

  }, [suscripcionStatus]);

  useEffect(() => {
    if (!suscripcionStatus || suscripcionStatus.length < 1) return;

    if (warning) {
      const primerElementoCumplidor = suscripcionStatus.find(compareStatus);
      if (primerElementoCumplidor) {
        setShowAlert2(true);
      }
    }
  }, [suscripcionStatus]);

  useEffect(() => {
    if (productFind && productFind.contract_date) {
      const { contract_date } = productFind;
      const date = DateTime.fromISO(contract_date);
      const formatedDate = date.setLocale('es').toFormat('dd \'de\' LLLL \'de\' yyyy');
      setContractDate(formatedDate);
    }
  }, [productFind]);

  useEffect(() => {
    if (productStatus) {
      const { status } = productStatus;
      let message = '';
      switch (status) {
        case 0:
          message = 'abortado';
          break;
        case 1:
          message = 'activo';
          break;
        case 2:
          message = 'pendiente de suscripción';
          break;
        case 3:
          message = 'habilitado';
          break;
        case 4:
          message = 'suscrito';
          break;
        case 5:
          message = 'pendiente de habilitación';
          break;
        case 6:
          message = 'suspendido';
          break;
        case 7:
          message = 'pendiente de habilitación';
          break;
        case 8:
          message = 'pendiente de contratar';
          break;
        case 9:
          message = 'pendiente de firmar';
          break;
        case 10:
          message = 'en instalación';
          break;
        case 11:
          message = 'habilitado';
          break;
        case 12:
          message = 'pendiente de anulación';
          break;
        case 13:
          message = 'pendiente de anulación';
          break;
        case 14:
          message = 'OT devuelta a inicio';
          break;
        case 20:
          message = 'en espera de habilitación';
          break;
        default:
          break;
      }
      if (message !== '') setStatus(message);
    }

  }, [productStatus]);

  const setClear = () => {
    setAddress('');
    setAddressValidationType('warning');
    setAddressValidationMessage('Ingrese una dirección');
    dispatch({ type: 'CLEAR_GENERAL_ADDRESS' });
    dispatch({ type: 'CLEAR_ADDRESS' });
    // dispatch({ type: 'ADDRESS_VALIDATION', payload: { key: 'error' }, step: 'step22' });
  };

  useEffect(() => {
    if (productFind) {
      setShowAlert(true);
    }

  }, [productFind]);

  useEffect(() => {
    if (!suscripcionStatus || suscripcionStatus.length < 1) return;
    const planesContratados = suscripcionStatus.filter(function (elemento) {
      return elemento.plans !== null && elemento.plans !== undefined && elemento.plans.products !== null && elemento.plans.products !== undefined;
    });

    const nombresPlanesContratados = planesContratados.map(function (elemento) {
      return elemento.plans.products.description;
    });
    setProducts(nombresPlanesContratados);
  }, [suscripcionStatus]);

  useEffect(() => {
    if (nid) {
      const { validations: { existing: { pending, warning, error, success } } } = step2;
      const validNid = validFormat(nid);
      if (!validNid) setNidStatus({ type: 'error', message: 'RUT inválido' });
      if (validNid) {
        if (success) setNidStatus({ type: 'success', message: 'Rut Cliente Validado' }), setExistentNid(false);
        else if (pending) setNidStatus({ type: 'info', message: 'Buscando si usuario esta suscrito' });
        else if (warning) {
          setNidStatus({ type: 'success', message: 'Rut Cliente Validado' });
          setValidInput(true);
        }
        else if (error) {
          setNidStatus({ type: 'error', message: 'Rut ya tiene este producto contratado' });
          setShowAlert(true);
        }
      }
    }

  }, [step2.validations.existing]);

  return (
    <div className={styles.company_info}>
      <div className={styles.company_info_container}>
        <div className={styles.billing_info_container}>
          <div className={styles.rut}>
            <Input
              customSize="m"
              name="name"
              id="rut"
              value={nid}
              onChange={setClientNid}
              placeholder="Ingresa rut"
              title="Rut cliente"
              type="text"
              stateMessage={nid && nidStatus.message}
              stateType={nid ? nidStatus.type : 'default'}
              disabled={step22.validations.existing.pending || step22.validations.existing.success}
              autoComplete="off"
            />
          </div>
          <div className={styles.social}>
            <Input
              customSize="l"
              name="social"
              id="social"
              value={social}
              onChange={({ target: { value } }) => setTempSocial(value)}
              placeholder="Ingresa razón social"
              title="Razón social"
              type="text"
              autoComplete="off"
            />
          </div>
          <div className={styles.mail}>
            <Input
              customSize="m"
              name="email"
              id="email"
              value={email}
              onChange={({ target: { value } }) => setEmail(value.trim())}
              placeholder="Ingresa email"
              title="Correo electrónico"
              type="text"
              stateMessage={emailState().message}
              stateType={emailState().type}
              autoComplete="off"
            />
          </div>
          <div className={styles.address}>
            <Typeahead label="Dirección de facturación" setClear={setClear} setPrediction={setPrediction} defaultPrediction={step22?.address && step22?.commune?.id ? { name: step22.address, description: step22.address + ',' + step22.commune.name + ',' + step22.region.name, place_id: 'asd123ads' } : null} onMenuToggleChange={handleMenuToggleChange} isOpen={blockType} hasNid={(nid.length > 0) ? true : false} />
            <div className={`${styles[addressValidationType]} flex items-center mt-2`} >
              {/* if no nid */}
              {
                !nid ? <>
                  {!address && <>
                    <InfoIcon customWidth="20" pathClass="stroke-warning" svgClass='mr-1' />
                    <small>Por favor, ingresa un <b>Rut cliente</b> antes de ingresar la dirección</small>
                  </>
                  }
                </> : <>
                  {
                    (addressValidationType === 'warning') && <InfoIcon customWidth="20" pathClass="stroke-warning" svgClass='mr-1' />
                  }
                  <span>{
                    addressValidationMessage
                  }</span>
                </>
              }
            </div>
          </div>
          <div className={styles.address_detail}>
            <Input
              customSize="m"
              name="address_detail"
              value={address_detail}
              onBlur={({ target: { value } }) => {
                setAddressDetail(value);
                setTimeout(() => {
                  storeStateBillingInfo();
                }, 1000);
              }}
              onChange={({ target: { value } }) => {
                setAddressDetail(value);
                setTimeout(() => {
                  storeStateBillingInfo();
                }, 1000);
              }}
              placeholder="Ingresa Block/Departamento "
              title="Block,Departamento (Opcional)"
              type="text"
              autoComplete="off"
            />
          </div>
          {
            loader ? <Skeleton
              borderColor="default"
              customClass=""
              customSize="m"
            /> : (tempAddress && <SelectRegion setLocality={setCommune} setRegion={setRegion} address={tempAddress} customClass="w-full" />)
          }

        </div>
        <h1 className={`col-span-4 ${styles.subtitle}`}>Representante Legal</h1>
        <div className={styles.client_info}>
          <div className={styles.rut}>
            <Input
              customSize="m"
              name="nid"
              value={nid2}
              onChange={setNid2Value}
              placeholder="Ingresa rut representante"
              title="Rut representante legal"
              type="text"
              stateMessage={nid2 && nid2Status.message}
              stateType={nid2 ? nid2Status.type : 'default'}
              id="legal"
              autoComplete="off"
            />
          </div>
          <div className={styles.serial}>
            <div>
              <Input
                customSize="m"
                name="serial"
                id="serial"
                value={serial}
                //onChange={({ target: { value } }) => setSerial(value)}
                onChange={setSerialValue}
                placeholder="Ingresa n° de documento"
                title="Nº de documento Representante legal"
                type="text"
                autoComplete="off"
                //stateMessage={ serialState().message }
                //stateType={ serialState().type }
                stateMessage={step22.validations.serial.error ? 'N° Documento inválido' : (step22.validations.serial.pending ? 'Validando Documento' : (step22.validations.serial.success ? 'N° Documento Válido ' : ''))}
                stateType={step22.validations.serial.error ? 'error' : (step22.validations.serial.success ? 'success' : 'default')}
                disabled={(step22.validations.serial.pending || step22.validations.debt.pending || step22.validations.info.pending) ||
                  step22.validations.serial.success && step22.validations.info.success || nidStatus.type === 'error'}
              />
            </div>
            <div className={'self-center'}>
              <TooltipGlobal customClass={`${styles.tooltip_serial} flex items-center`}
                content={<p><img src={image} /></p>}
                delay={0}
                direction="top"
                visible
              >
                <InfoIcon svgClass={styles.icon_color} />
              </TooltipGlobal>
            </div>
          </div>
          <div className={styles.name}>
            <Input
              name="name"
              id="name"
              value={name}
              onChange={({ target: { value } }) => setTempName(value)}
              placeholder="Ingresa nombre"
              title="Nombre representante legal"
              type="text"
              autoComplete="off"
            />
          </div>
        </div>
        {(!step22.frontCode || !step22.backCode) && step22.validations.serial.success && showCIForm &&
          <div className={styles.attach_ci}>
            <p>
              Adjunte las imágenes del <span>RUT del Representante Legal por ambos lados:</span>
            </p>
            <Button buttonType='button' type="secondary" onClick={handleToggleStatusModal}>
              Adjuntar CI
            </Button>
          </div>
        }
        {
          !!step22.frontCode && !!step22.backCode &&
          <PreviewAttach backCode={step22.backCode} frontCode={step22.frontCode} handleRemoveBase64={handleRemoveBase64} />
        }
        <Modal title={'Adjuntar CI'} showModal={showModal} onToggleStatus={handleToggleStatusModal}>
          <AttachCI stepKey={'step22'} setShowModal={setShowModal} />
        </Modal>
        <Modal title={''} showModal={showCustomerDebtsModal} onToggleStatus={handleToggleStatusCustomerDebtsModal} showCloseButton={false}>
          <CustomerDebts debtType={debtType} onToggleStatus={handleToggleStatusCustomerDebtsModal} />
        </Modal>
        <Modal title={'Persona natural'} showModal={showIncompatibleDegreeModal} showCloseButton={false}>
          <IncompatibleDegree text="El rut ingresado corresponde a Persona Natural" />
        </Modal>
        <Modal title={'Dirección sin numeración'} showModal={showWarningModal} setShowModal={setShowWarningModal} parentClass={isMenuOpen ? styles.z1005 : ''}>
          <WarningModal />
          <div className="w-full flex justify-end">
            <Button type='secondary' buttonType='button' onClick={() => setShowWarningModal(false)}>Cerrar</Button>
          </div>
        </Modal>
        <Modal title={''} showModal={showAlert} showCloseButton={false} containerClass={styles.modalWidth}>
          <div className="flex flex-col text-center items-center justify-center py-6 px-10">
            <img className={styles.img} src={alerta} />
            <p className={`${styles.warningTitle} mt-5`}>
              Usted ya contrató este producto, {contractDate && ` el ${contractDate}, `}
              <span className={styles.highlight}>y está {status ?? 'aún vigente'}</span>.
            </p>
            <p className={`${styles.warning} mt-5`}>
              No es necesario contratar nuevamente
            </p>
            <p className={`${styles.warning} my-5`}>
              Para consultas acerca de su Suscripción,
              comuníquese a <a href="comercial@imed.cl" target='_blank'><strong>comercial@imed.cl</strong></a>
            </p>
            <Button buttonType='button' type="secondary" onClick={() => { setShowAlert(false); window.location.reload(); }}>
              Ok, entendido
            </Button>
          </div>
        </Modal>
        <Modal title={''} showModal={showAlert2} showCloseButton={false} containerClass={styles.modalWidth}>
          <div className="flex flex-col text-center items-center justify-center py-6 px-10">
            <img className={styles.img} src={alerta} />
            <p className={`${styles.warningTitle} mt-5`}>
              Usted contrató previamente un producto Imed {products && `: ${products}`}, {contractDate && ` el ${contractDate}, `}
              <span className={styles.highlight}>y está {status ?? 'aún vigente'}</span>.
            </p>
            {
              (productStatus && productStatus.status === 6) ? <p className={`${styles.warning} mt-5`}>Para continuar con el proceso de contratación, le invitamos a regularizar su situación.</p> : <p className={`${styles.warning} mt-5`}>
                Si desea continuar con el proceso de suscripción, presione el botón a continuación.
              </p>
            }

            <p className={`${styles.warning} my-5`}>
              Para consultas acerca de su Suscripción,
              comuníquese a <a href="comercial@imed.cl" target='_blank'><strong>comercial@imed.cl</strong></a>
            </p>
            {
              (productStatus && productStatus.status === 6) ? <Button buttonType='button' type="secondary" onClick={() => { setShowAlert2(false); window.location.reload(); }}>
                Ok, entendido
              </Button> : <Button buttonType='button' type="secondary" onClick={() => setShowAlert2(false)}>
                Ok, deseo contratar de todas formas
              </Button>
            }
          </div>
        </Modal>
      </div>
    </div>
  );
};


export default CompanyInfo;
