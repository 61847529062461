import React from 'react';
import styles from './style.module.scss';
import { Button } from '@imed_npm/design-system';

interface OwnProps {
  actionPrevious?: () => void
  actionNext?: () => void
  backDisable?: boolean
  nextDisable?: boolean
  backText: string
  nextText: string
}


const Footer = ({ backText, nextText, backDisable = false, nextDisable = false, actionNext, actionPrevious }: OwnProps) => {

  const handleActionNext = () => {
    actionNext && actionNext();
  };

  const handleActionPrevious = () => {
    actionPrevious && actionPrevious();
  };

  return (
    <div className={styles.footer} >
      <Button buttonType='button' type="secondary-two" disabled={backDisable} onClick={handleActionPrevious} className={styles.button}>
        {backText}
      </Button>
      <Button buttonType='button' type="secondary" disabled={nextDisable} onClick={handleActionNext} className={styles.button}>
        {nextText}
      </Button>
    </div>
  );
};


export default Footer;
