import { createContext } from 'react';
import { AppContextInterface, initialUserData } from './loggedUsercontext.interface';
import Keycloak from 'keycloak-js';


const LoggedUserContext = createContext<AppContextInterface>({
  userData: initialUserData,
  setUserData: async () => null,
  token: '',
  keycloak: new Keycloak(),
  logout: async () => null,
  editor:{
    rows: [],
    data: [],
    idx: 0
  }
});

export default LoggedUserContext;
