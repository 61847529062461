import React, { useEffect } from 'react';
import styles from './style.module.scss';
import icon from './assets/icon.svg';
import LoadingComponent from '../../../LoadingComponent/LoadingComponent';

interface OwnProps {
  text?: string;
}

const IncompatibleDegree = ({ text }: OwnProps) => {

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = '/';
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.customer_debts}>
      <img src={icon} alt={'imagen'} />
      <h2 className={styles.title}>
        {text || 'El RUT ingresado no tiene un Título compatible con la suscripción de Licencia Médica electrónica.'}
      </h2>
      <p className={styles.content}>
        lo redireccionaremos a la pagina principal en unos segundos.
      </p>
      <div className={styles.container_button}>
        <LoadingComponent />
      </div>
    </div>
  );
};


export default IncompatibleDegree;
