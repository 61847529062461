import React, { useState, useEffect } from 'react';
import { Button } from '@imed_npm/design-system';
import Attach from './Components/Attach';
import { UseStep } from '../../../../Store/step-context';
// import { storeCI } from '../../../../Store/actions';

interface OwnProps {
  stepKey: string;
  setShowModal: any;
}

const AttachCI = ({ stepKey, setShowModal }: OwnProps) => {
  const [showButton, setShowButton] = useState(false);
  const { state: {
    steps: { step2, step22 }
  } } = UseStep();

  useEffect(() => {
    if ((stepKey == 'step2' && step2.frontCode && step2.backCode) || (stepKey == 'step22' && step22.frontCode && step22.backCode)) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [step2]);

  return (
    <>
      <Attach
        inputName={'frontCode'}
        urlImage={(stepKey == 'step2' ? step2 : step22).frontCode}
        showPreview={!!(stepKey == 'step2' ? step2 : step22).frontCode.length}
        stepKey={stepKey}
        label={'CI Frontal'} />
      <Attach
        inputName={'backCode'}
        urlImage={(stepKey == 'step2' ? step2 : step22).backCode}
        showPreview={!!(stepKey == 'step2' ? step2 : step22).backCode.length}
        stepKey={stepKey}
        label={'CI Trasera'} />
      {
        showButton && (
          <div className="flex justify-end">
            <Button buttonType='button' type="secondary" onClick={() => { setShowModal(false); }}>
              Adjuntar
            </Button>
          </div>
        )
      }
    </>
  );
};


export default AttachCI;
