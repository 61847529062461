/* eslint react/prop-types: 0 */
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Stepper, DataTable2, Skeleton, Select, Input, InfoIcon, Badge, CheckIcon, XIcon, LoadingImed, Button } from '@imed_npm/design-system';
// import dummy from './dummy/data.json';
import { formatRut, validFormat, clearRut } from '../../utils/rut';
import LoggedUserContext from '../../context/LoggedUserContext';
import Modal from '../Modal';
import LegalRepresentativeForm from '../LegalRepresentativeForm';
import { Professionals } from './interfaces/professional';
import { getPeriodicity, getProducts, getProfessionals, getProfessionalsNew, getStatuses, getAdminPlans } from './utils/actions';
import { GetProfessionalsOptions, Product, GetPlansOptions } from './utils/interfaces';
import { formatJsDate } from './utils/utils';
import ServiceActivation from './Components/ServiceActivation';
import { Periodicity, Status } from './interfaces/status';
import StatusHistory from './Components/StatusHistory';
import ChangeStatus from './Components/ChangeStatus';
import Info from './Components/Info';
import getEnv from '../../getEnv';
import styles from './style.module.scss';
import ShowCI from './Components/ShowCI';
import SendProspect from './Components/SendProspect';
import { EProfessionalStatus, EServices } from '../../enum/professional.enum';
import SendCvp from './Components/SendCvp';
import ShowCvp from './Components/ShowCvp';
import AdminPlans from './Components/Plans';
import SubscriptionAdmin from '../External/SubscriptionAdmin';
import ChangePlan from './Components/ChangePlan';

const APP_ID = getEnv('REACT_APP_ID');

interface OwnProps {
  type: string;
}

function Admin({ type }: OwnProps): JSX.Element {
  const navigate = useNavigate();
  const context = useContext(LoggedUserContext);
  const [data, setData] = useState<Professionals[]>();
  const [selectedProfessional, setSelectedProfessional] = useState<Professionals>();
  const [showServiceActivationModal, setShowServiceActivationModal] = useState<boolean>();
  const [showServiceActivationModal2, setShowServiceActivationModal2] = useState<boolean>();
  const [showStatusHistoryModal, setShowStatusHistoryModal] = useState<boolean>();
  const [showChangeStatusModal, setShowChangeStatusModal] = useState<boolean>();
  const [showSendProspectModal, setShowSendProspectModal] = useState<boolean>();
  const [showSendCvpModal, setShowSendCvpModal] = useState<boolean>();
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showEditPlan, setShowEditPlan] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>();
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [selectedPlanProduct, setSelectedPlanProduct] = useState<Product>();
  const [statusList, setStatusList] = useState<Status[]>();
  const [periodicityList, setPeriodicityList] = useState<Periodicity[]>();

  const [statusListChangeStatus, setStatusListChangeStatus] = useState<Status[]>();
  const [selectedStatus, setSelectedStatus] = useState<Status>();
  const [showFilters, setShowFilters] = useState(false);
  const [filterRut, setFilterRut] = useState<string | undefined>(undefined);
  const [filterCode, setFilterCode] = useState<string>();
  const [filterName, setFilterName] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [showCIModal, setShowCIModal] = useState(false);
  const [showCVPModal, setShowCVPModal] = useState(false);
  const [showSuscriptionEditorModal, setShowSuscriptionEditorModal] = useState(false);

  const [disabledFilter, setDisabledFilter] = useState<boolean>(true);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [showConfigTab, setShowConfigTab] = useState<boolean>(false);
  // for change plan select on action modal 
  const [dataPlan, setDataPlans] = useState<GetPlansOptions>();
  const [refresh, setRefresh] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const handleStepChange = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    const timeout = setTimeout(async () => {
      const [initProducts, initStatusList, initStatusListChange, initPeriodicityList] = await Promise.all(
        [
          getProducts(context),
          getStatuses(context, 'adscriptions'),
          getStatuses(context, 'adscriptions', true),
          getPeriodicity(context)
        ]);
      setProducts(initProducts);
      setStatusList(initStatusList);
      setStatusListChangeStatus(initStatusListChange);
      setPeriodicityList(initPeriodicityList);
      setShowLoading(false);
    }, 250);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!selectedProduct) return setShowFilters(false);

    getData();
    setShowFilters(true);
    setFilterCode(undefined);
    setFilterName(undefined);
    setFilterRut(undefined);
  }, [selectedProduct]);

  useEffect(() => {
    if (!products) return;
    context.setUserData(prevUserData => ({
      ...prevUserData,
      products
    }));
  }, [products]);

  useEffect(() => {
    const timeout = setTimeout(getData, 250);

    return () => clearTimeout(timeout);
  }, [filterCode, filterName, filterRut, selectedStatus]);

  useEffect(() => {
    if (refresh) {
      getData();
    }
  }, [refresh]);

  const getData = () => {
    if (!selectedProduct) return;
    setShowLoading(true);

    const data: GetProfessionalsOptions = {
      product: selectedProduct.name
    };

    if (filterCode) data.code = filterCode;
    if (filterName) data.name = filterName;
    if (filterRut) data.nid = filterRut;
    if (selectedStatus) data.status = selectedStatus.value;

    getProfessionalsNew(context, data).then(setData).finally(() => { setShowLoading(false); setRefresh(false); });
  };

  const getSelectItems = (row) => {
    const professional: Professionals = row.original;

    let activeInstitution, dataInstitution, resources, ActionChangeStatus, ActionEnable;
    if (getEnv('REACT_APP_IS_KEYCLOAK_DISABLED') === 'true') {
      activeInstitution = 1;// context.userData.activeInstitution.id;
      dataInstitution = {};//context.userData.institutions.find(o => o?.id === activeInstitution);
      resources = {};//(dataInstitution.branchOffices[0].apps.find(o => o?.id === APP_ID)).resources;
      ActionChangeStatus = true;//resources.find(o => o.resource_name === 'CRUD-Statuses') ? true : false;
      ActionEnable = true;//resources.find(o => o.resource_name === 'Accion-Habilitar') ? true : false;
    } else {
      activeInstitution = context.userData.activeInstitution?.id;
      dataInstitution = context.userData.institutions.find(o => o?.id === activeInstitution);
      resources = (dataInstitution.branchOffices[0].apps.find(o => o?.id === APP_ID)).resources;
      //console.log('resources', resources);
      ActionChangeStatus = resources.find(o => o.resource_name === 'CRUD-Statuses') ? true : false;
      ActionEnable = resources.find(o => o.resource_name === 'Accion-Habilitar') ? true : false;
    }

    return [
      {
        id: 1,
        name: 'Historial de cambios',
        show: true // agregar la cosa del recurso que permite hacer esto
      },
      {
        id: 2,
        name: 'Cambiar estado',
        show: ActionChangeStatus && statusListChangeStatus && statusListChangeStatus.length && (statusListChangeStatus.map((st) => { return st.value; })).includes(professional.adscription_status)
      },
      {
        id: 4,
        name: 'Habilitar prestador',
        show: [10].includes(professional.adscription_status) && ActionEnable && (selectedProduct && selectedProduct.name.toLowerCase() !== 'be') // agregar la cosa del recurso que permite hacer esto
      },
      // {
      //   id: 5,
      //   name: 'Ver CI',
      //   show: true // agregar la cosa del recurso que permite hacer esto
      // },
      {
        id: 6,
        name: 'Volver a enviar Prospecto',
        show: (professional.adscription_status === EProfessionalStatus.SUSCRITO) // agregar la cosa del recurso que permite hacer esto
      },
      // {
      //   id: 7,
      //   name: 'Ingresar CVP',
      //   show: [10, 11, 3].includes(professional.adscription_status) && (professional.professional_services.find(o => o && Number(o.service_id) === EServices.BONO) || professional.legal_services.find(o => o && Number(o.service_id) === EServices.BONO)) && ActionEnable && selectedProduct && selectedProduct.name.toLowerCase() === 'em' && !professional.adscription_cvp_code && professional.plans.code === 'PC71'
      // },
      // {
      //   id: 8,
      //   name: 'Ver CVP',
      //   show: professional.adscription_cvp_file ? true : false
      // },
      {
        id: 9,
        name: 'Editar Suscripción',
        //TODO: PONER LAS CONDICIONES BAJO LAS CUALES EL MODAL DEBERIA APARECER
        show: professional.adscription_id ? ((professional.adscription_status === EProfessionalStatus.ENABLED || professional.adscription_status === EProfessionalStatus.SERVICES_ENABLED) && true) : false
      },
      {
        id: 10,
        name: 'Ver información',
        show: true
      },
      {
        id: 11,
        name: 'Editar plan',
        show: [3].includes(professional.adscription_status)
        // show: ((professional.adscription_status === EProfessionalStatus.ENABLED || professional.adscription_status === EProfessionalStatus.SERVICES_ENABLED) && true)
      },
      {
        id: 12,
        name: 'Crear convenio Bono',
        show: (
          (
            selectedProduct &&
            selectedProduct.name.toLowerCase() === 'em' &&
            professional.plans.code === 'PC71' &&
            [3, 10, 11].includes(professional.adscription_status)
            // professional.adscription_status === 11
          ) ||
          (
            selectedProduct &&
            selectedProduct.name.toLowerCase() === 'be' &&
            [10].includes(professional.adscription_status)
          )
        ) && ActionEnable
      }

      //{
      //  id: 3,
      //  name: 'Editar datos del contrato',
      //  show: [3, 7, 8].includes(professional.status) && ActionEditClient
      //}
    ].filter(({ show }) => show);
    //solamente para los en estado suscrito, prospecto o pendiente de contrato
    //que tengan el recurso Accion-EditarDatosCliente
  };

  useEffect(() => {
    if (modalData) {
      setShowModal(true);
    }
  }, [modalData]);

  useEffect(() => {
    if (showSendCvpModal === true) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [showSendCvpModal]);

  useEffect(() => {
    if (!showServiceActivationModal2 || !showSendCvpModal) setCurrentStep(1);
  }, [showServiceActivationModal2, showSendCvpModal]);

  useEffect(() => {
    if (!context) return;
    const activeInstitution = context.userData.activeInstitution.id;
    const dataInstitution = context.userData.institutions.find(o => o.id === activeInstitution);
    if (dataInstitution) {
      const findAppInstitution = dataInstitution.branchOffices[0].apps.find(o => o?.id === APP_ID);
      if (findAppInstitution) {
        const { resources } = findAppInstitution;
        const showConfigure = resources.find(o => o.resource_id === '39') ? true : false;
        setShowConfigTab(showConfigure);
      }
    }
  }, [context]);

  const clearFilter = () => {
    setShowFilters(false);
    setShowLoading(true);

    setSelectedStatus(undefined);
    setFilterCode('');
    setFilterName('');
    setFilterRut('');
    setShowLoading(false);
  };

  useEffect(() => {
    if (selectedStatus || filterRut || filterName || filterCode) {
      setDisabledFilter(false);
    } else {
      setDisabledFilter(true);
    }
  }, [selectedStatus, filterRut, filterName, filterCode]);

  useEffect(() => {
    if (!showLoading && filterCode === '') setShowFilters(true);
  }, [showLoading]);

  useEffect(() => {
    if (!selectedProduct) return;
    const data: GetPlansOptions = {
      product: selectedProduct.name
    };
    // @ts-ignore
    getAdminPlans(context, data).then(setDataPlans);

  }, [selectedProduct]);

  const handleActions = (action, value) => {
    setShowModal(false);
    setSelectedProfessional(value.original);

    switch (action.id) {
      case 1:
        setShowStatusHistoryModal(true);
        break;
      case 2:
        setShowChangeStatusModal(true);
        break;
      case 3:
        setModalData(value);
        break;
      case 4:
        setShowServiceActivationModal(true);
        break;
      case 5:
        setShowCIModal(true);
        break;
      case 6:
        setShowSendProspectModal(true);
        break;
      case 7:
        setShowSendCvpModal(true);
        break;
      case 8:
        setShowCVPModal(true);
        break;
      case 9:
        // setShowSuscriptionEditorModal(true);
        navigate(`/backoffice/adscriptions/${selectedProduct?.name}/${value.original.adscription_id}`);
        break;
      case 10:
        setShowInfo(true);
        break;
      case 11:
        setShowEditPlan(true);
        break;
      case 12:
        setShowServiceActivationModal2(true);
        break;
      default:
        break;
    }
  };

  const triggerSearch = (select) => {
    // setSearch(select.description);
    setSelectedProduct(select);
  };

  const triggerPlanSearch = (select) => {
    // setSearch(select.description);
    setSelectedPlanProduct(select);
  };

  const updateTable = (value) => {
    clearFilter();
    setFilterRut(clearRut(value));
    setRefresh(true);
  };

  const getLabel = (e) => {
    switch (e) {
      case 0:
        return 'green';
      case 1:
        return 'red';
      case 2:
        return 'pink';
      case 3:
        return 'green';
      case 4:
        return 'green';
      case 5:
        return 'blue';
      case 6:
        return 'red';
      case 7:
        return 'purple';
      case 8:
        return 'pink';
      case 9:
        return 'purple';
      case 10:
        return 'purple';
      case 11:
        return 'blue';
      default:
        return 'green';
    }
  };

  const ActionCellRender = ({ row }: any) => {
    return (<Select
      customClass={styles.z0}
      customSize="s"
      items={getSelectItems(row)}
      name="selectName"
      nameType="name"
      onChange={(e) => handleActions(e, row)}
      placeholder="Seleccione"
    />);
  };

  const columns = [
    {
      Cell: ({ value }: any) => {
        return <div className='has-tooltip w-3/4 m-auto cursor-help'>
          <span className='tooltip rounded shadow-lg p-1 bg-black text-white -mt-8'>{value && value.length ? 'Cupón aplicado' : 'Sin descuento'}</span>
          <div className={(value && value.length) ? styles.bgSuccess : styles.bgWarning}></div>
        </div>;
      },
      Header: 'Dscto',
      accessor: 'discounts',
      filter: 'exact',
      skeleton: <Skeleton customClass="m-auto mt-1 mb-1" />,
    },
    {
      Cell: ({ value }) => value ? (value === 1 ? 'Natural' : 'Jurídica') : '-',
      Header: 'Persona',
      HeaderWidth: 'w-[31%]',
      accessor: 'adscription_person_type',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }: any) => {
        const list: any = statusList;
        return <Badge
          colorStyle={getLabel(value)}
          label={(
            list.find(({ value: v2 }) => value == v2)?.name || '-'
          )}
        />;
      },
      Header: 'Estado',
      accessor: 'adscription_status',
      filter: 'exact',
      skeleton: <Skeleton customClass="w-[50%] h-4 pl-4 m-auto mt-1 mb-1" />,
      sortDescFirst: false,
    },
    {
      Cell: ({ value }: any) => {
        return <div className='has-tooltip w-3/4 mx-auto text-left'>
          <span className='tooltip rounded shadow-lg p-1 bg-black text-white -mt-8'>{value ? value.trim() : 'Sin nombre'}</span>
          <p className='truncate cursor-help'>{value ? value.trim() : 'Sin nombre'}</p>
        </div>
          ;
      },
      Header: 'Nombre',
      HeaderWidth: 'w-[230px]',
      accessor: 'name',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value || 'Sin rut',
      Header: 'RUT',
      HeaderWidth: 'w-[31%]',
      accessor: 'nid',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value && value.length ? value[0].code : '-',
      Header: 'Código de lugar',
      HeaderWidth: 'w-[31%]',
      accessor: 'centers',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value ? value.name : '-',
      Header: 'Plan',
      HeaderWidth: 'w-[31%]',
      accessor: 'plans',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ({ value }) => value ? formatJsDate(value) : '-',
      Header: 'Fecha de estado',
      HeaderWidth: 'w-[31%]',
      accessor: 'adscription_status_changed_at',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    },
    {
      Cell: ActionCellRender,
      Header: 'Acciones',
      skeleton: <Skeleton customClass="w-[70%] h-4 pl-4 m-auto mt-1 mb-1" />
    }
  ];

  const renderCheckingInfo = () => {
    return (
      <>
        {(showLoading) && (<LoadingImed text="Cargando información" />)}
      </>
    );
  };

  const getSteps = () => {
    const steps = [
      {
        name: 'Ver PDF',
        number: 1,
        typeStyle: currentStep === 0 ? 'current' : 'previous',
      },
      {
        name: 'Ingresar CVP',
        number: 2,
        typeStyle: currentStep === 1 ? 'current' : currentStep > 1 ? 'previous' : '',
      }, {
        name: 'Habilitar servicios',
        number: 3,
        typeStyle: currentStep === 2 ? 'current' : currentStep > 2 ? 'previous' : '',
      }
    ];

    return steps;
  };


  const modalsToShow = () => {
    return (
      <>
        {showModal && modalData &&
          <Modal title={'Editar los datos del contrato para la firma del cliente'} modalClass={styles.modalWidth} containerClass='my-6' showModal={showModal} showCloseButton={false}>
            <LegalRepresentativeForm representativeProfessional={modalData} setShowModal={setShowModal} />
          </Modal>}
        {showServiceActivationModal &&
          <Modal title={'Activación de servicios'} modalClass={styles.modalWidth} containerClass='my-6' showModal={showServiceActivationModal} setShowModal={setShowServiceActivationModal}>
            {selectedProfessional &&
              <>
                <div className={`${styles.professional_info} mt-4 mb-10`}>
                  <p>
                    <span>Prestador:</span> {selectedProfessional.nid}
                  </p>
                  <p>
                    <span>Código de lugar:</span> {selectedProfessional.centers[0].code}
                  </p>
                </div>
                <ServiceActivation professional={selectedProfessional} selectedProduct={selectedProduct} setShowModal={setShowServiceActivationModal} setRefresh={setRefresh} clearFilter={updateTable} caseNumber={1} />
              </>
            }
          </Modal>}
        {/* Nuevo modal */}
        {(showServiceActivationModal2) && selectedProfessional &&
          <Modal title={'Crear convenio Bono'} modalClass={styles.modalWidth} containerClass='my-6' showModal={showServiceActivationModal2 ?? showSendCvpModal} setShowModal={showServiceActivationModal2 ? setShowServiceActivationModal2 : setShowSendCvpModal}>
            {/* <Modal title={'Crear convenio Bono'} modalClass={styles.modalWidth} containerClass='my-6' showModal={showServiceActivationModal} setShowModal={setShowServiceActivationModal}> */}
            {
              selectedProfessional && <div className={`${styles.professional_info} mt-4 mb-10`}>
                <p>
                  <span>Prestador:</span> {selectedProfessional.nid}
                </p>
                <p>
                  <span>Código de lugar:</span> {selectedProfessional.centers[0].code}
                </p>
              </div>
            }

            <Stepper
              showProgressBar
              // currentStep={getStep(selectedProfessional.adscription_status)}
              currentStep={currentStep}
              steps={getSteps()}
              typeStyle="header"
            />
            <div className='p-5 border-2 border-gray-200'>
              {
                ((currentStep === 1) && selectedProfessional) && <ShowCvp setShowChangeStatusModal={setShowChangeStatusModal} professional={selectedProfessional} nextStep={nextStep} />
              }

              {
                (currentStep === 2) &&
                selectedProfessional &&
                statusList &&
                periodicityList &&
                periodicityList.length > 0 &&
                (
                  <SendCvp
                    professional={selectedProfessional}
                    periodicityList={periodicityList}
                    setShowModal={setShowSendCvpModal}
                    nextStep={nextStep}
                  />
                )
              }
              {
                ((currentStep === 3) && selectedProfessional) && <ServiceActivation professional={selectedProfessional} selectedProduct={selectedProduct} setShowModal={setShowServiceActivationModal} nextStep={nextStep} setRefresh={setRefresh} clearFilter={updateTable} caseNumber={2} />
              }
            </div>
          </Modal>}
        {showStatusHistoryModal &&
          <Modal title={'Historial de cambios'} modalClass={styles.modalWidth} containerClass='my-6' showModal={showStatusHistoryModal} setShowModal={setShowStatusHistoryModal}>
            {selectedProfessional && statusList && <StatusHistory professional={selectedProfessional} statusList={statusList} />}
          </Modal>}
        {showChangeStatusModal &&
          <Modal title={'Cambio de estado'} modalClass={styles.modalWidth} containerClass='my-6' showModal={showChangeStatusModal} setShowModal={setShowChangeStatusModal}  >
            {selectedProfessional && statusListChangeStatus && statusList && <ChangeStatus setShowChangeStatusModal={setShowChangeStatusModal} professional={selectedProfessional} statusList={statusList} statusListAllowed={statusListChangeStatus} setRefresh={setRefresh} />}
          </Modal>}
        {showCVPModal &&
          <Modal title={'Archivo CVP'} showModal={showCVPModal} setShowModal={setShowCVPModal} modalClass='h-screen' containerClass='w-4/5'>
            {selectedProfessional && <ShowCvp setShowChangeStatusModal={setShowChangeStatusModal} professional={selectedProfessional} />}
          </Modal>}
        {showSuscriptionEditorModal &&
          <Modal title={'Administrador profesionales'} showModal={showSuscriptionEditorModal} setShowModal={setShowSuscriptionEditorModal} modalClass='h-screen' containerClass='w-4/5'>
            {selectedProfessional && <SubscriptionAdmin setShowSubscriptionEditorModal={setShowSuscriptionEditorModal} adscriptionId={selectedProfessional.adscription_id} />}
          </Modal>}
        {showCIModal &&
          <Modal title={'Imágenes CI'} modalClass={styles.modalWidth} containerClass='my-6' showModal={showCIModal} setShowModal={setShowCIModal}>
            {selectedProfessional && <ShowCI setShowChangeStatusModal={setShowChangeStatusModal} professional={selectedProfessional} showInfo={true} />}
          </Modal>}
        {showSendProspectModal &&
          <Modal title={'Volver a enviar Prospecto'} modalClass={styles.modalWidth} containerClass='my-6' showModal={showSendProspectModal} setShowModal={setShowSendProspectModal}>
            {selectedProfessional && statusList && <SendProspect professional={selectedProfessional} setShowModal={setShowSendProspectModal} />}
          </Modal>}
        {/* {showSendCvpModal &&
          <Modal modalClass={styles.modalWidth} containerClass='my-6' title={selectedProfessional && !selectedProfessional.adscription_cvp_periodicity_id ? 'Ingresar CVP' : 'CVP Ingresado'} showModal={showSendCvpModal} setShowModal={setShowSendCvpModal}>
            {selectedProfessional && statusList && periodicityList && periodicityList.length && <SendCvp professional={selectedProfessional} periodicityList={periodicityList} setShowModal={setShowSendCvpModal} />}
          </Modal>} */}
        {
          showInfo &&
          <Modal modalClass={styles.modalWidth} containerClass='my-6' title="Información de cliente" showModal={showInfo} setShowModal={setShowInfo}>
            {
              selectedProfessional && <>
                <Info professional={selectedProfessional} showButton={selectedProfessional.adscription_status === 7} />
                {/* <ShowCI setShowChangeStatusModal={setShowChangeStatusModal} professional={selectedProfessional} /> */}
              </>
            }
          </Modal>
        }
        {
          showEditPlan && <Modal modalClass={styles.modalWidth} containerClass='my-6' title="Editar plan" showModal={showEditPlan} setShowModal={setShowEditPlan}>
            {
              selectedProfessional && <ChangePlan options={dataPlan} professional={selectedProfessional} setShowModal={setShowEditPlan} selectedProduct={selectedProduct} setRefresh={setRefresh} />
            }
          </Modal>
        }
      </>
    );
  };

  return (
    <>
      {/* <div className='w-full'>
        <div className="light">
          <h1>Indicadores de estados</h1>
        </div>
      </div> */}
      {renderCheckingInfo()}
      <div className='w-full'>
        {/* tabs */}
        {modalsToShow()}
        {
          (type === 'reporte') && <div className="bg-white">
            <h1 className="text-secondary-imed-60 text-title tracking-[0.1px] font-normal my-4 mt-0">Administrador de suscripciones</h1>
            <p className={`${styles.subtitle} pb-5`}>Seleccione el producto y administre la opción seleccionada</p>
            <hr />
            <div className="mt-5">
              <div className='flex justify-between items-center'>
                <Select
                  customClass={`${styles.z0} mb-4`}
                  customSize="l"
                  items={products}
                  name="selectName"
                  nameType="description"
                  onChange={(e) => triggerSearch(e)}
                  placeholder="Seleccione una opción"
                  title="Producto"
                />
                {
                  showFilters && (
                    <button
                      disabled={disabledFilter}
                      className={`p-3 bg-red-500 hover:bg-red-700 text-white text-sm rounded flex items-center ${disabledFilter ? 'opacity-50 cursor-not-allowed' : ''}`}
                      onClick={clearFilter}>
                      <XIcon size="s" svgClass="mr-2" pathClass="stroke-white" /> Limpiar filtro
                    </button>
                  )
                }
              </div>

              {
                showFilters ? (
                  <div className='grid grid-cols-4 gap-4 pt-2 mb-6'>
                    {statusList && <div className={styles.fullinput}>
                      <Select
                        customSize="m"
                        customClass={styles.z0}
                        items={statusList.filter(item => item.active).map(({ value, name }) => ({ value: value.toString(), name }))}
                        nameType="name"
                        onChange={(e) => { setShowLoading(true); setSelectedStatus(statusList.find(({ value }) => e == value)); setShowLoading(false); }}
                        placeholder="Seleccione una opción"
                        title="Estado"
                      />
                    </div>}

                    <div className={styles.fullinput}>
                      <Input
                        focused
                        name="name"
                        value={filterRut}
                        onChange={({ target: { value } }) => { setFilterRut(clearRut(value)); }}
                        placeholder="Ingresa un rut"
                        title="Rut"
                        type="text"
                      />
                    </div>
                    <div className={styles.fullinput}>
                      <Input
                        focused
                        name="name"
                        value={filterName}
                        onChange={({ target: { value } }) => { setFilterName(value); }}
                        placeholder="Ingresa un nombre"
                        title="Nombre"
                        type="text"
                      />
                    </div>
                    <div className={styles.fullinput}>
                      <Input
                        focused
                        name="name"
                        value={filterCode}
                        onChange={({ target: { value } }) => { setFilterCode(value); }}
                        placeholder="Ingresa un código de lugar"
                        title="Código de lugar"
                        type="text"
                      />
                    </div>
                  </div>
                ) : <>
                  {
                    showLoading && <div className='grid grid-cols-4 gap-4 pt-2 mb-6'>
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                      <Skeleton />
                    </div>
                  }
                </>
              }
              {
                (data && !showLoading) ? (
                  <div>
                    <h4 className='text-base font-semibold my-10'>
                      Resultados de la búsqueda: <span className='text-blue-800'>{selectedProduct?.description}</span>
                    </h4>
                    <DataTable2
                      columns={columns}
                      data={data}
                      disableHeader
                      orderBy="adscription_status_changed_at"
                      perPage={10}
                      totalResults={data && data.length ? data.length : 0}
                      sortBy="desc"
                      colorStyle="neutral"
                    />
                  </div>
                ) : (
                  <div className='w-full border flex flex-col p-20 justify-center items-center'>
                    <InfoIcon size="m" />
                    <p className='mt-2'>Todavía no selecciona un producto para administrar</p>
                  </div>
                )
              }
            </div>
          </div>
        }

        {
          (type === 'plans') &&
          <div className="bg-white p-6">
            <h1 className="text-secondary-imed-60 text-title tracking-[0.1px] font-normal my-4 mt-0">Administrador de contratos</h1>
            <p className={`${styles.subtitle} pb-5`}>Seleccione el producto y administre la opción seleccionada</p>
            <hr />
            <div className="mt-5">
              <div className='flex justify-between items-center'>
                <Select
                  customClass={`${styles.z0} mb-4`}
                  customSize="l"
                  items={products}
                  name="selectName"
                  nameType="description"
                  onChange={(e) => triggerPlanSearch(e)}
                  placeholder="Seleccione un producto"
                  title="Producto"
                />
              </div>
            </div>
            <AdminPlans type={selectedPlanProduct} />
          </div>
        }

        {
          (type === 'status') &&
          <div className="bg-white p-6">
            <h1 className="text-secondary-imed-60 text-title tracking-[0.1px] font-normal my-4 mt-0">Administrador de contratos</h1>
            <p className={`${styles.subtitle} pb-5`}>Seleccione el producto y administre la opción seleccionada</p>
            <hr />
            <div className="mt-5">
              <div className='flex justify-between items-center'>
                <Select
                  customClass={`${styles.z0} mb-4`}
                  customSize="l"
                  items={products}
                  name="selectName"
                  nameType="description"
                  onChange={(e) => triggerPlanSearch(e)}
                  placeholder="Seleccione un producto"
                  title="Producto"
                />
              </div>
            </div>
            <AdminPlans type={selectedPlanProduct} />
          </div>
        }
      </div>
    </>);
}


export default Admin;
