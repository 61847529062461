import React, { useEffect, useState, ChangeEvent } from 'react';
import { Input, Select, WarningEmoji, UserHelp, Skeleton, LockEmoji } from '@imed_npm/design-system';
import { getBanks } from '../../../Form/Store/actions';
import { Bank, Account } from '../../../../utils/interfaces';
import { UseStep } from '../../Store/step-context';
import ToastMessage from '../../../../utils/ToastMessage';
import { compressFile } from '../../../../utils/fileCompressor'; // Asegúrate de que la ruta sea correcta
import styles from './styles.module.scss';

interface InputState {
  type: 'error' | 'success' | 'info' | 'warning' | 'default' | undefined;
  message: string;
}

const BankInfo = (): JSX.Element => {
  const { dispatch, state: { form: { rutRL }, steps: { step2, step22, step61 } } } = UseStep();

  const [loader, setLoader] = useState(true);

  const [banks, setBanks] = useState<Bank[]>([]);
  const [selectBank, setSelectBank] = useState<Bank>();

  const [accounts, setAccounts] = useState<Account[]>();
  const [selectAccount, setSelectedAccount] = useState<Account>();

  const [naccount, setNaccount] = useState<string>();
  const [validateNumber, setValidate] = useState<string>();

  const [defaultBank, setDefaultBank] = useState<{ id: number, name: string }>();
  const [defaultType, setDefaultType] = useState<{ id: number, name: string }>();

  const [nid, setNid] = useState<string>();
  const [nidRL, setNidRL] = useState<string>();

  const [selectedFile, setSelectedFile] = useState<string>('');

  const [value, setValue] = useState({
    id: 2,
    name: `RUT (${nid})`
  });

  const accountValidation = (e): InputState => {
    if (e && e.length < 3) return { type: 'error', message: 'N° de cuenta debe tener al menos 3 dígitos' };

    return { type: 'default', message: '' };
  };

  const serialState = (e): InputState => {
    if (validateNumber !== naccount) return { type: 'error', message: 'N° de cuenta es distinto' };

    return { type: 'default', message: '' };
  };

  useEffect(() => {
    if (step2) {
      setNid(step2.nid);
    }
  }, [step2]);

  useEffect(() => {
    if (step22) {
      setNidRL(step22.nid);
    }
  }, [step22]);

  const handleSelectOption = (e) => {
    setValue(e);
    dispatch({ type: 'SET_BUSINESS_RUT_TYPE', payload: e });
  };

  useEffect(() => {
    if (rutRL) {
      //@ts-ignore
      setValue(rutRL);
    }
  }, [rutRL]);

  useEffect(() => {
    if (step61) {
      const { bankList } = step61;
      if (bankList.length < 1) {
        getBanks().then((e) => {
          setBanks(e);
          dispatch({ type: 'SET_BANK_LIST', payload: e });
        });
      } else {
        setBanks(step61.bankList);
      }
    }
  }, [step61]);

  useEffect(() => {
    if (!nid) return;
    if (nid === nidRL) {
      const send = {
        id: 1,
        name: `Empresa (${nid})`
      };
      dispatch({ type: 'SET_BUSINESS_RUT_TYPE', payload: send });
    }
  }, [nid, nidRL]);

  useEffect(() => {
    if (step61 && step61.bankSelected && step61.bankSelected.type.id === 0) {
      if (banks && banks.length > 0) {
        setLoader(false);
      }
    }
  }, [step61, banks]);

  useEffect(() => {
    if (step61 && step61.bankSelected) {
      setNaccount(step61.bankSelected.number);
    }
  }, [step61]);

  useEffect(() => {
    if (step61 && step61.bankSelected && step61.bankSelected.bank.id !== 0) {
      setDefaultBank(step61.bankSelected.bank);
    }
  }, [step61]);

  useEffect(() => {
    if (defaultBank && accounts) {
      if (step61 && step61.bankSelected && step61.bankSelected.type) {
        setDefaultType(step61.bankSelected.type);
        setLoader(false);
      }
    }
  }, [defaultBank, accounts, step61]);

  useEffect(() => {
    if (step61 && step61.bankSelected) {
      setValidate(step61.bankSelected.validate);
    }
  }, [step61]);

  useEffect(() => {
    if (selectBank) {
      const { bank_account_types }: any = (selectBank);
      setAccounts(bank_account_types);
      dispatch({ type: 'STORE_BANK', payload: selectBank });
    } else {
      if (defaultBank) {
        const { bank_account_types }: any = (defaultBank);
        setAccounts(bank_account_types);
        dispatch({ type: 'STORE_BANK', payload: defaultBank });
      }
    }
  }, [selectBank, defaultBank]);

  useEffect(() => {
    if (selectAccount) {
      dispatch({ type: 'STORE_BANKACCOUNT_TYPE', payload: selectAccount });
    }
  }, [selectAccount]);

  useEffect(() => {
    if (naccount === undefined) return;
    dispatch({ type: 'STORE_BANKACCOUNT_NUMBER', payload: naccount });
  }, [naccount]);

  useEffect(() => {
    if (validateNumber === undefined) return;
    dispatch({ type: 'STORE_BANKACCOUNT_VALIDATE', payload: validateNumber });
  }, [validateNumber]);

  useEffect(() => {
    if (step61 && step61.file && selectedFile === '') {
      setSelectedFile('Archivo adjunto');
    }
  }, [step61]);

  const remove = () => {
    setSelectedFile('');
    dispatch({ type: 'STORE_BANK_FILE', payload: '' });
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && (file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/png')) {
      const { name } = file;
      try {
        const result = await compressFile(file);
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64String = reader.result as string;
          const base64WithoutHeader = base64String.substring(base64String.indexOf(',') + 1);
          dispatch({ type: 'STORE_BANK_FILE', payload: base64WithoutHeader });
          setSelectedFile(name);
        };

        reader.readAsDataURL(result.file);
      } catch (error) {
        ToastMessage.enviarToastError('Error al comprimir el archivo:', error);
      }
    } else {
      ToastMessage.enviarToastError('Por favor, seleccione un archivo de tipo .jpg, .png o .pdf');
    }
  };

  const renderInfoBank = (): JSX.Element => {
    return (
      <>
        <p className={`mb-6 ${styles.subtitle}`}>{(nidRL && (nidRL !== nid)) ? 'Selecciona el Rut del contratante e introduce' : 'Introduce'} los datos de tu cuenta corriente para que I-med S.A. <strong>pueda ingresar los pagos de las atenciones médicas prestados a través de su plataforma.</strong></p>
        {
          (nidRL && (nidRL !== nid)) ? (
            <Select
              customClass=""
              customSize="m"
              items={[
                {
                  id: 1,
                  name: `Empresa (${nid})`
                },
                {
                  id: 2,
                  name: `Representante legal (${nidRL})`
                }
              ]}
              name="selectName"
              nameType="name"
              onChange={handleSelectOption}
              value={value}
              placeholder="Seleccione un rut"
              title="Rut"
            />
          ) :
            <Input
              name="name"
              className='p-2 text-sm'
              title="Rut"
              type="text"
              value={nid}
              disabled
            />
        }
        <div className="max-w-[100%]">
          <UserHelp
            colorStyle="warning"
            icon={<WarningEmoji svgClass="h-10 w-10" />}
            text={<div className={styles.blocktext}>
              <p>
                El proveedor autoriza a I-med S.A a realizar ingresos en la cuenta corriente indicada. Por favor, asegúrate de que la información introducida es correcta para evitar cualquier problema.
                <strong>&nbsp;I-med S.A no será responsable de los pagos realizados a una cuenta corriente incorrecta.</strong></p></div>}
          />
        </div>
      </>
    );
  };

  const renderAccountBankData = (): JSX.Element => {
    return (
      <>
        <div className='grid grid-cols-3 gap-6 pt-2 mb-6'>
          <div className={styles.fullinput}>
            <Select
              customClass=""
              customSize="m"
              items={banks}
              name="selectName"
              nameType="name"
              onChange={(value) => { setSelectBank(value); }}
              value={defaultBank}
              placeholder="Seleccione entidad bancaria"
              title="Banco"
            />
          </div>
          <div className={styles.fullinput}>
            <Select
              customClass=""
              customSize="m"
              items={accounts}
              value={defaultType}
              name="selectName"
              nameType="name"
              onChange={(value) => { setSelectedAccount(value); }}
              placeholder="Seleccione"
              title="Tipo de cuenta"
            />
          </div>
          <div className={styles.fullinput}>
            <Input
              focused
              name="account"
              placeholder="Ingresar"
              title="N° de cuenta"
              type="number"
              value={naccount}
              onChange={({ target: { value } }) => setNaccount(value)}
              stateMessage={accountValidation(naccount).message}
              stateType={accountValidation(naccount).type}
            />
          </div>
        </div>
        {renderCheckAccountNumber()}
        <div className="max-w-[100%]">
          <UserHelp
            colorStyle="info"
            icon={<LockEmoji svgClass="h-10 w-10" />}
            text={<p>Para fortalecer la seguridad del procedimiento, te solicitamos que adjuntes un
              <strong>&nbsp;(Encabezado de la cartola bancaria o copia de un cheque)</strong>. <u>Formatos admitidos JPG, PNG, o PDF con un máximo 10 MB.</u></p>}
          />
        </div>

        {/* file */}
        {(selectedFile === '') ? <div className='flex justify-center p-4'>
          <input type="file" accept=".pdf, .png, .jpg, .jpeg" className={`${styles.custom_file_input}`} onChange={handleFileUpload} />
        </div> : <div className="mt-5 border p-4">
          <div className='flex w-full justify-between items-center'>
            <div className='flex items-center'>
              <p><small>{selectedFile}</small></p>
              <img className='ml-2' src="images/plan-standar.svg" />
            </div>
            <a type="button" onClick={remove} className={styles.removeFile}>Quitar documento</a>
          </div>
        </div>}
      </>
    );
  };

  const renderCheckAccountNumber = (): JSX.Element => {
    return (
      <div className={styles.singleinput}>
        <Input
          focused
          name="repeat"
          placeholder="Ingresar"
          title="Repetir N° de cuenta"
          type="number"
          value={validateNumber}
          onChange={({ target: { value } }) => setValidate(value)}
          stateMessage={serialState(validateNumber).message}
          stateType={serialState(validateNumber).type}
        />
      </div>
    );
  };

  return (
    <div>
      {renderInfoBank()}
      {loader ? <Skeleton
        borderColor="default"
        customClass=""
        customSize="m"
      /> : renderAccountBankData()}
    </div>
  );
};

export default BankInfo;
