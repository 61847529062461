
import {
  checkNavigationStep1,
  checkNavigationStep21,
  checkNavigationStep22,
  checkNavigationStep3,
  checkNavigationStep4,
  checkNavigationStep5,
  checkNavigationEMStep5,
  checkNavigationEMStep55,
  checkNavigationEMStep6,
  checkNavigationStep6,
  checkNavigationStep8,
  checkNavigationFinal,
  checkNavigationStep1BE
} from './LayoutForm/utils';

export const FORM_STEPS = {
  step1: {
    headerTitle: 'Selección de perfil',
    headerSubtitle: 'PASO 1 DE 6',
    subtitleText: 'Usted es:',
    nextStep: 'step2',
    previousStep: '',
    checkNavigation: checkNavigationStep1
  },
  ['step2.1']: {
    headerTitle: 'Datos profesional',
    headerSubtitle: 'PASO 2 DE 6',
    subtitleText: 'Ingresa los siguientes datos',
    nextStep: 'step3',
    previousStep: 'step1',
    checkNavigation: ({ step2 }) => checkNavigationStep21(step2)

  },
  ['step2.2']: {
    headerTitle: 'Datos cliente empresa',
    headerSubtitle: 'PASO 2 DE 6',
    subtitleText: 'Ingresa los datos de cliente y los de representante legal:',
    nextStep: 'step3',
    previousStep: 'step1',
    checkNavigation: (steps) => checkNavigationStep22(steps)
  },
  step3: {
    headerTitle: 'Validación de contacto',
    headerSubtitle: 'PASO 3 DE 6',
    subtitleText: 'Ingresa los siguientes datos',
    nextStep: 'step4',
    previousStep: 'step2',
    checkNavigation: checkNavigationStep3
  },
  step4: {
    headerTitle: 'Lugar de atención médica',
    headerSubtitle: 'PASO 4 DE 6',
    subtitleText: '¿En qué lugar atenderá a sus pacientes?',
    nextStep: 'step5',
    previousStep: 'step3',
    checkNavigation: checkNavigationStep4
  },
  step5: {
    headerTitle: 'Plan',
    headerSubtitle: 'PASO 5 DE 6',
    subtitleText: '¿Cuántos lectores de huella digital necesita?',
    nextStep: 'step6',
    previousStep: 'step4',
    checkNavigation: checkNavigationStep5
  },
  step6: {
    headerTitle: 'Firma de contrato',
    headerSubtitle: 'PASO 6 DE 6',
    subtitleText: 'Contrato de adhesión',
    nextStep: 'endScreen',
    previousStep: 'step5',
    checkNavigation: checkNavigationStep6
  },
  endScreen: {
    headerTitle: 'Paso final',
    headerSubtitle: 'Gracias por contratar',
    subtitleText: 'Muchas Gracias',
    checkNavigation: () => ({ backDisable: true, nextDisable: true }),
  },
};

export const FORM_STEPS_EM = {
  step1: {
    headerTitle: 'Selección de perfil',
    headerSubtitle: 'PASO 1 DE 8',
    subtitleText: 'Usted es:',
    nextStep: 'step2',
    previousStep: '',
    checkNavigation: checkNavigationStep1
  },
  ['step2.1']: {
    headerTitle: 'Datos profesional',
    headerSubtitle: 'PASO 2 DE 8',
    subtitleText: 'Ingresa los siguientes datos',
    nextStep: 'step3',
    previousStep: 'step1',
    checkNavigation: ({ step2 }) => checkNavigationStep21(step2)

  },
  ['step2.2']: {
    headerTitle: 'Datos cliente empresa',
    headerSubtitle: 'PASO 2 DE 8',
    subtitleText: 'Ingresa los datos de cliente y los de representante legal:',
    nextStep: 'step3',
    previousStep: 'step1',
    checkNavigation: (steps) => checkNavigationStep22(steps)
  },
  step3: {
    headerTitle: 'Validación de contacto',
    headerSubtitle: 'PASO 3 DE 8',
    subtitleText: 'Ingresa los siguientes datos',
    nextStep: 'step4',
    previousStep: 'step2',
    checkNavigation: checkNavigationStep3
  },
  step4: {
    headerTitle: 'Lugar de atención médica',
    headerSubtitle: 'PASO 4 DE 8',
    subtitleText: '¿En qué lugar atenderá a sus pacientes?',
    nextStep: 'step5',
    previousStep: 'step3',
    checkNavigation: checkNavigationStep4
  },
  step5: {
    headerTitle: 'Planes',
    headerSubtitle: 'PASO 5 DE 8',
    subtitleText: 'Selecciona el Plan y la cantidad de lectores de huella que mejor se adapte a tus necesidades ',
    nextStep: 'step6',
    previousStep: 'step4',
    checkNavigation: checkNavigationEMStep5
  },
  ['step5.5']: {
    headerTitle: 'Suscripción de Bono Electrónico',
    headerSubtitle: 'PASO 5 DE 8',
    subtitleText: 'Verifica que tienes convenio con Isapre o Fonasa',
    nextStep: 'step6',
    previousStep: 'step5',
    checkNavigation: checkNavigationEMStep55
  },
  step6: {
    headerTitle: 'Datos Bancarios',
    headerSubtitle: 'PASO 6 DE 8',
    subtitleText: 'Ingresa los datos bancarios del Rut contratante',
    nextStep: 'step7',
    previousStep: 'step5',
    checkNavigation: checkNavigationEMStep6
  },
  step7: {
    headerTitle: 'Suscripción a Escritorio Médico',
    headerSubtitle: 'PASO 7 DE 8',
    subtitleText: 'Ingresa tu método de pago',
    nextStep: 'step8',
    previousStep: 'step6',
    checkNavigation: () => ({ backDisable: false, nextDisable: false }),
  },
  step8: {
    headerTitle: 'Suscripción a Escritorio Médico',
    headerSubtitle: 'PASO 7 DE 8',
    subtitleText: 'Ingresa tu método de pago',
    nextStep: 'step9',
    previousStep: 'step7',
    checkNavigation: checkNavigationStep8
  },
  step9: {
    headerTitle: 'Firma de contrato',
    headerSubtitle: 'PASO 8 DE 8',
    subtitleText: 'Contrato de adhesión',
    previousStep: 'step6',
    checkNavigation: checkNavigationFinal
  },
  endScreen: {
    headerTitle: 'Paso final',
    headerSubtitle: 'Gracias por contratar algo',
    subtitleText: 'Muxa Gracias',
    checkNavigation: () => ({ backDisable: true, nextDisable: true }),
  },
};


export const FORM_STEPS_BE = {
  step1: {
    headerTitle: 'Datos cliente empresa',
    headerSubtitle: 'PASO 1 DE 7',
    subtitleText: 'Ingresa los datos de cliente y los de representante legal:',
    nextStep: 'step2',
    previousStep: '',
    checkNavigation: (steps) => checkNavigationStep1BE(steps)
  },
  step2: {
    headerTitle: 'Validación de contacto',
    headerSubtitle: 'PASO 2 DE 7',
    subtitleText: 'Ingresa los siguientes datos',
    nextStep: 'step3',
    previousStep: 'step1',
    checkNavigation: checkNavigationStep3
  },
  step3: {
    headerTitle: 'Lugar de atención médica',
    headerSubtitle: 'PASO 3 DE 7',
    subtitleText: '¿En qué lugar atenderá a sus pacientes?',
    nextStep: 'step4',
    previousStep: 'step2',
    checkNavigation: checkNavigationStep4
  },
  step4: {
    headerTitle: 'Lugar de atención médica',
    headerSubtitle: 'PASO 4 DE 7',
    subtitleText: '¿Cuántos lectores de huella digital necesita?',
    nextStep: 'step5',
    previousStep: 'step3',
    checkNavigation: () => ({ backDisable: false, nextDisable: false }),
  },
  step5: {
    headerTitle: 'Suscripción de Bono Electrónico',
    headerSubtitle: 'PASO 5 DE 7',
    subtitleText: 'Verifica que tienes convenio con Isapre o Fonasa',
    nextStep: 'step6',
    previousStep: 'step4',
    checkNavigation: checkNavigationEMStep55
  },
  step6: {
    headerTitle: 'Datos Bancarios',
    headerSubtitle: 'PASO 6 DE 7',
    subtitleText: 'Ingresa los datos bancarios del Rut contratante',
    nextStep: 'step7',
    previousStep: 'step5',
    checkNavigation: checkNavigationEMStep6
  },
  step7: {
    headerTitle: 'Firma de contrato',
    headerSubtitle: 'PASO 7 DE 7',
    subtitleText: 'Contrato de adhesión',
    previousStep: 'step6',
    checkNavigation: () => ({ backDisable: false, nextDisable: true }),
    // checkNavigation: checkNavigationFinal
  },
  endScreen: {
    headerTitle: 'Paso final',
    headerSubtitle: 'Gracias por contratar',
    subtitleText: 'Muchas Gracias',
    checkNavigation: () => ({ backDisable: false, nextDisable: true }),
  },
};